import { common } from '@mui/material/colors';
import { alpha, lighten, darken } from '@mui/material/styles';
import { error, info, neutral, success, warning, withAlphas } from '../colors';
import { getPrimary } from '../utils';

export const createPalette = (config) => {
  const { colorPreset, contrast ,primaryBrandColor,primaryTextColor,secondaryBrandColor,secondaryTextColor  } = config;
  // test theme
  const customPalette = withAlphas({
    lightest:primaryBrandColor ? lighten(primaryBrandColor, 0.9) : '#F5F8FF',
    light: primaryBrandColor ? lighten(primaryBrandColor, 0.7) : '#EBEFFF',
    main: primaryBrandColor || "#5862FF",
    dark: secondaryBrandColor ? darken(secondaryBrandColor,0.1): '#004EEB',
    darkest: primaryBrandColor ? darken(primaryBrandColor,0.2) : '#00359E',
    contrastText: "#FFFFFF",
  })
  // test theme ends
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[900], 0.38),
      disabledBackground: alpha(neutral[900], 0.12),
      focus: alpha(neutral[900], 0.16),
      hover: alpha(neutral[900], 0.04),
      selected: alpha(neutral[900], 0.12)
    },
    background: {
      default: contrast === 'high' ? neutral[50] : common.white,
      paper: common.white
    },
    divider: '#F2F4F7',
    error,
    info,
    mode: 'light',
    neutral,
    primary:primaryBrandColor ? customPalette : getPrimary(colorPreset),
    success,
    text: {
      primary: neutral[900],
      secondary: neutral[500],
      disabled: alpha(neutral[900], 0.38)
    },
    warning
  };
};