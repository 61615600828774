import { ArrowBackOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { v4 as uuidv4 } from "uuid";

import React, { useContext, useEffect, useState } from "react";
import { API_ADMIN_SERVICE_BACKEND, API_SERVICE_BACKEND } from "src/config";
import toast from "react-hot-toast";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { copyToClipboard } from "src/utils/misc";

const CreateUser = ({ setOpen, open, getAllWhiteLabelClients }) => {
  const theme = useTheme();
  const { whiteLableData } = useContext(WhiteLabelConfigurationContext);
  const handleClose = () => {
    setOpen({ open: false, data: null });
  };
  const [loading, setLoading] = useState(false);

  const [userProviderData, setUserProviderData] = useState({
    name: open.data?.name || "",
    email: open.data?.email || "",
    password: "",
    // confirm_password: "",
  });
  const [createUserError, setCreateUserError] = useState("");

  useEffect(() => {
    if (open?.data) {
      setUserProviderData({
        name: open.data?.name,
        email: open.data?.email,
      });
    } else {
      setUserProviderData({
        name: "",
        email: "",
        password: "",
        // confirm_password: "",
      });
    }
  }, [open?.data]);

  const generatePassword = (length = 8) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const handleGeneratePassword = () => {
    const newPassword = generatePassword(12);
    setUserProviderData((prev) => ({
      ...prev,
      password: newPassword,
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserProviderData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleCreateOrUpdate = async () => {
    const { email, password } = userProviderData;
    setLoading(true);
    // Validation for edit
    if (open.data) {
      // if (!name.trim()) {
      //   toast.error("Name is required");
      //   return;
      // }

      try {
        const requestData = {
          email,
          whiteLabelUserId: open?.data?.whiteLabelUserId,
        };

        const url = `${API_SERVICE_BACKEND}/updateUserWhiteLabel/${open?.data?._id}`;
        const method = "PUT";

        const response = await fetch(url, {
          method,
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestData),
        });

        const data = await response.json();
        if (response.ok) {
          setLoading(false);
          getAllWhiteLabelClients();
          toast.success(data.message || "User updated successfully!");
          setOpen({ open: false, data: null });
        } else {
          setLoading(false);
          toast.error(data.error || "Failed to update user.");
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error updating user.");
      }
      return;
    }

    // Validation for create
    // if (!name.trim()) {
    //   toast.error("Name is required");
    //   return;
    // }
    if (!email.trim()) {
      toast.error("Email is required");
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      setLoading(false);
      return;
    }

    if (!password.trim()) {
      toast.error("Password is required");
      setLoading(false);
      return;
    }

    if (password.length < 6) {
      toast.error("Password must be at least 6 characters long");
      setLoading(false);
      return;
    }

    // if (!confirm_password.trim()) {
    //   toast.error("Confirm password is required");
    //   return;
    // }

    // if (password !== confirm_password) {
    //   toast.error("Passwords do not match");
    //   return;
    // }

    // Create user
    try {
      const requestData = {
        name: email.split("@")[0],
        email,
        password,
        whiteLabelUserId: whiteLableData._id,
        organization_id: String(`${Date.now()}_${uuidv4()}`),
      };

      const url = `${API_SERVICE_BACKEND}/createUserWhiteLabel`;
      const method = "POST";

      const response = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });

      const data = await response.json();
      if (response.ok) {
        setLoading(false);
        getAllWhiteLabelClients();
        toast.success("User created successfully!");
        setOpen({ open: false, data: null });
        setUserProviderData({
          // name: "",
          email: "",
          password: "",
          // confirm_password: "",
        });
      } else {
        setLoading(false);
        // setCreateUserError(data.error);
        toast.error(data.error);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <Dialog open={open.open} maxWidth="xs" onClose={handleClose}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">
          {open.data ? "Edit User" : "Create New User"}
        </Typography>
        <IconButton edge="start" color="inherit" onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <form>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <Box>
                <FormLabel
                  sx={{
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "18px",
                    color: "#28287B",
                  }}
                >
                  Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <TextField
                  name="name"
                  placeholder="John"
                  value={userProviderData.name}
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                />
              </Box>
            </Grid> */}
            {open.data ? (
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <FormLabel
                    sx={{
                      fontSize: "14px",
                      fontWeight: 500,
                      lineHeight: "18px",
                      color: theme.palette.text.primary
                    }}
                  >
                    Email
                  </FormLabel>
                  <TextField
                    name="email"
                    value={userProviderData.email}
                    variant="outlined"
                    fullWidth
                    onChange={handleChange}
                    className="glass-input"
                  />
                </Stack>
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <FormLabel
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "18px",
                        color: theme.palette.text.primary
                      }}
                    >
                      Email <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <TextField
                      name="email"
                      placeholder="xyz@example.com"
                      value={userProviderData.email}
                      variant="outlined"
                      fullWidth
                      required
                      onChange={handleChange}
                      className="glass-input"
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FormLabel
                        sx={{
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "18px",
                          color: theme.palette.text.primary
                        }}
                      >
                        Password <span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <Button
                        variant="outlined"
                        style={{
                          fontSize: "14px",
                          fontWeight: 500,
                          lineHeight: "18px",
                          cursor: "pointer",
                        }}
                        onClick={handleGeneratePassword}
                        className="glass-button-secondary-50"
                      >
                        Auto Generate
                      </Button>
                    </Box>
                    <TextField
                      name="password"
                      placeholder="Password"
                      value={userProviderData.password}
                      variant="outlined"
                      className="glass-input"
                      fullWidth
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <ContentCopyRoundedIcon
                              fontSize="small"
                              sx={{
                                cursor: "pointer",
                                color: theme => theme.palette.text.primary,
                                marginLeft: "10px",
                                "&:hover": {
                                  color: "#5761FE",
                                },
                              }}
                              onClick={async () => {
                                if (
                                  await copyToClipboard(
                                    userProviderData.password
                                  )
                                ) {
                                  toast.success("Password copied to clipboard");
                                } else {
                                  toast.error("Something went wrong");
                                }
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>
                  <Typography sx={{ color: "red", fontSize: "12px" }}>
                    {createUserError}
                  </Typography>
                </Grid>
                {/* <Grid item xs={12}>
                  <Box>
                    <FormLabel
                      sx={{
                        fontSize: "14px",
                        fontWeight: 700,
                        lineHeight: "18px",
                        color: "#28287B",
                      }}
                    >
                      Confirm password <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <TextField
                      name="confirm_password"
                      placeholder="Confirm password"
                      value={userProviderData.confirm_password}
                      variant="outlined"
                      fullWidth
                      onChange={handleChange}
                    />
                  </Box>
                </Grid> */}
              </>
            )}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 2,
                  width: "100%",
                }}
              >
                <Button
                  onClick={handleCreateOrUpdate}
                  variant="contained"
                  // loading={loading}
                  disabled={loading}
                  loadingPosition="start"
                  sx={{
                    width: "100%",
                    flexGrow: 1,
                    textTransform: "none",
                  }}
                >
                  {loading && <CircularProgress size={20} mr={1}/>}
                  {open.data ? "Update" : "Create"}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateUser;
