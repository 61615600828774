import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import moment1 from "moment-timezone";
import moment from "moment/moment";


export const timeslot = [
    "9:00AM",
    "9:45AM",
    "10:30AM",
    "11:15AM",
    "12:00PM",
    "12:45PM",
    "01:30PM",
    "02:15PM",
    "03:00PM",
    "03:45PM",
    "04:30PM",
    "05:15PM",
  ];

  export const meetKeywords = [
    "schedule",
    "meet",
    "meeting",
    "connect",
    "representative",
    "reach",
    "SHOW",
  ];

export const TypingEffect = ({ text, videoRef }) => {
    const [displayText, setDisplayText] = useState("");
    useEffect(() => {
      const typingInterval = 70; // Adjust typing speed
      const words = text.split(" ");
      let currentIndex = 0;
      const typingAnimation = setInterval(() => {
        const currentWord = words[currentIndex];
        currentWord !== undefined &&
          setDisplayText((prevText) => prevText + " " + currentWord);
        currentIndex += 1;
  
        if (currentIndex > words.length) {
          clearInterval(typingAnimation);
        }
        if (videoRef) {
          videoRef.current.scrollTop = videoRef.current.scrollHeight;
        }
      }, typingInterval);
  
      return () => clearInterval(typingAnimation);
    }, [text, videoRef.current]);

    // Clean up the input text by removing unwanted <b></b> tags and ensuring HTML is rendered correctly
    const cleanText = displayText.replace(/<b><\/b>/g, '').replace(/<b>/g, '').replace(/<\/b>/g, '');
  
    // Use dangerouslySetInnerHTML to render HTML tags correctly
    return <span dangerouslySetInnerHTML={{ __html: cleanText }} />;
  };

  export const LimitedSlotText = (props) => {
    const { timezone, handleClickOpen } = props;
    const [browserWidth, setBrowserWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => {
        setBrowserWidth(window.innerWidth);
      };
      setBrowserWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [browserWidth]);
  
    return (
      
        <p
        style={{
          color: "#111927",
          fontWeight: "400",
          marginBottom:'25px'
        }}
        >

          Feel free to book a meeting with our team or visit our website using the buttons below.
        </p>
    );
  };

  const filterTimeslot = (selectedDate, timezone, stateValue) => {
    const date = moment().format("l");
    const newdate = selectedDate.format("l");
    if (
      new Date(date).getDay() === 6 ||
      new Date(date).getDay() === 0 ||
      new Date(newdate).getDay() === 6 ||
      new Date(newdate).getDay() === 0
    ) {
      return;
    }
    const currentTimeMoment = moment1().tz(timezone);
    // let HourTimeslots = []
    
    if (date === newdate) {
      const filteredTimeslot = timeslot.filter((time) => {
        const timeMoment = moment(time, "h:mmA");
        return timeMoment.isSameOrAfter(currentTimeMoment);
      });
      stateValue(filteredTimeslot);
    } else {
      // timeslot.forEach((slot, index) => {
      //   if (index % 2 === 1) {
      //     // Only consider odd-indexed timeslots (e.g., 9:30AM, 10:30AM, etc.)
      //     HourTimeslots.push(timeslot[index - 1]);
      //   }
      // });
      stateValue(timeslot);
    }
  };
  
  
  const limitedSlotText = (timezone, handleClickOpen) => {
    // const {} = options;
    return `We've limited slots available for a strategy session. Book a slot using this <a style="cursor:pointer" onclick="${handleClickOpen}" ><b>full calendar slots</b></a> or pick whichever slot is convenient for you from the options below.`;
  };