import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Switch,
  styled,
  useTheme
} from "@mui/material";
import { API_SERVICE_BACKEND } from "src/config";
import { getSessionStorage } from "src/utils/storage";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import toast from "react-hot-toast";

import UploadFiles from "src/components/whiteLable/UploadFiles";
import { uploadFileToAzureBlob } from "./uploadFileToAzureBlob";
import { debounce } from "lodash";
import { Seo } from "src/components/seo";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const BrandingManagement = () => {
  const theme = useTheme();
  const [formData, setFormData] = useState({
    companyName: "",
    logoURL: "",
    favIcon: "",
    description: "",
    title: "",
    tagLine: "",
    should_show_company_name: null,
    themeColors: {
      PrimaryTextColor: "#000000",
      SecondaryTextColor: "#6C737F",
      PrimaryBrandColor: "#5862ff", //"#004EEB",
      SecondaryBrandColor: "#5862ff",
    },
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFavFile, setSelectedFavFile] = useState(null);
  const [status, setStatus] = useState(false);
  const [dataStore, setDataStore] = useState(null);

  const { metaTitle, metaDescription, tagline, companyName } = useContext(
    WhiteLabelConfigurationContext
  );

  const email = getSessionStorage("userEmail");

  const { whiteLableId, getAllWhiteLabelClientConfigurations } = useContext(
    WhiteLabelConfigurationContext
  );

  const getAllWhiteLabelClients = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getwhiteLabelConfig/${whiteLableId}`
      );
      const data = await response.json();
      setSelectedFile(data?.data?.info?.logoUrl);
      setSelectedFavFile(data?.data?.info?.favIcon);
      setDataStore(data?.data ?? {});
      let updatedFormData = {
        description: data?.data?.seo?.description || metaDescription,
        tagLine: data?.data?.seo?.tagLine || tagline,
        title: data?.data?.seo?.title || metaTitle,
        companyName: data?.data?.info?.companyName || "",
        logoURL: data?.data?.info?.logoUrl || "",
        favIcon: data?.data?.info?.favIcon || "",
        should_show_company_name:
          data?.data?.metadata?.hasOwnProperty("should_show_company_name")
            ? data?.data?.metadata?.should_show_company_name
            : false,
  
        themeColors: {
          PrimaryTextColor: data?.data?.theme?.primaryTextColor || "#000000",
          SecondaryTextColor: data?.data?.theme?.secondaryTextColor || "#6C737F",
          PrimaryBrandColor: data?.data?.theme?.primaryBrandColor || "#5862ff", //"#004EEB",
          SecondaryBrandColor: data?.data?.theme?.secondaryBrandColor || "#5862ff",
        },
      };
      // Conditionally add supportcenter if it exists
      if (data?.data?.metadata?.hasOwnProperty("supportcenter")) {
        updatedFormData.supportcenter = data?.data?.metadata?.supportcenter;
      }
      setFormData(updatedFormData);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllWhiteLabelClients();
  }, [whiteLableId]);

  const handleSubmit = async () => {
    try {
      let uploadedLogoUrl, uploadedFavIconUrl;
      const now = new Date().toISOString();

      if (typeof selectedFile !== "string" && selectedFile) {
        const newName = `${now}_${selectedFile.name}`;
        uploadedLogoUrl = await uploadFileToAzureBlob(
          selectedFile,
          newName,
          "whitelableLogos"
        );
        // uploadedLogoUrl = await uploadFileToAzureBlob(selectedFile, "whitelableLogos");

        setFormData((prev) => ({ ...prev, logoURL: uploadedLogoUrl }));
      }

      if (typeof selectedFavFile !== "string" && selectedFavFile) {
        const newName = `${now}_${selectedFavFile.name}`;
        uploadedFavIconUrl = await uploadFileToAzureBlob(
          selectedFavFile,
          newName,
          "whitelableLogos"
        );
        // uploadedFavIconUrl = await uploadFileToAzureBlob(selectedFavFile, "whitelableLogos");

        setFormData((prev) => ({ ...prev, favIcon: uploadedFavIconUrl }));
      }

      let requestData = {
        email: email,
        seo: {
          description: formData?.description || metaDescription,
          tagLine: formData?.tagLine || tagline,
          title: formData?.title || metaTitle,
        },
        info: {
          companyName: formData?.companyName,
        },
        theme: {
          primaryTextColor: formData?.themeColors?.PrimaryTextColor,
          secondaryTextColor: formData?.themeColors?.SecondaryTextColor,
          primaryBrandColor: formData?.themeColors?.PrimaryBrandColor,
          secondaryBrandColor: formData?.themeColors?.SecondaryBrandColor,
        },
        metadata: {
          should_show_company_name: Boolean(formData?.should_show_company_name),
        },
      };
      if (formData?.hasOwnProperty("supportcenter")) {
        requestData.metadata.supportcenter = formData.supportcenter;
      }
      if (selectedFile) {
        requestData.info.logoUrl =
          typeof selectedFile === "string"
            ? formData?.logoURL
            : uploadedLogoUrl;
      }

      if (selectedFavFile) {
        requestData.info.favIcon =
          typeof selectedFavFile === "string"
            ? formData?.favIcon
            : uploadedFavIconUrl;
      }

      const response = await fetch(
        `${API_SERVICE_BACKEND}/handleWhiteLabelConfig`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        toast.success(
          response.message ?? "Theme updated/created successfully!",
          {
            autoClose: 3000,
          }
        );
        getAllWhiteLabelClients();
        getAllWhiteLabelClientConfigurations(whiteLableId);
      } else {
        toast.error(
          response.message ?? "There was an error updating the theme.",
          {
            autoClose: 3000,
          }
        );
      }
    } catch (error) {
      console.error("Error submitting theme data:", error);
    }
  };
  const debouncedSubmit = useCallback(
    debounce(() => setStatus(!status), 2000),
    [status]
  );
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    if (name === "should_show_company_name") {
      setFormData((prevData) => ({
        ...prevData,
        should_show_company_name: checked, // Use 'checked' for Switch
      }));
      debouncedSubmit();
      return () => debouncedSubmit.cancel();
    }

    if (name === "companyName" || name === "logoURL" || name === "favIcon") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      debouncedSubmit();
      return () => debouncedSubmit.cancel();
    } else if (name.startsWith("themeColors")) {
      setFormData((prevData) => ({
        ...prevData,
        themeColors: {
          ...prevData.themeColors,
          [name.split(".")[1]]: value,
        },
      }));
      debouncedSubmit();
      return () => debouncedSubmit.cancel();
    }
    debouncedSubmit();
    return () => debouncedSubmit.cancel();
  };

  useEffect(() => {
    if (dataStore) {
      handleSubmit();
    }
  }, [status]);

  const pageTitle = `Partner/White Label: Branding Management - ${
    metaTitle || companyName
  }`;

  return (
    <>
    <Seo title={pageTitle} />
    <Box sx={{width: {xs: "100%", md:"50%"}}}>
      {/* <Box sx={{ display: "flex", alignItems: "center", my: "20px" }}>
        <Typography sx={{ fontSize: "32px", fontWeight: 700, color: theme.palette.text.primary }}>
          Branding Management
        </Typography>
      </Box> */}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              required
              id="companyName"
              label="Company Name"
              variant="outlined"
              fullWidth
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className="glass-input"
            />
          </Grid>
          <Grid item xs={12} sx={{ marginBottom: "-10px  " }}>
            <Typography
              sx={{ fontSize: "18px", fontWeight: 700, color: theme.palette.text.primary }}
            >
              Logos
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography sx={{color: theme.palette.text.primary, mb: 1}}>Logo:</Typography>
            <UploadFiles
              setStatus={setStatus}
              debouncedSubmit={debouncedSubmit}
              status={status}
              setSelectedFile={setSelectedFile}
              selectedFile={selectedFile}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography sx={{color: theme.palette.text.primary, mb: 1}}>Favicon:</Typography>
            <UploadFiles
              setStatus={setStatus}
              debouncedSubmit={debouncedSubmit}
              status={status}
              setSelectedFile={setSelectedFavFile}
              selectedFile={selectedFavFile}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: "18px", fontWeight: 700, color: theme.palette.text.primary }}
            >
              Should show company name
            </Typography>
            <IOSSwitch
              inputProps={{
                name: "should_show_company_name",
                id: "should_show_company_name",
                "aria-label": "should_show_company_name",
              }}
              sx={{ m: 1 }}
              checked={formData?.should_show_company_name ?? false}
              // defaultChecked={
              //   menuState?.data?.accountStatus === 1
              // }
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{ fontSize: "18px", fontWeight: 700, color: theme.palette.text.primary }}
            >
              Brand Colors
            </Typography>
          </Grid>

          {Object.keys(formData.themeColors)?.slice(2)?.map((color, index) => (
            <Grid item xs={6} key={index}>
              <TextField
                required
                type="color"
                id={`themeColor${index}`}
                label={color.replace(/([A-Z])/g, " $1")}
                variant="outlined"
                fullWidth
                name={`themeColors.${color}`}
                value={formData.themeColors[color]}
                onChange={handleChange}
                className="glass-input"
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: "20px", width: "100%" }}
              onClick={() => {
                setSelectedFile(null);
                setSelectedFavFile(null);
                setFormData((prevData) => ({
                  ...prevData,
                  companyName: "",
                  logoURL: "",
                  favIcon: "",
                  themeColors: {
                    PrimaryTextColor: "#000000",
                    SecondaryTextColor: "#6C737F",
                    PrimaryBrandColor: "#5862ff", //"#004EEB",
                    SecondaryBrandColor: "#5862ff",
                  },
                }));
                debouncedSubmit();
                return () => debouncedSubmit.cancel();
              }}
            >
              Reset Theme
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default BrandingManagement;
