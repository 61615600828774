import React, { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  Stack,
  IconButton,
  Card,
  useTheme,
  Divider,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import toast from "react-hot-toast";

import AddPlans from "src/components/whiteLable/AddPlans";
import { API_SERVICE_BACKEND } from "src/config";
import { getSessionStorage } from "src/utils/storage";
import ConfirmDialog from "src/components/whiteLable/ConfirmDialog";
import activeIcon from "src/components/logos/activeIcon.png";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Seo } from "src/components/seo";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import PlanShow from "src/components/whiteLable/PlanShow";

const Plans = () => {
  const theme = useTheme();
  const [open, setOpen] = useState({
    open: false,
    data: null,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState({
    open: false,
    data: null,
  });

  const [plans, setPlans] = useState([]);
  const email = getSessionStorage("userEmail");

  // Fetch data from API to populate form
  const getAllWhiteLabelClients = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/plans?userEmail=${email}`
      );
      const data = await response.json();
      setPlans(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllWhiteLabelClients();
  }, [email]);

  const handleEdit = (data) => {
    setOpen({ open: true, data: data });
  };

  const handleDelete = async (data) => {
    const url = `${API_SERVICE_BACKEND}/plans/${data._id}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      if (response.ok) {
        getAllWhiteLabelClients();
        toast.success(data.message || "Delete plan successfully!");
        setOpenDeleteModal({ open: false, data: null });
      } else {
        toast.error(data.error || "Failed to connect");
      }
    } catch (error) {
      toast.error("Error submitting data");
    }
  };

  const numberFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
  });

  const keyLabelMap = {
    EMAIL_SENDING: "Email Sending",
    DATA_ENRICHMENT: "Data Enrichment",
    EMAIL_VALIDATION: "Email Validation",
    AI: "AI Features",
    MULTICHANNEL_OUTREACH: "Multichannel Outreach",
    TEAM_MEMBERS: "Team Members",
    DFY_DOMAIN: "DFY Domain",
  };

  const { metaTitle, companyName } = React.useContext(
    WhiteLabelConfigurationContext
  );
  const pageTitle = `Partner/White Label: Plans - ${metaTitle || companyName}`;

  return (
    <>
      <Seo title={pageTitle} />
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 700,
              color: theme.palette.text.primary,
            }}
          >
            Plans
          </Typography>
          <Button
            variant="contained"
            onClick={() => setOpen({ open: true, data: null })}
            sx={{
              marginLeft: "auto",
              color: "#ffffff",
              textTransform: "none",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            {" "}
            Add New Plan
          </Button>
        </Box>
        {plans?.length ? (
          <Grid
            container
            spacing="8px"
            sx={{
              paddingRight: "20px",
              paddingBottom: "25px",
              color: theme.palette.text.primary,
            }}
            style={{ marginTop: "0px" }}
          >
            {plans.map((plan, index) => {
              // Avoid naming conflict by renaming variables
              return (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={6}
                  md={4}
                  sx={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  <Card
                    className="sodhi glass-wrapper"
                    style={{ height: "100%" }}
                  >
                    <Stack
                      spacing={2}
                      style={{ padding: "20px 20px", height: "100%" }}
                    >
                      {/* Plan Name */}
                      <Box
                        sx={{
                          display: "flex",
                          mb: 2,
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h5"
                          sx={{
                            fontSize: "18px",
                            color: theme.palette.text.primary,
                          }}
                        >
                          {plan?.planName}
                        </Typography>
                        <Box>
                          <IconButton
                            onClick={() => handleEdit(plan)}
                            aria-label="settings"
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() =>
                              setOpenDeleteModal({ open: true, data: plan })
                            }
                            aria-label="settings"
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      </Box>

                      <Divider
                        sx={{ borderColor: "#ECE9F5", borderWidth: "1px" }}
                      />

                      {/* Monthly Credits List */}
                      <Stack spacing={2}>
                        {}
                        <PlanShow
                          value={plan?.features?.INTENT_DATA_TOPICS}
                          label="Intent Topics"
                        />
                        <PlanShow
                          value={plan?.monthlyCredits?.DATA_ENRICHMENT}
                          label="Data Enrichment"
                        />
                        <PlanShow
                          value={plan?.monthlyCredits?.EMAIL_VALIDATION}
                          label="Email Verification"
                        />
                        <PlanShow
                          value={plan?.monthlyCredits?.AI_WRITER}
                          label="AI Writer"
                        />
                        <PlanShow
                          value={plan?.features?.LINKEDIN_ACCOUNTS}
                          label="Multichannel Accounts"
                        />
                        <PlanShow
                          value={plan?.features?.TEAM_MEMBERS}
                          label="Team Members"
                        />
                        <PlanShow
                          value={plan?.features?.DFY_DOMAINS}
                          label="DFY Domains"
                        />
                        <PlanShow
                          value={plan?.features?.USER_MAILBOXES}
                          label="User Brought Mailboxes"
                        />
                        <PlanShow
                          value={plan?.features?.UNIFIED_INBOX}
                          label="Unibox"
                        />
                        <PlanShow
                          value={plan?.features?.DEAL_PIPELINES === 1 ? true : false}
                          label="Deal Pipeline"
                        />
                        <PlanShow
                          value={(plan?.features?.ZAPIER &&
                            plan?.features?.HUBSPOT &&
                            plan?.features?.ZOHO &&
                            plan?.features?.SALESFORCE) ? true : false }
                          label="Integrations"
                        />
                        <PlanShow
                          value={plan?.features?.ADVANCED_FILTERS}
                          label="Advanced Filters"
                        />
                        <PlanShow
                          value={plan?.features?.AI_AUTO_REPLIES}
                          label="AI Auto Replies"
                        />
                      </Stack>
                    </Stack>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          ""
        )}
        <AddPlans
          getAllWhiteLabelClients={getAllWhiteLabelClients}
          setOpen={setOpen}
          open={open}
        />
        <ConfirmDialog
          handleDelete={handleDelete}
          name={`  Are you sure you want to delete the plan "${openDeleteModal?.data?.planName}"`}
          setOpen={setOpenDeleteModal}
          open={openDeleteModal}
        />
      </Box>
    </>
  );
};

export default Plans;
