import React, { useState } from "react";
import "../style.css";
import {
  Typography,
  Stack,
  Button,
  Divider,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  Chip,
} from "@mui/material";
import { TbCalendar } from "react-icons/tb";
import { IoIosCheckmarkCircle } from "react-icons/io";

const StepItem = ({ stepTitle, stepText, stepContent, completed, deleted = false }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const handleClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Accordion
        expanded={expanded}
        sx={{
          "&.MuiAccordion-root": {
            backgroundColor: "transparent",
            boxShadow: "none",
            my: 0,
          },
          "&.MuiAccordion-root:before": { display: "none" },
        }}
       
      >
        <AccordionSummary
          sx={{
            px: 0,
            pointerEvents: "none",
            "&.MuiAccordionSummary-root .MuiAccordionSummary-content": {
              display: "flex",
              flexDirection: "column",
              gap: 3,
              m: 0,
            },
          }}          
        >
          <Stack direction="row" spacing={1}>
            <TbCalendar style={{fontSize: 22, color: theme.palette.primary.main }} />
            <Typography
              variant="h6"
              sx={{ fontSize: "16px", fontWeight: "700" }}
            >
              {stepTitle || "N/A"}
            </Typography>
            {Boolean(deleted) && <Chip size="small" label="Deleted" color="error" />}
          </Stack>

          <Stack direction="row" spacing={1} sx={{ padding: "0px 28px" }}>
            <IconButton
              sx={{ p: 0, height: "fit-content", pointerEvents: "auto" }}
              onClick={handleClick}
              disabled={!completed}
            >
              <IoIosCheckmarkCircle style={{color: completed ? theme.palette.primary.main : "#cdcdcd"}} />
            </IconButton>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 500 }}
            >
              {stepText}
            </Typography>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>{stepContent}</AccordionDetails>
      </Accordion>
    </>
  );
};

export default StepItem;
