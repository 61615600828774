import PropTypes from "prop-types";
import { withAuthGuard } from "src/hocs/with-auth-guard";
import { useSettings } from "src/hooks/use-settings";
import { useSections } from "./config";
import { HorizontalLayout } from "./horizontal-layout";
import { VerticalLayout } from "./vertical-layout";
import { useContext, useEffect, useState } from "react";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import { getSessionStorage } from "src/utils/storage";

export const Layout = withAuthGuard((props) => {
  const settings = useSettings();
  const sections = useSections();
  const { whiteLableStatus ,getUserDetails} = useContext(WhiteLabelConfigurationContext);
  const email = getSessionStorage("userEmail");

  useEffect(() => {
    getUserDetails();
  }, [email]);
  const filteredSections = sections.filter((section) => {
    if (section?.subheader === "White Label" && whiteLableStatus) {
      return true;
    }
    return section?.subheader !== "White Label" || whiteLableStatus;
  });

  if (settings.layout === "horizontal") {
    return (
      <HorizontalLayout
        sections={filteredSections}
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      sections={filteredSections}
      navColor={settings.navColor}
      {...props}
    />
  );
});

Layout.propTypes = {
  children: PropTypes.node,
};
