import React from "react";
import { GlobalStyles as MuiGlobalStyles, useTheme, alpha } from "@mui/material";

const GlobalStyles = ({ mode, isGlassmorphismOn = false }) => {
  const theme = useTheme();
  const borders = {
    // borderTop: "1px solid rgba(255, 255, 255, 0.4) !important",
    // borderRight: "1px solid rgba(255, 255, 255, 0) !important",
    // borderBottom: "1px solid rgba(255, 255, 255, 0) !important",
    // borderLeft: "1px solid rgba(255, 255, 255, 0.3) !important",
    border: "none !important",
    boxShadow: "-1px -1px 0px 0px rgba(255, 255, 255, 0.3) !important"
  };
  const defaultStyles = {
    ".MuiButton-root": {
      borderRadius: "6px !important"
    },
    ".MuiButton-root.Mui-disabled": {
      opacity: 0.5
    },
    ".MuiDivider-root": {
      borderColor: isGlassmorphismOn ? "rgba(224, 224, 229, 0.3) !important" : "rgba(224, 224, 229, 1) !important"
    },
    ".page-header": {
      color: `${theme.palette.text.primary} !important`,
      fontWeight: "600 !important",
      fontSize: "24px !important",
      marginTop: "12px !important"
    },
    ".DraftEditor-root": {color: theme.palette.text.primary},
    "::-webkit-scrollbar": {
      width: "14px",
      height: "14px",
    },
    "::-webkit-scrollbar-track": {
      borderRadius: "60px",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "#E4E4E5",
      borderRadius: "10px",
      border: "4px solid rgba(0, 0, 0, 0)",
      backgroundClip: "padding-box",
    },
    "::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#d5d5d5",
    },
  };
  const commonStyles = {
    ...defaultStyles,
    ".MuiTableRow-root:last-child .MuiTableCell-root": {
      border: "none"
    },
    ".ql-toolbar .ql-stroke": {
      fill: "none",
      stroke: theme.palette.text.primary,
    },
    ".ql-toolbar .ql-fill": {
      fill: theme.palette.text.primary,
      stroke: "none",
    },
    ".ql-toolbar .ql-picker": {
      color: theme.palette.text.primary,
    },
    ".glass-link": {
      color: `${theme.palette.text.primary} !important`,
      "&:hover": {
        textDecoration: "underline !important"
      }
    },
    ".glass-chip": {
      background: `rgba(250, 250, 250, 1) !important`,
      color: `rgba(65, 70, 81, 1) !important`,
      border: "1px solid rgba(233, 234, 235, 1) !important",
      borderRadius: "16px",
      padding: "2px 8px"
    },
    ".glass-chip-primary": {
      background: `${alpha(theme.palette.primary.main, 0.5)} !important`,
      border: `1px solid ${alpha(theme.palette.primary.main, 0.5)} !important`,
      color: `${theme.palette.primary.contrastText} !important`,
      borderRadius: "16px",
      padding: "2px 8px",
      backdropFilter: "blur(100px)",
      "-webkit-backdrop-filter": "blur(100px)",
    },
    ".glass-chip-warning": {
      background: `rgba(255, 250, 235, 1) !important`,
      border: `1px solid rgba(254, 223, 137, 1) !important`,
      color : "rgba(181, 71, 8, 1) !important",
      borderRadius: "16px",
      padding: "2px 8px",
      backdropFilter: "blur(100px)",
      "-webkit-backdrop-filter": "blur(100px)",
    },
    ".glass-chip-error": {
      background: `rgba(240, 68, 56, 0.5) !important`,
      border: `1px solid rgba(240, 68, 56, 0.5) !important`,
      color: "rgba(254, 205, 202, 1) !important",
      borderRadius: "16px",
      padding: "2px 8px",
      backdropFilter: "blur(100px)",
      "-webkit-backdrop-filter": "blur(100px)",
    },
    ".glass-chip-success": {
      background: `rgba(23, 178, 106, 0.5) !important`,
      border: `1px solid rgba(23, 178, 106, 0.5) !important`,
      color: "rgba(171, 239, 198, 1) !important",
      borderRadius: "16px",
      padding: "2px 8px",
      backdropFilter: "blur(100px)",
      "-webkit-backdrop-filter": "blur(100px)",
    },
    ".glass-chip-disabled": {
      background: `rgba(172, 172, 172, 0.5) !important`,
      border: `1px solid rgba(172, 172, 172, 0.5) !important`,
      color: "rgb(216, 216, 216) !important",
      borderRadius: "16px",
      padding: "2px 8px",
      backdropFilter: "blur(100px)",
      "-webkit-backdrop-filter": "blur(100px)",
    },
    ".chatpage-paper .MuiPaper-root, .chatpage-paper .MuiAutocomplete-popper .MuiPaper-root": {
      backgroundColor: `#fff !important`,
    },

  }
  const lightStyles = {
    ".glass-wrapper": {
      // background: `linear-gradient(87.84deg, rgba(255, 255, 255, 0.48) 45.4%, rgba(255, 255, 255, 0.24) 100.57%) !important`,
      // boxShadow: "0 1px 20px 0 rgba( 0, 0, 0, 0.1 )",
      // backdropFilter: "blur( 24px ) !important",
      // "-webkit-backdrop-filter": "blur( 24px ) !important",
      // borderRadius: "12px !important",
      backgroundColor: "rgba( 255, 255, 255, 0.25 ) !important",
      boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
      backdropFilter: "blur( 4px )",
      "-webkit-backdrop-filter": "blur( 4px )",
      borderRadius: "8px !important",
      ...borders,
    },
    ".glass-wrapper-50": {
      //background: `linear-gradient(87.84deg, rgba(255, 255, 255, 0.25) 45.4%, rgba(255, 255, 255, 0.15) 100.57%) !important`,
      backgroundColor: "rgba( 255, 255, 255, 0.25 ) !important",
      boxShadow: "0 1px 20px 0 rgba( 0, 0, 0, 0.1 )",
      borderRadius: "8px !important",
      ...borders,
    },
    ".glass-wrapper-25": {
      //background: `linear-gradient(87.84deg, rgba(255, 255, 255, 0.15) 45.4%, rgba(255, 255, 255, 0.05) 100.57%) !important`,
      backgroundColor: "rgba( 255, 255, 255, 0.25 ) !important",
      boxShadow: "0 1px 20px 0 rgba( 0, 0, 0, 0.1 )",
      borderRadius: "8px !important",
      ...borders,
    },
    ".glass-button-secondary": {
      background: `rgba( 255, 255, 255, 0.2 ) !important`,
      backdropFilter: "blur(100px)",
      "-webkit-backdrop-filter": "blur(100px)",
      borderRadius: "6px !important",
      ...borders,
      ":hover": {
        background: `rgba( 255, 255, 255, 0.4 ) !important`,
      },
    },
    ".glass-button-secondary-50": {
      background: `rgba( 255, 255, 255, 0.2 ) !important`,
      backdropFilter: "blur(100px)",
      "-webkit-backdrop-filter": "blur(100px)",
      borderRadius: "6px !important",
      ...borders,
      ":hover": {
        background: `rgba( 255, 255, 255, 0.4 ) !important`,
      },
    },
    ".glass-button-secondary-25": {
      background: `rgba( 255, 255, 255, 0.2 ) !important`,
      backdropFilter: "blur(100px)",
      "-webkit-backdrop-filter": "blur(100px)",
      borderRadius: "6px !important",
      ...borders,
      ":hover": {
        background: `rgba( 255, 255, 255, 0.4 ) !important`,
      },
    },
    ".glass-button-warning": {
      background: `rgba(240, 68, 56, 1) !important`,
      color: "rgba(255, 255, 255, 1) !important",
      borderRadius: "6px !important",
      ":hover": {
        background: `rgba(240, 68, 56, 0.8) !important`,
      },
    },
    ".glass-tag": {
      background: `rgba(255, 255, 255, 0.25) !important`,
      border: "none !important",
      color: `${theme.palette.text.primary} !important`,
      ".MuiSvgIcon-root": {
        color: `${theme.palette.text.primary} !important`,
      }
    },
    ".glass-selected": {background: `rgba(255, 255, 255, 0.25) !important`},
    ".glass-pane": {
      background: `linear-gradient(87.84deg, rgba(255, 255, 255, 0.25) 45.4%, rgba(255, 255, 255, 0.15) 100.57%) !important`,
      boxShadow: "0 1px 20px 0 rgba( 0, 0, 0, 0.1 )",
    },
    ".glass-input": {
      background: `linear-gradient(87.84deg, rgba(255, 255, 255, 0.25) 45.4%, rgba(255, 255, 255, 0.15) 100.57%) !important`,
      borderRadius: "8px",
      backdropFilter: "blur(10px)",
      ...borders,
      "::placeholder": {
        color: "rgba(0, 0, 0, 0.3) !important"
      },
      ".MuiFilledInput-root, fieldset": {
        border: "none !important"
      },
      ".MuiChip-root": {
        backgroundColor: "rgba(255, 255, 255, 0.25) !important",
        borderRadius: "6px !important",
      }
    },
    ".glass-dropdown": {
      background: `linear-gradient(87.84deg, rgba(255, 255, 255, 0.25) 45.4%, rgba(255, 255, 255, 0.15) 100.57%) !important`,
      borderRadius: "8px",
      backdropFilter: "blur(10px)",
      color: `${theme.palette.text.primary} !important`,
      "::placeholder": {
        color: "rgba(0, 0, 0, 0.3) !important"
      },
      
    },
    ".glass-tabs": {
      borderRadius: "10px", 
      backdropFilter: "blur(10px)",
      backgroundColor: `linear-gradient(87.84deg, rgba(255, 255, 255, 0.25) 45.4%, rgba(255, 255, 255, 0.15) 100.57%) !important`,
      padding: "4px",
      ...borders,
      ".MuiTabs-indicator": { display: "none" },
      ".MuiTab-root": {
        color: theme.palette.text.primary,
        padding: "8px 12px !important",
        marginLeft: "4px !important",
        minHeight: "36px !important",
      },
      ".MuiTab-root:first-child": {
        marginLeft: "0px !important"
      },
      ".MuiTab-root.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.primary.contrastText} !important`,
        borderRadius: "6px"
      }
    },
    ".MuiDialog-root MuiDialog-paper, .MuiPopover-root .MuiPaper-root, .MuiAutocomplete-popper .MuiPaper-root": {
      background: `rgba( 255, 255, 255, 0.5 ) !important`,
      backdropFilter: "blur(10px)",
      ...borders
    },
    ".glass-popup": {
      backgroundColor: "rgba( 255, 255, 255, 0.5 ) !important",
      backdropFilter: "blur(10px)",
      ...borders
    },
    ".glass-scrollbar": {
      "::-webkit-scrollbar": {
        width: "14px",
        height: "14px",
      },
      "::-webkit-scrollbar-track": {
        borderRadius: "60px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#E4E4E5",
        borderRadius: "10px",
        border: "4px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
      },
      "::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#d5d5d5",
      },
    },
    ".glass-table-header .MuiTableCell-root": {
      backgroundColor: "rgba(255, 255, 255, 0.35) !important",
      color: `${theme.palette.text.primary} !important`,
    },
    ...commonStyles
  }
  const darkStyles = {
    ".glass-wrapper": {
      background: `rgba(51, 51, 51, 0.7) !important`,
      //boxShadow: "0 1px 20px 0 rgba( 0, 0, 0, 0.1 )",
      // backdropFilter: "blur( 10px ) !important",
      // "-webkit-backdrop-filter": "blur( 10px ) !important",
      borderRadius: "8px !important",
      ...borders,
    },
    ".glass-wrapper-50": {
      background: `rgba(148, 148, 148, 0.2) !important`,
      boxShadow: "0 1px 20px 0 rgba( 0, 0, 0, 0.1 )",
      borderRadius: "8px !important",
      ...borders,
    },
    ".glass-wrapper-25": {
      background: `rgba(199, 199, 199, 0.2) !important`,
      boxShadow: "0 1px 20px 0 rgba( 0, 0, 0, 0.1 )",
      borderRadius: "8px !important",
      ...borders,
    },
    ".glass-button-secondary": {
      background: `rgba(51, 51, 51, 0.7) !important`,
      backdropFilter: "blur(100px)",
      "-webkit-backdrop-filter": "blur(100px)",
      borderRadius: "6px !important",
      color: `${theme.palette.text.primary} !important`,
      ...borders,
      ":hover": {
        background: `rgba(51, 51, 51, 0.5) !important`,
      },
    },
    ".glass-button-secondary-50": {
      background: `rgba(148, 148, 148, 0.2) !important`,
      backdropFilter: "blur(100px)",
      "-webkit-backdrop-filter": "blur(100px)",
      borderRadius: "6px !important",
      color: `${theme.palette.text.primary} !important`,
      ...borders,
      ":hover": {
        background: `rgba(148, 148, 148, 0.1) !important`,
      },
    },
    ".glass-button-secondary-25": {
      background: `rgba(199, 199, 199, 0.2) !important`,
      backdropFilter: "blur(100px)",
      "-webkit-backdrop-filter": "blur(100px)",
      borderRadius: "6px !important",
      color: `${theme.palette.text.primary} !important`,
      ...borders,
      ":hover": {
        background: `rgba(199, 199, 199, 0.1) !important`,
      },
    },
    ".glass-button-tertiary":{
      color: `${theme.palette.text.primary} !important`,
      "&:hover": {
        textDecoration: "underline !important"
      }
    },
    ".glass-button-warning": {
      background: `rgba(240, 68, 56, 1) !important`,
      color: "rgba(255, 255, 255, 1) !important",
      borderRadius: "6px !important",
      ":hover": {
        background: `rgba(240, 68, 56, 0.8) !important`,
      },
    },
    ".glass-tag": {
      background: `rgba(148, 148, 148, 0.2) !important`,
      border: "none !important",
      color: `${theme.palette.text.primary} !important`,
      ".MuiSvgIcon-root": {
        color: `${theme.palette.text.primary} !important`,
      }
    },
    ".glass-pane": {
      background: `rgba(148, 148, 148, 0.2) !important`,
      boxShadow: "0 1px 20px 0 rgba( 0, 0, 0, 0.1 )",
    },
    ".glass-selected": {background: `rgba(148, 148, 148, 0.2) !important`},
    ".glass-input": {
        backgroundColor: `rgba(148, 148, 148, 0.2) !important`,
        borderRadius: "8px",
        ...borders,
      ".MuiInputBase-root": {
        backgroundColor: `transparent !important`,
        borderRadius: "8px",
      },
      "::placeholder": {
        color: "rgba(213, 215, 218, 1) !important"
      },
      ".MuiFilledInput-root, fieldset": {
        border: "none !important"
      },
      ".MuiChip-root": {
        backgroundColor: "rgba(148, 148, 148, 0.2) !important",
        borderRadius: "6px !important",
      }
    },
    ".glass-dropdown": {
      background: `rgba(148, 148, 148, 0.2) !important`,
      borderRadius: "8px",
      color: `${theme.palette.text.primary} !important`,
      "fieldset": { border: "none !important" },
      ...borders,
      "::placeholder": {
        color: "rgba(213, 215, 218, 1) !important"
      },
      
    },
    ".glass-tabs": {
      borderRadius: "10px", 
      backdropFilter: "blur(10px)",
      backgroundColor: "rgba(148, 148, 148, 0.2) !important",
      padding: "4px",
      ...borders,
      ".MuiTabs-indicator": { display: "none" },
      ".MuiTab-root": {
        color: theme.palette.text.primary,
        padding: "8px 12px !important",
        marginLeft: "4px !important",
        minHeight: "36px !important",
      },
      ".MuiTab-root:first-child": {
        marginLeft: "0px !important"
      },
      ".MuiTab-root.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.primary.contrastText} !important`,
        borderRadius: "6px"
      }
    },
    //popups
    ".MuiDrawer-root .MuiBackdrop-root.MuiModal-backdrop, .MuiDialog-root .MuiBackdrop-root.MuiModal-backdrop": {
      backgroundColor: "rgba(0, 0, 0, 0.72) !important"
    },
    ".MuiDialog-root .MuiDialog-paper, .MuiPopover-root .MuiPaper-root, .MuiAutocomplete-popper .MuiPaper-root": {
      background: `rgba(51, 51, 51, 0.7) !important`,
      backdropFilter: "blur(100px)",
      ...borders
    },
    ".glass-popup": {
      background: `rgba(51, 51, 51, 0.7) !important`,
      backdropFilter: "blur(100px)",
      ...borders
    },
    //scrollbar
    ".glass-scrollbar": {
      "::-webkit-scrollbar": {
        width: "14px",
        height: "14px",
      },
      "::-webkit-scrollbar-track": {
        borderRadius: "60px",
      },
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "#E4E4E5",
        borderRadius: "10px",
        border: "4px solid rgba(0, 0, 0, 0)",
        backgroundClip: "padding-box",
      },
      "::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#d5d5d5",
      },
    },
    ".glass-table-header .MuiTableCell-root": {
      backgroundColor: "rgba(164, 167, 174, 0.5) !important",
      color: `${theme.palette.text.primary} !important`,
    },
    "#availability-grid .AR__timezone-selector--button": {
      backgroundColor: `rgba(148, 148, 148, 0.2) !important`,
      borderRadius: "8px",
      color: theme.palette.text.primary,
      ...borders,
      ":hover":{
        color: theme.palette.text.primary,
      }
    },
    "#availability-grid .AR__timezone-selector--button .AR__timezone-selector--icon": {
      fill: theme.palette.text.primary
    },
    "#availability-grid .AvailabilityViewer__confirmation": {color: "#111927"},
    "#availability-grid .AR__timezone-selector--listbox-wrapper": {
      background: `rgba(51, 51, 51, 0.7) !important`,
      backdropFilter: "blur(100px)",
      ...borders
    },
    "#availability-grid .AvailabilityViewer__error-text, .CalendarSync__error-text": {
      color: `${theme.palette.text.secondary} !important`
    },
    "#availability-grid .AvailabilityViewer__error-icon, .CalendarSync__error-icon": {
      fill: `${theme.palette.text.secondary} !important`
    },
    ...commonStyles
  }
  return (
    <MuiGlobalStyles
      styles={isGlassmorphismOn ? theme.palette.mode === "dark" ? darkStyles : lightStyles : defaultStyles}
    />
  );
};

export default GlobalStyles;
