import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import moment from "moment";
import {IconButton}from "@mui/material";
import { CloseOutlined } from "@mui/icons-material"
const SubscriptionCancel = ({ open, setOpen, handleCancelSubscription, loading, subscriptionData }) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className='model-heading' sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {"Subscription Cancel"}
                    <IconButton onClick={() => handleClose(false)} sx={{ pr: 0, color: theme=>theme.palette.text.primary }}>
                                      <CloseOutlined />
                                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{color:theme=>theme.palette.text.secondary}}>
                        Are you sure you want to cancel the subscription? Please confirm.
                    </DialogContentText>
                    <DialogContentText id="alert-dialog-description" sx={{color:theme=>theme.palette.text.secondary}}>
                        Your subscription will be cancel on <span>
                            ({
                                moment(subscriptionData?.subscriptions?.base?.endDate).format("DD-MM-YYYY")
                            })
                        </span>{" end of current billing cycle"}.

                    </DialogContentText>

                </DialogContent>
                <DialogActions className="btn-modal">
                    <Button variant='contained' onClick={handleClose} 
                    sx={{ fontSize: "14px"}}
                    >Not Now</Button>
                    {loading ?
                        <CircularProgress color="inherit" sx={{ margin: "auto" }} />
                        :
                        <Button variant='contained' onClick={handleCancelSubscription} autoFocus>
                            Confirm
                        </Button>
                    }
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default SubscriptionCancel;