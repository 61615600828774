// find second to exact number units wise
export const convertSecondsToUnitTime = (seconds, unit) => {
    if (typeof seconds !== "number") {
        return `NaN - convertSecondsToUnitTime`;
    }
    switch (unit) {
        case "days":
            return seconds / (60 * 60 * 24);
        case "hours":
            return seconds / (60 * 60);
        case "minutes":
            return seconds / 60;
        case "seconds":
            return seconds;
        default:
            return `Invalid unit - convertSecondsToUnitTime`;
    }
}


// convert into seconds from exact number units wise
export const convertUnitTimeToSeconds = (unitTime, unit) => {
    if (typeof unitTime !== "number") {
        return `NaN - convertUnitTimeToSeconds`;
    }
    switch (unit) {
        case "days":
            return unitTime * 60 * 60 * 24;
        case "hours":
            return unitTime * 60 * 60;
        case "minutes":
            return unitTime * 60;
        case "seconds":
            return unitTime;
        default:
            return `Invalid unit - convertUnitTimeToSeconds`;
    }
}