import { Box, CircularProgress, Typography } from "@mui/material";
import { Logo } from "./logo";
import { useRoutes } from "react-router-dom";
import { routes } from "../routes";

export const SplashScreen = () => {
  const element = useRoutes(routes);
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "background.paper",
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100vw",
        zIndex: 1400,
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          height: 48,
          width: 48,
        }}
      >
        {element?.props?.match?.pathname.includes("invitations/") ? (
          <Box className="text-loader"></Box>
        ) : (
          <CircularProgress />
        )}
      </Box>
    </Box>
  );
};
