import "../style.css";
import {
  Typography,
  Stack,
  Box,
  IconButton,
  Popover,
  Tooltip,
  Divider,
  Grid,
  alpha,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  Chip,
  Avatar,
  Menu,
  MenuItem,
  CircularProgress,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import { useState, useEffect, useRef, useCallback } from "react";
import { API_SERVICE_BACKEND } from "src/config";
import axios from "axios";
import moment from "moment";

import AttachmentViewer from "./AttachmentViewer";
import { DoneAll } from "@mui/icons-material";
function applyFormatting({ text, attributes }) {
  let formattedText = text;
  let offset = 0; // To track the offset of added tags


  attributes.forEach((attr,index) => {
    let { start, length, attributeKind } = attr;
    let end = start + length;

    formattedText.replace("<p></p>", "");
    formattedText.replaceAll("\n", "");

    // same range come in multiple times
    const checkPreviousRange = (index)=>{
      if (start === attributes?.[index - 1]?.start && !attributeKind.lineBreak) {
        if (attributes?.[index - 1]?.attributeKind?.paragraph) {
          // offset -= "<p></p>".length;
        start -= "<p></p>".length;
        // end += "</p>".length;
        }
        if (attributes?.[index - 1]?.attributeKind?.listItem) {
          // offset -= "<ul><li></li></ul>".length;
        start -= "<ul><li></li></ul>".length;
        checkPreviousRange(index - 1);
        // end += "</li></ul>".length;
        }
        if (attributes?.[index - 1]?.attributeKind?.hyperlink) {
          // offset -= `<a href="${attributes?.[index - 1]?.attributeKind.hyperlink.url}">`.length;
        start -= `<a href="${attributes?.[index - 1]?.attributeKind.hyperlink.url}"></a>`.length;
        // end += "</a>".length;
        checkPreviousRange(index - 1);
        }
        if (attributes?.[index - 1]?.attributeKind?.bold) {
          // offset -= " <b>".length;
        start -= "<b></b>".length;
        // end += "</b>".length;
        checkPreviousRange(index - 1);
        
        }
        if (attributes?.[index - 1]?.attributeKind?.italic) {
          // offset -= "<i>".length;
        start -= "<i></i>".length;
        // end += "</i>".length;
        checkPreviousRange(index - 1);
        }
        if (attributes?.[index - 1]?.attributeKind?.underline) {
          // offset -= "<u>".length;
        start -= "<u></u>".length;
        // end += "</u>".length;
        checkPreviousRange(index - 1);
        }
        if (attributes?.[index - 1]?.attributeKind?.lineBreak) {
          // offset -= "<br/>".length;
        start -= "<br/>".length;
        // end += "<br/>".length;
        checkPreviousRange(index - 1);
        }
      }
  
    }
    checkPreviousRange(index);

    console.log(formattedText.slice(start + offset, end + offset))
    // if(formattedText.slice(start + offset, end + offset)=="\n" ||formattedText.slice(start + offset, end + offset)==""){
    //   return;
    // }
    // if (attributeKind.paragraph) {
    //   const openTag = '<p>';
    //   const closeTag = '</p>';

    //   formattedText = 
    //     formattedText.slice(0, start + offset) + 
    //     openTag + 
    //     formattedText.slice(start + offset, end + offset) + 
    //     closeTag + 
    //     formattedText.slice(end + offset);
      
    //   offset += openTag.length + closeTag.length;
    //   return;
    // }
    if (attributeKind.listItem) {
      const openTag = '<ul><li>';
      const closeTag = '</li></ul>';

      formattedText = 
        formattedText.slice(0, start + offset) + 
        openTag + 
        formattedText.slice(start + offset, end + offset) + 
        closeTag + 
        formattedText.slice(end + offset);
      
      offset += openTag.length + closeTag.length;
      return;
    }
    if (attributeKind.hyperlink) {
      const openTag = `<a href="${attributeKind.hyperlink.url}">`;
      const closeTag = '</a>';

      formattedText = 
        formattedText.slice(0, start + offset) + 
        openTag + 
        formattedText.slice(start + offset, end + offset) + 
        closeTag + 
        formattedText.slice(end + offset);
      
      offset += openTag.length + closeTag.length;
      return;
    }

    if (attributeKind.bold) {
      const openTag = '<b>';
      const closeTag = '</b>';

      formattedText = 
        formattedText.slice(0, start + offset) + 
        openTag + 
        formattedText.slice(start + offset, end + offset) + 
        closeTag + 
        formattedText.slice(end + offset);
      
      offset += openTag.length + closeTag.length;
      return;
    }

    if (attributeKind.italic) {
      const openTag = '<i>';
      const closeTag = '</i>';

      formattedText = 
        formattedText.slice(0, start + offset) + 
        openTag + 
        formattedText.slice(start + offset, end + offset) + 
        closeTag + 
        formattedText.slice(end + offset);
      
      offset += openTag.length + closeTag.length;
      return;
    }

    if (attributeKind.underline) {
      const openTag = '<u>';
      const closeTag = '</u>';

      formattedText = 
        formattedText.slice(0, start + offset) + 
        openTag + 
        formattedText.slice(start + offset, end + offset) + 
        closeTag + 
        formattedText.slice(end + offset);
      
      offset += openTag.length + closeTag.length;
      return;
    }
    if(attributeKind.lineBreak){
      const openTag = '<br/>';
      formattedText = 
        formattedText.slice(0, start + offset) + 
        openTag + 
        formattedText.slice(start + offset, end + offset) + 
        formattedText.slice(end + offset);
      
      offset += openTag.length;
      return;
    }
  });

  return formattedText;
}


function reconstructMessage(data) {

  const attributes = [...(JSON.parse(data.original)?.body.attributes || [])]; // Clone attributes array

  const renderContent = [...(JSON.parse(data.original)?.renderContent || [])]; // Clone attributes array
  let text = JSON.parse(data.original)?.body.text || "";
  const formattedText = applyFormatting({ text, attributes });

  let content = "<div style='display:flex;gap:2;align-items-center;justify-content:center;'>";
  renderContent.forEach((attr) => {

    const actions = attr?.conversationAdsMessageContent?.sponsoredMessageOptions ?? []
    actions?.map((action) => {
      content += `<button class='MuiButtonBase-root MuiButton-root MuiLoadingButton-root MuiButton-contained MuiButton-rounded MuiButton-containedInherit MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-colorInherit MuiButton-root MuiLoadingButton-root MuiButton-contained MuiButton-containedInherit MuiButton-sizeSmall MuiButton-containedSizeSmall MuiButton-colorInherit css-1fdud19-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root'>${action.optionText.text}</button>`
    })
  })
  content += "</div>";
  return formattedText + content;

}
function MessageRenderer({ data , messageId, linkClicksData }) {
  const htmlMessage = reconstructMessage(data);
  const [clickDates, setClickDates] = useState([]);
  const divRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, target, clickData) => {
    setAnchorEl(event.currentTarget);
    if (clickData?.clicks?.length > 0) {
      setClickDates(clickData?.clicks);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickDates([]);
  };
  useEffect(() => {
    if (divRef.current && linkClicksData.count && linkClicksData.count > 0&& data.id == messageId) {
     
      const links = divRef.current.querySelectorAll("a");
      
      links.forEach((link) => {
        const href = link.getAttribute('href').split("/").filter(Boolean).pop();
        const clickData = linkClicksData.urls && linkClicksData.urls.length > 0 && href && linkClicksData.urls.find((url) => url.shortId === href);
        if (data.is_sender === 1) {
          link.setAttribute("href", clickData?.originalUrl);
        }
        const span = document.createElement('span');
        span.textContent = ` ${clickData?.clicks?.length ?? 0}`;
        span.style.marginLeft = "5px";
        span.style.fontSize = "12px";
        span.style.backgroundColor = "#005d91";
        span.style.color = "#fff";
        span.style.padding = "2px 8px";
        span.style.borderRadius = "100%";
        span.style.display = "inline-flex";
        span.style.alignItems = "center";
        span.style.justifyContent = "center"; 
        span.style.fontWeight = "600";
        span.style.cursor = "pointer";
        span.addEventListener("click", (event) => handleClick(event, link, clickData));
        link.parentNode.insertBefore(span, link.nextSibling);
      });
    }  
  }, [data, messageId, linkClicksData]);
useEffect(() => {
  if(data.id == messageId){
    const element = document.getElementById(messageId);
    if (element) {
      // Add animation for highlighting the message
      element.style.backgroundColor = alpha("#005d91", 0.1);
      setTimeout(() => {
        element.style.backgroundColor = "transparent";
      }
        , 3000);
    }
  }
},[messageId])
  return (
    <Box
      sx={
        {
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          whiteSpace: "pre-wrap",

        }}
        id={data.id}
    >
      <div
      ref={divRef}
        dangerouslySetInnerHTML={{ __html: htmlMessage }}
        style={{ whiteSpace: "pre-wrap" }} // Preserve line breaks
      />
      <Popover
                  sx={{ "& .MuiPopover-paper": { p: 2 } }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {clickDates?.length === 0 ? (
                    <Typography>No clicks</Typography>
                  ) : (
                    <Stack direction={"column"}
                      gap={1}>
                      {clickDates?.map((click, index) => (
                        <Typography key={index}>
                          {moment(click).format("MM/DD/YYYY, h:mm A")}
                        </Typography>
                      ))}
                    </Stack>
                  )}
                </Popover>
      {/* {data.is_sender === 1 && ( */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
          marginTop: "5px",
          gap: 1
        }}>
        <Typography variant="caption" sx={{ fontSize: "x-small" }}>
          {moment(data.timestamp).format("hh:mm A")}
        </Typography>
        {data.is_sender === 1 && <Tooltip title={data.seen == 1 ? "Read" : "Delivered"}>
          {/* show chat sending message time */}
          <DoneAll sx={{
            color: data.seen === 1 ? "#00AAE4" : "disabled",
            alignSelf: "flex-end",
            fontSize: "small"
          }} />
        </Tooltip>}
      </Box>
      {/* )} */}
    </Box>
  );
}


const ChatComponent = ({ chats, accountId, chatId, linkedinURL, messageId="", linkClicksData={} }) => {
  const [messages, setMessages] = useState(chats || []);
  const [input, setInput] = useState("");
  const [replyTo, setReplyTo] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null); // For the menu
  const [linkedinProfile, setLinkedinProfile] = useState(null);
  const [currentMessage, setCurrentMessage] = useState(null); // Track the message for the menu
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const chatRef = useRef(null);
  const handleSendMessage = async () => {
    if (input.trim() === "") return;
    const response = await axios.post(

      `${API_SERVICE_BACKEND}/unipile/linkedin/sendMessage`,
      {
        accountId: accountId,
        chatId: chatId,
        message: input,
        // replyTo: replyTo,
      }
    )
    if (!response.data) return;
    if (response.data.message_id) {
      setMessages([
        ...messages,
        {
          id: response.data.message_id,
          text: input,
          is_sender: 1,
          deleted: false,
          original: JSON.stringify({ body: { attributes: [] } }),
        },
      ]);
      scrollToBottom()
    }
    // setMessages([
    //   ...messages,
    //   {
    //     id: messages.length + 1,
    //     text: replyTo ? `Replying to: ${replyTo.text}\n${input}` : input,
    //     is_sender: 1,
    //     deleted: false,
    //   },
    // ]);

    setInput("");
    setReplyTo(null);
  };

  const handleDeleteMessage = () => {
    setIsDeleteDialogOpen(true);
    handleCloseMenu();
  };


  const handleReply = (e) => {
    setReplyTo(currentMessage);
    setAnchorEl(null);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };
  const handleOpenMenu = (event, message) => {
    setAnchorEl(event.currentTarget);
    setCurrentMessage(message);
  };

  const getLinkedinProfile = async () => {
    try {
      const publicIdentifier = linkedinURL.split("/").filter(Boolean).pop();
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/unipile/linkedin/getUserProfile?accountId=${accountId}&identifier=${publicIdentifier}`
      );
      if (!res.data) return;
      setLinkedinProfile(res.data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCurrentMessage(null);
  };
  const deleteLinkedinMessage = async (messageId) => {
    const res = await axios.post(
      `${API_SERVICE_BACKEND}/unipile/linkedin/deleteMessage`,
      {
        accountId: accountId,
        chatId: chatId,
        messageId: messageId,
      }
    );
    if (!res.data) return;
  };

  const handleConfirmDeleteMessage = () => {
    const updatedMessages = messages.map((message) => {
      if (message.id === currentMessage.id) {
        return { ...message, deleted: true };
      }
      return message;
    });
    setMessages(updatedMessages);
    deleteLinkedinMessage(currentMessage.id);
    setIsDeleteDialogOpen(false);
  };

  const scrollToBottom = useCallback(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  },[messages.length]);

  useEffect(() => {
    if (linkedinURL) {
      getLinkedinProfile();
    }
  }
    , [linkedinURL]);

  const handleGoToMessage = (messageId) => {
    const element = document.getElementById(messageId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" }); 
    }
  };
  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom()
    }
  }, [messages.length]);
useEffect(() => {
  if (messageId) {
    handleGoToMessage(messageId);
  }
},[messageId])
  return (
    <>
      <Box
        sx={{
          width: "100%",
          border: "1px solid #ccc",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          margin: "auto",
          bgcolor: "background.paper",
        }}
        className="glass-wrapper-25"
      >
        {/* Message List */}
        <List ref={chatRef} sx={{ flex: 1, padding: "10px", overflowY: "auto", maxHeight: "500px" }} >
          {messages.map((message,index) => {
            if (message.deleted == 1 && message.is_sender == 0) return null;
            const prevMessage = messages[index - 1];
            const showTimestamp = !prevMessage || moment(message.timestamp).fromNow() !== moment(prevMessage.timestamp).fromNow();

            return (
              <>
                {showTimestamp && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      margin: "10px 0",
                    }}
                  >
                    <Chip

                      label={moment(message.timestamp).diff(moment(), "days") === 0 ? "Today" : moment(message.timestamp).format("MMM DD, YYYY")}
                      size="small"
                      color="primary"
                    />
                  </Box>
                )}

                {message.text && <ListItem
                  key={message.id}
                  sx={{
                    display: "flex",
                    justifyContent: message.is_sender === 1 ? "flex-end" : "flex-start",
                    alignItems: "flex-start",
                    position: "relative",
                    "&:hover .actions": { opacity: 1 },
                  }}
                >
                  {message.is_sender === 0 && (
                    <Avatar
                      alt="User"
                      src={linkedinProfile?.profile_picture_url ?? "https://www.w3schools.com/howto/img_avatar.png"}
                      sx={{ width: 32, height: 32, marginRight: "10px" }}
                    />
                  )}

                  <Stack
                    direction="column"
                    spacing={1}
                    sx={{
                      maxWidth: "70%",
                      alignItems: message.is_sender === 1 ? "flex-end" : "flex-start",
                    }}
                  >
                    {message.deleted == 0 ? (
                      <>
                        <Box sx={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}>
                          <Typography
                            variant="body1"
                            sx={{
                              bgcolor: message.is_sender === 1 ? "#E6F7FF" : "#F3F2F1",
                              padding: "8px 14px",
                              borderRadius: "6px",
                              border: "1px solid #ccc",
                            }}
                            className={ message.is_sender === 1 ? "glass-wrapper-25" : "glass-wrapper"}
                          >
                            <MessageRenderer data={message}
                             messageId={messageId}
                              linkClicksData={linkClicksData} />
                          </Typography>
                          {/* <IconButton
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: message.is_sender === 1 ? "-10px" : "10px",
                      opacity: 0,
                      transition: "opacity 0.3s",
                    }}
                    className="actions"
                    onClick={(e) => handleOpenMenu(e, message)}
                  >
                    <MoreVertIcon />
                  </IconButton> */}

                        </Box>
                      </>
                    ) : (
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        fontStyle="italic"
                        sx={{
                          padding: "8px 12px",
                          bgcolor: "#F3F2F1",
                          borderRadius: "6px",
                        }}
                        className="glass-wrapper-25"
                      >
                        Message has been deleted
                      </Typography>
                    )}

                  </Stack>
                </ListItem>}
                {
                  message.attachments && message.attachments.length > 0 && message.attachments.map((attachment) => (
                    <ListItem
                      key={attachment.id}
                      sx={{
                        display: "flex",
                        justifyContent: message.is_sender === 1 ? "flex-end" : "flex-start",
                        alignItems: "flex-start",
                        position: "relative",
                        "&:hover .actions": { opacity: 1 },
                      }}
                    > {message.is_sender === 0 && (
                      <Avatar
                        alt="User"
                        src={linkedinProfile?.profile_picture_url ?? "https://www.w3schools.com/howto/img_avatar.png"}
                        sx={{ width: 32, height: 32, marginRight: "10px" }}
                      />
                    )}

                      <AttachmentViewer attachment={attachment} isSender={message.is_sender} messageId={message?.id} timestamp={message.timestamp} seen={message.seen} />
                    </ListItem>
                  ))}


              </>
            )
          })}
        </List>

        {/* Actions Menu */}
        {/* <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
        <MenuItem onClick={handleReply}>Reply</MenuItem>
        <MenuItem onClick={handleDeleteMessage}>Delete</MenuItem>
      </Menu> */}

        {/* Reply Section */}
        {replyTo && (
          <Box
            sx={{
              bgcolor: "#f1f1f1",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              sx={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 2, // Show up to 2 lines
                textOverflow: "ellipsis",
                maxWidth: "100%", // Ensure ellipsis works within container width
              }}
            >
              Replying to: <strong>{replyTo.text}</strong>
            </Typography>
            <Button variant="contained" color="error" size="small" onClick={() => setReplyTo(null)}>
              Cancel
            </Button>
          </Box>
        )}

        {/* Input Section */}
        <Box
          sx={{
            display: "flex",
            padding: "10px",
            borderTop: "1px solid #ccc",
          }}
        >
          <TextField
            variant="outlined"
            value={input}
            onChange={handleInputChange}
            placeholder="Type a message..."
            fullWidth
            size="small"
            className="glass-input"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendMessage}
            sx={{ marginLeft: "10px" }}
          >
            Send
          </Button>
        </Box>
      </Box>
      {/*  Delete confirmation dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>Delete Message</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this message?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={() => handleConfirmDeleteMessage()} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

// export default ChatComponent;

const SequenceStepLinkedinContent = ({ content, status, providers, linkedinURL }) => {
  const linkedin = providers?.linkedin;
  const [chatData, setChatData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [linkClicksData, setLinkClicksData] = useState([]);
  const [emailData, setEmailData] = useState(null);
  const [sortedClicks, setSortedClicks] = useState([]);
  const [sortedOpens, setSortedOpens] = useState([]);

  const fetchLinkedinChat = async () => {
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/unipile/linkedin/getChatById?chatId=${linkedin?.chatId}&identifier=${linkedinURL.split("/").filter(Boolean).pop()}&accountId=${linkedin.accountId}`
      );
      if (!res.data) return;
      setChatData(res.data.items.reverse());
      setIsFetching(false);
    } catch (err) {
      setIsFetching(false);
      console.log(err);
    }
  };
  useEffect(() => {
    if (linkedin?.chatId || linkedin?.accountId) {
      setIsFetching(true);
      fetchLinkedinChat();
    }
  }, [linkedin?.chatId]);
useEffect(() => {
  const fetchEmailContent = async () => {
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/getEmailSentInfo?id=${content}&analytics=true`
      );
    
      if (!res.data) return;
      setEmailData({
        clicked: res.data.clicked,
        opened: res.data.opened,
        email: res.data.email,
        from: res.data.from,
        to: res.data.to,
        sentAt: res.data.createdAt,
        messageId: res.data.messageId,
      });
      setLinkClicksData(res.data.analyticsData);
      setSortedClicks(res.data.clicked);
      setSortedOpens(res.data.opened);
    } catch (error) {
      console.log(error);
    }
  };
  if (content) {
    fetchEmailContent();
  }
},[content])
  return (
    <>
      <Stack
        sx={{
          //backgroundColor: "#FFFFFF",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {!content && status === 2 ? (
          <Typography sx={{ p: "28px", color: theme => theme.palette.text.primary }}>
            The prospect has potentially unsubscribed from the email journey.
          </Typography>
        ) : (
          chatData.length > 0 ? <ChatComponent
            messageId={emailData?.messageId}
            accountId={linkedin?.accountId}
            chatId={linkedin?.chatId}
            chats={chatData}
            linkedinURL={linkedinURL}
            linkClicksData={linkClicksData}
          /> : isFetching ?
            (
              <Box sx={{ p: 2 }}>
                {Array.from(new Array(6)).map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: index % 2 === 0 ? "row" : "row-reverse",
                      mb: 2,
                    }}
                  >
                    <Skeleton
                      variant="circular"
                      width={40}
                      height={40}
                      sx={{ flexShrink: 0, mx: 1 }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={50}
                      sx={{
                        borderRadius: 2,
                        alignSelf: "center",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            ) : <Typography sx={{ p: "28px" }}>No chat data available</Typography>
        )}
      </Stack>
    </>
  );
};

export default SequenceStepLinkedinContent;
