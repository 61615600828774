import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Grid,
    Select,
    Typography,
    Dialog,
    InputLabel,
    Autocomplete,
  } from "@mui/material";
import moment from "moment/moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { API_SERVICE_BACKEND_3} from "src/config";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";


const TimePopup = ({
    // timeSlotes,
    calenderId,
    typingref,
    sub,
    selectedDates,
    handleDateChange,
    option,
    setTimezones,
    cronofyToken,
    timezones,
    handleClose,
    open,
    handleOpen2,
    campaignData,
    handleDisable = () => { },
    isGlobal,
  }) => {
    const [timeSlotes, setTimeSlotes] = useState(null);
    // const [cronofyToken, setCronofyToken] = useState('')
    const [cronofyData, setCronofyData] = useState([]);
    // const [calenderId, setCalenderId] = useState('')
    // const [sub, setSub] = useState()
    // const userEmail = getSessionStorage("userEmail");
  
    // useEffect(() => {
    //   const getAccessToken = async () => {
    //     const userInfo = await getUserInfo(userEmail);
    //     const accessToken = userInfo?.extra?.accessToken?.access_token;
    //     const sub = userInfo?.extra?.accessToken?.sub
    //     setSub(sub)
    //     setCronofyToken(accessToken)
    //   };
    //   getAccessToken()
    // }, [userEmail])
  
    // const cronofycalendar = async () => {
    //   try {
    //     const responseDataLog = await fetch(
    //       `${API_SERVICE_BACKEND_2}/calendar`,
    //       {
    //         method: "GET",
    //         headers: {
    //           "cronofyToken": cronofyToken
    //         },
    //       }
    //     ).then((res) => res.json());
    //     setCalenderId(responseDataLog.calendars[0].calendar_id)
    //   } catch (err) {
    //
    //   }
    // }
  
    const date1 = moment().format("YYYY-MM-DD");
    const date2 = selectedDates.format("YYYY-MM-DD");
    const utctime = moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
    const cronofyApi3 = async () => {
      const email = campaignData?.clientEmail; //getSessionStorage("userEmail");
      const url = isGlobal
        ? `${API_SERVICE_BACKEND_3}/get-slot-preset/${email}`
        : `${API_SERVICE_BACKEND_3}/get-campaign-slot-preset/${campaignData._id}`;
      // `${API_SERVICE_BACKEND_3}/get-campaign-slot-preset/${campaignData._id}`,
      // `${API_SERVICE_BACKEND_3}/get-slot-preset/${email}`,
      const userSlotPreset = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const duration = await userSlotPreset.json();
      const slotDuration = duration?.data?.slotsPreset;
      try {
        let data = {
          participants: [
            {
              members: [
                {
                  sub: sub,
                  calendar_ids: [calenderId],
                  managed_availability: true,
                },
              ],
              required: 1,
            },
          ],
          // required_duration: { minutes: 60 },
          required_duration: { minutes: slotDuration ? slotDuration : 30 },
          response_format: "slots",
          // In query_periods, we are giving timeframe EST 9 AM to 6 PM for getting timeslots.
          // query_periods: [
          //   {
          //     // start: date1 === date2 ? utctime : moment(selectedDates.format('YYYY-MM-DDT09:00:00Z')).utc(),
          //     // end: moment(selectedDates.format('YYYY-MM-DDT18:00:00Z')).utc()
          //     start:
          //       date1 === date2
          //         ? utctime
          //         : moment(date2)
          //           .add(1, "days")
          //           .utc()
          //           .format("YYYY-MM-DDT14:00:00[Z]"), // here, we are converting in EST 9 AM in UTC
          //     end:
          //       date1 === date2
          //         ? moment().utc().format("YYYY-MM-DDT23:59:00[Z]") // here, we are converting in EST 6 PM in UTC
          //         : moment(date2)
          //           .add(1, "days")
          //           .utc()
          //           .format("YYYY-MM-DDT23:00:00[Z]"),
          //   },
          // ],
          query_periods: [
            {
              start:
                date1 === date2
                  ? utctime
                  : moment(date2)
                    .startOf("day")
                    // .utc()
                    .format("YYYY-MM-DDT00:00:00[Z]"),
              end:
                date1 === date2
                  ? moment().utc().format("YYYY-MM-DDT23:59:59[Z]")
                  : moment(date2)
                    // .add(1, "days")
                    // .utc()
                    .format("YYYY-MM-DDT23:59:59[Z]"),
            },
          ],
        };
  
        let buffer = duration?.data?.buffer;
        if (buffer && buffer.isSetBuffer) {
          data = {
            ...data,
            buffer: {
              before: { minutes: buffer.before },
              after: { minutes: buffer.after },
            },
          };
        }
  
        const responseDataLog = await fetch(
          `${API_SERVICE_BACKEND_3}/availability`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              cronofyToken: cronofyToken,
            },
            body: JSON.stringify(data),
          }
        ).then((res) => res.json());
        // setCronofyData(responseDataLog?.data?.available_periods)
        setCronofyData(responseDataLog?.data?.available_slots);
      } catch (err) { }
    };
  
    // useEffect(() => {
    //   if (cronofyToken && open) {
    //     cronofycalendar()
    //   }
    // }, [cronofyToken, open])
  
    useEffect(() => {
      setTimeSlotes(null);
      if (calenderId && cronofyToken && open && campaignData?.clientEmail) {
        // if (date1 === date2 && new Date().getHours() >= 18) {
        //   return;
        // } else {
        cronofyApi3();
        // }
      }
    }, [cronofyToken, calenderId, selectedDates, timezones, open, campaignData]);
  
    useEffect(() => {
      // function generateTimeSlots(events) {
      //   const timeSlots = [];
  
      //   // Loop through each event
      //   for (let i = 0; i < events?.length; i++) {
      //     // const startTime = moment1(events[i].start).tz('UTC');
      //     // const endTime = moment1(events[i].end).tz('UTC');
      //     const startTime = moment1(events[i].start).tz(timezones);
      //     const endTime = moment1(events[i].end).tz(timezones);
      //     const gap = 45;
  
      //     // Generate time slots with 30-minute intervals
      //     // while (startTime.isBefore(endTime)) {
      //     while (startTime.isBefore(endTime) && endTime.diff(startTime, 'minutes') >= gap) {
      //       timeSlots.push(startTime.format('hh:mma'));
      //       startTime.add(gap, 'minutes');
      //     }
      //   }
      //   return timeSlots;
      // }
      function generateTimeSlots(events, timezones) {
        const timeSlotsAM = [];
        const timeSlotsPM = [];
  
        // Loop through each event
        for (let i = 0; i < events?.length; i++) {
          const startTime = moment(events[i].start).tz(timezones);
          const formattedTime = startTime.format("hh:mma");
  
          // Check if the time is in AM or PM and push to the corresponding array
          if (startTime.hour() < 12) {
            timeSlotsAM.push(formattedTime);
          } else {
            timeSlotsPM.push(formattedTime);
          }
        }
  
        // Concatenate the AM and PM time slots
        return timeSlotsAM.concat(timeSlotsPM);
      }
  
      const timeSlots = generateTimeSlots(cronofyData, timezones);
  
      setTimeSlotes(timeSlots);
    }, [cronofyData]);
  
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        fullWidth
        maxWidth="md"
      >
        <Grid container spacing={2} sx={{backgroundColor: "#ffffff"}}>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                boxShadow: "0px 8px 8px -4px #10182808",
                bgcolor: "white",
                p: 2,
                borderRadius: "8px",
              }}
            >
              {" "}
              <InputLabel
                sx={{
                  // fontFamily: "DM Sans",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  color: "#55597A",
                  mb: "6px",
                }}
              >
                Your current timezone
              </InputLabel>{" "}
              <Select
                // displayEmpty
                renderValue={(selected) => {
                  if (selected === "") {
                    return "Placeholder";
                  }
                  return selected;
                }}
                value={timezones}
                onChange={(e) => setTimezones(e.target.value)}
                fullWidth
                size="small"
                MenuProps={{
                  sx: {
                    "& .MuiMenu-paper": {
                      backgroundColor: "#ffffff !important"
                    }
                  }
                }}
              >
                {/* <MenuItem disabled value="">
                        Placeholder
                      </MenuItem> */}
                {/* {option?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))} */}
              </Select>
            </Box>
          </Grid>
  
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                width: "100%",
                boxShadow: "0px 8px 8px -4px #10182808",
                bgcolor: "white",
                p: 2,
                borderRadius: "8px",
              }}
            >
              {" "}
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <DatePicker defaultValue={today} disablePast /> */}
                <StaticDatePicker
                  // shouldDisableDate={handleDisable}
                  sx={{
                    "& .MuiPickersToolbar-root": {
                      color: "#050C46",
                      borderRadius: 2,
                      borderWidth: 1,
                      borderColor: "#2196f3",
                      border: "1px solid",
                      // backgroundColor: "#0d47a1",
                    },
                  }}
                  value={selectedDates}
                  onChange={handleDateChange}
                  disablePast
                  componentsProps={{
                    actionBar: {
                      actions: [],
                    },
                  }}
                  slotProps={{
                    actionBar: {
                      actions: [
                        {
                          name: "Custom Action",
                          onClick: () => {
                            console.log("Custom action clicked");
                          },
                        },
                      ],
                    },
                  }}
                />
              </LocalizationProvider>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  onClick={handleClose}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  Return
                </Button>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} sx={{ minHeight: "100%" }}>
            {" "}
            <Box
              sx={{
                width: "100%",
                minHeight: "100%",
                boxShadow: "0px 8px 8px -4px #10182808",
                bgcolor: "white",
                p: 2,
                borderRadius: "8px",
              }}
            >
              <Box
                sx={{
                  minHeight: "100%",
                  border: "1px solid #ECE9F5",
                  bgcolor: "#F8F7FC",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  width: "100%",
                  p: 2,
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{
                    // fontFamily: "DM Sans",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "20px",
                    mb: 2,
                    marginRight: "5px",
                    color: "#111927"
                  }}
                >
                  Book a slot below for:{" "}
                </Typography>
                {timeSlotes ? (
                  !timeSlotes.length ? (
                    <Typography
                      sx={{
                        // fontFamily: "DM Sans",
                        fontSize: "13px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        mb: 2,
                        color: "#111927"
                      }}
                    >
                      No free slots available{" "}
                    </Typography>
                  ) : (
                    timeSlotes.map((item) => {
  
                      return (
                        <>
                          <Button
                            sx={{
                              mb: 1,
                              borderRadius: "6px",
                              background: "#fff",
                              // background:
                              //   selectedDate === question ? "#00AA38" : "#fff",
                              border: "1px solid #ECE9F5",
                              color: "#050C46",
                              // color:
                              //   selectedDate === question ? "#fff" : "#050C46",
                              // p: 2,
                              boxShadow: 0,
                              "&:hover": {
                                backgroundColor: "#ECE9F5",
                                // backgroundColor:
                                //   selectedDate === question
                                //     ? "#00AA38"
                                //     : "#ECE9F5",
                                // color: "#fff",
                                boxShadow: "0px 1px 2px 0px #1018280F",
                              },
                              // fontFamily: "DM Sans",
                              fontSize: "14px",
                              fontWeight: 500,
                              lineHeight: "20px",
                            }}
                            fullWidth
                            onClick={() =>
                              handleOpen2(
                                selectedDates.format("YYYY-MM-DD"),
                                item
                              )
                            }
                            variant="contained"
                          >
                            {item}
                          </Button>
                        </>
                      );
                    })
                  )
                ) : (
                  <CircularProgress color="inherit" sx={{ margin: "auto" }} />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    );
  };

export default TimePopup;
