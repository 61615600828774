import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Switch,
  styled,
} from "@mui/material";
import { API_SERVICE_BACKEND } from "src/config";
import { getSessionStorage } from "src/utils/storage";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import toast from "react-hot-toast";
import { Seo } from "src/components/seo";

const SupportCenter = () => {

    const [formData, setFormData] = useState('');
      const [isFormDataChange, setIsFormDataChange] = useState(false);
    
      const [defaultData, setDefaultData] = useState({});
      const email = getSessionStorage("userEmail");
      const { whiteLableId, getAllWhiteLabelClientConfigurations, metaTitle, companyName } = useContext(
        WhiteLabelConfigurationContext
      );
      // Handle input changes
      const handleChange = (e) => {
        const { name, value } = e.target;
    
        setFormData(value);
        setIsFormDataChange(true);
      };
    
      // Fetch data from API to populate form
      const getAllWhiteLabelClients = async () => {
        try {
          const response = await fetch(
            `${API_SERVICE_BACKEND}/getwhiteLabelConfig/${whiteLableId}`
          );
          const data = await response.json();
    
    
          setFormData(data.data.metadata?.supportcenter || "");
          setDefaultData(data.data);
        } catch (err) {
          console.log(err);
        }
      };
    
      useEffect(() => {
        getAllWhiteLabelClients();
      }, [whiteLableId]);
    
      const handleSubmit = async () => {
        const requestData = {
            email: email,
            seo: {
              title: defaultData.seo.title,
              description: defaultData.seo.description,
              tagLine: defaultData.seo.tagLine,
            },
            info: {
              companyName: defaultData.info.companyName,
              logoUrl: defaultData.info.logoUrl,
              favIcon: defaultData.info.favIcon,
            },
            theme: {
              primaryTextColor: defaultData.theme.primaryTextColor,
              secondaryTextColor: defaultData.theme.secondaryTextColor,
              primaryBrandColor: defaultData.theme.primaryBrandColor,
              secondaryBrandColor: defaultData.theme.secondaryBrandColor,
            },
            metadata: {},
          };
        
          if (defaultData?.metadata?.hasOwnProperty("should_show_company_name")) {
            requestData.metadata.should_show_company_name =
              defaultData.metadata.should_show_company_name;
          }
        
          if (formData) {
            requestData.metadata.supportcenter = formData;
          }
    
        try {
          const response = await fetch(
            `${API_SERVICE_BACKEND}/handleWhiteLabelConfig`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestData),
            }
          );
    
          const data = await response.json();
    
          if (response.status === 200) {
            toast.success(response.message ?? "Support Center URL updated successfully!", {
              autoClose: 3000,
            });
            getAllWhiteLabelClients();
            getAllWhiteLabelClientConfigurations(whiteLableId);
          } else {
            // Error
            toast.error(response.message ?? "Failed to connect", {
              autoClose: 3000,
            });
          }
        } catch (error) {
          console.error("Error submitting Support Center URL data:", error);
        }
      };
    
      useEffect(() => {
        const debounce = setTimeout(() => {
          if (isFormDataChange) {
            handleSubmit();
            setIsFormDataChange(false);
          }
        }, 2000);
    
        return () => clearTimeout(debounce);
    
      }, [formData, isFormDataChange]);
    
      const pageTitle = `Partner/White Label: Support Center - ${metaTitle || companyName }`;

  return (
    <>
    <Seo title={pageTitle} />
    <Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography
          sx={{ fontSize: "20px", fontWeight: 700, color: theme => theme.palette.text.primary }}
        >
          Support Center
        </Typography>
      </Box>
      <Grid container spacing={1} sx={{mt: 1}}>
        <Grid item md={6} xs={12}>

          <TextField
            id="supportcenter"
            label="Enter Support Center URL"
            variant="outlined"
            fullWidth
            name="supportcenter"
            value={formData}
            onChange={handleChange}
            sx={{ mb: 3 }}
            className="glass-input"
          />
         
        </Grid>  
      </Grid>
     
    </Box>
    </>
  );
};

export default SupportCenter;
