import React, { useEffect, useState } from "react";
import "@material-ui/core/styles";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Grid,
  CircularProgress,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Chip
} from "@mui/material";
import {
  API_SERVICE_BACKEND_2,
  APP_TIMEZONE,
} from "src/config";
import moment from "moment-timezone";
import "../style.css";
import LinkIcon from "src/components/logos/linkIcon.png";
import Linkbroken from "src/components/logos/linkBroken.png";
import { useAuth } from "src/contexts/auth/firebase-context";
import { getServerDateTime } from "src/pages/dashboard/customers/allCustomers";
import { DifferentOwners } from "./owners"
import { Person } from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ZapierIntegrationSettings from "./integrations/zapier"
import PipedriveIntegrationSettings from "./integrations/pipedrive"
import SalesforceIntegrationSettings from './integrations/salesforce'
import LogDrawer from "./LogDrawer";
import { BsCodeSlash } from "react-icons/bs";
import { TbWorld } from "react-icons/tb";
import { AiOutlineLink, AiOutlinePlus, AiOutlineCheck } from "react-icons/ai";
import { PiLinkBreakBold } from "react-icons/pi";
import { MdOutlineHistory } from "react-icons/md";
import { addDomain, verifyDomain, getDomains } from "src/api/analytics";

import { toast } from "react-hot-toast";
export const INTEGRATIONTYPE = Object.freeze({
  HUBSPOT: "hubspot",
  ZOHO: "zoho",
  ZAPIER: "zapier",
  SALESFORCE: "salesforce"
})

const HUBSPOTOWNERASSIGNMENTTYPE = Object.freeze({
  SEPARATE: "SEPARATE",
  DEFAULT: "DEFAULT"
})

const IntegrationSettings = (props) => {

  const {
    email,
    section9Ref,
    userCampaigns,
    loadUserCampaigns,
    userCampaignLoading
  } = props;

  const { user } = useAuth();
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  
  // Add state for tracking code
  const [domainValue, setDomainValue] = useState("YOUR_DOMAIN");
  const organizationId = sessionStorage.getItem("organization_id") || user?.uid
  const [isZohoAuto, setIsZohoAuto] = useState(false);
  const [isHubspotAuto, setIsHubspotAuto] = useState(false);
  const [hasZohoIntegration, setHasZohoIntegration] = useState(false);
  const [hasHubSpotIntegration, setHasHubSpotIntegration] = useState(false);

  const [zohoExpired, setZohoExpired] = useState(false);
  const [hubspotExpired, setHubSpotExpired] = useState(false);

  const [zohoIntegration, setZohoIntegration] = useState({});
  const [hubSpotIntegration, setHubSpotIntegration] = useState({});
  const [zohoAutoExecution, setZohoAutoExecution] = useState();
  const [hubspotAutoExecution, setHubspotAutoExecution] = useState();
  const [hubspotUsers, setHubspotUsers] = useState()

  const [hubspotOwnerAssignment, sethubspotOwnerAssignment] = useState(HUBSPOTOWNERASSIGNMENTTYPE.DEFAULT)
  const [selectedOwner, setSelectedOwner] = useState('')

  const [Baropen, setBarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const [domain, setDomain] = useState("");
  const [integrationModalOpen, setIntegrationModalOpen] = useState(false);
  const [isDomainIntegrated, setIsDomainIntegrated] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("wordpress");
  const [userDomains, setUserDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState("");
  const [showTrackingScript, setShowTrackingScript] = useState(false);
  const [showScript, setShowScript] = useState(false);
  
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleIntegration = () => {
    if (domain) {
      setDomainValue(domain);
      setIsDomainIntegrated(true);
      setIntegrationModalOpen(false);
      openSnackbar(`Successfully integrated tracking for ${domain}`, "success");
    }
  };
  
  // Function to verify if integration is working
  const verifyIntegration = () => {
    // Here you would implement logic to check if the tracking is active
    // For now, we'll just show a success message
    openSnackbar("Integration verification successful! Tracking is active.", "success");
  };

  const handleDisconnect = async (userEmail, type) => {
    // console.log(userEmail, type, "comingData");
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND_2}/removeIntegration`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userEmail: userEmail,
            type: type,
          }),
        }
      );
      // console.log(response, "responsedel");
      if (response.ok) {
        type === INTEGRATIONTYPE.HUBSPOT
          ? setHasHubSpotIntegration(false)
          : setHasZohoIntegration(false);
        // console.log(response, "resMsg");

        if (type === INTEGRATIONTYPE.HUBSPOT) {
          setHasHubSpotIntegration(false);
          setHubSpotExpired(false);
          setHubSpotIntegration(null);
        } else if (type === INTEGRATIONTYPE.ZOHO) {
          setHasZohoIntegration(false);
          setZohoExpired(false);
          setZohoIntegration(null);
        }
      } else {
        const errorData = await response.json();
        console.error(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Internal Server Error:", error);
    }
  };

  const updateIntegrationAutoSyncFlag = async (email, type, isAuto) => {
    try {
      await fetch(`${API_SERVICE_BACKEND_2}/updateIntegration`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          isAuto: isAuto,
          userEmail: email,
          type: type,
        }),
      });
    } catch (error) {
      console.error("Error updating isAuto:", error);
    }
  };
  const trackingCode = `<!-- Analytics Tracking Code -->
<script 
  async 
  src="https://cdn.jsdelivr.net/gh/leadinglydevelopment/pixel-tracking@latest/pixel.js" 
  data-site-id="${domainValue}"
  data-user-id="${organizationId}"
></script>`;

  // Function to copy the tracking code to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(trackingCode);
    openSnackbar("Tracking code copied to clipboard", "success");
  };

  const initiateZohoOAuth = () => {
    // Replace 'YOUR_CLIENT_ID' with the actual Client ID obtained from the Zoho Developer Console
    const clientId = "1000.9VUAZN3TVQ0OSAOQSXUNGBHC72RRWA";
    const redirectUri = `${API_SERVICE_BACKEND_2}/zoho-callback`;


    const queryParams = {
      access_type: "offline",
      prompt: "consent",
      response_type: "code",
      client_id: clientId,
      redirect_uri: redirectUri,
      scope: "ZohoCRM.modules.ALL",
    };

    const oauthUrl = `https://accounts.zoho.com/oauth/v2/auth?${new URLSearchParams(
      queryParams
    ).toString()}`;
    console.log(oauthUrl);
    // Redirect the user to Zoho for authorization
    window.location.replace(oauthUrl);
  };

  const changeOwnerAssignmentStatus = (value) => {
    sethubspotOwnerAssignment(value)
    if (value === HUBSPOTOWNERASSIGNMENTTYPE.SEPARATE) {
      loadUserCampaigns()
    }
  }

  const addOwner = async (e) => {
    e.preventDefault();
    console.log('selected owner', selectedOwner)
    try {
      await fetch(`${API_SERVICE_BACKEND_2}/hubspot-owners-save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userEmail: user.email,
          type: INTEGRATIONTYPE.HUBSPOT,
          compain_id: undefined,
          owner_id: selectedOwner,
          hubspotOwnerAssignment: hubspotOwnerAssignment
        }),
      });
      openSnackbar("Default owner assigned for all agents.", "success");
    } catch (error) {
      console.error("Error updating isAuto:", error);
    }
  }

  const openSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setBarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setBarOpen(false);
  };
  useEffect(() => {
    const fetchDomains = async () => {
      try {
        const domains = await getDomains(organizationId);
        setUserDomains(domains.data);
      } catch (error) {
        console.error("Error fetching domains:", error);
        openSnackbar("Failed to fetch domains", "error");
      }
    };

    if (organizationId) {
      fetchDomains();
    }
  }, [organizationId]);

  useEffect(() => {
    const tokenZoho = user?.integrations?.zoho?.access_token;
    const zohoLastAutoExecution = user?.integrations?.zoho?.lastAutoExecution;
    const formattedZohoLastAutoExecution = moment(zohoLastAutoExecution).format(
      "MMMM Do YYYY, h:mm:ss a"
    );
    if (tokenZoho) {
      setHasZohoIntegration(true);
      setZohoIntegration(user?.integrations?.zoho);
      setIsZohoAuto(user?.integrations?.zoho?.isAuto);
      setZohoAutoExecution(formattedZohoLastAutoExecution);
    } else {
      setHasZohoIntegration(false);
    }

    const tokenHubspot = user?.integrations?.hubspot?.access_token;
    const hubspotLastAutoExecution =
      user?.integrations?.hubspot?.lastAutoExecution;
    const formattedHubspotLastAutoExecution = moment(
      hubspotLastAutoExecution
    ).format("MMMM Do YYYY, h:mm:ss a");
    if (tokenHubspot) {
      setHasHubSpotIntegration(true);
      setHubSpotIntegration(user?.integrations?.hubspot);
      setIsHubspotAuto(user?.integrations?.hubspot?.isAuto);
      setHubspotAutoExecution(formattedHubspotLastAutoExecution);
    } else {
      setHasHubSpotIntegration(false);
    }

    console.log({ user });
  }, [user]);

  useEffect(() => {
    (async () => {
      const { dateTime } = await getServerDateTime();
      const now = moment.tz(dateTime, APP_TIMEZONE);

      if (
        moment.tz(hubSpotIntegration?.expires_at, APP_TIMEZONE).unix() <
        now.unix()
      ) {
        setHubSpotExpired(true);
      } else {
        setHubSpotExpired(false);
      }

      if (hubSpotIntegration?.hubspotOwnerAssignment) {
        sethubspotOwnerAssignment(hubSpotIntegration?.hubspotOwnerAssignment)
      }

      if (hubSpotIntegration?.defaultHubspotContactsOwner) {
        setSelectedOwner(hubSpotIntegration?.defaultHubspotContactsOwner)
      }

      if (
        moment.tz(zohoIntegration?.expires_at, APP_TIMEZONE).unix() < now.unix()
      ) {
        setZohoExpired(true);
      } else {
        setZohoExpired(false);
      }
    })();
  }, [hubSpotIntegration, zohoIntegration]);

  useEffect(() => {

    const getOwnersList = async () => {
      try {
        const res = await fetch(`${API_SERVICE_BACKEND_2}/hubspot-contacts?email=${email}&refresh_token=${user?.integrations?.hubspot?.refresh_token}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }).then(response => response.json());

        setHubspotUsers(res.results)
      } catch (error) {
        console.error("Error contacts list:", error);
      }
    }

    const defContacts = async () => {
      if (hubSpotIntegration) {
        getOwnersList()
      }
    }
    defContacts()
  }, [hubSpotIntegration])

  return (


    <Stack
      gap={2}
      ref={section9Ref}
      id="section9"
      justifyContent={"space-between"}
      sx={{
        flexWrap: "wrap",
        width: "100%",
        transition: "all 0.3s ease-in-out",
      }}

    >



      {/* Pipedrive Section */}
      <Stack
        direction={"row"}

        justifyContent={"space-between"}
        sx={{ flexWrap: "wrap" }}
      >

        {/* Pipedrive Settings Section */}

        <PipedriveIntegrationSettings updateIntegrationAutoSyncFlag={updateIntegrationAutoSyncFlag} openSnackbar={openSnackbar} />

        {/* Zapier Settings Section */}
        <ZapierIntegrationSettings />
      </Stack>
      <Stack
        direction={"row"}

        justifyContent={"space-between"}
        sx={{ flexWrap: "wrap" }}
      >
        <SalesforceIntegrationSettings />

        {/* Zoho Settings Section */}
        <Card sx={{
          marginTop: "10px",
          marginLeft: "4px",
          width: { xs: "100%", md: "49%" },
          border: "1px solid #EAECF0",

        }}
          className="glass-wrapper"
        >
          <CardContent style={{ padding: "24px" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  style={{ marginRight: "10px" }}
                  width="28"
                  height="28"
                  src="https://uploads-ssl.webflow.com/594d8d64dc2b583adddea915/5a7fd806c7942700013399da_zoho.png"
                  alt="external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo"
                />
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: theme.palette.text.primary,
                  }}
                >
                  Zoho
                </Typography>
              </div>

              {
                hasZohoIntegration ? (
                  <div
                    style={{
                      textAlign: "center",
                      display: "flex",
                      alignItems: "center",
                      color: zohoExpired ? "red" : "green",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={initiateZohoOAuth}
                      sx={{


                        marginRight: "5px",
                        marginLeft: "5px",
                        padding: "5px 10px",
                        fontSize: "0.8em"
                      }}

                    >
                      <>
                        {/* <img
                              src={LinkIcon}
                              style={{ marginRight: "8px" }}
                            /> */}
                        <AiOutlineLink
                          style={{
                            marginRight: "4px",
                            height: "23px",
                            fontSize: "38px",

                          }}
                        />
                      </>
                      Reconnect
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() =>
                        handleDisconnect(
                          user?.dbRef?.email,
                          INTEGRATIONTYPE.ZOHO
                        )
                      }
                      sx={{


                        marginRight: "10px",
                        marginLeft: "5px",
                        padding: "5px 10px",
                        fontSize: "0.8em"
                      }}
                    // className="glass-button-secondary-25"
                    >
                      <>
                        {/* <img
                              src={Linkbroken}
                              style={{ marginRight: "8px" }}
                            /> */}
                        <PiLinkBreakBold

                          style={{
                            marginRight: "8px",

                            fontSize: "26px",
                            color: theme.palette.text.primary,
                          }}
                        />
                      </>
                      Disconnect
                    </Button>
                  </div>
                ) : <Button
                  variant="contained"
                  color="primary"
                  onClick={initiateZohoOAuth}
                  size="small"
                  sx={{


                    marginLeft: "5px",
                    width: "max-content",
                    padding: "5px 10px",
                    fontSize: "0.8em"
                  }}
                // className="glass-button-secondary-25"
                >
                  {/* <img
                        src={LinkIcon}
                        style={{ marginRight: "8px" }}
                      /> */}
                  <>
                    <AiOutlineLink
                      style={{
                        marginRight: "4px",
                        height: "23px",
                        fontSize: "38px",

                      }}
                    />


                  </>
                  Connect
                </Button>
              }
            </div>

            <Grid item xs={4} paddingTop={"20px"}>
              <Typography fontSize={"16px"} paddingTop={"20px"} color={theme => theme.palette.text.primary}>ZoHo</Typography>

              <Typography fontSize={"16px"} paddingTop={"5px"} color={theme => theme.palette.text.secondary}>
                Integrate your Zoho Automations to
                simplify and automate your daily tasks effortlessly
              </Typography>

            </Grid>
            <div style={{ display: "flex", margin: "16px 0px" }} >
              {
                hasZohoIntegration ? (
                  <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item md={4}>
                      <Typography fontSize={"14px"} fontWeight={400}>{'Auto Transfer Leads'}</Typography>
                    </Grid>

                    {
                      hasZohoIntegration &&
                        isZohoAuto &&
                        zohoAutoExecution !== "Invalid date" ? (
                        <Grid item md={4} sx={{ textAlign: "center" }}>
                          <Typography
                            style={{
                              color: theme.palette.text.primary,
                              fontWeight: "bold",
                            }}
                            fontSize={12}
                          >
                            Last Auto Sync
                          </Typography>
                          <Typography fontSize={12} style={{ color: theme.palette.text.secondary }}>
                            {zohoAutoExecution}
                          </Typography>
                        </Grid>
                      ) : null
                    }

                    <Grid item md={4} sx={{ textAlign: "right" }}>
                      <Switch
                        checked={isZohoAuto}
                        onChange={async ({
                          target: { checked },
                        }) => {
                          setIsZohoAuto(checked);
                          try {
                            await updateIntegrationAutoSyncFlag(
                              user.email,
                              INTEGRATIONTYPE.ZOHO,
                              checked
                            );
                          } catch (ex) {
                            console.log({ ex });
                          }
                        }}
                        value={INTEGRATIONTYPE.ZOHO}
                      />
                    </Grid>
                  </Grid>
                ) : null
              }

            </div>
          </CardContent>
        </Card>
      </Stack>

      {/* HubSpot Settings Section */}
      <Card style={{ marginTop: '1rem', marginLeft: "4px" }} className="glass-wrapper">
        <CardContent style={{ padding: "24px" }}>
          <div>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // flexDirection: "row",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  style={{ marginRight: "10px" }}
                  width="28"
                  height="28"
                  src="https://img.icons8.com/external-tal-revivo-color-tal-revivo/48/external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo.png"
                  alt="external-hubspot-a-developer-and-marketer-of-software-products-logo-color-tal-revivo"
                />
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: theme.palette.text.primary,
                  }}
                >
                  Hubspot
                </Typography>
              </div>

              {hasHubSpotIntegration ? (
                <div
                  style={{
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    color: hubspotExpired ? "red" : "green",
                  }}
                >
                  {/* <Typography style={{ fontSize: "13px" }}>
                                  Expires:
                                  {moment
                                    .tz(
                                      hubSpotIntegration.expires_at,
                                      APP_TIMEZONE
                                    )
                                    .format("MM/DD/YYYY hh:mm A z")}
                                </Typography> */}
                  {/* <Button
                          variant="outlined"
                          onClick={() => setIsDrawerOpen(true)}
                          sx={{
                            color: theme.palette.text.primary,
                            border: "1px solid #ECE9F5",
                            marginRight: "5px",
                            marginLeft: "5px",
                            padding: "5px 10px",
                            fontSize: "0.8em",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px"
                          }}
                        >
                          <MdOutlineHistory
                            style={{
                              fontSize: "18px",
                              color: theme.palette.text.primary,
                            }}
                          />
                          View Logs
                        </Button> */}
                  <Button
                    variant="contained"
                    href={`${API_SERVICE_BACKEND_2}/oauth?userEmail=${email}`}
                    sx={{

                      // border: "1px solid #ECE9F5",
                      marginRight: "5px",
                      marginLeft: "5px",
                      padding: "5px 10px",
                      fontSize: "0.8em"
                    }}
                  // className="glass-button-secondary-25"
                  >
                    <>

                      <AiOutlineLink
                        style={{
                          marginRight: "4px",
                          height: "23px",
                          fontSize: "38px",

                        }}
                      />

                    </>
                    Reconnect
                  </Button>

                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleDisconnect(
                        user?.dbRef?.email,
                        INTEGRATIONTYPE.HUBSPOT
                      )
                    }
                    sx={{

                      // border: "1px solid #ECE9F5",
                      marginRight: "10px",
                      marginLeft: "5px",
                      padding: "5px 10px",
                      fontSize: "0.8em"
                    }}
                  // className="glass-button-secondary-25"
                  >
                    <>
                      {/* <img
                              src={Linkbroken}
                              style={{ marginRight: "8px" }}
                            /> */}
                      <PiLinkBreakBold

                        style={{
                          marginRight: "8px",

                          fontSize: "26px",

                        }}
                      />

                    </>
                    Disconnect
                  </Button>
                </div>
              ) : <Button
                variant="contained"
                href={`${API_SERVICE_BACKEND_2}/oauth?userEmail=${email}`}
                size="small"
                sx={{

                  // border: "1px solid #ECE9F5",
                  marginLeft: "5px",
                  width: "max-content",
                  padding: "5px 10px",
                  fontSize: "0.8em"
                }}
              // className="glass-button-secondary-25"
              >
                <>
                  {/* <img
                          src={LinkIcon}
                          style={{ marginRight: "8px" }}
                        /> */}
                  <AiOutlineLink
                    style={{
                      marginRight: "4px",
                      height: "23px",
                      fontSize: "38px",

                    }}
                  />


                </>
                Connect
              </Button>
              }
            </div>
            <Grid item xs={4} paddingTop={"10x"}>
              <Typography fontSize={"16px"} paddingTop={"20px"} color={theme => theme.palette.text.primary}>HubSpot</Typography>

              <Typography fontSize={"16px"} paddingTop={"5px"} color={theme => theme.palette.text.secondary}>
                Integrate your Hubspot Automations to
                simplify and automate your daily tasks effortlessly
              </Typography>

            </Grid>
            {
              hasHubSpotIntegration &&
              <>
                {/* <Divider style={{ marginTop: "8px" }}/> */}
                <div style={{ display: "flex", margin: "16px 0px" }}>
                  {
                    hasHubSpotIntegration ? (
                      <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item md={4}>
                          <Typography fontSize={"14px"} fontWeight={400}>{'Auto Transfer Leads'}</Typography>
                        </Grid>

                        {
                          hasHubSpotIntegration &&
                            isHubspotAuto &&
                            hubspotAutoExecution !== "Invalid date" ? (
                            <Grid item md={4} sx={{ textAlign: "center" }}>
                              <Typography
                                style={{
                                  color: theme.palette.text.primary,
                                  fontWeight: "bold",
                                }}
                                fontSize={12}
                              >
                                Last Auto Sync
                              </Typography>
                              <Typography fontSize={12} style={{ color: theme.palette.text.secondary }}>
                                {hubspotAutoExecution}
                              </Typography>
                            </Grid>
                          ) : null
                        }

                        {/* <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={isHubspotAuto}
                                      onChange={async ({
                                        target: { checked },
                                      }) => {
                                        setIsHubspotAuto(checked);
                                        try {
                                          await updateIntegrationAutoSyncFlag(
                                            user.email,
                                            "hubspot",
                                            checked
                                          );
                                        } catch (ex) {
                                          console.log({ ex });
                                        }
                                      }}
                                      value={"hubspot"}
                                    />
                                  }
                                  label="Auto Transfer Leads"
                                  labelPlacement="start"
                                />
                              </FormGroup> */}

                        <Grid item md={4} sx={{ textAlign: "right" }}>
                          <Switch
                            checked={isHubspotAuto}
                            onChange={async ({
                              target: { checked },
                            }) => {
                              setIsHubspotAuto(checked);
                              try {
                                await updateIntegrationAutoSyncFlag(
                                  user.email,
                                  INTEGRATIONTYPE.HUBSPOT,
                                  checked
                                );
                              } catch (ex) {
                                console.log({ ex });
                              }
                            }}
                            value={INTEGRATIONTYPE.HUBSPOT}
                          />
                        </Grid>
                      </Grid>
                    ) : null
                  }

                </div>
                <Card
                  style={{
                    boxShadow: "none",
                    background: "transparent",
                    //border: "1px solid #ddd"
                  }}

                >
                  <CardContent sx={{ p: 0 }}>
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="ownertype-group-label"
                        name="ownertype-group"
                        defaultValue={hubspotOwnerAssignment}
                      >
                        <FormControlLabel value={HUBSPOTOWNERASSIGNMENTTYPE.DEFAULT} control={<Radio onChange={(e) => changeOwnerAssignmentStatus(e.target.value)} />} label="Use Single Default Owner for All Agents" checked={HUBSPOTOWNERASSIGNMENTTYPE.DEFAULT == hubspotOwnerAssignment} />
                        <FormControlLabel value={HUBSPOTOWNERASSIGNMENTTYPE.SEPARATE} control={<Radio onChange={(e) => changeOwnerAssignmentStatus(e.target.value)} />} label="Use Different Owner for Different Agents" checked={HUBSPOTOWNERASSIGNMENTTYPE.SEPARATE == hubspotOwnerAssignment} />
                      </RadioGroup>
                    </FormControl>
                  </CardContent>

                  <Divider />

                  <CardContent sx={{ pt: 1 }}>
                    {
                      hubspotOwnerAssignment === HUBSPOTOWNERASSIGNMENTTYPE.DEFAULT &&
                      <form onSubmit={addOwner}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6} >
                            <FormControl sx={{ maxWidth: "300px", width: "100%" }}>
                              <InputLabel id="select-owner-label">Select Owner</InputLabel>
                              <Select
                                labelId="select-owner-label"
                                id="select-owner"
                                value={selectedOwner}
                                label="Select Owner"
                                className="glass-dropdown"
                                onChange={(e) => {
                                  setSelectedOwner(e.target.value)
                                }}
                              >
                                {
                                  hubspotUsers?.map((hubspotUser, index) => {
                                    return <MenuItem key={index} value={hubspotUser.id}>{hubspotUser.firstName}</MenuItem>
                                  })
                                }
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Button
                          type="submit"
                          variant="contained"
                          size="small"
                          color="primary"
                          sx={{
                            mt: 1,
                            ml: "auto",
                            borderRadius: "8px",
                            backgroundColor: theme.palette.primary.main,
                            color: "white",
                            "&:hover": {
                              backgroundColor: "theme.palette.primary.main",
                            },
                          }}
                          startIcon={<Person />}
                        >
                          Save Changes
                        </Button>
                      </form>
                    }

                    {
                      hubspotOwnerAssignment === HUBSPOTOWNERASSIGNMENTTYPE.SEPARATE && (
                        userCampaignLoading === false ? <DifferentOwners userCampaigns={userCampaigns} hubspotUsers={hubspotUsers} props={{
                          userEmail: user.email,
                          type: INTEGRATIONTYPE.HUBSPOT,
                          hubspotOwnerAssignment: hubspotOwnerAssignment
                        }}
                          openSnackbar={openSnackbar}
                        /> : <div style={{ textAlign: "center" }}><CircularProgress /></div>
                      )
                    }
                  </CardContent>
                </Card>
              </>
            }
          </div>
        </CardContent>
      </Card>
     

   
      <Snackbar
        open={Baropen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} style={{ color: "white" }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <LogDrawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} />
                    {/* Website Tracking Integration Section */}
                    <Card style={{ marginTop: '1rem' }} className="glass-wrapper">
              <CardContent style={{ padding: "24px" }}>
                <div
                  style={{
                    width: "100%",
                    display: "flex", 
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "8px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TbWorld
                      style={{
                        marginRight: "10px",
                        fontSize: "28px",
                        color: theme.palette.text.primary,
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: theme.palette.text.primary,
                      }}
                    >
                      Website Tracking
                    </Typography>
                  </div>
                </div>

                <Divider/>
                <div style={{ margin: "16px 0px" }}>
                  <Typography fontSize="14px" sx={{ mb: 2 }}>
                    Track and capture visitor activity on your website by integrating our tracking script.
                  </Typography>

                  {userDomains && userDomains.length > 0 ? (
                    <div style={{ marginBottom: '16px' }}>
                      <Typography fontSize="14px" sx={{ mb: 1 }}>
                        Your domains:
                      </Typography>
                      <Stack direction="row" spacing={1} flexWrap="wrap">
                        {userDomains.map((domainObj, index) => (
                          <Chip
                            key={index}
                            label={domainObj.domain}
                            clickable
                            onClick={() => {
                              if (selectedDomain === domainObj.domain) {
                                // Unselect if clicking the same domain again
                                setDomain('');
                                setSelectedDomain('');
                              } else {
                                // Select the new domain
                                setDomain(domainObj.domain);
                                setSelectedDomain(domainObj.domain);
                              }
                            }}
                            color={selectedDomain === domainObj.domain ? "primary" : "default"}
                            sx={{ mb: 1 }}
                          />
                        ))}
                      </Stack>
                    </div>
                  ) : (
                    <Typography fontSize="14px" sx={{ mb: 2, fontStyle: 'italic', color: 'text.secondary' }}>
                      No domains integrated yet.
                    </Typography>
                  )}

                  <div style={{ display: 'flex', gap: 2 }}>
                    <Button
                      variant="contained"
                      onClick={() => setIntegrationModalOpen(true)}
                      startIcon={<AiOutlinePlus />}
                    >
                      Integrate Website
                    </Button>
                    {selectedDomain && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setDomain(selectedDomain);
                          setShowScript(true);
                          setIntegrationModalOpen(true);
                        }}
                        startIcon={<BsCodeSlash />}
                        sx={{ ml: 2 }}
                      >
                        View Script
                      </Button>
                    )}
                  
                    {selectedDomain && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          if (domain) {
                            const verifyBtn = document.activeElement;
                            
                            // Change button text to "Verifying..."
                            verifyBtn.textContent = "Verifying...";
                            verifyBtn.disabled = true;
                            
                            // Check if domain exists by trying to fetch its favicon
                            const img = new Image();
                            img.onload = async () => {
                              // Domain exists, attempt backend verification
                              try {
                                const response = await verifyDomain(organizationId, domain);
                                if (response.success) {
                                  // Only show success if both frontend and backend verify
                                  verifyBtn.innerHTML = '<span class="MuiButton-startIcon MuiButton-iconSizeMedium css-1d6wzja-MuiButton-startIcon"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 0 0-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path></svg></span>Verified';
                                  verifyBtn.style.backgroundColor = '#e6f7e6';
                                  verifyBtn.style.color = '#2e7d32';
                                  verifyBtn.style.borderColor = '#2e7d32';
                                  
                                  setIsDomainIntegrated(true);
                                  toast.success('Website verified successfully!');
                                } else {
                                  throw new Error('Backend verification failed');
                                }
                              } catch (error) {
                                verifyBtn.textContent = "Verify Integration";
                                verifyBtn.disabled = false;
                                toast.error('Backend verification failed');
                              }
                            };
                            
                            img.onerror = () => {
                              // Domain doesn't exist or can't be reached
                              verifyBtn.textContent = "Verify Integration";
                              verifyBtn.disabled = false;
                              toast.error('Wrong website or domain cannot be reached');
                            };
                            
                            // Try to fetch favicon from the domain
                            const cleanDomain = domain.replace(/^https?:\/\//, '').replace(/\/$/, '');
                            if (!cleanDomain.startsWith('www.')) {
                              img.src = `https://www.${cleanDomain}/favicon.ico?${new Date().getTime()}`;
                            } else {
                              img.src = `https://${cleanDomain}/favicon.ico?${new Date().getTime()}`; 
                            }
                            
                            // Set a timeout in case the request hangs
                            setTimeout(() => {
                              if (!img.complete) {
                                img.src = "";  // Cancel the request
                                verifyBtn.textContent = "Verify Integration";
                                verifyBtn.disabled = false;
                                toast.error('Verification timed out. Please try again.');
                              }
                            }, 5000);
                          } else {
                            toast.warning('Please enter a domain first');
                          }
                        }}
                        startIcon={<AiOutlineCheck />}
                        sx={{ ml: 2 }}
                      >
                        Verify Integration
                      </Button>
                    )}
                  </div>
                </div>

                {/* Integration Modal */}
                <Dialog 
                  open={integrationModalOpen}
                  onClose={() => setIntegrationModalOpen(false)}
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle>
                    Integrate Website Tracking
                  </DialogTitle>
                  <DialogContent>
                    <div style={{ display: 'flex', alignItems: 'flex-start', gap: 2, marginTop: 16 }}>
                      <TextField
                        fullWidth
                        label="Website Domain"
                        placeholder="example.com"
                        value={domain}
                        onChange={(e) => setDomain(e.target.value)}
                      />
                      <Button
                        variant="contained"
                        onClick={() => {
                          if (!domain || domain.trim() === '') {
                            toast.warning('Please enter a valid domain');
                            return;
                          }
                          
                          // Change button text to "Verifying..."
                          const getScriptBtn = document.activeElement;
                          getScriptBtn.textContent = "Verifying...";
                          getScriptBtn.disabled = true;
                          
                          // Check if domain exists by trying to fetch its favicon
                          const img = new Image();
                          img.onload = async () => {
                            // Domain exists, now make API call to add domain
                            try {
                              // Replace with your actual API endpoint
                              const response = await addDomain(organizationId, domain.trim());
                              
                              if (response.status === "ok") {
                                setIsDomainIntegrated(true);
                                setShowScript(true);
                                toast.success('Domain verified and added successfully!');
                              } else {
                                toast.error('Failed to add domain. Please try again.');
                              }
                            } catch (error) {
                              console.error('Error adding domain:', error);
                              toast.error('An error occurred. Please try again.');
                            } finally {
                              getScriptBtn.textContent = "Get Script";
                              getScriptBtn.disabled = false;
                            }
                          };
                          
                          img.onerror = () => {
                            // Domain doesn't exist or can't be reached
                            getScriptBtn.textContent = "Get Script";
                            getScriptBtn.disabled = false;
                            toast.error('Domain cannot be reached. Please check and try again.');
                          };
                          
                          // Try to fetch favicon from the domain
                          // Handle various domain formats (with or without protocol, www, trailing slashes)
                          let cleanDomain = domain.trim().replace(/^https?:\/\//, '').replace(/\/$/, '');
                          
                          // If domain contains path or query parameters, extract just the domain part
                          if (cleanDomain.includes('/')) {
                            cleanDomain = cleanDomain.split('/')[0];
                          }
                          
                          // Remove www. if present
                          cleanDomain = cleanDomain.replace(/^www\./, '');
                          
                          // Add www. for better favicon detection chances
                          if (!cleanDomain.startsWith('www.')) {
                            cleanDomain = 'www.' + cleanDomain;
                          }
                          
                          img.src = `https://${cleanDomain}/favicon.ico?${new Date().getTime()}`;
                          // Set a timeout in case the request hangs
                          setTimeout(() => {
                            if (!img.complete) {
                              img.src = "";  // Cancel the request
                              getScriptBtn.textContent = "Get Script";
                              getScriptBtn.disabled = false;
                              toast.error('Verification timed out. Please try again.');
                            }
                          }, 5000);
                        }}
                      >
                        Verify
                      </Button>
                    </div>
                    
                    {domain && showScript && (
                      <>
                        <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                          Installation Instructions
                        </Typography>
                        
                        <Typography variant="body2" sx={{ mb: 2 }}>
                          Copy the following script and add it to your website's HTML just before the closing &lt;/head&gt; tag:
                        </Typography>
                        
                        <FormControl fullWidth sx={{ mb: 3, mt: 1 }}>
                          <InputLabel id="platform-select-label">Select Your Platform</InputLabel>
                          <Select
                            labelId="platform-select-label"
                            id="platform-select"
                            label="Select Your Platform"
                            defaultValue="wordpress"
                            onChange={(e) => setSelectedPlatform(e.target.value)}
                            value={selectedPlatform}
                          >
                            <MenuItem value="wordpress">WordPress</MenuItem>
                            <MenuItem value="webflow">Webflow</MenuItem>
                            <MenuItem value="shopify">Shopify</MenuItem>
                            <MenuItem value="wix">Wix</MenuItem>
                            <MenuItem value="squarespace">Squarespace</MenuItem>
                            <MenuItem value="other">Other Platforms</MenuItem>
                          </Select>
                        </FormControl>
                        
                        {selectedPlatform === "wordpress" && (
                          <Card sx={{ mb: 2, p: 2, borderLeft: `4px solid ${theme.palette.primary.main}` }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                              WordPress Installation
                            </Typography>
                            <Stack spacing={1}>
                              <Typography variant="body2">
                                <strong>Option 1:</strong> Using a plugin
                              </Typography>
                              <ol style={{ margin: 0, paddingLeft: '20px' }}>
                                <li>Install and activate the "Insert Headers and Footers" plugin</li>
                                <li>Go to Settings → Insert Headers and Footers</li>
                                <li>Paste the tracking code into the "Scripts in Header" section</li>
                                <li>Click "Save"</li>
                              </ol>
                              
                              <Typography variant="body2" sx={{ mt: 1 }}>
                                <strong>Option 2:</strong> Editing theme files
                              </Typography>
                              <ol style={{ margin: 0, paddingLeft: '20px' }}>
                                <li>Go to Appearance → Theme Editor</li>
                                <li>Select your theme's header.php file</li>
                                <li>Paste the code just before the <code>&lt;/head&gt;</code> tag</li>
                                <li>Click "Update File"</li>
                              </ol>
                            </Stack>
                          </Card>
                        )}
                        
                        {selectedPlatform === "webflow" && (
                          <Card sx={{ mb: 2, p: 2, borderLeft: `4px solid ${theme.palette.info.main}` }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                              Webflow Installation
                            </Typography>
                            <ol style={{ margin: 0, paddingLeft: '20px' }}>
                              <li>Open your Webflow project</li>
                              <li>Navigate to Project Settings → Custom Code</li>
                              <li>Paste the tracking code in the "Head Code" section</li>
                              <li>Click "Save Changes"</li>
                              <li>Publish your site for changes to take effect</li>
                            </ol>
                          </Card>
                        )}
                        
                        {selectedPlatform === "shopify" && (
                          <Card sx={{ mb: 2, p: 2, borderLeft: `4px solid ${theme.palette.success.main}` }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                              Shopify Installation
                            </Typography>
                            <ol style={{ margin: 0, paddingLeft: '20px' }}>
                              <li>Log in to your Shopify admin panel</li>
                              <li>Go to Online Store → Themes</li>
                              <li>Find your current theme and click "Actions" → "Edit code"</li>
                              <li>Open the theme.liquid file (under Layout)</li>
                              <li>Locate the <code>&lt;/head&gt;</code> tag and paste the code just before it</li>
                              <li>Click "Save"</li>
                            </ol>
                          </Card>
                        )}
                        
                        {selectedPlatform === "wix" && (
                          <Card sx={{ mb: 2, p: 2, borderLeft: `4px solid ${theme.palette.warning.main}` }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                              Wix Installation
                            </Typography>
                            <ol style={{ margin: 0, paddingLeft: '20px' }}>
                              <li>Log in to your Wix account and open your site</li>
                              <li>Click on Settings (gear icon) in the dashboard</li>
                              <li>Select "Advanced" → "Custom Code"</li>
                              <li>Click "+ Add Custom Code"</li>
                              <li>Paste the tracking code</li>
                              <li>Set "Add Code to Pages" to "All pages"</li>
                              <li>Set "Place Code in" to "Head"</li>
                              <li>Click "Apply" and publish your site</li>
                            </ol>
                          </Card>
                        )}
                        
                        {selectedPlatform === "squarespace" && (
                          <Card sx={{ mb: 2, p: 2, borderLeft: `4px solid ${theme.palette.secondary.main}` }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                              Squarespace Installation
                            </Typography>
                            <ol style={{ margin: 0, paddingLeft: '20px' }}>
                              <li>Log in to your Squarespace account</li>
                              <li>Go to Settings → Advanced → Code Injection</li>
                              <li>Paste the tracking code into the "Header" field</li>
                              <li>Click "Save"</li>
                              <li>Your changes will be applied automatically</li>
                            </ol>
                          </Card>
                        )}
                        
                        {selectedPlatform === "other" && (
                          <Card sx={{ mb: 2, p: 2, borderLeft: `4px solid ${theme.palette.grey[500]}` }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1 }}>
                              Other Platforms
                            </Typography>
                            <Typography variant="body2">
                              For custom websites or other platforms, add the tracking script to your HTML template files just before the <code>&lt;/head&gt;</code> tag.
                            </Typography>
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              If you're using a CMS or website builder not listed above, look for options like:
                            </Typography>
                            <ul style={{ margin: '8px 0', paddingLeft: '20px' }}>
                              <li>Header scripts</li>
                              <li>Custom HTML</li>
                              <li>Code injection</li>
                              <li>Site-wide code</li>
                            </ul>
                          </Card>
                        )}

                        <Card sx={{ 
                          p: 2, 
                          backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.1)' : 'rgba(0,0,0,0.03)',
                          position: 'relative',
                          borderRadius: '8px'
                        }}>
                          <pre style={{ 
                            overflow: 'auto', 
                            margin: 0,
                            padding: '10px',
                            color: theme.palette.text.primary,
                            fontFamily: 'monospace',
                            fontSize: '13px'
                          }}>
                          {`<script async src="https://cdn.jsdelivr.net/gh/leadinglydevelopment/pixel-tracking@latest/pixel.js" data-site-id="${domain.trim()}" data-user-id="${organizationId}"></script>`}
                          </pre>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={copyToClipboard}
                            startIcon={<BsCodeSlash />}
                            sx={{
                              position: 'absolute',
                              top: '8px',
                              right: '8px',
                              fontSize: '0.7rem',
                              padding: '4px 8px'
                            }}
                          >
                            Copy
                          </Button>
                        </Card>

                        <Typography variant="body2" sx={{ mt: 2 }}>
                          After adding the script, click "Done" and use the Verify Integration button to confirm it's working correctly.
                        </Typography>
                      </>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setIntegrationModalOpen(false)}>Cancel</Button>
                    <Button 
                      variant="contained"
                      onClick={handleIntegration}
                      disabled={!domain}
                    >
                      Done
                    </Button>
                  </DialogActions>
                </Dialog>
              </CardContent>
            </Card>

    </Stack>


  );
};

export default IntegrationSettings;
