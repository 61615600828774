import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaFileAlt, FaDownload } from "react-icons/fa";
import { API_SERVICE_BACKEND } from "src/config";
import { Box, Dialog, DialogTitle, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { DoneAll } from "@mui/icons-material";

const AttachmentViewer = ({ messageId,isSender, attachment, timestamp, seen }) => {
  const [fileUrl, setFileUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFile = async () => {
      
      try {
        // Simulating an API call to get the file URL
        const response = await axios.get(`${API_SERVICE_BACKEND}/unipile/linkedin/getAttachment?messageId=${messageId}&attachmentId=${attachment?.id}`, {
          responseType: "blob",
        });

        // response will be original file not url
        if (!response.data) {
          throw new Error("Failed to load attachment");
        }

        const file = new Blob([response.data], { type: response.headers["content-type"] });
        setFileUrl(URL.createObjectURL(file));
      } catch (err) {
        setError("Failed to load attachment");
      } finally {
        setLoading(false);
      }
    };

    fetchFile();
  }, [attachment?.id]);
  const [showPreview, setShowPreview] = useState(false);

  if (loading) return <p>Loading attachment...</p>;
  if (error) return <p style={{ color: "red" }}>{error}</p>;


  const handleImageClick = () => {
    setShowPreview(true);
  };

  return (
    <div className="border p-3 rounded-md shadow-md max-w-md">
      {attachment.type.startsWith("img") ? (
        <Box sx={{
          padding: "5px",
          borderRadius: "4px",
          bgcolor: isSender === 1 ? "#E6F7FF" : "#F3F2F1",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          color: "white",
          gap: "10px",
          // width:275
        }}>
          <img
            src={fileUrl}
            alt="Attachment"
            className="rounded cursor-pointer"
            width={275}
            onClick={handleImageClick}
          />
           <Box 
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        marginTop: "5px",
        color: "black",
        gap:1
      }}>
        <Typography variant="caption" sx={{ fontSize: "x-small" }}>
          {moment(timestamp).format("hh:mm A")}
        </Typography>
     {isSender === 1 &&  <Tooltip title={seen == 1 ?"Read":"Delivered"}>
        {/* show chat sending message time */}
        <DoneAll sx={{
          color:seen === 1? "#00AAE4":"disabled",
          alignSelf: "flex-end",
          fontSize: "small"
        }} />
      </Tooltip>}
      </Box>
          {showPreview && (
            <Dialog open={showPreview} onClose={() => setShowPreview(false)} >
              <DialogTitle>Preview</DialogTitle>
              {/* <DialogContent sx={{
                width:"fit-content",
                height:"fit-content"
              }}> */}
                <img
                  src={fileUrl}
                  alt="Attachment Preview"
                  // className="w-full h-auto rounded"
                  width={600}
                />
              {/* </DialogContent> */}
            </Dialog>
          )}
        </Box>
      ) : attachment.type.startsWith("video") ? (
        <Box sx={{
          padding: "10px",
          borderRadius: "4px",
          bgcolor: isSender === 1 ? "#E6F7FF" : "#F3F2F1",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          color: "white",
          gap: "10px",
          width:275
        }}>
        <video controls className="rounded" width={250} height={150}>
          <source src={fileUrl}  />
          Your browser does not support the video tag.
        </video>
        <Box 
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "end",
        color: "black",
        flexDirection: "column",
        width: "100%",
        marginTop: "5px",
        gap:1
      }}>
        <Typography variant="caption" sx={{ fontSize: "x-small" }}>
          {moment(timestamp).format("hh:mm A")}
        </Typography>
     {isSender === 1 &&  <Tooltip title={seen == 1 ?"Read":"Delivered"}>
        {/* show chat sending message time */}
        <DoneAll sx={{
          color:seen === 1? "#00AAE4":"disabled",
          alignSelf: "flex-end",
          fontSize: "small"
        }} />
      </Tooltip>}
      </Box>
        </Box>
      ) : (
        <Box  sx={{
          padding: "10px",
          borderRadius: "4px",
          bgcolor: isSender === 1 ? "#E6F7FF" : "#F3F2F1",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          color: "white",
          gap: "10px"
        }}>
          <Box sx={{
            display: "flex",
            gap: 2
          }}>
          <Typography sx={{
            display: "flex",
            alignItems: "center",
            color: "black",
            gap: 2
          }}>
          <FaFileAlt className="text-gray-500 text-2xl" />
            {attachment?.file_name ?? attachment.id}
            </Typography>
          {fileUrl && (
            <a href={fileUrl} download className="ml-auto text-blue-500">
              <FaDownload />
            </a>
          )}
          </Box>
             <Box 
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        width: "100%",
        marginTop: "5px",
        gap:1
      }}>
        <Typography variant="caption" sx={{ fontSize: "x-small", color:"black" }}>
          {moment(timestamp).format("hh:mm A")}
        </Typography>
     {isSender === 1 &&  <Tooltip title={seen == 1 ?"Read":"Delivered"}>
        {/* show chat sending message time */}
        <DoneAll sx={{
          color:seen === 1? "#00AAE4":"disabled",
          alignSelf: "flex-end",
          fontSize: "small"
        }} />
      </Tooltip>}
      </Box>
        </Box>
      )}
    </div>
  );
};

export default AttachmentViewer;