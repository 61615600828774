import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  useTheme
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { SequenceStepTimeline } from "./TimelineSteps/SequenceStepTimeline";
import { Box } from "@mui/system";

export const TimeLinePageV2 = (props) => {
  const { open, setTimelineOpen, timelineData, sequence , lead} = props;
  const theme = useTheme()
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setTimelineOpen(false)}
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        
      >
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid #F8F7FC",
          padding: "10px 20px",
          position: "relative",
        }}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: "700", color: theme.palette.primary.main }}
          >
            Sequence
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              setTimelineOpen(false);
            }}
            style={{}}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{ margin: 0, padding: 0 , height :"80vh" }} >
          <Box
            sx={{
              "& .MuiStepConnector-line": {
                borderLeftColor: "divider",
                borderLeftWidth: 2,
                ml: 1,
              },
              "& .MuiStepConnector-root.Mui-active .MuiStepConnector-line": {
                borderColor: "#00AA38",
              },
              "& .MuiStepConnector-root.Mui-completed .MuiStepConnector-line": {
                borderColor: "#00AA38",
              },
              "& .MuiStepLabel-root .Mui-completed": {
                color: "#00AA38",
              },
              "& .MuiStepLabel-root .Mui-active": {
                color: theme.palette.primary.main,
              },
              border: "1px solid #F8F7FC",
              backgroundColor: "white",
              borderRadius: "16px",
              padding: "10px",
            }}
            className="glass-wrapper"
          >
            <SequenceStepTimeline
              emailSeqStatus={timelineData?.emailSeqStatus}
              steps={sequence?.value?.steps ?? sequence?.steps ?? []}
              lead={lead}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

