import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getSessionStorage } from "src/utils/storage";
import toast from "react-hot-toast";
import { API_SERVICE_BACKEND } from "src/config";
import {
  TextInput,
  NumberInput,
  SwitchInput,
  SelectInput,
} from "./InputComponents"; // Import input components
const initialFormState = {
  CONTACT_LOOKUPS: 1000000,
  CONTACT_EXPORTS: 1000000,
  CONTACT_IMPORTS: 1000000,
  EMAIL_SENDING: 1000000,
  EMAIL_VALIDATION: null,
  DATA_ENRICHMENT: null,
  AI_WRITER: null,
  AI_AUTO_REPLIES: false,
  AI_AUTOPILOT: false,
  INTENT_DATA_TOPICS: null,
  ADVANCED_FILTERS: false,
  USER_MAILBOXES: null,
  DFY_DOMAINS: null,
  AB_TESTING: false,
  GLOBAL_BLOCKLIST: false,
  DOMAIN_TRACKING: false,
  LINKEDIN_ACCOUNTS: null,
  SMS_ACCOUNTS: 0,
  WHATSAPP_ACCOUNTS: 0,
  UNIFIED_INBOX: false,
  DEAL_PIPELINES: 0,
  CAMPAIGN_ANALYTICS: true,
  GLOBAL_ANALYTICS: false,
  DASHBOARD: false,
  WORKSPACES: false,
  TEAM_MEMBERS: null,
  ZAPIER: false,
  HUBSPOT: false,
  ZOHO: false,
  SALESFORCE: false,
  WEBINARS: true,
  CHAT_SUPPORT: true,
  INTEGRATIONS: false,
};

const basic = {
  // Credits & Limits
  CONTACT_LOOKUPS: 1000000,
  CONTACT_EXPORTS: 1000000,
  CONTACT_IMPORTS: 1000000,
  EMAIL_SENDING: 1000000,
  EMAIL_VALIDATION: 5000,
  DATA_ENRICHMENT: 5000,
  AI_WRITER: 5000,
  // Artificial Intelligence
  AI_AUTO_REPLIES: false,
  AI_AUTOPILOT: false,
  // Contact Database
  INTENT_DATA_TOPICS: 3,
  ADVANCED_FILTERS: false,
  // Campaigns & Sequences
  USER_MAILBOXES: 1,
  DFY_DOMAINS: null,
  AB_TESTING: true,
  GLOBAL_BLOCKLIST: true,
  DOMAIN_TRACKING: false,
  // Multichannel Outreach
  LINKEDIN_ACCOUNTS: 1,
  SMS_ACCOUNTS: 0,
  WHATSAPP_ACCOUNTS: 0,
  // Inbox & CRM
  UNIFIED_INBOX: true,
  DEAL_PIPELINES: 1,
  // Analytics & Reporting
  CAMPAIGN_ANALYTICS: true,
  GLOBAL_ANALYTICS: true,
  DASHBOARD: true,
  // Additional Functionality
  WORKSPACES: true,
  TEAM_MEMBERS: 1,
  // Integrations
  ZAPIER: true,
  HUBSPOT: false,
  ZOHO: false,
  SALESFORCE: false,
  //Support & Knowledge
  WEBINARS: true,
  CHAT_SUPPORT: true,
  HELP_CENTER: true,
  ONE_ON_ONE_SUPPORT: false,
};

const scale = {
  // Credits & Limits
  CONTACT_LOOKUPS: 1000000,
  CONTACT_EXPORTS: 1000000,
  CONTACT_IMPORTS: 1000000,
  EMAIL_SENDING: 1000000,
  EMAIL_VALIDATION: 10000,
  DATA_ENRICHMENT: 10000,
  AI_WRITER: 10000,
  // Artificial Intelligence
  AI_AUTO_REPLIES: true,
  AI_AUTOPILOT: false,
  // Contact Database
  INTENT_DATA_TOPICS: 6,
  ADVANCED_FILTERS: true,
  // Campaigns & Sequences
  USER_MAILBOXES: 5,
  DFY_DOMAINS: null,
  AB_TESTING: true,
  GLOBAL_BLOCKLIST: true,
  DOMAIN_TRACKING: true,
  // Multichannel Outreach
  LINKEDIN_ACCOUNTS: 1,
  SMS_ACCOUNTS: 1,
  WHATSAPP_ACCOUNTS: 0,
  // Inbox & CRM
  UNIFIED_INBOX: true,
  DEAL_PIPELINES: 1,
  // Analytics & Reporting
  CAMPAIGN_ANALYTICS: true,
  GLOBAL_ANALYTICS: true,
  DASHBOARD: true,
  // Additional Functionality
  WORKSPACES: true,
  TEAM_MEMBERS: 3,
  // Integrations
  ZAPIER: true,
  HUBSPOT: true,
  ZOHO: true,
  SALESFORCE: false,
  //Support & Knowledge
  WEBINARS: true,
  CHAT_SUPPORT: true,
  HELP_CENTER: true,
  ONE_ON_ONE_SUPPORT: false,
};

const unlimited = {
  // Credits & Limits
  CONTACT_LOOKUPS: 1000000,
  CONTACT_EXPORTS: 1000000,
  CONTACT_IMPORTS: 1000000,
  EMAIL_SENDING: 1000000,
  EMAIL_VALIDATION: 30000,
  DATA_ENRICHMENT: 30000,
  AI_WRITER: 30000,
  // Artificial Intelligence
  AI_AUTO_REPLIES: true,
  AI_AUTOPILOT: true,
  // Contact Database
  INTENT_DATA_TOPICS: 12,
  ADVANCED_FILTERS: true,
  // Campaigns & Sequences
  USER_MAILBOXES: 20,
  DFY_DOMAINS: null,
  AB_TESTING: true,
  GLOBAL_BLOCKLIST: true,
  DOMAIN_TRACKING: true,
  // Multichannel Outreach
  LINKEDIN_ACCOUNTS: 1,
  SMS_ACCOUNTS: 1,
  WHATSAPP_ACCOUNTS: 1,
  // Inbox & CRM
  UNIFIED_INBOX: true,
  DEAL_PIPELINES: 1,
  // Analytics & Reporting
  CAMPAIGN_ANALYTICS: true,
  GLOBAL_ANALYTICS: true,
  DASHBOARD: true,
  // Additional Functionality
  WORKSPACES: true,
  TEAM_MEMBERS: 1,
  // Integrations
  ZAPIER: true,
  HUBSPOT: true,
  ZOHO: true,
  SALESFORCE: true,
  //Support & Knowledge
  WEBINARS: true,
  CHAT_SUPPORT: true,
  HELP_CENTER: true,
  ONE_ON_ONE_SUPPORT: true,
};

const options = [
  {
    title: "Basic",
    value: "basic",
  },
  {
    title: "Scale",
    value: "scale",
  },
  {
    title: "Unlimited",
    value: "unlimited",
  },
];

const AddPlans = ({ setOpen, open, getAllWhiteLabelClients }) => {
  const email = getSessionStorage("userEmail");
  const [planName, setPlanName] = useState("");
  const [formState, setFormState] = useState(initialFormState);
  const [selectTemplate, setSeletedTemplate] = useState("");


  useEffect(() => {
    if (open.data) {
      setPlanName(open.data.planName || "");
      setFormState({
        ...formState,
        ...open.data.monthlyCredits,
        ...open.data.features,
      });
    }
  }, [open.data]);

  useEffect(() => {
    if (selectTemplate === "basic") {
      setFormState(basic);
    } else if (selectTemplate === "scale") {
      setFormState(scale);
    } else if (selectTemplate === "unlimited") {
      setFormState(unlimited);
    } else {
      setFormState(initialFormState);
    }
  }, [selectTemplate]);

  const handlePlanName = (e) => {
    setPlanName(e.target.value);
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormState((prev) => {
      const updatedState = {
        ...prev,
        [name]: name === "DEAL_PIPELINES" ? (checked ? 1 : 0) : value,
      };

      // Handle INTEGRATIONS switch
      if (name === "INTEGRATIONS") {
        const newValue = checked;
        // Set ZAPIER, HUBSPOT, ZOHO, SALESFORCE to the same value as INTEGRATIONS
        updatedState.ZAPIER = newValue;
        updatedState.HUBSPOT = newValue;
        updatedState.ZOHO = newValue;
        updatedState.SALESFORCE = newValue;
      }

      // For other switches (in case needed, e.g., update more complex behavior)
      // Example for UNIFIED_INBOX and AI_AUTO_REPLIES
      if (
        name === "UNIFIED_INBOX" ||
        name === "AI_AUTO_REPLIES" ||
        name === "ADVANCED_FILTERS"
      ) {
        updatedState[name] = checked;
      }

      return updatedState;
    });
  };

  const handleSubmit = async () => {
    const requestData = {
      userEmail: email,
      planName: planName,
      monthlyCredits: {
        CONTACT_LOOKUPS: formState.CONTACT_LOOKUPS,
        CONTACT_EXPORTS: formState.CONTACT_EXPORTS,
        CONTACT_IMPORTS: formState.CONTACT_IMPORTS,
        EMAIL_SENDING: formState.EMAIL_SENDING,
        EMAIL_VALIDATION: Number(formState.EMAIL_VALIDATION),
        DATA_ENRICHMENT: Number(formState.DATA_ENRICHMENT),
        AI_WRITER: Number(formState.AI_WRITER),
      },
      features: {
        AI_AUTO_REPLIES: formState.AI_AUTO_REPLIES,
        AI_AUTOPILOT: formState.AI_AUTOPILOT,
        INTENT_DATA_TOPICS: Number(formState.INTENT_DATA_TOPICS),
        ADVANCED_FILTERS: formState.ADVANCED_FILTERS,
        USER_MAILBOXES: Number(formState.USER_MAILBOXES),
        DFY_DOMAINS: Number(formState.DFY_DOMAINS),
        AB_TESTING: formState.AB_TESTING,
        GLOBAL_BLOCKLIST: formState.GLOBAL_BLOCKLIST,
        DOMAIN_TRACKING: formState.DOMAIN_TRACKING,
        LINKEDIN_ACCOUNTS: Number(formState.LINKEDIN_ACCOUNTS),
        SMS_ACCOUNTS: formState.SMS_ACCOUNTS,
        WHATSAPP_ACCOUNTS: formState.WHATSAPP_ACCOUNTS,
        UNIFIED_INBOX: formState.UNIFIED_INBOX,
        DEAL_PIPELINES: formState.DEAL_PIPELINES,
        CAMPAIGN_ANALYTICS: formState.CAMPAIGN_ANALYTICS,
        GLOBAL_ANALYTICS: formState.GLOBAL_ANALYTICS,
        DASHBOARD: formState.DASHBOARD,
        WORKSPACES: formState.WORKSPACES,
        TEAM_MEMBERS: Number(formState.TEAM_MEMBERS),
        ZAPIER: formState.ZAPIER,
        HUBSPOT: formState.HUBSPOT,
        ZOHO: formState.ZOHO,
        SALESFORCE: formState.SALESFORCE,
        WEBINARS: formState.WEBINARS,
        CHAT_SUPPORT: formState.CHAT_SUPPORT,
      },
    };

    const url = open.data
      ? `${API_SERVICE_BACKEND}/plans/${open.data._id}`
      : `${API_SERVICE_BACKEND}/plans`;
    const method = open.data ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();

      if (response.ok) {
        getAllWhiteLabelClients();
        toast.success(
          data.message ||
            (open.data
              ? "Edit Plan successfully!"
              : "Add New Plan successfully!")
        );
        handleDisacrd();
      } else {
        toast.error(data.error || "Failed to connect");
      }
    } catch (error) {
      toast.error("Error submitting data");
    }
  };
  const handleDisacrd = () => {
    setOpen({ open: false, data: null });
    setPlanName("");
    setSeletedTemplate();
    setFormState(initialFormState);
  };
  return (
    <Dialog open={open.open} onClose={() => handleDisacrd()}>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{color:theme=>theme.palette.text.primary}}>
          {open.data ? "Edit" : "Create"} a custom plan
        </Typography>
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => handleDisacrd()}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{marginTop:"8px"}}>
            <TextInput
              label="Plan Name"
              name="planName"
              value={planName}
             
              onChange={handlePlanName}
            
            />
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              label="Start with a template"
              options={options}
              onChange={(e) => setSeletedTemplate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ pt: 2 }}>
              Credits
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              label="Intent Topics"
              name="INTENT_DATA_TOPICS"
              value={formState.INTENT_DATA_TOPICS}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              label="Data Enrichment"
              name="DATA_ENRICHMENT"
              value={formState.DATA_ENRICHMENT}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              label="Email Varification"
              name="EMAIL_VALIDATION"
              value={formState.EMAIL_VALIDATION}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              label="AI Writer"
              name="AI_WRITER"
              value={formState.AI_WRITER}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              label="Multichannel Accounts"
              name="LINKEDIN_ACCOUNTS"
              value={formState.LINKEDIN_ACCOUNTS}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              label="Team Members"
              name="TEAM_MEMBERS"
              value={formState.TEAM_MEMBERS}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={4}>
            <NumberInput
              label="DFY Domains"
              name="DFY_DOMAINS"
              value={formState.DFY_DOMAINS}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              label="User Brought Mailboxes"
              name="USER_MAILBOXES"
              value={formState.USER_MAILBOXES}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{ pt: 2 ,color:theme=>theme.palette.text.primary}}>
              Features
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <SwitchInput
              label="Unibox"
              name="UNIFIED_INBOX"
              checked={formState.UNIFIED_INBOX}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <SwitchInput
              label="Deal Pipeline"
              name="DEAL_PIPELINES"
              checked={formState.DEAL_PIPELINES === 1 ? true : false}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <SwitchInput
              label="Integrations"
              name="INTEGRATIONS"
              checked={
                formState.ZAPIER &&
                formState.HUBSPOT &&
                formState.ZOHO &&
                formState.SALESFORCE
              }
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <SwitchInput
              label="Advanced Filters"
              name="ADVANCED_FILTERS"
              checked={formState.ADVANCED_FILTERS}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <SwitchInput
              label="AI Auto Replies"
              name="AI_AUTO_REPLIES"
              checked={formState.AI_AUTO_REPLIES}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" className="glass-button-secondary-50" onClick={handleDisacrd} fullWidth>
          Discard
        </Button>
        <Button onClick={handleSubmit} variant="contained" fullWidth>
          {open.data ? "Update" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddPlans;
