import React, { useEffect, useState } from "react";
import { Card, CardContent, Button, useTheme,Grid,Typography } from "@mui/material";
import { INTEGRATIONTYPE } from "../integrations";
import { API_SERVICE_BACKEND_2, salesforceOauthConfig } from "src/config";
import { useAuth } from "src/contexts/auth/firebase-context";
import SalesforceLogo from "./salesforce-logo";
import LinkIcon from "src/components/logos/linkIcon.png";
import axios from "axios";
import { INTEGRATION_TYPE } from "src/helpers/constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { AiOutlineLink } from "react-icons/ai";
import { height } from "@mui/system";

const apiEndpoints = Object.freeze({
  ADD_SALESFORCE_ACCOUNT: `${API_SERVICE_BACKEND_2}/salesforce-integrations/add`,
  REMOVE_SALESFORCE_ACCOUNT: `${API_SERVICE_BACKEND_2}/salesforce-integrations/remove`,
});

function generateCodeVerifier() {
  const randomString = Array.from(crypto.getRandomValues(new Uint8Array(32)))
    .map((b) => String.fromCharCode((b % 26) + 97))
    .join("");
  return randomString;
}

async function generateCodeChallenge(verifier) {
  const encoder = new TextEncoder();
  const data = encoder.encode(verifier);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return btoa(String.fromCharCode.apply(null, hashArray))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
}

// Example of generating a code challenge from a code verifier
let codeVerifierFire = "";
export const getSalesforceAuthURL = async () => {
  const { oauthUrl, redirectUrl, clientId, scopes } = salesforceOauthConfig;

  const codeVerifier = generateCodeVerifier();

  const codeChallenge = await generateCodeChallenge(codeVerifier);
  console.log("codeChallenge: ", codeChallenge);

  sessionStorage.setItem("codeVerifier", codeVerifier);

  const authURL = `${oauthUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scopes}&code_challenge=${codeChallenge}&code_challenge_method=S256`;
  codeVerifierFire = codeChallenge;
  return { authURL, codeChallenge };
};

// export const getSalesforceAuthURL = () => {
//   const { oauthUrl, redirectUrl, clientId, scopes } = salesforceOauthConfig;
//   const authURL = `${oauthUrl}?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${scopes}`;
//   return authURL;
// };

const SalesforceIntegrationSettings = () => {
  const { user, setUserInfo } = useAuth();
  const navigate = useNavigate();
  const [hasSalesforceIntegrated, setHasSalesforceIntegrated] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setHasSalesforceIntegrated(
      Boolean(
        user.integrations.salesforce?.integrations?.hasSalesforceIntegrated
      )
    );
  }, [user]);

  useEffect(() => {
    const url = window?.location?.href ?? "";
    if (!!url) {
      const code = new URLSearchParams(url).get("code");
      navigate("/dashboard/account?tab=integrations");
      addSalesforceIntegration(code);
    }
  }, []);

  const initiateSalesforceOauthFlow = () => {
    const ab = async () => {
      const salesUrl = await getSalesforceAuthURL();
      window.location.href = salesUrl.authURL;
    };
    ab();
  };

  const addSalesforceIntegration = async (code) => {
    if (!code) {
      return;
    }

    const codeVerifier = sessionStorage.getItem("codeVerifier");

    try {
      await axios.post(apiEndpoints.ADD_SALESFORCE_ACCOUNT, {
        code,
        codeVerifier,
        type: INTEGRATION_TYPE.SALESFORCE,
        userEmail: user.email,
      });

      toast.success("Salesforce account connected!");
      setHasSalesforceIntegrated(true);
      setUserInfo(user.email);
    } catch (error) {
      console.error("Error updating isAuto:", error);
      toast.error("Error occured while connecting Salesforce account!");
    }
  };

  const removeSalesforceIntegration = async () => {
    try {
      await axios.post(apiEndpoints.REMOVE_SALESFORCE_ACCOUNT, {
        type: INTEGRATION_TYPE.SALESFORCE,
        userEmail: user.email,
      });

      toast.success("Salesforce account removed!");
      setHasSalesforceIntegrated(false);
    } catch (error) {
      console.error("Error updating isAuto:", error);
      toast.error("Error occured while removing Salesforce account!");
    }
  };

  return (
    <Card sx={{ 
      marginTop: "10px",
      marginLeft:"4px",
      width: { xs: "100%", md: "49%" },
      border: "1px solid #EAECF0",
      
    }}
    className="glass-wrapper"
  >
      <CardContent style={{ padding: "24px" , paddingLeft:"15px", paddingTop:"15px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "8px",
          }}
        >
          <SalesforceLogo style={{height:"60px"}} />

          <Button
            variant="contained"
            color={hasSalesforceIntegrated ? "error" : "primary"}
            onClick={
              hasSalesforceIntegrated
                ? removeSalesforceIntegration
                : initiateSalesforceOauthFlow
            }
            size="small"
            sx={{
              
              
              marginLeft: "5px",
              width: "max-content",
              padding: "5px 10px",
              fontSize: "0.8em",
            }}
            // className="glass-button-secondary-25"
          >
            {/* <img src={LinkIcon} style={{ marginRight: "8px" }} /> */}
            <AiOutlineLink
              style={{
                marginRight: "8px",
                height: "23px",
                fontSize: "38px",
                
              }} />


            {hasSalesforceIntegrated ? "Disconnect" : "Connect"}
          </Button>
        </div>
         <Grid item xs={4} >
                            <Typography fontSize={"16px"} paddingTop={"20px"}color={theme=>theme.palette.text.primary}>Salesforce</Typography>
                            
                                              <Typography fontSize={"16px"} paddingTop={"5px"} color={theme=>theme.palette.text.secondary}>
                                                Integrate your Salesforce Automations to
                                                simplify and automate your daily tasks effortlessly
                                              </Typography>
                                              
                          </Grid>
        
      </CardContent>
    </Card>
  );
};

export default SalesforceIntegrationSettings;
