import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { MuiTelInput } from "mui-tel-input";
import "@material-ui/core/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import PlansUI from "./plans-ui";
import AibdrDetails from "../../dashboard/aibdr/aibdrdetails";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  Unstable_Grid2 as Grid,
  InputAdornment,
  Autocomplete,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  InputLabel,
  ListItem,
  CardActions,
  Tabs,
  Tab,
  styled,
  Popper,
  useTheme,
  IconButton,
  CircularProgress
} from "@mui/material";
import axios from "axios";
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updateProfile,
} from "firebase/auth";
import { toast } from "react-hot-toast";
import { toast as toastify } from "react-toastify";
import { CloseOutlined } from "@mui/icons-material"


import {
  API_SERVICE_BACKEND,
  cronofy,
  API_SERVICE_BACKEND_2,
  API_SERVICE_BACKEND_3,
  ADMIN_SERVER,
} from "src/config";
import {
  AvailabilityRulesWrapper,
  CalendarSyncWrapper,
} from "src/components/cronofy";
import moment from "moment-timezone";
import UserCircle from "src/components/logos/userCircle.png";
import GlobeIcon from "src/components/logos/globeIcon.png";
import Email from "src/components/logos/Email.png";
import MarkerPin from "src/components/logos/markerPin.png";
import calenderPlus from "src/components/logos/calendarPlus.png";
import LinkIcon from "src/components/logos/linkIcon.png";
import LinkIcon2 from "@mui/icons-material/Link";
import SelectIcon from "src/components/logos/selectIcon.png";
import Key from "src/components/logos/key.png";
import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import { useRef } from "react";
import { thunks } from "src/thunks/mail";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "src/contexts/auth/firebase-context";
import { cronofyApi } from "src/api/cronofy";
import StackedCoinIcon from "src/components/logos/stackedCoinIcon.png";
import { jobsApi } from "../../../api/jobs";
import EmailAccounts from "./email-accounts/emailAccounts";
import { option } from "../../../assets/data";
import { getSessionStorage, setSessionStorage } from "src/utils/storage";
import BlackList from "./blacklist/blackList";
import CampaignSchedule from "./campaignSchedule/campaign-schedule";
import SubscriptionCancel from "./SubscriptionCancel";
import IntegrationSettings from "./general-settings/integrations";
import Credits from "./credits/credits";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import Invoices from "./invoices";
import "./style.css";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { AiOutlineLink } from "react-icons/ai";
import { color } from "@mui/system";
import { IoKeyOutline } from "react-icons/io5";
import { PiGlobeHemisphereWestBold } from "react-icons/pi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { HiOutlineCircleStack } from "react-icons/hi2";
//import {EditCalendarOutlinedIcon} from '@mui/icons-material/EditCalendarOutlined';
import { LuCalendarPlus } from "react-icons/lu";
import { MdOutlineWatchLater } from "react-icons/md";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import { Seo } from "src/components/seo";
import { CustomSwitch } from "src/components/custom-switch";
import { SettingsContext } from "src/contexts/settings-context";





const scrollBarStyle = {
  "&::-webkit-scrollbar": {
    width: "14px",
    height: "14px",
  },
  "&::-webkit-scrollbar-track": {
    borderRadius: "60px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#E4E4E5",
    borderRadius: "10px",
    border: "4px solid rgba(0, 0, 0, 0)",
    backgroundClip: "padding-box",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "#d5d5d5",
  },
};

function CompanyLogoUpload({ email, companyLogo, showAlert }) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [imageRemoveDialog, setImageRemoveDialog] = React.useState(false);
  const hiddenFileInput = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  // image upload
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [originalImage, setOriginalImage] = useState(null);
  const [filePreview, setFilePreview] = useState(null);

  const selectFile = (e, fileType) => {
    setIsUploadingFile(true);
    const file = e.target.files[0];
    setOriginalImage(file);
    const preview = URL.createObjectURL(file);
    setFilePreview(preview);
    setDialogOpen(true);
  };

  // crop
  const [crop, setCrop] = useState();
  const aspectRatio = 1 / 1;

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 100,
        },
        aspectRatio,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  }
  // this ref is passed to the image inside the crop modal
  const cropRef = useRef(null);
  const [cropResult, setCropResult] = useState(null);

  useEffect(() => {
    // once we saved our crop, we now need to process this new image size
    if (cropRef.current && crop.width && crop.height) {
      getCroppedImg();
    }
  }, [crop]);

  const getCroppedImg = () => {
    const image = cropRef.current;
    const cropWidth = image.naturalWidth * (crop.width / 100);
    const cropHeight = image.naturalHeight * (crop.height / 100);
    const startX = image.naturalWidth * (crop.x / 100);
    const startY = image.naturalHeight * (crop.y / 100);


    const canvas = document.createElement("canvas");
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      startX,
      startY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    const reader = new FileReader();
    canvas.toBlob(
      (blob) => {
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          dataURLtoFile(reader.result, originalImage.name);
        };
      },
      "image/jpeg",
      1
    );
  };

  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    setCropResult(croppedImage);
  };
  const [updatedProfileImage, setUpdatedProfileImage] = useState(companyLogo);
  useEffect(() => {
    if (companyLogo !== getSessionStorage("photoUrl")) {
      setUpdatedProfileImage(getSessionStorage("photoUrl") || companyLogo);
    } else {
      setUpdatedProfileImage(companyLogo);
    }
  }, [companyLogo]);

  const handleUpload = async () => {
    try {
      let image = cropResult;
      let formData = new FormData();
      if (image.size <= 2 * 1024 * 1024) {
        // 2 MB in bytes
        formData.append("file", image);
      } else {
        toast.error("Image size exceeds 2 MB");
        console.error("Image size exceeds 2 MB");
        return;
      }

      const response = await axios.post(
        `${API_SERVICE_BACKEND_2}/upload`,
        formData
      );

      const imageUrl = response.data?.url;

      const request = {
        profile_image: imageUrl,
      };
      if (response) {
        const uploadResponse = await axios.put(
          `${API_SERVICE_BACKEND_2}/updateuserprofileimage/${email}`,
          request
        );
        if (uploadResponse.data.status === true) {
          toast.success("Image updated successfully");
        }
        const profileImage = uploadResponse.data?.data?.profile_image;
        setUpdatedProfileImage(profileImage);
        setSessionStorage("photoUrl", profileImage);
        navigate(`${location.pathname}`);
      }
    } catch (error) {
      console.log("error", error);
    }
    setDialogOpen(false);
  };

  const handleDeleteImage = async () => {
    try {
      let fileUrl = updatedProfileImage ? updatedProfileImage : companyLogo;
      const response = await axios.post(
        `${API_SERVICE_BACKEND_2}/removeProfileImage`,
        { fileUrl: fileUrl }
      );

      if (response.status === 200) {
        const request = {
          profile_image: "",
        };
        const uploadResponse = await axios.put(
          `${API_SERVICE_BACKEND_2}/deleteUserProfileImage/${email}`,
          request
        );
        if (uploadResponse.data.status === true) {
          toast.success("Image removed successfully");
        }
        setUpdatedProfileImage(null);
        setSessionStorage("photoUrl", null);
        setImageRemoveDialog(false);
        navigate(`${location.pathname}`);
      } else {
        toast.error("Error removing image");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Stack sx={{ mt: 0 }} direction="column" spacing={2}>
        <Typography className="title" sx={{ fontSize: "14px", color: theme.palette.text.primary }}>
          Company Logo
        </Typography>
        <Stack
          direction={"row"}
          gap={1}
          justifyContent={"flex-start"}
          alignItems={"center"}
        >
          <Avatar
            sx={{
              height: 50,
              width: 50,
              color: theme.palette.text.primary
            }}
            src={updatedProfileImage}
          >
            <SvgIcon>
              <User01Icon />
            </SvgIcon>
          </Avatar>
          <Stack>
            <Stack
              direction={"row"}
              gap={1}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <Button
                sx={{
                  width: "fit-content",
                  p: 0,
                  fontWeight: 500,
                  fontSize: "14px",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                className="glass-button-tertiary"
                disabled={showAlert ? true : false}
                disableTouchRipple
                onClick={() => {
                  hiddenFileInput.current.value = null;
                  hiddenFileInput.current.click();
                }}
              >
                {updatedProfileImage
                  ? `Update your company logo`
                  : `Upload your company logo`}
              </Button>
              <input
                name="cover"
                type="file"
                disabled={showAlert ? true : false}
                onChange={(e) => selectFile(e, "cover")}
                ref={hiddenFileInput}
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
              />

              {updatedProfileImage && (
                <>
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    style={{ borderColor: "rgba(0,0,0,0.5)" }}
                  />
                  <Button
                    sx={{
                      width: "fit-content",
                      p: 0,
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "red",
                      "&:hover": {
                        backgroundColor: "transparent",
                      },
                    }}
                    disableTouchRipple
                    disabled={showAlert ? true : false}
                    onClick={() => setImageRemoveDialog(true)}
                    >
                    Remove
                  </Button>
                </>
              )}
            </Stack>
            <Typography
              sx={{
                fontSize: "12px",
                color: theme.palette.text.secondary

              }}
            >
              Logo should be in the standard format png. jpg & no more than 2MB
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogContent>
          <Stack>
            <ReactCrop
              crop={crop}
              onChange={(c, percentCrop) => setCrop(percentCrop)}
              aspect={aspectRatio}
            //style={{ width: 300 }}
            >
              <img
                src={filePreview}
                ref={cropRef}
                onLoad={onImageLoad}
                alt="Your uploaded image"
              />
            </ReactCrop>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button className="glass-button-tertiary" onClick={handleUpload}>Save Logo</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={imageRemoveDialog}>
        <DialogTitle id="alert-dialog-title"
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6" sx={{ display: "flex", alignItems: "center", color: theme.palette.text.primary }}>Remove Image</Typography>
          <IconButton onClick={() => setImageRemoveDialog(false)} sx={{  color: theme.palette.text.primary }}>
            <CloseOutlined />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <DialogContentText sx={{ color: theme.palette.text.secondary }}>
            Are you sure you want to remove the company logo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className="glass-button-secondary-50" onClick={() => setImageRemoveDialog(false)}>Cancel</Button>
          <Button variant="contained" onClick={handleDeleteImage} sx={{ backgroundColor: "red", "&:hover": { backgroundColor: "#e30000" }, }}>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const CalendarPanel = ({
  section7Ref,
  conferencingLinked,
  handleConferencingLink,
  calendarOptions,
  email,
  availabilityElements,
  userCampaigns,
  location,
  navigate,
  userCampaignLoading,
  activeSubTab,
  setActiveSubTab
}) => {

  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setActiveSubTab((prev) => newValue);
  };
  return (
    <Box
      ref={section7Ref}
      id="section7"
      sx={{
        borderRadius: "12px",
        border: "1px solid rgba(224, 224, 229, 1)",
        boxShadow: "0 1px 2px 0 rgba(16, 24, 40, 0.05)",
        backgroundColor: "rgba(255, 255, 255, 1)",
      }}
      className="glass-wrapper"
    >
      <Box
        sx={{
          borderBottom: "1px solid rgba(224, 224, 229, 1)",
          px: 2,
          py: 0,
        }}
      >
        <Tabs
          value={activeSubTab ?? 0}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTab-root": {
              pb: "16px",
              pt: "20px",
              color: theme.palette.text.primary,
              "&.Mui-selected": {
                color: theme.palette.primary.main,
              }
            },
          }}
        >
          <Tab label="Global Calendar" {...a11yProps(0)} />
          <Tab label="AI Agent Calendars" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box>
        <CustomTabPanel value={activeSubTab} index={0}>
          <Stack spacing={2}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "15px",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <>
                  {/* <img
                    src={calenderPlus}
                    style={{ marginRight: "10px", height: "23px" }}
                  /> */}
                  <LuCalendarPlus
                    style={{
                      fontSize: "22px",
                      color: theme.palette.primary.main,
                    }}
                  />
                </>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: theme.palette.text.primary,
                  }}
                >
                  Global Calendar
                </Typography>
                {!conferencingLinked.linked && (
                  <Chip
                    size="small"
                    color="error"
                    label="Link meeting platform"
                    // className="glass-chip"
                    onClick={() => {
                      handleConferencingLink(conferencingLinked.accessToken);
                    }}
                    icon={<LinkIcon2 style={{ color: "white" }} />}
                  />
                )}
              </div>
              <Divider />
              <div style={{ marginTop: "20px" }}>
                <CalendarSyncWrapper options={calendarOptions} email={email} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {Boolean(availabilityElements.length) && (
                <div
                  // ref={section8Ref}
                  // id="section8"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "15px",
                    }}
                  >
                    <>
                      {/* <img
                        src={SelectIcon}
                        style={{ marginRight: "10px", height: "23px" }}
                      /> */}

                      <MdOutlineWatchLater
                        style={{
                          marginRight: "10px",
                          fontSize: "24px",
                          color: theme.palette.primary.main,
                        }}
                      />

                    </>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: theme.palette.text.primary,
                      }}
                    >
                      {/* Select Your Availability */}
                      Set Working Hours / Availability
                    </Typography>
                  </div>
                  <Divider />
                  <div id="availability-grid" style={{ marginTop: "20px" }}>
                    {availabilityElements.map((element) => element)}
                  </div>
                </div>
              )}
              <SlotPresetSetting />
            </div>
          </Stack>
        </CustomTabPanel>
        <CustomTabPanel value={activeSubTab} index={1}>
          <div
            // ref={section10Ref}
            // id="section10"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: "15px",
              }}
            >
              <>
                <LuCalendarPlus
                  style={{
                    marginRight: "10px",
                    fontSize: "22px",
                    color: theme.palette.primary.main,
                  }}
                />
              </>
              <Typography
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                  color: theme.palette.text.primary,
                }}
              >
                AI Agent Calendars
              </Typography>
            </div>
            <Divider />

            <div style={{ marginTop: "20px" }}>
              <Stack
                // alignItems="center"
                // direction="column"
                sx={{
                  // border: "1px solid #EAECF0",
                  // borderRadius: "12px",
                  // padding: "5px",
                }}
                gap={"8px"}
              >
                {!userCampaignLoading ? userCampaigns?.map((campaign, index) => {
                  return (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          // border: "1px solid #EAECF0",
                          borderRadius: "12px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          paddingX: "10px",
                        }}
                      >
                        <Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>
                          <b>{campaign.companyName ? campaign.companyName + " " : ""}</b>
                          {campaign.companyName && campaign.title ? " - " : ""}
                          {campaign.title ? campaign.title : ""}
                          {campaign.responsibility ? (
                            <span style={{ fontSize: "12px", color: theme.palette.text.secondary }}>
                              {" (" + campaign.responsibility + ")"}
                            </span>
                          ) : null}
                        </Typography>

                        <Button
                          variant={"outlined"}
                          sx={{
                            color: theme.palette.text.primary,
                            border: "1px solid #ECE9F5",
                            marginRight: "10px",
                            marginLeft: "5px",
                            fontSize: "14px",

                          }}
                          className="glass-button-secondary-50"
                          onClick={() => {
                            // console.log(
                            //   "props extrra -------->",
                            //   campaign.extra
                            // );
                            navigate(
                              `${location.pathname}?campaign_id=${campaign._id}&title=${campaign.title}`,
                              { state: { extra: campaign.extra } }
                            );
                          }}
                        >
                          {/* {campaign.extra
                            ? "Connected"
                            : "Not Connected"} */}
                          View More
                        </Button>
                      </Box>
                    </>
                  );
                }) : userCampaignLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <Typography
                    sx={{
                      color: theme.palette.text.primary,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    No AI Agent Calendars
                  </Typography>)
                }
              </Stack>
            </div>
          </div>
        </CustomTabPanel>
      </Box>
    </Box>
  );
};

async function generateHMAC(message, key) {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(key);
  const messageData = encoder.encode(message);

  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "HMAC", hash: { name: "SHA-256" } },
    false,
    ["sign"]
  );

  const signature = await crypto.subtle.sign("HMAC", cryptoKey, messageData);
  return Array.from(new Uint8Array(signature))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

const hash = await generateHMAC(
  getSessionStorage("userEmail"),
  process.env.REACT_APP_INTERCOM_HASH_KEY
);

export const UserMeetingSchedules = ({ date, index }) => {
  const meetingTime = [
    "09:00AM",
    "11:00AM",
    "01:00PM",
    "03:00PM",
    "04:00PM",
    "09:30AM",
    "11:30AM",
    "01:30PM",
    "02:30PM",
    "04:30PM",
    "09:45AM",
    "11:45AM",
    "01:45PM",
    "02:45PM",
    "03:45PM",
    "09:15AM",
    "11:30AM",
    "01:30PM",
    "03:15PM",
    "04:15PM",
    "10:00AM",
    "11:15AM",
    "01:15PM",
    "02:45PM",
    "03:30PM",
  ];
  const selectedTimeSlots = meetingTime.slice(index * 5, index * 5 + 5);

  return (
    <>
      <Grid>
        <Stack alignItems="center" direction="row" spacing={3}>
          <TextField
            type="text"
            defaultValue={date}
            sx={{ mt: 1 }}
            variant="outlined"
            disabled={true}
            className="glass-input"
          />

          {selectedTimeSlots.map((time, timeIndex) => (
            <TextField
              key={timeIndex}
              defaultValue={time}
              variant="outlined"
              sx={{ mt: 2, display: "none" }}
              type="text"
              disabled={true}
              className="glass-input"
            />
          ))}
        </Stack>
      </Grid>
    </>
  );
};

let campaignMaxCallsAllowedToGetAccessToken = 3;
const now = new Date();
const campaignSaveToDB = async (email, key, value, campaign_id) => {
  // console.log(key, value)
  // const res = await axios.patch(`${API_SERVICE_BACKEND}/patchUser`, {
  const res = await axios.patch(`${API_SERVICE_BACKEND}/patchCampaign`, {
    email,
    campaign_id,
    payload: {
      [key]: { generatedAt: Date.now(), ...value },
    },
  });
  // console.log("res.data", res.data);
  if (res.status !== 200) return false;
  return true;
};
function formatDate(date) {
  // Extract date components
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let year = date.getFullYear();

  // Extract time components
  let hours = date.getHours();
  let minutes = date.getMinutes().toString().padStart(2, "0");

  // Determine AM/PM
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // The hour '0' should be '12'
  hours = hours.toString().padStart(2, "0");

  // Combine date and time components
  let formattedDate = `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
  return formattedDate;
}

const CampaignSlotPresetSetting = ({ campaignId }) => {
  const [duration, setDuration] = useState(0);
  const checkFirstRender = useRef(true);
  const [buffer, setBuffer] = useState({
    isSetBuffer: false,
    before: 0,
    after: 0,
  });
  const [minimumNotice, setMinimumNotice] = useState(null);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [dateOption, setDateOption] = useState(null);
  const [scheduleVisibility, setScheduleVisibility] = useState("");
  const [scheduleDate, setScheduleDate] = useState(null);
  const [minimumNoticeTime, setMinimumNoticeTime] = useState(null);
  const theme = useTheme();

  const email = getSessionStorage("userEmail");
  const handleDurationChange = async (event) => {
    const targetValue = event.target.value;
    setDuration(targetValue);
  };

  const durationTime = async () => {
    const userSlotPreset = await fetch(
      `${API_SERVICE_BACKEND_3}/get-campaign-slot-preset/${campaignId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const duration = await userSlotPreset.json();
    setDuration(duration?.data?.slotsPreset);

    if (duration?.data?.buffer) {
      setBuffer(duration?.data?.buffer);
    }
    setDuration(duration?.data?.slotsPreset);
    let scheduleVisible = duration?.data?.schedulingOption
      ? duration?.data?.schedulingOption
      : "rolling";
    setScheduleVisibility(scheduleVisible);
    if (duration?.data?.schedulingOption === "rolling") {
      setScheduleTime(duration?.data?.rollingTime.split(" ")[0]);
      setDateOption(duration?.data?.rollingTime.split(" ")[1]);
    } else if (duration?.data?.schedulingOption === "custom") {
      let maxScheduleDate = new Date(duration?.data?.maximumSchedulingTime);
      maxScheduleDate.setDate(maxScheduleDate.getDate() - 1);
      setScheduleDate(maxScheduleDate);
    }

    let minimumNoticeDate = duration?.data?.minimumNotice;
    if (minimumNoticeDate && new Date(minimumNoticeDate) > new Date()) {
      let minimumNoticeTime = new Date(duration?.data?.minimumNotice);
      setMinimumNoticeTime(formatDate(minimumNoticeTime));
      let diff = new Date(minimumNoticeDate) - new Date();
      setMinimumNotice(Math.ceil(diff / 1000 / 60 / 60));
    }
  };
  useEffect(() => {
    durationTime();
  }, []);

  useEffect(() => {
    if (duration) {
      if (checkFirstRender.current) {
        checkFirstRender.current = false;
        return;
      }
      (async () => {
        const res = await fetch(
          `${API_SERVICE_BACKEND_3}/updateCampaignSlotPreset/${campaignId}/${duration}`,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        if (res.status === 200) {
          toast.success("Slot Preset Updated Successfully");
        } else {
          toast.error("Error Updating Slot Preset");
        }
      })();
    }
  }, [duration]);

  const handleBufferChange = (event) => {
    const { name, value } = event.target;
    setBuffer((prevState) => ({
      ...prevState,
      [name]: Number(value),
    }));
  };

  const handleBufferVisibilityChange = async (event) => {
    setBuffer((prevState) => ({
      ...prevState,
      isSetBuffer: !buffer.isSetBuffer,
    }));

    if (event.target.value === "false") {
      const res = await updateSlotPreset(false);
      if (res.status === 200) {
        toast.success("Buffer Updated Successfully");
      } else {
        toast.error("Error Updating Buffer");
      }
    }
  };

  const updateSlotPreset = async (isSetBuffer) => {
    return await fetch(
      `${API_SERVICE_BACKEND_3}/updateCampaignSlotPreset/${campaignId}/${duration}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...buffer,
          isSetBuffer: isSetBuffer ?? buffer.isSetBuffer,
        }),
      }
    );
  };

  const handleSaveBuffer = () => {
    (async () => {
      const res = await updateSlotPreset();
      if (res.status === 200) {
        toast.success("Buffer Updated Successfully");
      } else {
        toast.error("Error Updating Buffer");
      }
    })();
  };
  const handleMinimumNotice = async () => {
    if (!minimumNotice) {
      toast.error("Please enter minimum notice time");
      return;
    }
    let minimum_notice = new Date();
    minimum_notice.setHours(minimum_notice.getHours() + minimumNotice);
    if (minimumNotice === 0) {
      minimum_notice = null;
    }
    const res = await axios.post(
      `${API_SERVICE_BACKEND_3}/updateCampaignMinimumNotice/${campaignId}`,
      { minimum_notice },
      {
        minimumNotice,
      }
    );
    if (res.status === 200) {
      if (minimum_notice && minimum_notice > new Date()) {
        setMinimumNoticeTime(formatDate(minimum_notice));
        let diff = new Date(minimum_notice) - new Date();
        setMinimumNotice(Math.ceil(diff / 1000 / 60 / 60));
      }
      toast.success("Minimum Notice updated successfully!!");
    } else {
      toast.error("Error Updating Minimum Notice Time");
    }
  };
  const removeMinimumNotice = async () => {
    const res = await axios.post(
      `${API_SERVICE_BACKEND_3}/updateCampaignMinimumNotice/${campaignId}`,
      { minimum_notice: null }
    );
    if (res.status === 200) {
      setMinimumNotice("");
      setMinimumNoticeTime(null);
      toast.success("Minimum Notice removed successfully!!");
    } else {
      toast.error("Error Removing Minimum Notice Time");
    }
  };
  const dateOptions = ["Day", "Week", "Month"];

  const handleSchedulingDate = (event, value) => {
    setDateOption(value);
  };
  const handleScheduleVisibility = async (event) => {
    setScheduleVisibility(event.target.value);
  };
  const handleSchedulingCalendar = async (event) => {
    setScheduleDate(event);
  };

  const handleMaxScheduleTime = async () => {
    if (!scheduleVisibility) {
      toast.error("Please select the above options");
      return;
    }
    let res;
    if (scheduleVisibility === "rolling") {
      if (!scheduleTime || !dateOption) {
        toast.error("Please select the rolling period");
        return;
      }
      res = await axios.post(
        `${API_SERVICE_BACKEND_3}/updateCampaignMaximumSchedulingTime/${campaignId}`,
        {
          scheduleTime: parseInt(scheduleTime),
          dateOption: dateOption,
          option: scheduleVisibility,
        }
      );
      if (res.status === 200) {
        setScheduleTime(parseInt(scheduleTime));
        setScheduleDate(null);
        toast.success("Maximum Scheduling Time updated successfully!!");
      } else {
        toast.error("Error Updating Maximum Scheduling Time");
      }
    } else {
      if (!scheduleDate) {
        toast.error("Please select the date");
        return;
      }
      res = await axios.post(
        `${API_SERVICE_BACKEND_3}/updateCampaignMaximumSchedulingTime/${campaignId}`,
        {
          scheduleDate: scheduleDate,
          option: scheduleVisibility,
        }
      );
      if (res.status === 200) {
        setScheduleDate(scheduleDate);
        setScheduleTime("");
        toast.success("Maximum Scheduling Time updated successfully!!");
      } else {
        toast.error("Error Updating Maximum Scheduling Time");
      }
    }
  };

  const removeMaxScheduleTime = async () => {
    if (scheduleVisibility === "rolling") {
      const res = await axios.post(
        `${API_SERVICE_BACKEND_3}/updateCampaignMaximumSchedulingTime/${campaignId}`,
        {
          scheduleTime: null,
          dateOption: null,
          option: null,
        }
      );
      if (res.status === 200) {
        setScheduleTime("");
        setDateOption(null);
        toast.success("Maximum Scheduling Time removed successfully!!");
      } else {
        toast.error("Error Removing Maximum Scheduling Time");
      }
    } else {
      const res = await axios.post(
        `${API_SERVICE_BACKEND_3}/updateCampaignMaximumSchedulingTime/${campaignId}`,
        {
          scheduleDate: null,
          option: null,
        }
      );
      if (res.status === 200) {
        setScheduleDate(null);
        toast.success("Maximum Scheduling Time removed successfully!!");
      } else {
        toast.error("Error Removing Maximum Scheduling Time");
      }
    }
  };

  const CustomPopper = styled(Popper)(({ theme }) => ({
    "& .MuiAutocomplete-listbox": {
      fontSize: "14px", // Change this value to set your desired font size
    },
  }));

  return (
    <>
      {/* <FormLabel component="legend">Select Slot Preset</FormLabel> */}
      <RadioGroup
        onChange={handleDurationChange}
        row
        aria-label="duration"
        name="duration"
        value={duration}
      >
        <FormControlLabel
          value={15}
          control={<Radio />}
          label={
            <Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>15 Minutes</Typography>
          }
        />
        <FormControlLabel
          value={30}
          control={<Radio />}
          label={
            <Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>30 Minutes</Typography>
          }
        />
        <FormControlLabel
          value={60}
          control={<Radio />}
          label={
            <Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>60 Minutes</Typography>
          }
        />
      </RadioGroup>

      <Divider />

      <Grid
        item
        container
        direction="row"
        gap={2}
        alignItems={"center"}
        marginTop={"10px"}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: theme.palette.text.primary,
            fontWeight: 600,
          }}
        >
          Buffer Time:
        </Typography>

        <RadioGroup
          onChange={handleBufferVisibilityChange}
          row
          aria-label="buffer"
          value={buffer.isSetBuffer}
          name={"isSetBuffer"}
        >
          <FormControlLabel value={false} control={<Radio />} label="No" />
          <FormControlLabel value={true} control={<Radio />} label="Yes" />
        </RadioGroup>
      </Grid>

      {buffer && buffer.isSetBuffer && (
        <Grid container alignItems="center" columnSpacing={2}>
          <Grid item container direction="row" gap={2}>
            <Box display={"flex"} flexDirection={"row"} width={"100%"} gap={5}>
              <Grid item container alignItems="center" columnSpacing={1}>
                <Typography sx={{ marginRight: "8px", fontSize: "14px", color: theme.palette.text.primary }}>
                  before :{" "}
                </Typography>
                <TextField
                  name={"before"}
                  value={buffer?.before}
                  variant="outlined"
                  size="small"
                  type="number"
                  max={60}
                  error={buffer?.before > 60}
                  helperText={
                    buffer?.before > 60 && "maximum buffer time should be 60"
                  }
                  sx={{ flexGrow: 1 }}
                  inputProps={{ min: 0 }}
                  onChange={handleBufferChange}
                  className="glass-input"
                />
              </Grid>

              <Grid item container alignItems="center" columnSpacing={1}>
                <Typography sx={{ marginRight: "8px", fontSize: "14px", color: theme.palette.text.primary }}>
                  after :{" "}
                </Typography>
                <TextField
                  name={"after"}
                  value={buffer?.after}
                  variant="outlined"
                  size="small"
                  type="number"
                  max={60}
                  error={buffer?.after > 60}
                  helperText={
                    buffer?.after > 60 && "maximum buffer time should be 60"
                  }
                  sx={{ flexGrow: 1 }}
                  inputProps={{ min: 0 }}
                  onChange={handleBufferChange}
                  className="glass-input"
                />
              </Grid>
            </Box>
            <Grid item container alignItems="center" columnSpacing={1}>
              <Button
                sx={{ marginRight: "8px", fontSize: "14px" }}
                className="glass-button-secondary-50"
                disabled={buffer?.after > 60 || buffer?.before > 60}
                variant={"outlined"}
                size={"small"}
                onClick={handleSaveBuffer}
              >
                Save buffer{" "}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Divider sx={{ marginY: "10px" }} />
      <Grid item container gap={2} direction="column" marginTop={"10px"}>
        <Typography
          sx={{
            fontSize: "16px",
            color: theme.palette.text.primary,
            fontWeight: 600,
          }}
        >
          Maximum Scheduling Time
        </Typography>
        <RadioGroup
          onChange={handleScheduleVisibility}
          row
          aria-label="Schedule Time"
          value={scheduleVisibility}
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="rolling"
            control={<Radio />}
            label={
              <Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>
                Over a period of rolling time
              </Typography>
            }
          />
          <FormControlLabel
            value="custom"
            control={<Radio />}
            label={
              <Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>Custom date</Typography>
            }
          />
        </RadioGroup>
        {scheduleVisibility === "rolling" ? (
          <Grid container alignItems="center" columnSpacing={2}>
            <Grid item container direction="row" gap={2}>
              <Grid item container alignItems="center" columnSpacing={1}>
                <TextField
                  placeholder="Enter Period"
                  value={scheduleTime}
                  variant="outlined"
                  id="outlined-basic"
                  size="small"
                  type="number"
                  sx={{ flexGrow: 1 }}
                  className="glass-input"
                  inputProps={{ min: 1 }}
                  onChange={(e) => setScheduleTime(parseInt(e.target.value))}
                />
              </Grid>

              <Autocomplete
                options={dateOptions}
                sx={{
                  width: "50%",
                  "& .MuiAutocomplete-listbox": {
                    fontSize: "14px",
                  },
                }}
                className="glass-input"
                size="small"
                variant="outlined"
                id="outlined-basic"
                PopperComponent={CustomPopper}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"outlined"}
                    placeholder="Select"
                  />
                )}
                onChange={handleSchedulingDate}
                value={dateOption}
              />
              <Grid item container alignItems="center" columnSpacing={2}>
                <Button
                  sx={{ marginRight: "8px" }}
                  className="glass-button-secondary-50"
                  variant={"outlined"}
                  size={"small"}
                  onClick={handleMaxScheduleTime}
                >
                  Set
                </Button>
                <Button
                  sx={{ marginRight: "8px" }}
                  className="glass-button-secondary-50"
                  variant={"outlined"}
                  size={"small"}
                  onClick={removeMaxScheduleTime}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Grid item container alignItems="center" direction="row" gap="2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Max Date"
                  disablePast
                  value={scheduleDate}
                  onChange={handleSchedulingCalendar}
                  renderInput={(params) => <TextField {...params} />}
                  className="glass-input"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item container alignItems="center" marginTop={2}>
              <Button
                sx={{ marginRight: "8px" }}
                variant={"outlined"}
                size={"small"}
                onClick={handleMaxScheduleTime}
                className="glass-button-secondary-50"
              >
                Set
              </Button>
              <Button
                sx={{ marginRight: "8px" }}
                variant={"outlined"}
                size={"small"}
                onClick={removeMaxScheduleTime}
                className="glass-button-secondary-50"
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Divider sx={{ marginY: "10px" }} />
      <Grid item container gap={2} direction="column" marginTop={"10px"}>
        <Typography
          sx={{
            fontSize: "16px",
            color: theme.palette.text.primary,
            fontWeight: 600,
          }}
        >
          Minimum Notice Time
        </Typography>
        <Grid container alignItems="center" columnSpacing={2}>
          <Grid item container direction="row" gap={1}>
            <Grid item container alignItems="center" columnSpacing={1}>
              <TextField
                placeholder="In Hours"
                value={minimumNotice}
                variant="outlined"
                size="small"
                type="number"
                sx={{ flexGrow: 1 }}
                className="glass-input"
                inputProps={{ min: 0 }}
                onChange={(e) => setMinimumNotice(parseInt(e.target.value))}
              />
            </Grid>

            <Grid item container alignItems="center">
              <Button
                sx={{ marginRight: "8px" }}
                variant={"outlined"}
                size={"small"}
                onClick={handleMinimumNotice}
                className="glass-button-secondary-50"
              >
                Set
              </Button>
            </Grid>
            <Grid item container alignItems="center">
              <Button
                sx={{ marginRight: "8px" }}
                variant={"outlined"}
                size={"small"}
                onClick={removeMinimumNotice}
                className="glass-button-secondary-50"
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {minimumNoticeTime && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.text.primary
            }}
          >
            Valid till{" "}
            <span style={{ color: "red", fontWeight: 400 }}>
              {minimumNoticeTime}
            </span>
          </Typography>
        )}
      </Grid>
    </>
  );
};

const CalenderView = (props) => {
  const { user } = useAuth();

  // const [openCalenderDialog, setOpenCalenderDialog] = useState(false);
  const location = useLocation();
  const { whiteLableStatus, subUserStatus,customEmailConfigDomain } = useContext(WhiteLabelConfigurationContext);
  const whiteLabelDomain = "https://" + customEmailConfigDomain;
  const redirect_uri = `${subUserStatus ? whiteLabelDomain : cronofy.redirect_uri}${location.pathname}?from=cronofy`;

  const query = new URLSearchParams(location.search);
  const queryParams = {};
  query.forEach((value, key) => (queryParams[key] = value));

  const { code, from, campaign_id: _id, state, title } = queryParams;
  const extra = location?.state?.extra;
  const myState = JSON.stringify({
    campaign_id: _id,
    title: title,
  });

  const [calendarOptions, setCalendarOptions] = useState({
    data_center: cronofy.data_center,
    target_id: `cronofy-calendar-sync`,
    authorization: {
      redirect_uri,
      client_id: cronofy.client_id,
      scope: "read_write",
      avoid_linking: true,
      state: myState,
    },
    styles: {
      prefix: "CalendarSync",
    },
  });

  const [accessToken, setAccessToken] = useState(null);
  const [conferencingLinked, setConferencingLinked] = useState({
    linked: true,
    accessToken: null,
  });
  const section8Ref = useRef();
  const theme = useTheme();

  const [availabilityOptions, setAvailabilityOptions] = useState({
    target_id: "cronofy-availability-rules",
    data_center: cronofy.data_center,
    availability_rule_id: "work_hours",
    tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const [dialogState, setDialogState] = useState({
    open: false,
    loadUrl: "",
  });

  const linkConferencingService = async (accessTokenCur) => {
    if (!accessTokenCur) {
      accessTokenCur = accessToken;
    }
    const conferencingService = await cronofyApi.conferencingByCampaign({
      token: accessTokenCur?.access_token,
      redirect_uri: `${redirect_uri}&campaign_id=${_id}&title=${title}`,
      email: getSessionStorage("userEmail"),
      profile_email: accessTokenCur.linking_profile.profile_name,
      campaign_id: _id,
      state: myState,
    });

    if (conferencingService.linked) {
      return;
    }
    if (conferencingService.url) {
      setDialogState({ open: true, loadUrl: conferencingService?.url });
    }
  };

  // link calendar
  useEffect(() => {
    if (!code || from !== "cronofy") {
      window.Intercom("update", {
        email: getSessionStorage("userEmail"),
        user_hash: hash,
        calendar_synced: false,
      });
      return;
    }
    console.log(` Getting Access Token using the code:`);
    cronofyApi
      .getAccessToken({
        code,
        redirect_uri,
        // redirect_uri,
      })
      .then(async (accessToken) => {
        //console.log(` Getting Access Token after`, accessToken);
        if (!accessToken) return;
        if (accessToken.linking_profile.provider_name !== "google") {
          await linkConferencingService(accessToken);
        }

        // Saving Access token to db
        window.Intercom("trackEvent", "calendar_synced", {
          email: getSessionStorage("userEmail"),
        });
        window.Intercom("update", {
          email: getSessionStorage("userEmail"),
          user_hash: hash,
          calendar_synced: true,
        });
        const opSuc = campaignSaveToDB(
          user.email,
          "extra.accessToken",
          accessToken,
          _id
        );
        if (!opSuc) throw new Error(" Access Token couldn't be saved");
        setAccessToken(accessToken);
        // navigate(location.pathname, {
        //   replace: true,
        //   preventScrollReset: true,
        // });

        // console.log(`Getting the Element token using sub from Access token`);
        // const elementToken = await cronofyApi.getElementToken({
        //   sub: accessToken.sub,
        //   permissions: ["account_management"],
        //   origin: cronofy.redirect_uri,
        // });
        // if (!elementToken) return;
        // setCalendarToken(elementToken.element_token.token);
        // navigate(location.pathname, {
        //   replace: true,
        //   preventScrollReset: true,
        // });

        // console.log(`Getting the Element token using sub from Access token`);
        // const elementToken = await cronofyApi.getElementToken({
        //   sub: accessToken.sub,
        //   permissions: ["account_management"],
        //   origin: cronofy.redirect_uri,
        // });
        // if (!elementToken) return;
        // setCalendarToken(elementToken.element_token.token);
      });
  }, [code]);

  const [availabilityElements, setAvailabilityElements] = useState([]);
  useEffect(() => {
    if (!availabilityOptions.element_token) return;

    setAvailabilityElements((prev) => [
      // ...prev,
      <AvailabilityRulesWrapper
        key="availability-rules"
        options={availabilityOptions}
      />,
    ]);
  }, [availabilityOptions]);
  const getAccessToken = async (_id) => {
    if (!_id) return;
    // console.log(`Getting the Element token using sub from Access token`);
    campaignMaxCallsAllowedToGetAccessToken -= 1;
    // console.log(maxCallsAllowedToGetAccessToken);

    const campaign = await jobsApi.getCampaignDetails(_id);
    const userInfo = campaign[0];
    const accessToken = userInfo?.extra?.accessToken;
    if (
      userInfo?.extra?.accessToken?.linking_profile?.provider_name !== "google"
    ) {
      const conferencingArray = userInfo?.extra?.conferencing_linked?.find(
        (item) => item.email === accessToken?.linking_profile.profile_name
      );
      if (conferencingArray) {
        setConferencingLinked({
          linked: true,
          accessToken: accessToken,
        });
      } else {
        setConferencingLinked({
          linked: false,
          accessToken: accessToken,
        });
      }
    }
    if (accessToken) {
      window.Intercom("update", {
        email: getSessionStorage("userEmail"),
        name: getSessionStorage("fullName"),
        user_hash: hash,
        calendar_synced: true,
      });
    }
    if (!accessToken && campaignMaxCallsAllowedToGetAccessToken > 0) {
      console.log(`Access Token not found, Trying again!`);
      window.Intercom("update", {
        email: getSessionStorage("userEmail"),
        name: getSessionStorage("fullName"),
        user_hash: hash,
        calendar_synced: false,
      });
      // setTimeout(getAccessToken, 1000);
      return;
    }
    // setuserCompanyName(userInfo?.companyName);
    // setuserCompanyAddress(userInfo?.companyAddress);
    // setuserDocumentId(userInfo?._id);
    // setuserPhoneNumber(userInfo?.phone);

    const calendarToken = await cronofyApi.getElementToken({
      sub: accessToken?.sub,
      permissions: ["account_management"],
      origin: subUserStatus ? [whiteLabelDomain,cronofy.redirect_uri] : cronofy.redirect_uri,
    });
    if (calendarToken) {
      //console.log("inside if calendarToken -->", calendarToken);
      setCalendarOptions((prev) => ({
        ...prev,
        element_token: calendarToken.element_token.token,
        target_id: "cronofy-calendar-sync",
      }));
      // setCalendarToken(calendarToken.element_token.token);
    }

    const availabilityToken = await cronofyApi.getElementToken({
      sub: accessToken?.sub,
      permissions: ["managed_availability"],
      origin: subUserStatus ? [whiteLabelDomain,cronofy.redirect_uri] : cronofy.redirect_uri,
    });
    if (availabilityToken) {
      //console.log("inside if availabilityToken -->", calendarToken);
      setAvailabilityOptions((prev) => ({
        ...prev,
        target_id: "cronofy-availability-rules",
        element_token: availabilityToken.element_token.token,
      }));
      // setAvailabilityToken(availabilityToken.element_token.token);
    }

    // console.log(
    //   "calender token, availibility token",
    //   calendarToken,
    //   availabilityToken
    // );
  };

  // get calendar link data
  useEffect(() => {
    // if(!openCalenderDialog)return
    // if(openCalenderDialog){

    campaignMaxCallsAllowedToGetAccessToken = 5;
    // console.log(
    //   `1122 Getting the Element token using sub from Access token`,
    //   accessToken,
    //   location?.state?.extra
    // );

    // if(location?.state){
    //   getAccessTokenExtra(_id)
    // }else{
    getAccessToken(_id);
    // }

    // getAccessToken({ accessToken: accessToken ? accessToken : location?.state?.extra });
    // }
  }, [accessToken, _id]);

  const handleConferencingLink = async (accessTokenCur) => {
    // console.log(
    //   " called handleConferencingLink -->",
    //   accessTokenCur,
    //   accessToken
    // );
    if (!accessTokenCur) {
      accessTokenCur = accessToken;
    }
    const conferencingService = await cronofyApi.conferencingByCampaign({
      token: accessTokenCur?.access_token,
      redirect_uri: `${redirect_uri}&campaign_id=${_id}&title=${title}`,
      email: getSessionStorage("userEmail"),
      profile_email: accessTokenCur?.linking_profile.profile_name,
      campaign_id: _id,
    });
    // console.log(" called conferencingService -->", conferencingService);

    if (conferencingService.linked) {
      return;
    }
    if (conferencingService.url) {
      setDialogState({ open: true, loadUrl: conferencingService?.url });
    }
  };
  const openUrl = () => {
    // console.log("1122 open called -->", dialogState.loadUrl);
    window.open(`${dialogState.loadUrl}`, "_self", "noopener,noreferrer");
    setDialogState({ open: false, loadUrl: "" });
  };

  return (
    <>
      <Dialog
        open={dialogState?.open}
        onClose={(event, reason) => {
          if (
            reason &&
            (reason === "backdropClick" || reason === "escapeKeyDown")
          ) {
            return;
          }
          setDialogState({ open: false, loadUrl: "" });
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Choose the Default Meeting Platform."}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Conference services are not linked to the connected calendar. Please
            click on "Authorize" to establish a connection with your default
            meeting scheduling platform.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button onClick={openUrl} autoFocus>
            Authorize
          </Button>
        </DialogActions>
      </Dialog>

      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            marginBottom: "10px",
            fontSize: "16px",
          }}
        >
          <LuCalendarPlus
            style={{
              marginRight: "10px",
              fontSize: "22px",
              color: theme.palette.primary.main,
            }}
          />
          {title}
        </Box>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Card sx={{ marginY: "10px" }} className="glass-wrapper-25">
          <CardContent>
            <div
              id="section7"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: "15px",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <>
                  <LuCalendarPlus
                    style={{
                      fontSize: "22px",
                      color: theme.palette.primary.main,
                    }}
                  />
                </>
                <Typography
                  sx={{
                    fontWeight: "700",
                    fontSize: "16px",
                    color: theme.palette.text.primary,
                  }}
                >
                  Link calendar
                </Typography>
                {!conferencingLinked.linked && (
                  <Chip
                    size="small"
                    color="error"
                    label="Link meeting platform"
                    onClick={() => {
                      handleConferencingLink(conferencingLinked.accessToken);
                    }}
                    icon={<LinkIcon2 style={{ color: "white" }} />}
                  />
                )}
              </div>
              <Divider />
              <div style={{ marginTop: "20px" }}>
                <CalendarSyncWrapper
                  options={calendarOptions}
                  email={user.email}
                />
              </div>
            </div>
          </CardContent>
        </Card>

        <Card className="glass-wrapper-25">
          <CardContent>
            {Boolean(availabilityElements.length) && (
              <div
                ref={section8Ref}
                id="section8"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "15px",
                  }}
                >
                  <>
                    <MdOutlineWatchLater
                      style={{
                        marginRight: "10px",
                        fontSize: "24px",
                        color: theme.palette.primary.main,
                      }}
                    />
                  </>
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: "16px",
                      color: theme.palette.text.primary
                    }}
                  >
                    {/* Select Your Availability */}
                    Set Working Hours / Availability
                  </Typography>
                </div>
                <Divider />
                <div id="availability-grid" style={{ marginTop: "20px" }}>
                  {availabilityElements.map((element) => element)}
                </div>
              </div>
            )}
            <CampaignSlotPresetSetting campaignId={_id} />
          </CardContent>
        </Card>
      </DialogContent>
    </>
  );
};

const SlotPresetSetting = () => {
  const [duration, setDuration] = useState(30);
  const checkFirstRender = useRef(true);
  const [buffer, setBuffer] = useState({
    isSetBuffer: false,
    before: 0,
    after: 0,
  });
  const [minimumNoticeTime, setMinimumNoticeTime] = useState(null);
  const [minimumNotice, setMinimumNotice] = useState(null);
  const [scheduleTime, setScheduleTime] = useState(null);
  const [dateOption, setDateOption] = useState(null);
  const [scheduleVisibility, setScheduleVisibility] = useState("");
  const [scheduleDate, setScheduleDate] = useState(null);
  const email = getSessionStorage("userEmail");
  const theme = useTheme();
  const handleDurationChange = async (event) => {
    const targetValue = event.target.value;
    setDuration(targetValue);
    updatedurationTime(targetValue);
  };

  const durationTime = async () => {
    const userSlotPreset = await fetch(
      `${API_SERVICE_BACKEND_3}/get-slot-preset/${email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const duration = await userSlotPreset.json();
    setDuration(duration?.data?.slotsPreset);
    let scheduleVisible = duration?.data?.schedulingOption
      ? duration?.data?.schedulingOption
      : "rolling";
    setScheduleVisibility(scheduleVisible);
    if (duration?.data?.schedulingOption === "rolling") {
      setScheduleTime(duration?.data?.rollingTime.split(" ")[0]);
      setDateOption(duration?.data?.rollingTime.split(" ")[1]);
    } else if (duration?.data?.schedulingOption === "custom") {
      let maxScheduleDate = new Date(duration?.data?.maximumSchedulingTime);
      maxScheduleDate.setDate(maxScheduleDate.getDate() - 1);
      setScheduleDate(maxScheduleDate);
    }
    if (duration.data.timeZone) {
      setTimezones(duration.data.timeZone);
    } else {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      await handleTimeZoneChange(null, timeZone, false);
    }

    if (duration?.data?.buffer) {
      setBuffer(duration?.data?.buffer);
    }
    let minimumNoticeDate = duration?.data?.minimumNotice;
    if (minimumNoticeDate && new Date(minimumNoticeDate) > new Date()) {
      let minimumNoticeTime = new Date(duration?.data?.minimumNotice);
      setMinimumNoticeTime(formatDate(minimumNoticeTime));
      let diff = new Date(minimumNoticeDate) - new Date();
      setMinimumNotice(Math.ceil(diff / 1000 / 60 / 60));
    }
  };

  // console.log(
  //   "Intl.DateTimeFormat().resolvedOptions().timeZone",
  //   Intl.DateTimeFormat().resolvedOptions().timeZone
  // );
  useEffect(() => {
    durationTime();
  }, []);

  const updatedurationTime = async (targetValue) => {
    const res = await fetch(
      `${API_SERVICE_BACKEND_3}/updateUserSlotPreset/${email}/${targetValue}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    );
    if (res.status === 200) {
      toast.success("Slot Preset Updated Successfully");
    } else {
      toast.error("Error Updating Slot Preset");
    }
  };

  useEffect(() => {
    if (checkFirstRender.current) {
      checkFirstRender.current = false;
      return;
    }
  }, [duration]);

  const handleBufferChange = (event) => {
    const { name, value } = event.target;
    setBuffer((prevState) => ({
      ...prevState,
      [name]: Number(value),
    }));
  };

  const handleBufferVisibilityChange = async (event) => {
    setBuffer((prevState) => ({
      ...prevState,
      isSetBuffer: !buffer.isSetBuffer,
    }));

    if (event.target.value === "false") {
      const res = await updateSlotPreset(false);
      if (res.status === 200) {
        toast.success("Buffer Updated Successfully");
      } else {
        toast.error("Error Updating Buffer");
      }
    }
  };

  const updateSlotPreset = async (isSetBuffer) => {
    //console.log("12 GLOBAL -->", isSetBuffer);
    return await fetch(
      `${API_SERVICE_BACKEND_3}/updateUserSlotPreset/${email}/${duration}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...buffer,
          isSetBuffer: isSetBuffer ?? buffer.isSetBuffer,
        }),
      }
    );
  };

  const handleSaveBuffer = () => {
    (async () => {
      const res = await updateSlotPreset();
      if (res.status === 200) {
        toast.success("Buffer Updated Successfully");
      } else {
        toast.error("Error Updating Buffer");
      }
    })();
  };

  const [timezones, setTimezones] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );

  const handleTimeZoneChange = async (event, value, msg) => {
    try {
      if (msg === "clear") {
        return;
      }
      const res = await axios.post(
        `${API_SERVICE_BACKEND_3}/updateUserTimeZone/${email}`,
        {
          timeZone: value || Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      );

      if (res.status === 200) {
        setTimezones(res.data.data.timeZone);
        if (msg !== false) {
          toast.success("Time Zone updated successfully!!");
        }
      }
    } catch (e) {
      console.log("err --->", e);
    }
  };

  const handleMinimumNotice = async () => {
    if (!minimumNotice) {
      toast.error("Please enter minimum notice time");
      return;
    }
    let minimum_notice = new Date();
    minimum_notice.setHours(minimum_notice.getHours() + minimumNotice);
    minimum_notice = moment(minimum_notice)
      .tz(timezones)
      .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
    if (minimumNotice === 0) {
      minimum_notice = null;
    }
    const res = await axios.post(
      `${API_SERVICE_BACKEND_3}/updateUserMinimumNotice/${email}`,
      {
        minimum_notice,
      }
    );
    if (res.status === 200) {
      let currentDate = moment(new Date())
        .tz(timezones)
        .format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      if (minimum_notice && new Date(minimum_notice) > new Date(currentDate)) {
        setMinimumNoticeTime(formatDate(new Date(minimum_notice)));
        let diff = new Date(minimum_notice) - new Date(currentDate);
        setMinimumNotice(Math.ceil(diff / 1000 / 60 / 60));
      }
      toast.success("Minimum Notice updated successfully!!");
    } else {
      toast.error("Error Updating Minimum Notice Time");
    }
  };
  const removeMinimumNotice = async () => {
    const res = await axios.post(
      `${API_SERVICE_BACKEND_3}/updateUserMinimumNotice/${email}`,
      { minimum_notice: null }
    );
    if (res.status === 200) {
      setMinimumNotice("");
      setMinimumNoticeTime(null);
      toast.success("Minimum Notice removed successfully!!");
    } else {
      toast.error("Error Removing Minimum Notice Time");
    }
  };
  const handleMaxScheduleTime = async () => {
    if (!scheduleVisibility) {
      toast.error("Please select the above options");
      return;
    }
    let res;
    if (scheduleVisibility === "rolling") {
      if (!scheduleTime || !dateOption) {
        toast.error("Please select the rolling period");
        return;
      }
      res = await axios.post(
        `${API_SERVICE_BACKEND_3}/updateUserMaximumSchedulingTime/${email}`,
        {
          scheduleTime: parseInt(scheduleTime),
          dateOption: dateOption,
          option: scheduleVisibility,
        }
      );
      if (res.status === 200) {
        setScheduleTime(parseInt(scheduleTime));
        setScheduleDate(null);
        toast.success("Maximum Scheduling Time updated successfully!!");
      } else {
        toast.error("Error Updating Maximum Scheduling Time");
      }
    } else {
      if (!scheduleDate) {
        toast.error("Please select the date");
        return;
      }
      res = await axios.post(
        `${API_SERVICE_BACKEND_3}/updateUserMaximumSchedulingTime/${email}`,
        {
          scheduleDate: scheduleDate,
          option: scheduleVisibility,
        }
      );
      if (res.status === 200) {
        setScheduleDate(scheduleDate);
        setScheduleTime("");
        toast.success("Maximum Scheduling Time updated successfully!!");
      } else {
        toast.error("Error Updating Maximum Scheduling Time");
      }
    }
  };
  const removeMaxScheduleTime = async () => {
    if (scheduleVisibility === "rolling") {
      const res = await axios.post(
        `${API_SERVICE_BACKEND_3}/updateUserMaximumSchedulingTime/${email}`,
        {
          scheduleTime: null,
          dateOption: null,
          option: null,
        }
      );
      if (res.status === 200) {
        setScheduleTime("");
        setDateOption(null);
        toast.success("Maximum Scheduling Time removed successfully!!");
      } else {
        toast.error("Error Removing Maximum Scheduling Time");
      }
    } else {
      const res = await axios.post(
        `${API_SERVICE_BACKEND_3}/updateUserMaximumSchedulingTime/${email}`,
        {
          scheduleDate: null,
          option: null,
        }
      );
      if (res.status === 200) {
        setScheduleDate(null);
        toast.success("Maximum Scheduling Time removed successfully!!");
      } else {
        toast.error("Error Removing Maximum Scheduling Time");
      }
    }
  };
  const dateOptions = ["Day", "Week", "Month"];

  const handleSchedulingDate = async (event, value) => {
    setDateOption(value);
  };

  const handleScheduleVisibility = async (event) => {
    setScheduleVisibility(event.target.value);
  };

  const handleSchedulingCalendar = async (event) => {
    setScheduleDate(event);
  };

  const CustomPopper = styled(Popper)(({ theme }) => ({
    "& .MuiAutocomplete-listbox": {
      fontSize: "14px",
    },
  }));

  return (
    <>
      <Divider />

      <Grid item xs={12}>
        <InputLabel
          sx={{
            // ml: 3,
            mt: 3,
            fontFamily: "Inter",
            // fontSize: "14px",
            // fontWeight: 500,
            lineHeight: "20px",
            // color: "#55597A",
            mb: "6px",

            fontSize: "16px",
            color: theme.palette.text.primary,
            fontWeight: 600,
          }}

        >
          Timezone
        </InputLabel>{" "}
        <Autocomplete
          options={option}
          sx={{ width: { xs: "80%", md: "95%" } }}
          id="auto-highlight"
          PopperComponent={CustomPopper}
          autoHighlight
          renderInput={(params) => (
            <TextField {...params} variant={"outlined"} />
          )}
          onChange={handleTimeZoneChange}
          value={timezones}
          className="glass-input"
        />
      </Grid>

      <Divider sx={{ marginY: "10px" }} />

      <FormLabel
        component="legend"
        sx={{
          fontSize: "16px",
          color: theme.palette.text.primary,
          fontWeight: 600,
        }}
      >
        Meeting Duration
      </FormLabel>
      <RadioGroup
        onChange={handleDurationChange}
        row
        aria-label="duration"
        name="duration"
        value={duration}
        style={{ fontSize: '14px' }}
      >
        <FormControlLabel
          value={15}
          control={<Radio />}
          label={
            <Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>15 Minutes</Typography>
          }
        />
        <FormControlLabel
          value={30}
          control={<Radio />}
          label={
            <Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>30 Minutes</Typography>
          }
        />
        <FormControlLabel
          value={60}
          control={<Radio />}
          label={
            <Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>60 Minutes</Typography>
          }
        />
      </RadioGroup>

      <Divider sx={{ marginY: "10px" }} />

      <Grid
        item
        container
        direction="row"
        gap={2}
        alignItems={"center"}
        marginTop={"10px"}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: theme.palette.text.primary,
            fontWeight: 600,
          }}
        >
          Buffer Time:
        </Typography>

        <RadioGroup
          onChange={handleBufferVisibilityChange}
          row
          aria-label="buffer"
          value={buffer.isSetBuffer}
          name={"isSetBuffer"}
        >
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={<Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>No</Typography>}
          />
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={<Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>Yes</Typography>}
          />
        </RadioGroup>
      </Grid>

      {buffer && buffer.isSetBuffer && (
        <Grid container alignItems="center" columnSpacing={2}>
          <Grid item container direction="row" gap={2}>
            <Grid item container alignItems="center" columnSpacing={1}>
              <Typography sx={{ marginRight: "8px", fontSize: "14px", color: theme.palette.text.primary }}>
                before :{" "}
              </Typography>
              <TextField
                name={"before"}
                value={buffer?.before}
                variant="outlined"
                size="small"
                type="number"
                max={60}
                error={buffer?.before > 60}
                helperText={
                  buffer?.before > 60 && "maximum buffer time should be 60"
                }
                sx={{ flexGrow: 1, fontSize: "14px" }}
                inputProps={{ min: 0 }}
                onChange={handleBufferChange}
                className="glass-input"
              />
            </Grid>

            <Grid item container alignItems="center" columnSpacing={1}>
              <Typography sx={{ marginRight: "8px", fontSize: "14px", color: theme.palette.text.primary }}>
                after :{" "}
              </Typography>
              <TextField
                name={"after"}
                value={buffer?.after}
                variant="outlined"
                size="small"
                type="number"
                max={60}
                error={buffer?.after > 60}
                helperText={
                  buffer?.after > 60 && "maximum buffer time should be 60"
                }
                sx={{ flexGrow: 1, fontSize: "14px" }}
                inputProps={{ min: 0 }}
                onChange={handleBufferChange}
                className="glass-input"
              />
            </Grid>

            <Grid item container alignItems="center" columnSpacing={1}>
              <Button
                sx={{ marginRight: "8px", fontSize: "14px" }}
                disabled={buffer?.after > 60 || buffer?.before > 60}
                variant={"outlined"}
                size={"small"}
                onClick={handleSaveBuffer}
                className="glass-button-secondary-50"
              >
                Save Buffer{" "}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Divider sx={{ marginY: "10px" }} />
      <Grid item container gap={2} direction="column" marginTop={"10px"}>
        <Typography
          sx={{
            fontSize: "16px",
            color: theme.palette.text.primary,
            fontWeight: 600,
          }}
        >
          Maximum Scheduling Time
        </Typography>
        <RadioGroup
          onChange={handleScheduleVisibility}
          row
          aria-label="Schedule Time"
          value={scheduleVisibility}
          name="radio-buttons-group"
        >
          <FormControlLabel
            value="rolling"
            control={<Radio />}
            label={
              <Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>
                Over a period of rolling time
              </Typography>
            }
          />
          <FormControlLabel
            value="custom"
            control={<Radio />}
            label={
              <Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>Custom date</Typography>
            }
          />
        </RadioGroup>
        {scheduleVisibility === "rolling" ? (
          <Grid container alignItems="center" columnSpacing={2}>
            <Grid item container direction="row" gap={2}>
              <Grid item container alignItems="center" columnSpacing={1}>
                <TextField
                  placeholder="Enter Period"
                  value={scheduleTime}
                  variant="outlined"
                  id="outlined-basic"
                  size="small"
                  type="number"
                  sx={{ flexGrow: 1 }}
                  className="glass-input"
                  inputProps={{ min: 1 }}
                  onChange={(e) => setScheduleTime(parseInt(e.target.value))}
                />
              </Grid>

              <Autocomplete
                options={dateOptions}
                sx={{ width: "50%" }}
                // className="glass-input"
                size="small"
                variant="outlined"
                id="outlined-basic"
                className="glass-input"
                PopperComponent={CustomPopper}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"outlined"}
                    placeholder="Select"
                  />
                )}
                onChange={handleSchedulingDate}
                value={dateOption}
              />
              <Grid item container alignItems="center" columnSpacing={2}>
                <Button
                  sx={{ marginRight: "8px" }}
                  variant={"outlined"}
                  size={"small"}
                  onClick={handleMaxScheduleTime}
                  className="glass-button-secondary-50"
                >
                  Set
                </Button>
                <Button
                  sx={{ marginRight: "8px" }}
                  variant={"outlined"}
                  size={"small"}
                  onClick={removeMaxScheduleTime}
                  className="glass-button-secondary-50"
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid>
            <Grid item container alignItems="center" direction="row" gap="2">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Max Date"
                  disablePast
                  value={scheduleDate}
                  onChange={handleSchedulingCalendar}
                  renderInput={(params) => <TextField {...params} />}
                  className="glass-input"
                />
              </LocalizationProvider>
            </Grid>
            <Grid item container alignItems="center" marginTop={2}>
              <Button
                sx={{ marginRight: "8px" }}
                variant={"outlined"}
                size={"small"}
                onClick={handleMaxScheduleTime}
                className="glass-button-secondary-50"
              >
                Set
              </Button>
              <Button
                sx={{ marginRight: "8px" }}
                variant={"outlined"}
                size={"small"}
                onClick={removeMaxScheduleTime}
                className="glass-button-secondary-50"
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Divider sx={{ marginY: "10px" }} />
      <Grid item container gap={2} direction="column" marginTop={"10px"}>
        <Typography
          sx={{
            fontSize: "16px",
            color: theme.palette.text.primary,
            fontWeight: 600,
          }}
        >
          Minimum Notice Time
        </Typography>

        <Grid container alignItems="center" columnSpacing={2}>
          <Grid item container direction="row" gap={1}>
            <Grid item container alignItems="center" columnSpacing={1}>
              <TextField
                placeholder="In Hours"
                value={minimumNotice === null ? "" : minimumNotice}
                variant="outlined"
                id="outlined-basic"
                size="small"
                type="number"
                sx={{ flexGrow: 1 }}
                className="glass-input"
                inputProps={{ min: 0 }}
                onChange={(e) => setMinimumNotice(parseInt(e.target.value))}
              />
            </Grid>

            <Grid item container alignItems="center">
              <Button
                sx={{ marginRight: "8px" }}
                variant={"outlined"}
                size="small"
                onClick={handleMinimumNotice}
                className="glass-button-secondary-50"
              >
                Set
              </Button>
            </Grid>
            <Grid item container alignItems="center">
              <Button
                sx={{ marginRight: "8px" }}
                variant={"outlined"}
                size={"small"}
                onClick={removeMinimumNotice}
                className="glass-button-secondary-50"
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {minimumNoticeTime && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 500,
              color: theme.palette.text.primary
            }}
          >
            Valid till{" "}
            <span style={{ color: "red", fontWeight: 400 }}>
              {minimumNoticeTime}
            </span>
          </Typography>
        )}
      </Grid>
    </>
  );
};
export const AccountGeneralSettings = (props) => {
  const location = useLocation();
  const { user } = useAuth();
  //console.log(user,"user");
  // console.log(location);
  const [isNewInterfaceEnabled, setIsNewInterfaceEnabled] = useState(false);
  const [openCalenderDialog, setOpenCalenderDialog] = useState(false);
  const [allow, setAllow] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [activeSubTab, setActiveSubTab] = useState(0); //

  const query = new URLSearchParams(location.search);
  const queryParams = {};
  query.forEach((value, key) => (queryParams[key] = value));
  const navigate = useNavigate();

  const { code, from, campaign_id, title, state } = queryParams;
  const myState = state ? JSON.parse(state) : {};

  useEffect(() => {
    if (user && user.dbRef && user.dbRef.allowDuplicates !== undefined) {
      setAllow(user.dbRef.allowDuplicates);
    }
  }, [user]);
  const duplicateChange = async (event) => {
    const value = event.target.value === "true";
    setAllow(value);
    var data = {
      userId: user.dbRef._id,
      allowDuplicates: value,
    };

    try {
      const response = await fetch(`${API_SERVICE_BACKEND}/updateuser`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      // console.log("Success:", responseData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const [campaign_id, setCampaign_id] = useState();
  // const [title, setTitle] = useState()
  // useEffect(() => {
  //   setTitle(queryParams.title ? queryParams.title : myState.title)
  // }, []);
  // useEffect(() => {
  //   setCampaign_id(queryParams.campaign_id ? queryParams.campaign_id : myState.campaign_id)
  // }, []);
  // let campaign_id = queryParams.campaign_id ? queryParams.campaign_id : myState.campaign_id;
  // let title = queryParams.title ? queryParams.title : myState.title;
  useEffect(() => {
    if (state) {
      const myState = JSON.parse(state);
      // console.log(
      //   "props effect called and inside if ---->",
      //   openCalenderDialog,
      //   myState
      // );
      navigate(
        `${location.pathname}?from=cronofy&code=${code}&campaign_id=${myState.campaign_id}&title=${myState.title}`,
        {
          replace: true,
          preventScrollReset: true,
        }
      );
    }
  }, [state]);
  const extraToken = location?.state?.extra;
  // useEffect(() => {
  //   if(extraToken)
  //   setExtra(extraToken);
  // }, [extraToken]);
  const handleCalenderDialogClose = () => {
    // console.log("props calender closed --->");
    setOpenCalenderDialog(false);
    navigate(location.pathname, { replace: true, preventScrollReset: true });
  };
  useEffect(() => {
    if (campaign_id) {
      setOpenCalenderDialog(true);
    }
  }, [campaign_id]);

  useEffect(() => {
    // Parse the query parameters
    const searchParams = new URLSearchParams(location.search);
    const from = searchParams.get("from");
    const lastLinkedProfileId = searchParams.get("last_linked_profile_id");
    const campaign_id = searchParams.get("campaign_id");

    // Check if the desired query parameters are present
    if (from === "cronofy" && lastLinkedProfileId) {
      // Make your API call here
      makeApiCall(from, lastLinkedProfileId, campaign_id);
    }
  }, [location.search]);

  const makeApiCall = (from, lastLinkedProfileId, campaign_id) => {
    if (campaign_id) {
      cronofyApi.conferencingLinkedByCampaign({ campaign_id: campaign_id });
    } else {
      cronofyApi.conferencingLinked({ email: email });
    }
    // cronofyApi.conferencingLinkedByCampaign({ email: email });
  };

  const section1Ref = useRef();
  const section2Ref = useRef();
  const section3Ref = useRef();
  const section4Ref = useRef();
  const section5Ref = useRef();
  const section6Ref = useRef();
  const section7Ref = useRef();
  const section8Ref = useRef();
  const section9Ref = useRef();
  const section10Ref = useRef();
  const section11Ref = useRef();
  const section12Ref = useRef();
  const section13Ref = useRef();
  const section14Ref = useRef();
  const section15Ref = useRef();
  const section16Ref = useRef();
  const section17Ref = useRef();

  let isFreeSubscriptionUser = user?.dbRef?.freeSubscription;
  const userType = user?.dbRef?.userType;

  let navHeader = [
    {
      headerTitle: "Account Details",
      headerRef: section1Ref,
      headerID: "section1",
      tabId: "accountdetails",
    },
    {
      headerTitle: "Billing",
      headerRef: section16Ref,
      headerID: "section16",
      tabId: "credits",
    },
    // {
    //   headerTitle: "Sending Accounts",
    //   headerRef: section11Ref,
    //   headerID: "section11",
    //   tabId: "sendingaccounts",
    // },
    {
      headerTitle: "Team Access",
      headerRef: section13Ref,
      headerID: "section13",
      tabId: "inviteteammembers",
    },
    {
      headerTitle: "Subscription",
      headerRef: section5Ref,
      headerID: "section5",
      tabId: "subscriptiondetails",
    },
    // {
    //   headerTitle: "Meetings Available Slots",
    //   headerRef: section5Ref,
    //   headerID: "section5",
    // },
    // {
    //   headerTitle: "Meeting Link",
    //   headerRef: section6Ref,
    //   headerID: "section6",
    // },
    {
      headerTitle: "Calendars",
      headerRef: section7Ref,
      headerID: "section7",
      tabId: "calendars",
    },
    // {
    //   headerTitle: "Global Calendar",
    //   headerRef: section7Ref,
    //   headerID: "section7",
    //   tabId: "globalcalendar"
    // },
    // {
    //   headerTitle: "AI Agent Calendars",
    //   headerRef: section10Ref,
    //   headerID: "section10",
    //   tabId: "aiagentcalendars"
    // },
    {
      headerTitle: "Integrations",
      headerRef: section9Ref,
      headerID: "section9",
      tabId: "integrations",
    },
    // {
    //   headerTitle: "Manage Duplicates",
    //   headerRef: section12Ref,
    //   headerID: "section12",
    //   tabId: "manageduplicates",
    // },
    {
      headerTitle: "Block List",
      headerRef: section14Ref,
      headerID: "section14",
      tabId: "blocklist",
    },
    // {
    //   headerTitle: "AI BDR",
    //   headerRef: section17Ref,
    //   headerID: "section17",
    //   tabId: "aibdr",
    // },
  ];

  // if (isFreeSubscriptionUser) {
  //   navHeader = navHeader.filter(
  //     (ele) => ele.headerTitle != "Subscription Details"
  //   );
  // }

  if (userType !== "enterprise") {
    navHeader = navHeader.filter(
      (ele) => ele.headerTitle != "Sending Accounts"
    );
  }

  let ownerEmail = getSessionStorage("ownerEmail") || null;
  if (ownerEmail) {
    navHeader = navHeader.filter((ele) => ele.headerTitle != "User Details");
  }

  useEffect(() => {
    const query = new URLSearchParams(location?.search);
    const tab = query?.get("tab");
    let isValid = true;
    if (tab) {
      // if (isFreeSubscriptionUser) {
      //   if (tab === "subscriptiondetails") {
      //     isValid = false;
      //   }
      // }
      if (userType !== "enterprise") {
        if (tab === "sendingaccounts") {
          isValid = false;
        }
      }
      if (ownerEmail) {
        if (tab === "basicdetails") {
          isValid = false;
        }
      }
      if (isValid) {
        const filteredTab = navHeader?.find((header) => header.tabId === tab);
        if (filteredTab?.headerID) {
          setActiveMenuItem(filteredTab?.headerID);
        }
      }
    }
  }, [location.search, navHeader]);
  //function to handle change in phone number
  const handleChange = (newValue) => setuserPhoneNumber(newValue);

  const [prevPassword, setPrevPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState(null);

  const [dates, setDates] = useState([]);
  const dispatch = useDispatch();
  // console.log("prevPassword: ", prevPassword);

  useEffect(() => {
    const date = [];
    // Calculate the list of dates starting from tomorrow and spanning the next 7 days
    const startDate = moment().add(1, "days"); // Start from tomorrow
    for (let i = 0; i < 5; i++) {
      date.push(startDate.format("YYYY-MM-DD"));
      startDate.add(1, "days");
    }
    setDates(date);
    // Set the list of dates as options for the Select component
    setuserMeetingDate(dates[0]); // Set the default selected date to the first one
  }, []);

  const isStrongPassword = (password) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }

    // Password should contain at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      return "Password must contain at least one uppercase letter.";
    }

    // Password should contain at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      return "Password must contain at least one lowercase letter.";
    }

    // Password should contain at least one digit
    if (!/\d/.test(password)) {
      return "Password must contain at least one digit.";
    }

    // Password should contain at least one special character
    if (!/[@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password)) {
      return "Password must contain at least one special character.";
    }

    // If all conditions are met, the password is considered strong
    return true;
  };

  const handlePasswordChange = (e) => {
    const newPasswordValue = e.target.value;
    setNewPassword(newPasswordValue);

    // Check the strength of the password and update the error message
    const passwordStrengthError = isStrongPassword(newPasswordValue);
    setPasswordError(passwordStrengthError);
  };

  const handlePasswordUpdate = async () => {
    if (newPassword === "" || prevPassword === "") {
      toast.error("Invalid Password!!");
      return;
    }

    const passwordStrengthError = isStrongPassword(newPassword);
    if (passwordStrengthError !== true) {
      toast.error(passwordStrengthError);
      setPasswordError(passwordStrengthError);
      return;
    }

    // Clear any previous password error
    setPasswordError(null);

    const auth = getAuth();
    const user = auth.currentUser;

    // Re-authenticating the user with their current credentials
    const credential = EmailAuthProvider.credential(user.email, prevPassword);

    await reauthenticateWithCredential(user, credential)
      .then((res) => {
        // User successfully re-authenticated
        updatePassword(user, newPassword)
          .then(() => {
            toast.success("Password updated successfully");
            setPrevPassword(newPassword);
          })
          .catch((error) => {
            toast.error("Error updating password:", error.message);
          });
      })
      .catch((error) => {
        // console.log("error: ", error.message);
        if (error.message.includes("wrong-password")) {
          return toast.error("Please enter valid password");
        }
        toast.error("Please Re-login");
      });
  };

  const {
    showAlert,
    avatar,
    email,
    name,
    element_tokens,
    cronofy_options,
    userCompanyName,
    setuserCompanyName,
    updateCompany,
    updateCompanyAddress,
    setuserCompanyAddress,
    userCompanyAddress,
    userCompanyLogo,
    updateUserPhoneNumber,
    setuserPhoneNumber,
    userPhoneNumber,
    addmeetingschedule,
    setuserMeetingDate,
    setuserMeetingTime,
    userMeetingDate,
    userMeetingTime,
    userMeetingLink,
    setuserMeetingLink,
    updateUserMeetingScheduleLink,
    userMeetingData,
    setuserMeetingId,
    updateUserMeetingDate,
    setupdateUserMeetingDate,
    updateUserMeetingTime,
    setupdateUserMeetingTime,
    updatemeetingschedule,
    deletemeetingschedule,
    setAdd,
    add,
    userFirstName,
    userLastName,
    setUserFirstName,
    setUserLastName,
    conferencingLinked,
    handleConferencingLink,
    setInfoUser,
    infoUser,
    getAccessToken,
    // getUserInfo
  } = props;
  const [userName, setUserName] = useState(name);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeMenuItem, setActiveMenuItem] = useState(
    ownerEmail ? "section11" : "section1"
  );
  const [uploadingProfileImage, setUploadingProfileImage] = useState(false);
  const [updatedProfileImage, setUpdatedProfileImage] = useState("");
  let inputRef;

  const handleUserNameUpdate = async () => {
    if (userName === undefined || userName === "" || userName === null) {
      toast.error("Invalid User Name!!");
      return;
    }

    const auth = getAuth();
    updateProfile(auth.currentUser, {
      displayName: userName,
    })
      .then(async () => {
        try {
          var dataSend = {
            name: userName,
          };
          const email = getSessionStorage("userEmail");

          const responseDataLog = await fetch(
            `${API_SERVICE_BACKEND}/updateuserbasicdetails/${email}`,
            {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(dataSend),
            }
          );
          if (
            responseDataLog.status === 200 ||
            responseDataLog.status === 201
          ) {
            toast.success("User Name updated successfully");
            setSessionStorage("fullName", userName);
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((error) => {
        toast.error("Error updating User Name:", error.message);
      });
  };

  const [userDetail, setUserDetail] = useState("");
  const getUserInfo = async (email) => {
    try {
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/getuserdetail/${email}`
      );
      if (!res.data.status) return;
      setUserDetail(res.data.data);
      setIsNewInterfaceEnabled(res?.data?.data?.newDesignInterface || false)
      return res.data.data;
    } catch (err) {
      console.log({ err });
      return;
    }
  };
  useEffect(() => {
    getUserInfo(getSessionStorage("userEmail"));
  }, []);
  // const [imageUpload, setImageUpload] = useState(null);
  // const [imageUrl, setImageUrl] = useState(null);

  // const uploadFile = () => {
  //   if (imageUpload === null) return;
  //   const imageRef = ref(
  //     storage,
  //     `/ProfileImages/${imageUpload.name + Date.now()}`
  //   );
  //   uploadBytes(imageRef, imageUpload).then((snapshot) => {
  //     getDownloadURL(snapshot.ref).then((url) => {
  //       setImageUrl(url);
  //     });
  //   });
  // };

  // console.log(imageUrl);
  // Cronofy
  const { redirect_uri } = cronofy_options;
  const { calendarToken, availabilityToken } = element_tokens;

  const [calendarOptions, setCalendarOptions] = useState({
    data_center: cronofy.data_center,
    target_id: "cronofy-calendar-sync",
    authorization: {
      redirect_uri,
      client_id: cronofy.client_id,
      scope: "read_write",
      avoid_linking: true,
    },
    styles: {
      prefix: "CalendarSync",
    },
  });

  useEffect(() => {
    if (!calendarToken) return;
    setCalendarOptions((prev) => ({
      ...prev,
      element_token: calendarToken,
      target_id: "cronofy-calendar-sync",
    }));
  }, [calendarToken, activeSubTab]);

  const [availabilityOptions, setAvailabilityOptions] = useState({
    target_id: "cronofy-availability-rules",
    data_center: cronofy.data_center,
    availability_rule_id: "work_hours",
    tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  useEffect(() => {
    if (!availabilityToken) return;
    setAvailabilityOptions((prev) => ({
      ...prev,
      element_token: availabilityToken,
      target_id: "cronofy-availability-rules",
    }));
  }, [availabilityToken, activeSubTab]);

  const [availabilityElements, setAvailabilityElements] = useState([]);
  useEffect(() => {
    if (!availabilityOptions.element_token) return;
    setAvailabilityElements((prev) => [
      // ...prev,
      // ...prev,
      <AvailabilityRulesWrapper
        key="availability-rules"
        options={availabilityOptions}
      />,
    ]);
  }, [availabilityOptions, activeSubTab]);

  const saveAccessToken = async (paramsStr) => {
    const res = await axios.get(
      `${API_SERVICE_BACKEND_2}/saveZoho?${paramsStr}`,
      {
        params: {
          email: user.email,
        },
      }
    );
  };

  // let params = new URLSearchParams(window.location.search);
  // console.log("params", params.toString());

  // let status = params.get("i");
  // let access_token = params.get("at");
  // let refresh_token = params.get("rt");
  // let expires_at = params.get("ea");
  // let type = params.get("t");
  // console.log("access_token", access_token);
  useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(window.location.search);
      const status = params.get("i");
      const access_token = params.get("at");
      const refresh_token = params.get("rt");
      const type = params.get("t");
      const expires_at = params.get("ea");
      const account_server = params.get("act");
      const loc = params.get("loc");

      dispatch(thunks.setAccessToken(access_token));
      try {
        setSessionStorage("hubspot_access_token", access_token);
      } catch (error) {
        console.error("Error setting local storage:", error);
      }
      if (status === "s") {
        var dataSend = {
          userEmail: email,
          access_token,
          expires_at,
          refresh_token,
          type,
          account_server,
          location: loc,
        };

        const resDataLog = await fetch(
          `${API_SERVICE_BACKEND_2}/addintegration`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataSend),
          }
        );
        if (resDataLog.status === 200) {
          // Handle success
          window.location.replace(location.pathname);
        }
      }
    };

    // Call fetchData() inside the useEffect
    fetchData();
  }, [activeSubTab]);

  useEffect(() => {
    const isInViewport = (element) => {
      let rect = {};
      if (element) {
        rect = element.getBoundingClientRect();
      }
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
        (window.innerWidth || document.documentElement.clientWidth)
      );
    };
    const handleScroll = () => {
      if (isInViewport(section1Ref?.current)) {
        setActiveMenuItem("section1");
      } else if (isInViewport(section2Ref?.current)) {
        setActiveMenuItem("section2");
      } else if (isInViewport(section3Ref?.current)) {
        setActiveMenuItem("section3");
      } else if (isInViewport(section4Ref?.current)) {
        setActiveMenuItem("section4");
      }
      // else if (isInViewport(section5Ref?.current)) {
      //   setActiveMenuItem('section5');
      // }
      // else if (isInViewport(section6Ref?.current)) {
      //   setActiveMenuItem('section6');
      // }
      else if (isInViewport(section7Ref?.current)) {
        setActiveMenuItem("section7");
        setCalendarOptions((prev) => ({
          ...prev,
          target_id: "cronofy-calendar-sync",
        }));
      } else if (isInViewport(section8Ref?.current)) {
        setActiveMenuItem("section8");
      } else if (isInViewport(section9Ref?.current)) {
        setActiveMenuItem("section9");
      } else if (isInViewport(section10Ref?.current)) {
        setActiveMenuItem("section10");
      } else if (isInViewport(section11Ref?.current)) {
        setActiveMenuItem("section11");
      } else if (isInViewport(section12Ref?.current)) {
        setActiveMenuItem("section12");
      } else {
        setActiveMenuItem(null);
      }
    };
    // window.addEventListener("scroll", handleScroll);
    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  function stringAvatar(name) {
    return {
      children: `${name?.split(" ")[0][0]}`,
    };
  }

  const handleUpload = async (e) => {
    setUploadingProfileImage(true);
    try {
      let image = e[0];
      let formData = new FormData();
      formData.append("file", image);

      const response = await axios.post(
        // `${API_LOCAL_IP}upload`,
        `${API_SERVICE_BACKEND_2}/upload`,
        formData
      );

      const imageUrl = response.data?.url;

      const request = {
        profile_image: imageUrl,
      };
      if (response) {
        const uploadResponse = await axios.put(
          // `${API_LOCAL_IP}updateuserprofileimage/${email}`,
          `${API_SERVICE_BACKEND_2}/updateuserprofileimage/${email}`,
          request
        );
        if (uploadResponse.data.status === true) {
          toast.success("Image updated successfully");
        }
        const profileImage = uploadResponse.data?.data?.profile_image;
        setUpdatedProfileImage(profileImage);
      }
    } catch (error) {
      console.log("error", error);
    }
    setUploadingProfileImage(false);
  };

  const [customCampLimit, setCustomCampLimit] = useState(0);
  const [userCampaigns, setUserCampaigns] = useState([]);
  const [userCampaignLoading, setUserCampaignLoading] = useState(false);
  const organizationId = getSessionStorage("organization_id");
  const [emailInvites, setEmailInvites] = useState("");
  const [userData, setUserData] = useState([]);
  const [invite, setInvite] = useState(false);
  const [resend, setResend] = useState(false);
  const [invitedUserData, setInvitedUserData] = useState([]);
  const theme = useTheme();

  const loadPolicyDetails = () => {
    try {
      axios
        .get(
          `${ADMIN_SERVER}/customer/getPolicyDetails/${user?.email}/${organizationId}`
        )
        .then((res) => {
          // console.log(res);
          const agentCount = parseInt(res.data.policyControls["Agent Count"]);
          setCustomCampLimit(agentCount || 0);
        })
        .catch((error) => {
          // console.log("error", error);
          return error;
        });
    } catch (e) { }
  };

  const loadUserCampaigns = () => {
    try {
      setUserCampaignLoading(true);
      jobsApi
        .getUserCampaigns(user?.email, "")
        .then((data) => {
          setUserCampaigns(data?.data);
          setUserCampaignLoading(false);
          // setFilteredCampaigns(data);
        })
        .catch((error) => {
          // console.log("error", error);
          // return error
        })
        .finally(() => {
          // setIsEmptyChartData(false);
          // setCampaignsLoading(false);
        });

      // jobsApi.getChartData(user?.email).then((data) => {
      //   // console.log(data.dailySends);
      //   setChartData(data.dailySends);
      // });
      // setInterval(() => {
      //   console.log("Charts updated");
      //   jobsApi.getChartData(user?.email).then((data) => {
      //     // console.log(data.dailySends);
      //     setChartData(data.dailySends);
      //   });
      // }, UPDATE_CHARTS_EVERY_X_SECS * 1000);
    } catch (e) {
      // console.log(e);
    }
  };

  useEffect(() => {
    loadPolicyDetails();
    loadUserCampaigns();
  }, [user]);

  const getUserDetails = async () => {
    await axios
      .get(
        // `${API_SERVICE_BACKEND}/getuserdetail/${user.email}`
        `${API_SERVICE_BACKEND}/list-invite/${ownerEmail ?? user.email}`
      )
      .then((res) => {
        // setUserData(res.data?.data?.invites);
        // console.log("res====>", res);
        if (ownerEmail) {
          setUserData(
            res.data?.data?.filter((user) => user?.inviteBy === ownerEmail)
          );
        } else {
          setUserData(res.data?.data);
        }
      })
      .catch((err) => {
        console.log("🚀 ~ err-->", err);
      });
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleInvite = async () => {
    try {
      const validEmail = validateEmail(emailInvites);
      if (!validEmail) {
        toastify.error("Invalid email!!");
        return;
      }
      const response = await axios.post(`${API_SERVICE_BACKEND}/user-invites`, {
        sender_mail: ownerEmail ?? user?.email,
        receiver_mail: emailInvites,
      });
      if (!response?.data?.status) {
        toastify.warn(response.data.message);
        setInvite(false);
        return;
      } else {
        toastify.success(response.data.message);
        setInvite(false);
        setEmailInvites("");
        getUserDetails();
      }
    } catch (error) {
      toastify.error(error?.response?.data?.message);
      setInvite(false);
    }
  };

  const handleResend = async (toEmail, companyName) => {
    try {
      const validEmail = validateEmail(toEmail);
      if (!validEmail) {
        toastify.error("Invalid email!!");
        return;
      }
      setResend(true);
      toastify.info("please wait for few seconds");
      setTimeout(async () => {
        const response = await axios.post(
          `${API_SERVICE_BACKEND}/user-invites`,
          {
            sender_mail: ownerEmail ?? user?.email,
            receiver_mail: toEmail,
            sendEmailOnly: true,
            companyName: companyName ?? "",
          }
        );
        // console.log("response check==>", response.data.message);
        if (!response?.data?.status) {
          setResend(false);
          toastify.warn(response.data.message);
          return;
        } else {
          toastify.success(response.data.message);
          setResend(false);

          getUserDetails();
        }
      }, 20000);
    } catch (error) {
      setResend(false);
      toastify.error(error?.response?.data?.message);
    }
  };

  const handleRemoveUser = async (deleteEmail) => {
    try {
      const validEmail = validateEmail(deleteEmail);
      if (!validEmail) {
        toastify.error("Invalid email!!");
        return;
      }
      const response = await axios.post(
        `${API_SERVICE_BACKEND}/delete-invite`,
        {
          sender_mail: user?.email,
          delete_mail: deleteEmail,
        }
      );
      if (!response.data.data) {
        toastify.warn(response.data.message);
        return;
      } else {
        toastify.success("User removed successfully");
        getUserDetails();
      }
    } catch (error) {
      toastify.error(error.message);
    }
  };
  const confirmRemoveUser = (deleteEmail) => {
    setSelectedEmail(deleteEmail);
    setIsDeleteOpen(true);
  };
  const handleConfirmDelete = () => {
    if (selectedEmail) {
      handleRemoveUser(selectedEmail); // Call existing function
      setIsDeleteOpen(false); // Close dialog after API call
    }
  };

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCancelSubscription = async () => {
    try {
      setLoading(true);
      let result = await axios.post(
        `${API_SERVICE_BACKEND}/cancelPlanSubscription`,
        {
          userEmail: getSessionStorage("userEmail"),
        }
      );
      //console.log("result", result)
      if (result.status == 200) {
        toast.success("Subscription cancelled!");
        getUserInfo(getSessionStorage("userEmail"));
        getAccessToken();
      } else {
        toast.warn("Something went wrong!");
      }
      setLoading(false);
      setOpen(false);
    } catch (err) {
      toast.error("Internal Server Error!");
      setOpen(false);
      setLoading(false);
    }
  };

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  const handleManageSubscription = async () => {
    try {
      const baseURL = `${API_SERVICE_BACKEND}/createCustomerPortalSession?userEmail=${sessionStorage.getItem("userEmail")}`;
      const response = await axios.get(baseURL);
      if (response.data.url) {
        window.open(response.data.url, "_blank");
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error || error?.message);
    }
  }

  const { getUserInterfaceInfo } = useContext(SettingsContext);
  const handleNewInterfaceToggle = async (newValue) => {
    try {
      const baseURL = `${API_SERVICE_BACKEND}/updateUserInterface/${sessionStorage.getItem("userEmail")}`;
      const response = await axios.put(baseURL, { "newDesignInterface": newValue });
      if (response) {
        setIsNewInterfaceEnabled(newValue)
        getUserInterfaceInfo();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error || error?.message);
    }
  }
  const { metaTitle, companyName, subUserStatus } = useContext(WhiteLabelConfigurationContext);
  const pageTitle = `Settings - ${metaTitle || companyName}`;
  return (
    <>
      <Seo title={pageTitle} />
      <div style={{maxWidth:"100%"}}>
        <Box
          sx={{
            //borderBottom: "1px solid rgba(224, 224, 229, 1)",
            //px: 2,
            py: 0,
            
          }}
        >
          <Tabs
            onChange={(e) => {
              const headerId = e.target.value;
              setActiveMenuItem(headerId);
              if (headerId === "section7") {
                setCalendarOptions((prev) => ({
                  ...prev,
                  target_id: "cronofy-calendar-sync",
                  element_token: calendarToken,
                }));

                setAvailabilityOptions((prev) => ({
                  ...prev,
                  target_id: "cronofy-availability-rules",
                  element_token: availabilityToken,
                }));
              }
            }}
            value={activeMenuItem}
            indicatorColor="primary"
            scrollButtons="auto"
            textColor="primary"
            variant="scrollable"
            className="glass-tabs"
            sx={{
              width: "fit-content",
              "& .MuiTab-root": {
                color: theme.palette.text.primary,
                "&.Mui-selected": {
                  color: theme.palette.primary.main,
                },
                pb: "16px",
                pt: "20px",
              },
            }}
          >
            {/* {navHeader.map((header, index) => (
            (header.headerTitle !== "Subscription" || !subUserStatus) && (
              <Tab
                // disabled ={showAlert ? true : false}
                key={header.headerID}
                label={header.headerTitle}
                value={header.headerID}
                onClick={() => navigate(`?tab=${header.tabId}`)}
              />
            )
          ))} */}
            {navHeader.map((header, index) => (
              (!((userDetail.whiteLabelUserId && (header.headerTitle === "Subscription")))) && (
                <Tab
                  key={header.headerID}
                  label={header.headerTitle}
                  value={header.headerID}
                  onClick={() => navigate(`?tab=${header.tabId}`)}
                />
              )
            ))}
          </Tabs>
        </Box>
        <Dialog
          open={openCalenderDialog}
          onClose={handleCalenderDialogClose}
          fullWidth
          maxWidth="md"
        >
          {openCalenderDialog &&
            <CalenderView
            // user={user}
            // id={campaign_id}
            // extra={extra}
            // title={title}
            // params={location.search}
            />
          }
        </Dialog>
        {/* <ul style={{ listStyleType: "none" }}>
          <div className="navigation">
            {navHeader.map((header, index) => (
              <li
                style={{ margin: "5px" }}
                onClick={() => {
                  setActiveMenuItem(header.headerID);
                  navigate(`?tab=${header.tabId}`);
                  if (header.headerID === "section7") {
                    setCalendarOptions((prev) => ({
                      ...prev,
                      target_id: "cronofy-calendar-sync",
                      element_token: calendarToken,
                    }));

                    setAvailabilityOptions((prev) => ({
                      ...prev,
                      target_id: "cronofy-availability-rules",
                      element_token: availabilityToken,
                    }));
                  }
                }}
              >
                <span
                  // href={`#${header.headerID}`}
                  className="listLink"
                  key={index + header.headerID}
                  style={{
                    display: "block",
                    width: "100%",
                    marginTop: 0,
                    marginBottom: 0,
                    cursor: "pointer",
                    backgroundColor:
                      activeMenuItem === header.headerID
                        ? "rgb(220 221 239)"
                        : "",
                  }}
                >
                  {header.headerTitle}
                </span>
              </li>
            ))}
          </div>
        </ul> */}
        {
          <Stack
            spacing={4}
            {...props}
            sx={{
              width: "100%",
              pt: 2,
              height: "calc(100vh - 132px)",
              overflow: "auto",
              ...scrollBarStyle,
            }}
          >
            {/* {showAlert === true ? (
              <Alert
                variant="filled"
                severity="error"
                style={{ paddingBottom: "2vh", paddingTop: "2vh" }}
              >
                Please fill in the company name to complete the sign-up process.
              </Alert>
            ) : null} */}

            {/* <Button
                    color="inherit"
                    size="small"
                    onClick={handleUserNameUpdate}
                  >
                    Save
                  </Button> */}
            {!ownerEmail && activeMenuItem === "section1" && (
              <Stack
                gap={2}
                ref={section1Ref}
                id="section1"
                style={{ opacity: showAlert ? 0.5 : 1, height: "100%" }}
              >
                <Stack
                  direction={"row"}
                  gap={2}
                  justifyContent={"space-between"}
                  sx={{ flexWrap: "wrap" }}
                >
                  <Card
                    sx={{
                      marginTop: "10px",
                      width: { xs: "100%", md: "49%" },
                      border: "1px solid #EAECF0",
                      marginLeft:"4px"
                    }}
                    className="glass-wrapper"
                  >
                    <CardContent>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", marginBottom: "15px", alignItems: "center" }}>
                          <>
                            
                            {/* <AccountCircleOutlinedIcon
                              sx={{
                                marginRight: "10px",

                                fontSize: "24px",
                                color: theme.palette.primary.main, // Dynamically sets the color
                              }}
                            /> */}
                          </>
                          <Typography
                            className="subHeadding"
                            sx={{ fontWeight: "700", color: theme.palette.text.primary }}
                          >
                            User Details
                          </Typography>
                        </div>
                      </div>
                      {/* <Divider /> */}
                      <Grid sx={{ marginTop: "8px" }}>
                        <Stack spacing={3}>
                          <Stack direction="row" spacing={3}>
                            <div className="basicDetails">
                              <Typography
                                sx={{
                                  marginBottom: "10px",
                                  fontSize: "14px",
                                  color: theme.palette.text.primary
                                }}
                              >
                                First Name
                              </Typography>
                              <TextField
                                value={userFirstName}
                                variant="outlined"
                                size="small"
                                fullWidth
                                disabled={showAlert ? true : false}
                                sx={{ flexGrow: 1 }}
                                spellCheck={true}
                                onChange={(e) =>
                                  setUserFirstName(e.target.value)
                                }
                                className="glass-input"
                              />
                            </div>
                            <div className="basicDetails">
                              <Typography
                                sx={{
                                  marginBottom: "10px",
                                  fontSize: "14px",
                                  color: theme.palette.text.primary
                                }}
                              >
                                Last Name
                              </Typography>
                              <TextField
                                value={userLastName}
                                variant="outlined"
                                size="small"
                                disabled={showAlert ? true : false}
                                fullWidth
                                sx={{ flexGrow: 1 }}
                                spellCheck={true}
                                onChange={(e) =>
                                  setUserLastName(e.target.value)
                                }
                                className="glass-input"
                              />
                            </div>
                          </Stack>
                          <Stack direction="row" spacing={3}>
                            <Stack
                              direction="column"
                              sx={{ width: "100%" }}
                              spacing={1}
                            >
                              <Typography
                                sx={{ color: theme.palette.text.primary, fontSize: "14px" }}
                              >
                                Email Address
                              </Typography>
                              <TextField
                                defaultValue={email}
                                disabled
                                variant="outlined"
                                required
                                sx={{ color: theme.palette.text.primary }}
                                size="small"
                                fullWidth
                                InputProps={{
                                  startAdornment: (
                                    // <img
                                    //   src={Email}
                                    //   style={{
                                    //     paddingRight: "10px",
                                    //     height: "23px",
                                    //     color:theme.palette.text.primary
                                    //   }}
                                    // />
                                    <EmailOutlinedIcon
                                      sx={{
                                        marginRight: "10px",
                                        width: "23px",
                                        fontSize: "28px",
                                        color: theme.palette.text.primary

                                      }} />
                                  ),
                                }}
                                className="glass-input"
                              />
                              {/* <div
                          style={{ display: "flex", flexDirection: "row" }}
                        >
                          <div>
                            <Avatar
                              {...stringAvatar(name)}
                              sx={{
                                backgroundColor: "#5766F6",
                                color: "white",
                                marginRight: "20px",
                              }}
                            />
                          </div>
                          <div className="dropZoneArea">
                            {uploadingProfileImage && (
                              <Oval
                                height={70}
                                width={70}
                                color="#040b44"
                                secondaryColor="#5664f1"
                                strokeWidth={2}
                                strokeWidthSecondary={2}
                                wrapperClass="imageLoader"
                                visible={true}
                                ariaLabel="oval-loading"
                              />
                            )}

                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                handleUpload(acceptedFiles);
                                console.log(acceptedFiles);
                              }}
                              accept={{
                                "image/jpeg": [],
                                "image/png": [],
                                "image/jpg": [],
                                "image/svg+xml": [],
                                "image/svg": [],
                                "image/gif": [],
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div {...getRootProps()}> */}
                              {/* <input
                                        {...getInputProps()}
                                        accept={".png, .jpg, .jpeg"}
                                      /> */}
                              {/* <img
                                      src={UploadIcon}
                                      className="uploadIcon"
                                    />
                                    <p className="dragText">
                                      <button
                                        onClick={() => inputRef.click()}
                                        className="uploadButton"
                                      > */}
                              {/* Click to upload */}
                              {/* </button> */}
                              {/* <input
                                          type="file"
                                          hidden={true}
                                          ref={(refParam) =>
                                            (inputRef = refParam)
                                          }
                                          accept={".png, .jpg, .jpeg"}
                                          onChange={(e) => {
                                            // handleUpload(e);
                                          }}
                                        /> */}
                              {/* a file or Drag and drop
                                    </p>
                                    <p className="svgText">
                                      SVG, PNG, JPG or GIF (max. 800x400px)
                                    </p>
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </div> */}
                            </Stack>
                          </Stack>
                          <div style={{ marginTop: "20px" }}>
                            <Stack direction="column" spacing={2}>
                              <Typography
                                sx={{ fontSize: "14px", color: theme.palette.text.primary }}
                              >
                                Phone Number
                              </Typography>

                              {/* <MuiTelInput
                                value={userPhoneNumber}
                                onChange={handleChange}
                                style={{ flex: 1, marginTop: "10px"}}
                              /> */}
                              <MuiTelInput
                                disabled={showAlert ? true : false}
                                value={userPhoneNumber}
                                onChange={handleChange}
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{ flexGrow: 1 }}
                                className="glass-input"
                              />
                            </Stack>
                          </div>
                        </Stack>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Card
                    sx={{
                      marginTop: "10px",
                      width: { xs: "100%", md: "49%" },
                      border: "1px solid #EAECF0",
                    }}
                    className="glass-wrapper"
                  >
                    <CardContent>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "15px",
                            alignItems: "center",
                            fontSize: "30px"
                          }}
                        >
                          <>
                            
                            {/* <PiGlobeHemisphereWestBold
                              style={{
                                marginRight: "8px",
                                fontSize: "24px",
                                color: theme.palette.primary.main,
                              }}
                            /> */}
                          </>
                          <Typography
                            className="subHeadding"
                            sx={{ fontWeight: "700", fontSize: "16px", color: theme.palette.text.primary }}
                          >
                            Company Details
                          </Typography>
                        </div>
                        {/* <Divider /> */}
                        <div style={{ marginTop: "8px" }}>
                          <Stack
                            direction="column"
                            spacing={2}
                            sx={{ fontSize: "14px" }}
                          >
                            <CompanyLogoUpload
                            email={email}
                            companyLogo={userCompanyLogo}
                            showAlert={showAlert}
                          />
                            <Typography
                              className="title"
                              sx={{ fontSize: "14px", color: theme.palette.text.primary }}
                            >
                              {" "}
                              Company Name
                            </Typography>
                            <TextField
                              fullWidth
                              placeholder="Enter Company Name"
                              variant="outlined"
                              size="small"
                              type="text"
                              value={userCompanyName}
                              disabled={showAlert ? true : false}
                              spellCheck={true}
                              sx={{
                                flexGrow: 1,
                              }}
                              onChange={(e) =>
                                setuserCompanyName(e.target.value)
                              }
                              className="glass-input"
                            />
                            {/* <Button onClick={updateCompany}>Save</Button> */}
                          </Stack>

                          <Stack sx={{ mt: 2 }} direction="column" spacing={2}>
                            <Typography
                              className="title"
                              sx={{ fontSize: "14px", color: theme.palette.text.primary }}
                            >
                              Company Address
                            </Typography>
                            <TextField
                              fullWidth
                              size="small"
                              variant="outlined"
                              disabled={showAlert ? true : false}
                              placeholder="Enter Company Address"
                              type="text"
                              value={userCompanyAddress}
                              spellCheck={true}
                              sx={{
                                flexGrow: 1,
                              }}
                              onChange={(e) =>
                                setuserCompanyAddress(e.target.value)
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {/* <img src={MarkerPin} /> */}
                                    <HiOutlineLocationMarker
                                      style={{
                                        fontSize: "22px",
                                        color: theme.palette.text.primary,
                                      }}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                              className="glass-input"
                            />
                            {/* <Button onClick={updateCompanyAddress}>Save</Button> */}
                          </Stack>
                          {/* <CompanyLogoUpload
                            email={email}
                            companyLogo={userCompanyLogo}
                            showAlert={showAlert}
                          /> */}
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </Stack>
                <Stack
                  direction={"row"}
                  gap={2}
                  justifyContent={"space-between"}
                  sx={{ flexWrap: "wrap" }}
                >
                  <Card
                    sx={{
                      marginTop: "10px",
                      width: { xs: "100%", md: "49%" },
                      border: "1px solid #EAECF0",
                      marginLeft:"4px"
                    }}
                    className="glass-wrapper"
                  >
                    <CardContent>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            marginBottom: "15px",
                            alignItems: "center"
                          }}
                        >
                          <>
                            
                            {/* <IoKeyOutline
                              style={{
                                marginRight: "10px",
                                fontSize: "24px",
                                color: theme.palette.primary.main,
                              }}
                            /> */}
                          </>
                          <Typography
                            sx={{
                              fontWeight: "700",
                              fontSize: "16px",
                              color: theme.palette.text.primary,
                            }}
                          >
                            Change Password
                          </Typography>
                        </div>
                        {/* <Divider /> */}
                        <Grid xs={12} sm={12} md={8} sx={{ marginTop: "8px" }}>
                          <Stack direction="column" spacing={2}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: theme.palette.text.primary,
                                fontWeight: 500,
                              }}
                            >
                              Previous Password
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder="Enter Previous Password"
                              spellCheck={true}
                              type="password"
                              disabled={showAlert ? true : false}
                              size="small"
                              value={prevPassword}
                              sx={{
                                flexGrow: 1,
                                color: theme.palette.text.primary
                              }}
                              onChange={(e) => setPrevPassword(e.target.value)}
                              className="glass-input"
                            // InputProps={{
                            //   endAdornment: (
                            //     <>
                            //       {" "}
                            //       <InputAdornment position="end">
                            //         <IconButton
                            //           aria-label="toggle password visibility"
                            //           // onClick={handleClickShowPassword}
                            //           // onMouseDown={handleMouseDownPassword}
                            //           edge="end"
                            //           sx={{ mr: "1px" }}
                            //         >
                            //           <Visibility />
                            //           {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                            //         </IconButton>
                            //       </InputAdornment>
                            //     </>
                            //   ),
                            // }}
                            />
                          </Stack>
                          <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: theme.palette.text.primary,
                                fontWeight: 500,
                              }}
                            >
                              New Password
                            </Typography>
                            <TextField
                              fullWidth
                              variant="outlined"
                              size="small"
                              spellCheck={true}
                              placeholder="Enter New Password"
                              type="password"
                              value={newPassword}
                              disabled={showAlert ? true : false}
                              sx={{
                                flexGrow: 1,
                                color: theme.palette.text.primary
                              }}
                              onChange={handlePasswordChange}
                              className="glass-input"
                            // InputProps={{
                            //   endAdornment: (
                            //     <>
                            //       {" "}
                            //       <InputAdornment position="end">
                            //         <IconButton
                            //           aria-label="toggle password visibility"
                            //           // onClick={handleClickShowPassword}
                            //           // onMouseDown={handleMouseDownPassword}
                            //           edge="end"
                            //           sx={{ mr: "1px" }}
                            //         >
                            //           <Visibility />
                            //           {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                            //         </IconButton>
                            //       </InputAdornment>
                            //     </>
                            //   ),
                            // }}
                            />
                            {passwordError && (
                              <Typography color="error" sx={{ fontSize: "12px" }}>
                                {passwordError}
                              </Typography>
                            )}
                            <Box display="flex" justifyContent="flex-start">
                            <Button
                            variant="contained"
                            size="medium"
                              onClick={handlePasswordUpdate}
                              disabled={showAlert ? true : false}
                              sx={{ minWidth: "auto" }}
                              
                            >
                              Change Password
                            </Button>
                            </Box>
                          </Stack>
                        </Grid>
                      </div>
                    </CardContent>
                  </Card>
                  {!subUserStatus && (
                    <Card
                      sx={{
                        marginTop: "10px",
                        width: { xs: "100%", md: "49%" },
                        border: "1px solid #EAECF0",
                      }}
                      className="glass-wrapper"
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 2,
                          height: "100%",
                          width: "75%",
                          justifySelf: "center"
                        }}
                      >
                        <Chip className="glass-chip" label="New" />
                        <Typography
                          sx={{
                            fontSize: "24px",
                            fontWeight: 600,
                            color: theme.palette.text.primary,
                            marginBottom: "12px",
                            letterSpacing: "-0.003em",
                          }}
                        >
                          Redesigned Interface
                        </Typography>
                        <Typography sx={{ fontSize: "14px", color: theme.palette.text.secondary, textAlign: "center" }}>
                          Experience our new glass-inspired design system, bringing clarity and depth to your workflow.
                        </Typography>
                        <Divider sx={{ width: "100%", my: 1 }} />
                        <Stack sx={{ width: "100%" }} flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 500,
                              color: theme.palette.text.primary
                            }}
                          >
                            Enable new design
                          </Typography>
                          <CustomSwitch checked={isNewInterfaceEnabled} onChange={(e) => handleNewInterfaceToggle(e.target.checked)} />
                        </Stack>
                      </CardContent>
                    </Card>
                  )}
                </Stack>
              </Stack>
            )}

            {/* phone number details  */}
            {/* {!isFreeSubscriptionUser && activeMenuItem === "section5" && ( */}
            {activeMenuItem === "section5" && (
              <Card sx={{ overflow: "visible", border: "1px solid #EAECF0" }}
                className="glass-wrapper"
              >
                <CardContent sx={{ p: 2 }}>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    ref={section5Ref}
                    id="section5"
                  >
                    <div
                      className="subscription-wrap "
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "15px",
                        alignItems: "flex-start",
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        {/* <img
                          src={StackedCoinIcon}
                          style={{ marginRight: "0px", height: "23px",color:theme.palette.text.primary }}
                        /> */}


                        < HiOutlineCircleStack

                          style={{
                            marginRight: "10px",
                            fontSize: "25px",
                            color: theme.palette.primary.main,
                          }}
                        />
                        <Typography
                          sx={{
                            fontWeight: "700",
                            fontSize: "16px",
                            color: theme.palette.text.primary,
                          }}
                        >
                          Subscription Details
                          {userDetail?.userSubscription?.subscriptions?.base
                            ?.packageName ? (
                            <>
                              <Typography
                                sx={{
                                  fontWeight: "600",
                                  fontSize: "14px",
                                  lineHeight: "24px",
                                  fontFamily: "Inter",
                                  marginTop: "10px",
                                  color: theme.palette.text.primary
                                }}
                              >
                                Current Active Subscription : &nbsp;
                                <span>
                                  {
                                    userDetail?.userSubscription?.subscriptions
                                      ?.base?.packageName
                                  }
                                </span>
                              </Typography>
                              {userDetail?.userSubscription?.subscriptions?.base
                                ?.updatedSubscription?.name && (
                                  <Typography
                                    sx={{
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      lineHeight: "24px",
                                      fontFamily: "Inter",
                                      marginTop: "10px",
                                      color: theme.palette.text.primary
                                    }}
                                  >
                                    Your subscription is scheduled to update to{" "}
                                    {
                                      userDetail?.userSubscription?.subscriptions
                                        ?.base?.updatedSubscription?.name
                                    }{" "}
                                    on{" "}
                                    {formatTimestamp(
                                      userDetail?.userSubscription?.subscriptions
                                        ?.base?.updatedSubscription?.date
                                    )}
                                  </Typography>
                                )}
                            </>
                          ) : userDetail?.freeSubscription &&
                            !userDetail?.isPaymentWall &&
                            userDetail?.accountStatus === 1 &&
                            userDetail?.freeSubscriptionDetails ? (
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "14px",
                                lineHeight: "24px",
                                fontFamily: "Inter",
                                marginTop: "10px",
                                color: theme.palette.text.primary
                              }}
                            >
                              Custom Subscription
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                fontWeight: "600",
                                fontSize: "14px",
                                lineHeight: "24px",
                                fontFamily: "Inter",
                                marginTop: "10px",
                                color: theme.palette.text.primary
                              }}
                            >
                              Custom Subscription
                            </Typography>
                          )}
                        </Typography>
                      </div>
                      <CardActions className="cancel-sub">
                        {userDetail?.userSubscription?.planStatus == "active" &&
                          userDetail?.userSubscription?.subscriptionId !==
                          "free" && (
                            <Button
                              sx={{
                                justifyContent: "space-between",
                                alignItems: "center",
                                gap: 1,
                                borderRadius: 1,
                                fontSize: "14px",
                              }}
                              variant="contained"
                              onClick={handleClickOpen}
                              disabled={
                                userDetail?.userSubscription?.status ==
                                  "cancelled"
                                  ? true
                                  : false
                              }
                              startIcon={<DeleteIcon />}
                            >
                              {userDetail?.userSubscription?.status ==
                                "cancelled"
                                ? "Cancelled "
                                : "Cancel "}{" "}
                              Subscription
                            </Button>
                          )}
                        <Button
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 1,
                            borderRadius: 1,
                            fontSize: "14px",
                          }}
                          variant="contained"
                          onClick={handleManageSubscription}
                        >
                          Manage Subscription
                        </Button>
                      </CardActions>
                      <SubscriptionCancel
                        open={open}
                        setOpen={setOpen}
                        handleCancelSubscription={handleCancelSubscription}
                        loading={loading}
                        subscriptionData={userDetail?.userSubscription}
                      />
                    </div>

                    {userDetail?.userSubscription?.planStatus !== "active" &&
                      userDetail?.freeSubscription &&
                      !userDetail?.isPaymentWall &&
                      userDetail?.accountStatus === 1 &&
                      userDetail?.freeSubscriptionDetails && (
                        <Box mt={0} mb={4}>
                          <Grid container spacing={2}>
                            {userDetail?.freeSubscriptionDetails
                              ?.split("\n")
                              .map((detail, index) => (
                                <Grid item xs={6} md={4} lg={3} key={index}>
                                  <Box
                                    bgcolor="#f9f9f9"
                                    borderRadius="8px"
                                    p={2}
                                    boxShadow="0 2px 10px rgba(0, 0, 0, 0.05)"
                                  >
                                    <Typography
                                      variant="body1"
                                      fontWeight="500"
                                      fontSize="16px"
                                      sx={{ color: theme.palette.text.primary }}
                                    >
                                      {detail.split(":")[0]}
                                    </Typography>
                                    <Typography
                                      variant="h6"
                                      fontWeight="700"
                                      sx={{ color: theme.palette.text.primary }}
                                    >
                                      {detail.split(":")[1].trim()}
                                    </Typography>
                                  </Box>
                                </Grid>
                              ))}
                          </Grid>
                        </Box>
                      )}

                    <Divider />

                    <PlansUI
                      userSubscription={userDetail?.userSubscription}
                      getUserDetail={getUserInfo}
                    />

                    <Invoices details={userDetail} />

                    {/* <Plan details={infoUser} getUserInfo={getUserInfo} /> */}
                  </div>
                </CardContent>
              </Card>
            )}

            {/* <Card>
              <CardContent>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    ref={section5Ref}
                    id="section5"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "15px",
                    }}
                  >
                    <>
                      <img src={MeetingIcon} style={{ marginRight: "10px", height: '23px' }} />
                    </>
                    <Typography
                      sx={{ fontWeight: "700", fontSize: "16px", color: "#050C46" }}
                    >
                      Meetings Available Slots
                    </Typography>
                  </div>
                  <Divider />
                  <div style={{ justifyContent: "flex-end", marginTop: "20px" }}>
                    <Autocomplete
                      getOptionLabel={(option) => option.text}
                      options={dates}
                      sx={{ mb: '20px' }}
                      renderInput={(params) => (
                        <>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#050C46",
                              fontWeight: 500,
                              marginBottom: '10px'
                            }}
                          >
                            Select Date
                          </Typography>
                          <TextField
                            {...params}
                            variant="outlined"
                            fullWidth
                            placeholder="Enter available slots"
                            name="TimeSlots"
                          />
                        </>
                      )}
                    />
                    <Divider />
                    <div style={{ marginTop: '20px' }}>
                      <div style={{ border: '1px solid #ECE9F5', backgroundColor: '#ECE9F5', height: '84px', borderRadius: '12px' }}>

                      </div>
                      <div style={{ border: '1px solid #ECE9F5', backgroundColor: '#ECE9F5', height: '84px', borderRadius: '12px', marginTop: '15px' }}>

                      </div>
                    </div> }
              {/* {dates?.map((date, index) => {
                    return (
                      <>
                        <UserMeetingSchedules
                          date={moment(date).format("ddd,MMM Do")}
                          key={index}
                          index={index}
                        />
                      </>
                    );
                  })} 
              {/* </div>
                </div>
              </CardContent>
            </Card> 

            {/* <Card>
              <CardContent>
                <div
                  ref={section6Ref}
                  id="section6"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "15px",
                    }}
                  >
                    <>
                      <img
                        src={Link}
                        style={{ marginRight: "10px", height: "23px" }}
                      />
                    </>
                    <Typography
                      sx={{
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "#050C46",
                      }}
                    >
                      Meeting Link
                    </Typography>
                  </div>
                  <Divider />
                  <div style={{ marginTop: "20px" }}>
                    <Stack direction="column" spacing={2}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#050C46",
                          fontWeight: 500,
                        }}
                      >
                        Link
                      </Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        placeholder="Add Link"
                        type="text"
                        value={userMeetingLink}
                        sx={{
                          flexGrow: 1,
                        }}
                        onChange={(e) => setuserMeetingLink(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img src={LinkIcon} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>
                  </div>
                </div>
              </CardContent>
            </Card> */}

            {activeMenuItem === "section7" && (
                <CalendarPanel
                  section7Ref={section7Ref}
                  conferencingLinked={conferencingLinked}
                  handleConferencingLink={handleConferencingLink}
                  calendarOptions={calendarOptions}
                  email={email}
                  availabilityElements={availabilityElements}
                  userCampaigns={userCampaigns}
                  location={location}
                  navigate={navigate}
                  userCampaignLoading={userCampaignLoading}
                  activeSubTab={activeSubTab}
                  setActiveSubTab={setActiveSubTab}
                />
               
            )}

            {activeMenuItem === "section9" && (
              <IntegrationSettings
                email={email}
                section9Ref={section9Ref}
                userCampaigns={userCampaigns}
                loadUserCampaigns={loadUserCampaigns}
                userCampaignLoading={userCampaignLoading}
              />
            )}

            {activeMenuItem === "section10" && (
              <Card>
                <CardContent>
                  <div
                    ref={section10Ref}
                    id="section10"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "15px",
                      }}
                    >
                      <>
                        <LuCalendarPlus
                          style={{
                            marginRight: "10px",
                            fontSize: "22px",
                            color: theme.palette.primary.main,
                          }}
                        />
                      </>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "16px",
                          color: theme.palette.text.primary
                        }}
                      >
                        AI Agent Calendars
                      </Typography>
                    </div>
                    <Divider />

                    <div style={{ marginTop: "20px" }}
                      className="glass-wrapper">
                      <Stack
                        // alignItems="center"
                        // direction="column"
                        sx={{
                          border: "1px solid #EAECF0",
                          borderRadius: "12px",
                          padding: "5px",
                        }}
                        gap={"5px"}
                      >
                        {userCampaigns?.map((campaign, index) => {
                          return (
                            <>
                              <Box
                                sx={{
                                  width: "100%",
                                  // border: "1px solid #EAECF0",
                                  borderRadius: "12px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  paddingX: "10px",
                                }}
                              >
                                <Typography style={{ fontSize: "14px", color: theme.palette.text.primary }}>
                                  <b style={{ fontSize: "14px" }}>
                                    {" "}
                                    {campaign.companyName}
                                  </b>{" "}
                                  - {campaign.title}
                                </Typography>

                                <Button
                                  variant={"outlined"}
                                  sx={{
                                    color: "#111927",
                                    border: "1px solid #ECE9F5",
                                    marginRight: "10px",
                                    marginLeft: "5px",
                                    fontSize: "14px",
                                  }}
                                  onClick={() => {
                                    // console.log(
                                    //   "props extrra -------->",
                                    //   campaign.extra
                                    // );
                                    navigate(
                                      `${location.pathname}?campaign_id=${campaign._id}&title=${campaign.title}`,
                                      { state: { extra: campaign.extra } }
                                    );
                                  }}
                                >
                                  {/* {campaign.extra
                                    ? "Connected"
                                    : "Not Connected"} */}
                                  View More
                                </Button>
                              </Box>
                            </>
                          );
                        })}
                      </Stack>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )}

            {activeMenuItem === "section11" && (
              <EmailAccounts
                sectionRef={section11Ref}
                id="section11"
                userEmail={user?.email}
                userType={userType}
              />
            )}

            {/* {activeMenuItem === "section12" && (
              <Card>
                <CardContent>
                  <div
                    ref={section12Ref}
                    id="section11"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      container
                      direction="row"
                      alignItems="center"
                      gap={2}
                      style={{
                        marginTop: "10px",
                        padding: "10px",
                        borderRadius: "12px",
                      }}
                    >
                      <div></div>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "#050C46",
                          fontWeight: 600,
                          marginBottom: "15px",
                        }}
                      >
                        Manage Duplicates:
                      </Typography>
                      <Divider />
                      <RadioGroup
                        onChange={duplicateChange}
                        row
                        aria-label="allow"
                        value={allow.toString()}
                        name="isSetduplicate"
                      >
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="Don't Allow"
                        />
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Allow "
                        />
                      </RadioGroup>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )} */}

            {activeMenuItem === "section13" && (
              <Card
                id="sectionInvites"
                sx={{ overflow: "visible", border: "1px solid #EAECF0" }}
                className="glass-wrapper"
              >
                <CardContent>
                  <div
                    style={{ display: "flex", flexDirection: "column" }}
                    ref={section13Ref}
                    id="sectionInvites"
                  >
                    <div style={{ display: "flex", marginBottom: "15px", alignItems: "center" }}>
                      <>
                        {/* <img
                          src={LinkIcon}
                          style={{ marginRight: "10px", height: "23px" }}
                        /> */}
                        <AiOutlineLink
                          style={{
                            marginRight: "10px",

                            fontSize: "24px",
                            color: theme.palette.text.primary,
                          }} />
                      </>
                      <Typography
                        className="subHeadding"
                        sx={{ fontWeight: "700", color: theme.palette.text.primary }}
                      >
                        Team


                      </Typography>
                    </div>

                    <Grid>
                      <Grid>
                        <Stack spacing={3}>
                          {/* <Stack direction="row" spacing={3}>
                            <div className="basicDetails">
                              <Typography
                                sx={{ marginBottom: "10px", fontSize: "14px" }}
                              >
                                Email
                              </Typography>
                              <TextField
                                variant="outlined"
                                sx={{ color: "#111927" }}
                                size="small"
                                fullWidth
                                placeholder="Enter Email Address"
                                value={emailInvites}
                                onChange={(e) =>
                                  setEmailInvites(e.target.value)
                                }
                                InputProps={{
                                  startAdornment: (
                                    <img
                                      src={Email}
                                      style={{
                                        paddingRight: "10px",
                                        height: "23px",
                                      }}
                                    />
                                  ),
                                }}
                              />
                            </div>
                          </Stack>
                          <Divider /> */}

                          {/* <Button
                            disabled={invite || resend}
                            onClick={() => handleInvite()}
                            sx={{
                              width: "130px",
                            }}
                          >
                            Invite Team
                          </Button>  */}

                          <Stack direction="row" spacing={3} sx={{ alignItems: "center" }}>
                            <div className="basicDetails" style={{ position: "relative", width: "100%" }}>
                              <Typography sx={{ marginBottom: "10px", fontSize: "14px", color: theme.palette.text.primary }}>
                                Email
                              </Typography>
                              <TextField
                                variant="outlined"
                                sx={{ width: "100%" }}
                                size="small"
                                fullWidth
                                placeholder="Enter Email Address"
                                value={emailInvites}
                                onChange={(e) => setEmailInvites(e.target.value)}
                                InputProps={{
                                  startAdornment: (
                                    // <img
                                    //   src={Email}
                                    //   style={{
                                    //     paddingRight: "10px",
                                    //     height: "23px",
                                    //   }}
                                    // />
                                    <EmailOutlinedIcon
                                      sx={{
                                        marginRight: "10px",
                                        width: "23px",
                                        fontSize: "30px",
                                        color: theme.palette.text.primary

                                      }} />
                                  ),
                                  endAdornment: (
                                    <Button
                                      disabled={invite || resend}
                                      onClick={() => handleInvite()}
                                      sx={{
                                        height: "100%",
                                        borderRadius: "4px",  // Slightly round corners
                                        borderLeft: "1px solid #1976d2",  // Blue border
                                        width: "130px",
                                        padding: "0",
                                        borderColor: theme.palette.primary.main,  // Set blue border color
                                        fontSize: "14px",
                                      }}
                                      variant="outlined"  // Bordered button
                                      className="glass-button-secondary-25"
                                    >
                                      Invite Team
                                    </Button>

                                  ),
                                }}
                                className="glass-input"
                              />
                            </div>
                          </Stack>
                          <Divider />

                          <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
                            {userData && userData?.length ? (
                              <Box>
                                <Typography
                                  className="subHeadding"
                                  sx={{
                                    fontWeight: "700",
                                    marginBottom: "15px",
                                    fontSize: "16px",
                                    color: theme.palette.text.primary
                                  }}
                                >
                                  Team Members List
                                </Typography>
                                <Divider />
                                {userData?.map((data) => (
                                  <>
                                    {/* {console.log("data ::: ::", data)} */}
                                    <Box
                                      sx={{
                                        border: "1px solid #EAECF0",
                                        borderRadius: "12px",
                                        padding: "5px",
                                        display: "flex",
                                        flexDirection: "row",
                                        marginTop: "20px",
                                        justifyContent: "space-between",
                                      }}
                                      className="glass-wrapper-25"
                                      gap={"5px"}
                                    >
                                      <ListItem>
                                        <Typography
                                          style={{ fontSize: "14px", color: theme.palette.text.primary }}
                                        >
                                          <b>{data.invitationToCompanyName} </b>{" "}
                                          - {data.invitationTo}
                                        </Typography>
                                      </ListItem>
                                      <ListItem>
                                        <Typography
                                          sx={{
                                            fontSize: "14px",
                                            backgroundColor: data.status === "accept" ? "green" : "red",
                                            borderRadius: 2,
                                            px: 1
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: "14px",
                                              color: "#ffffff",
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {data.status == "accept"
                                              ? "Accepted"
                                              : data.status}{" "}
                                          </span>
                                        </Typography>
                                      </ListItem>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          gap: 1
                                        }}
                                      >
                                        {data.status == "pending" ? (
                                          <Button
                                            disabled={resend}
                                            style={{
                                              width: "80px",
                                              fontSize: "14px",
                                            }}
                                            onClick={() =>
                                              handleResend(
                                                data.invitationTo,
                                                data.inviteByCompanyName
                                              )
                                            }
                                            className="glass-button-secondary-25"
                                          >
                                            Resend
                                          </Button>
                                        ) : (
                                          <Box style={{ width: "80px" }}></Box>
                                        )}
                                        <Button
                                          onClick={() =>
                                            confirmRemoveUser(data.invitationTo)
                                          }
                                          className="glass-button-secondary-25"
                                        >
                                          Remove
                                        </Button>
                                      </Box>
                                    </Box>
                                  </>
                                ))}
                                <Dialog
                                  open={isDeleteOpen}
                                  onClose={() => setIsDeleteOpen(false)}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogTitle
                                    id="alert-dialog-title"
                                    sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                                  >
                                    <Typography variant="h6" sx={{ display: "flex", alignItems: "center", color: theme.palette.text.primary }}>

                                      Remove User
                                    </Typography>
                                    <IconButton onClick={() => setIsDeleteOpen(false)} sx={{  color: theme.palette.text.primary }}>
                                      <CloseOutlined />
                                    </IconButton>
                                  </DialogTitle>

                                  <DialogContent>
                                    <DialogContentText
                                      id="alert-dialog-description"
                                      sx={{ fontSize: "18px", color: theme.palette.text.primary }}
                                    >
                                      Are you sure you want to remove this user?
                                    </DialogContentText>
                                  </DialogContent>

                                  <DialogActions>
                                    <Button
                                      sx={{ borderRadius: 1 }}
                                      className="glass-button-secondary-50"
                                      onClick={() => setIsDeleteOpen(false)}
                                      variant="outlined"
                                    >
                                      Cancel
                                    </Button>
                                    <Button
                                      variant="contained"
                                      sx={{ borderRadius: 1, backgroundColor: "red", "&:hover": { backgroundColor: "#e30000" } }}
                                      onClick={handleConfirmDelete}
                                    >
                                      Remove
                                    </Button>
                                  </DialogActions>
                                </Dialog>

                              </Box>
                            )
                              : (
                                ""
                              )}
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>

                </CardContent>
              </Card>

            )}

            {activeMenuItem === "section14" && (
              <BlackList userEmail={user?.email} />
            )}

            {activeMenuItem === "section15" && <CampaignSchedule />}

            {activeMenuItem === "section16" && (
              <Credits userEmail={user?.email} userDetails={infoUser} />
            )}
            {activeMenuItem === "section17" && (
              <AibdrDetails sectionRef={section17Ref} id="section17" />
            )}
            {/* <Card>
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      xs={12}
                      md={4}
                    >
                      <Typography variant="h6">
                        Public profile
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={12}
                      md={8}
                    >
                      <Stack
                        divider={<Divider />}
                        spacing={3}
                      >
                        <Stack
                          alignItems="flex-start"
                          direction="row"
                          justifyContent="space-between"
                          spacing={3}
                        >
                          <Stack spacing={1}>
                            <Typography variant="subtitle1">
                              Make Contact Info Public
                            </Typography>
                            <Typography
                              color="text.secondary"
                              variant="body2"
                            >
                              Means that anyone viewing your profile will be able to see your contacts
                              details.
                            </Typography>
                          </Stack>
                          <Switch />
                        </Stack>
                        <Stack
                          alignItems="flex-start"
                          direction="row"
                          justifyContent="space-between"
                          spacing={3}
                        >
                          <Stack spacing={1}>
                            <Typography variant="subtitle1">
                              Available to hire
                            </Typography>
                            <Typography
                              color="text.secondary"
                              variant="body2"
                            >
                              Toggling this will let your teammates know that you are available for
                              acquiring new projects.
                            </Typography>
                          </Stack>
                          <Switch defaultChecked />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
            </Card> */}

            {/* <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid xs={12} md={4}>
                    <Typography variant='h6'>Delete Account</Typography>
                  </Grid>
                  <Grid xs={12} md={8}>
                    <Stack alignItems='flex-start' spacing={3}>
                      <Typography variant='subtitle1'>
                        Delete your account and all of your source data. This is
                        irreversible.
                      </Typography>
                      <Button color='error' variant='outlined'>
                        Delete account
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </CardContent>
            </Card> */}
          </Stack>
        }
      </div>
    </>
  );
};

AccountGeneralSettings.propTypes = {
  avatar: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
