import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

const ConfirmDialog = ({ setOpen, open, handleDelete ,name }) => {
  const handleClose = () => {
    setOpen({ open: false, data: null })
  };

  const handleConfirm = () => {
    handleDelete(open.data); 
    handleClose();
  };

  return (
    <Dialog open={open.open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Confirm the action</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography sx={{color:theme=>theme.palette.text.primary}}>
           {name}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button onClick={() => handleConfirm()} color="error" variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
