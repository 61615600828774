import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MobileNav } from "../mobile-nav";
import { SideNav } from "./side-nav";
import { TopNav } from "./top-nav";
import { useMobileNav } from "./use-mobile-nav";
import { HorizontalTabs } from "./horizontal-tabs";
import { useSections } from "../config";
import { Box } from "@mui/material";
import bg1 from "src/assets/bg-1.jpg";
import bg2 from "src/assets/bg-2.jpg";
import bg3 from "src/assets/bg-3.jpg";
import bg4 from "src/assets/bg-4.png";
import { SettingsContext } from "src/contexts/settings-context";

const SIDE_NAV_WIDTH = 270; //280

const VerticalLayoutRoot = styled("div")(({ theme, open, isNewInterfaceEnabled }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  background: isNewInterfaceEnabled ? "transparent" : "rgba(248, 248, 249, 1)",
  padding: "8px",
  [theme.breakpoints.up("lg")]: {
    //paddingLeft: SIDE_NAV_WIDTH + 16,
    paddingLeft: open
      ? SIDE_NAV_WIDTH + 26
      : `calc(${theme.spacing(7)} + 27px)`,
  },
}));

const VerticalLayoutContainer = styled("div")({
  position: "relative",
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  width: "100%",
  // backgroundColor: "rgba(248, 248, 249, 1)",
  // border: "1px solid rgba(224, 224, 229, 1)",
  padding: "0px 8px",
  borderRadius: "16px",
  height: "calc(100vh - 20px)",
});

function debounce(func, wait) {
  let timeout;

  return function (...args) {
    const context = this;

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

export const VerticalLayout = (props) => {
  const { children, sections, navColor } = props;
  const theme = useTheme();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const mobileNav = useMobileNav();
  const subSections = useSections(true);
  const [open, setOpen] = useState(false);
  const [flagSidebar, setFlagSidebar] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const images = [bg1, bg2, bg3];
  const {isNewInterfaceEnabled} = useContext(SettingsContext);


  useEffect(() => {
    debounce(() => {}, 1000);
    setOpen(flagSidebar);
  }, [flagSidebar]);
  const handleDrawerToggle = debounce((e, flag = null) => {
    if (flag === null) {
      setFlagSidebar(!flagSidebar);
    } else if (!flagSidebar && (flag === true || flag === false)) {
      console.log({ flag });
      setOpen(flag);
    }
  }, 100);

  return (
    <>
      {isNewInterfaceEnabled && (
        <>
          <Box
            id="background-overlay"
            sx={{
              position: "fixed",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: (theme) =>
                theme.palette.mode === "dark"
                  ? "linear-gradient(180deg,hsla(0,0%,0%,0.4) 0%,hsla(0,0%,0%,0.1) 15%, hsla(0,0%,0%,0) 30%, hsla(0,0%,0%,0) 50%, hsla(0,0%,0%,0) 100%)"
                  : "linear-gradient(180deg,hsla(0,0%,100%,0.4) 0%,hsla(0,0%,100%,0) 100%)",
              backgroundSize: "cover",
              zIndex: "-1",
            }}
          ></Box>
          <Box className="glass-background-image">
            <img
              src={bg4}
              width="100%"
              height="100%"
            />
          </Box>
        </>
      )}
      {!lgUp && <TopNav onMobileNavOpen={mobileNav.handleOpen} />}
      {/* {lgUp && <SideNav color={navColor} sections={sections} />} */}
      {lgUp && (
        <SideNav
          color={navColor}
          sections={sections}
          open={open}
          handleDrawerToggle={handleDrawerToggle}
        />
      )}
      {!lgUp && (
        <MobileNav
          color={navColor}
          onClose={mobileNav.handleClose}
          open={mobileNav.open}
          sections={sections}
        />
      )}
      <VerticalLayoutRoot open={open} isNewInterfaceEnabled={isNewInterfaceEnabled}>
        <VerticalLayoutContainer>
          {/* <HorizontalTabs sections={subSections} /> */}
          {children}
        </VerticalLayoutContainer>
      </VerticalLayoutRoot>
    </>
  );
};

VerticalLayout.propTypes = {
  children: PropTypes.node,
  navColor: PropTypes.oneOf(["blend-in", "discreet", "evident"]),
  sections: PropTypes.array,
};
