import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Switch,
  Typography,
  Grid,
  TextField,
  Box
} from "@mui/material";
import { API_SERVICE_BACKEND_2 } from "src/config"
import { INTEGRATION_TYPE } from "src/helpers/constants"
import { useAuth } from 'src/contexts/auth/firebase-context';
import PipedriveLogo from "./logo"
import LinkIcon from "src/components/logos/linkIcon.png";
import Linkbroken from "src/components/logos/linkBroken.png";
import { Key } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const ApiTokenIntegration = ({
  updateIntegrationAutoSyncFlag = (email = '', type = '', isAuto = false) => { },
  openSnackbar
}) => {

  const { user, setUserInfo } = useAuth();

  const [hasPipedriveIntegration, setHasPipedriveIntegration] = useState(false)
  // const [pipedriveExpired, setSalesforceExpired] = useState(false);
  const [isPipedriveAuto, setIsPipedriveAuto] = useState(false);
  const [pipedriveAutoExecution, setSaleForceAutoExecution] = useState();
  const [tokenInput, setTokenInput] = useState('')

  const addApiToken = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND_2}/pipedrive-add-api_token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userEmail: user.email,
            type: INTEGRATION_TYPE.PIPEDRIVE,
            api_token: tokenInput
          }),
        }
      );

      if (response.ok) {
        let result = await response.json()

        openSnackbar(result.message, "success");
        setUserInfo(user.email)
        setTokenInput('')
      } else {
        const errorData = await response.json();
        console.error(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Internal Server Error:", error);
    }
  }

  const removeApiToken = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND_2}/pipedrive-remove-api_token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userEmail: user.email,
            type: INTEGRATION_TYPE.PIPEDRIVE
          }),
        }
      );

      if (response.ok) {
        let result = await response.json()

        openSnackbar(result.message, "error");
        setUserInfo(user.email)
      } else {
        const errorData = await response.json();
        console.error(`Error: ${errorData.error}`);
      }
    } catch (error) {
      console.error("Internal Server Error:", error);
    }
  }

  useEffect(() => {
    setHasPipedriveIntegration(!!user?.integrations?.pipedrive?.api_token)
    setIsPipedriveAuto(user?.integrations?.pipedrive?.isAuto)
  }, [user])

  console.log('pipedrive user', user)

  return (
    <Card
      sx={{
        marginTop: "10px",
        marginLeft: "4px",
        width: { xs: "100%", md: "49%" },
        border: "1px solid #EAECF0",
        
      }}
      className="glass-wrapper"
    >
      <CardContent style={{ padding: "24px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "8px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <PipedriveLogo style={{ height: "25px", fill: "#017737" }} />
          </div>
        </div>
        <Grid item xs={4} >
                    <Typography fontSize={"16px"} paddingTop={"20px"}color={theme=>theme.palette.text.primary}>Pipedrive</Typography>
                    
                                      <Typography fontSize={"16px"} paddingTop={"5px"} color={theme=>theme.palette.text.secondary}>
                                        Integrate your PipeDrive Automations to
                                        simplify and automate your daily tasks effortlessly
                                      </Typography>
                                      
                  </Grid>
        {
          hasPipedriveIntegration ? (
            <>
            
              {/* <Divider style={{ marginTop: "16px", marginBottom: "16px" }} /> */}
              <div style={{ display: "flex", margin: "16px 0px" }}>
                <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"} sx={{ flexWrap: "nowrap" }}>
                  <Grid item xs={4} >
                    <Typography fontSize={"14px"} fontWeight={400}>{'Auto Transfer Leads'}</Typography>
                  </Grid>

                  {
                    isPipedriveAuto &&
                      pipedriveAutoExecution !== "Invalid date" ? (
                      <Grid item md={4} sx={{ textAlign: "center" }}>
                        <Typography
                          style={{
                            color: "green",
                            fontWeight: "bold",
                          }}
                          fontSize={12}
                        >
                          Last Auto Sync
                        </Typography>
                        <Typography fontSize={12} style={{ color: "green" }}>
                          {pipedriveAutoExecution}
                        </Typography>
                      </Grid>
                    ) : null
                  }

                  <Grid item xs={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Switch
                      checked={isPipedriveAuto}
                      onChange={async ({
                        target: { checked },
                      }) => {
                        setIsPipedriveAuto(checked)
                        try {
                          await updateIntegrationAutoSyncFlag(
                            user?.email,
                            INTEGRATION_TYPE.PIPEDRIVE,
                            checked,
                          );
                        } catch (ex) {
                          console.log({ ex });
                        }
                      }}
                      value={INTEGRATION_TYPE.PIPEDRIVE}
                    />
                  </Grid>
                </Grid>
              </div>
              <div>
                <Box display="flex" alignItems="center" gap={1} sx={{ width: "100%" }}>
                  <Box sx={{ flexGrow: 1, marginRight: 1, minWidth: "50%", padding: "8px 16px", borderRadius: 1, overflowWrap: "break-word", fontSize: "14px" }}className="glass-pane">
                    {user?.integrations?.pipedrive?.api_token}
                  </Box>

                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="error"
                    sx={{
                      borderRadius: "8px",
                      minWidth: "90px",
                      padding: "6px 12px",
                      color: "white",
                    }}
                    startIcon={<Key />}
                    onClick={removeApiToken}
                  >
                    Remove
                  </Button>
                </Box>
              </div>
            </>
          ) : (
            <div>
              <Typography
                sx={{
                  marginBottom: "10px",
                  fontSize: "14px",
                  fontWeight: "500",
                  marginTop: 1
                }}
              >
                Api Token: <Box component={"span"} fontSize={"12px"} >Click <Link to={'https://pipedrive.readme.io/docs/how-to-find-the-api-token'} target="_blank">here</Link> to get Pipedrive <code style={{ background: "#f7f7f7", padding: "0 3px", borderRadius: "10px", fontSize: '14px' }} className='glass-chip'>api_token</code> </Box>
              </Typography>
              <Box display="flex">
                <TextField
                  value={tokenInput}
                  variant="outlined"
                  className="glass-input"
                  size="small"
                  fullWidth
                  sx={{ flexGrow: 1, marginRight: 1 }}
                  spellCheck={false}
                  onChange={(e) =>
                    setTokenInput(e.target.value)
                  }
                />

                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  color="primary"
                  sx={{
                    ml: "auto",
                    borderRadius: "8px",
                  }}
                  startIcon={<Key />}
                  onClick={addApiToken}
                >
                  Add
                </Button>
              </Box>
            </div>
          )
        }
      </CardContent>
    </Card>
  )
}

export default ApiTokenIntegration