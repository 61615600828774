import React, { useEffect, useState } from 'react'
import { Box, ListItem, Typography, MenuItem, Select, FormControl, InputLabel, Button } from '@mui/material'
import {
  API_SERVICE_BACKEND_2
} from "src/config";

const DifferentOwnerCampaign = ({ campaign, hubspotUsers, props, openSnackbar }) => {

  const [_campaign, setCampaign] = useState()
  const [selectedOwner, setSelectedOwner] = useState('')
  
  const addOwner = async (_owner_id = "") => {
    const owner_id = _owner_id ?? selectedOwner
    const data = {
      ...props,
      campaign_id: campaign._id,
      owner_id: owner_id
    }

    try {
      await fetch(`${API_SERVICE_BACKEND_2}/hubspot-owners-save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(response => {
        setCampaign({..._campaign, hubspotContactsOwner: owner_id})
        openSnackbar("Owner assigned for agent", "success");
      });
    } catch (error) {
      console.error("Error updating isAuto:", error);
    }
  }

  const unAssignOwner = async () => {
    const data = {
      ...props,
      campaign_id: campaign._id,
      owner_id: null
    }

    try {
      await fetch(`${API_SERVICE_BACKEND_2}/hubspot-owners-save`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then(response => {
        setCampaign({..._campaign, hubspotContactsOwner: null})
        setSelectedOwner('')
        openSnackbar("Owner un-assigned from agent", "error");
      });
    } catch (error) {
      console.error("Error updating isAuto:", error);
    }
  }

  useEffect(() => {
    setSelectedOwner(campaign.hubspotContactsOwner ?? '')
    if(campaign)
    {
      setCampaign(campaign)
    }
  }, [campaign])

  return (
    <Box
      sx={{
          border: "1px solid #EAECF0",
          borderRadius: "12px",
          padding: "5px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          '&:not(:first-child)' : {
            marginTop: "20px",
          }
      }}
      className="glass-wrapper-50"
      gap={"5px"}
      >
      <ListItem>
        <Typography sx={{color:theme=>theme.palette.text.primary}}>
        <b>{_campaign?.companyName}</b>{" "}
        - {_campaign?.title}
        </Typography>
      </ListItem>
      <ListItem>
        <Typography component={"p"}>
        <span
            style={{ 
              color: `${_campaign?.hubspotContactsOwner ? "#e8ffe8" : "#ffeaea"}`,
              textTransform: "capitalize",
              fontWeight: "500",
              fontSize: "12px",
              background: _campaign?.hubspotContactsOwner ? "#479847" : "#c24646",
              padding: "0 5px",
              borderRadius: "100px"
            }}
        >
            {_campaign?.hubspotContactsOwner ? "Assigned" : "Not assigned"}{" "}
        </span>
        </Typography>
        {
          _campaign?.hubspotContactsOwner &&
        <Button
          type="button"
          variant="text"
          size="small"
          color="primary"
          sx={{
            p: 0,
            marginLeft:"4px",
            borderRadius: "8px",
          }}
          className='glass-button-tertiary'
          onClick={unAssignOwner}
        >
          Un assign
        </Button>
        }
      </ListItem>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControl sx={{ minWidth: "200px", maxWidth: "300px", width: "100%" }} size="small">
          <InputLabel id="select-owner-label">Select Owner</InputLabel>
          <Select
            labelId="select-owner-label"
            id="select-owner"
            value={selectedOwner}
            label="Select Owner"
            onChange={(e) => {
              setSelectedOwner(e.target.value)
              addOwner(e.target.value)
            }}
            className="glass-input"
          >
            {
              hubspotUsers?.map((hubspotUser, h_index) => {
                return <MenuItem key={h_index} value={hubspotUser.id}>{hubspotUser.firstName}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

export default DifferentOwnerCampaign