import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  Skeleton,
  Dialog,
  DialogTitle,
  Divider,
  AvatarGroup,
  useMediaQuery,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { toast as hotToast } from "react-hot-toast";
import moment from "moment/moment";
import { useRoutes } from "react-router-dom";

import { BotAvatar } from "src/assets/chatPage/BotAvatar";
import BouncingDotsLoader from "src/components/BouncingDotsLoader";
import { useMobileNav } from "src/layouts/dashboard/vertical-layout/use-mobile-nav";
import { jobsApi } from "src/api/jobs";

import {
  API_SERVICE_BACKEND,
  API_SERVICE_BACKEND_2,
  cronofy,
  API_SERVICE_BACKEND_3,
} from "../config";
import { routes } from "../routes/index";
import { Seo } from "../components/seo";
import { getSessionStorage } from "src/utils/storage";
import SideMenu from "./chatPageMenu/sideMenu";
import AiChatBlock from "./chatPageMenu/aiChatBlock";
import companyLogo1 from "../assets/companyLogo1.png";
import companyLogo2 from "../assets/companyLogo2.png";
import send from "../assets/send.png";
import { LimitedSlotText, meetKeywords } from "./chatPageMenu/chatFile";

import "./style.css";
import { useSettings } from "src/hooks/use-settings";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import { SplashScreen } from "src/components/splash-screen";

const { getGPT_Prompt } = require("./chatTemplate");

const { meetPrefs } = cronofy;

const decoder = new TextDecoder("utf-8");

const checkKeywords = (input) => {
  for (let i = 0; i < meetKeywords.length; i++) {
    const pattern = new RegExp(meetKeywords[i], "i");
    if (pattern.test(input)) {
      return true;
    }
  }
  return false;
};

const getUserInfo = async (email) => {
  try {
    const res = await axios.get(
      `${API_SERVICE_BACKEND}/getuserdetail/${email}`
    );
    if (!res.data.status) return;
    return res.data.data;
  } catch (error) {
    return;
  }
};

const ChatPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const midUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const videoRef = useRef();
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const location = useLocation();
  const invitationId = location.pathname.split("/").pop();
  const query = new URLSearchParams(location.search);
  const queryParams = {};
  query.forEach((value, key) => (queryParams[key] = value));

  const {
    user: leadId,
    leadEmail,
    eid: emailId,
    fname,
    lname,
    // companyName,
    redirectURL = false,
  } = queryParams;
  const userId1 = queryParams.user;
  const campaignId = queryParams?.campaignId;
  const step = parseInt(queryParams?.step) || 1;
  const variant = parseInt(queryParams?.variant) || 1;
  const [campaignData, setCampaignData] = useState(null);
  const [isChatClick, setIsChatClick] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [ab, setAb] = useState([]);
  const [timezone, setTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  );
  const { companyName, primaryBrandColor ,secondaryBrandColor, whiteLableStatus , subUserStatus,  setCompanyName, faviconUrl } = useContext(
    WhiteLabelConfigurationContext
  );
  const domain = window.location.hostname;
  const [whiteLableId , setWhiteLableId ] = useState(null)

  const { urlId, campaignId: campaignId_urlParam } = useParams();

  const firefun = (data) => {
    setAb(data);
  };

  const generateAIVar = async (campaignInfo, leadInfo) => {
    const aiGen = {
      challenges: null,
      companyMission: null,
      sellingPoints: null,
      prospectIndustry: null,
    };

    const campaignDetail =
      campaignInfo?.sequence?.value?.steps[step - 1]?.variants?.value[
        variant - 1
      ]?.template?.config;

    const senderName = mailbox?.fullName || "Adam";
    const companyName = campaignDetail?.companyDesc || "";
    const companyDescription = campaignDetail?.companyOverview || "";

    const { emailBody, industry: prospectIndustry, organization } = leadInfo;
    const prospectAddress = {
      city: leadInfo?.address?.city ? leadInfo?.address?.city : "",
      state: leadInfo?.address?.state ? leadInfo?.address?.state : "",
      country: leadInfo?.address?.country ? leadInfo?.address?.country : "",
    };

    const aiParams = {
      challenges: { prospectIndustry, emailBody },
      companyMission: { organization, emailBody },
      sellingPoints: { companyName, companyDescription },
      prospectIndustry: { emailBody },
    };

    const aiPromises = Object.keys(aiGen).map(async (key) => {
      const content = getGPT_Prompt[key](aiParams[key]);
      const tempPrompt = [{ role: "user", content }];
      const result = await getGPTResponse(tempPrompt);
      const ai_msg = result?.choices?.[0]?.message;
      return ai_msg?.content;
    });

    const contentArray = await Promise.all(aiPromises);
    Object.keys(aiGen).forEach((key, index) => {
      aiGen[key] = contentArray[index];
    });

    const templateContent = `Your role is to welcome and guide prospects who land on a page from a cold email. Keep messages brief and engaging. Focus on making the prospect feel valued and interested in ${organization}'s offerings.\n
    SENDER INFORMATION
    SENDER_FIRST_NAME: ${senderName}.\n
    SENDER_COMPANY: ${companyName}.\n
    SENDER_COMPANY_DESCRIPTION: ${companyDescription}.\n

    PROSPECT INFORMATION
    PROSPECT_FIRST_NAME: ${leadInfo?.fname}.\n
    PROSPECT_TITLE: ${leadInfo?.description}.\n
    PROSPECT_COMPANY: ${leadInfo?.organization}.\n
    PROSPECT_LINKEDIN_PROFILE_DESCRIPTION: ${leadInfo?.linkedin_url}.\n
    PROSPECT_LOCATION: ${JSON.stringify(prospectAddress)}.\n
    PROSPECT_SKILLS: ${JSON.stringify(leadInfo?.skills)}.\n
    PROSPECT_EDUCATION:${JSON.stringify(leadInfo?.education)}.\n
    PROSPECT_JOB_HISTORY:  ${JSON.stringify(leadInfo?.job_history)}.\n
    PROSPECT_COMPANY_DESCRIPTION: ${
      leadInfo?.job_history ? leadInfo?.job_history?.[0]?.description : ""
    }.\n
    PROSPECT_COMPANY_INDUSTRY: ${leadInfo?.organization}.\n
    
    Chatbot Instructions:\nChatbot Name: ${senderName}\nGreet the Prospect: Start with 'Hello!' or 'Welcome to ${companyName}!' Personalize using the prospect's first name.\nOffer Help: Ask 'How can I assist you today?' or 'Do you have any questions about ${JSON.stringify(
      companyDescription
    )}.\n?' Keep your responses to 50 words or less.
    `;

    return templateContent;
  };

  // useEffect(() => {
  //   const faviconIco = document.createElement("link");
  //   const favIcon1 = document.getElementById("icon1");
  //   const favIcon2 = document.getElementById("icon2");
  //   const favIcon3 = document.getElementById("icon3");

  //   // document.head.removeChild(favIcon1);
  //   // document.head.removeChild(favIcon2);
  //   // document.head.removeChild(favIcon3);

  //   faviconIco.rel = "icon";
  //   faviconIco.href = "/internet.png";
  //   document.head.appendChild(faviconIco);

  //   return () => {
  //     document.head.removeChild(faviconIco);
  //     document.head.appendChild(favIcon1);
  //     document.head.appendChild(favIcon2);
  //     document.head.appendChild(favIcon3);
  //   };
  // }, []);

  useEffect(() => {
    const box = document.querySelector("#chat-container");

    const isInViewport = (element) => {
      let rect = {};
      if (element) {
        rect = element.getBoundingClientRect();
      }

      return (
        rect.bottom > 110 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left >= 0 &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };
    const handleScroll = () => {
      if (isInViewport(videoRef?.current)) {
        setIsVideoVisible(true);
      } else {
        setIsVideoVisible(false);
      }
    };
    if (redirectURL === "false" || campaignData?.link?.url === "") {
      box.addEventListener("scroll", handleScroll);

      return () => {
        box.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const box = document.querySelector("#chat-container");

    const isInViewport = (element) => {
      let rect = {};
      if (element) {
        rect = element.getBoundingClientRect();
      }

      return (
        rect.bottom > 110 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left >= 0 &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };
    const handleScroll = () => {
      if (isInViewport(videoRef?.current)) {
        setIsVideoVisible(true);
      } else {
        setIsVideoVisible(false);
      }
    };
    if (redirectURL === "false" || campaignData?.link?.url === "") {
      box.addEventListener("scroll", handleScroll);

      return () => {
        box.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const generateContext = (campaignInfo, leadInfo) => {
    const campaignDetail =
      campaignInfo?.sequence?.value?.steps[step - 1]?.variants?.value[
        variant - 1
      ]?.template?.config;

    const senderName = campaignDetail?.responsibility || "Adam";
    const companyName = campaignDetail?.companyDesc || "";
    const companyDescription = campaignDetail?.companyOverview || "";

    const prospectAddress = {
      city: leadInfo?.address?.city ? leadInfo?.address?.city : "",
      state: leadInfo?.address?.state ? leadInfo?.address?.state : "",
      country: leadInfo?.address?.country ? leadInfo?.address?.country : "",
    };

    let context = `You are live ai chatbot for ${companyName}.\n
      Your name is  ${senderName}.\n
      Prospects will engage with you after visiting our landing page.\n
      You will be communicating on behalf of ${senderName}.\n
      Your 1st message should ONLY be to greet ${leadInfo?.fname}.\n
      AFTER the prospect asks you a question, align your reply with the listed below, UNLESS they ask to not be contacted or are not interested.\n
      Keep your replies concise, to 100 words or less. Use line breaks where necessary so your replies are easy for the user to read.\n

      SENDER INFORMATION
      SENDER_FIRST_NAME: ${senderName}.\n
      SENDER_COMPANY: ${companyName}.\n
      SENDER_COMPANY_DESCRIPTION: ${companyDescription}.\n

      PROSPECT INFORMATION
      PROSPECT_FIRST_NAME: ${leadInfo?.fname}.\n
      PROSPECT_TITLE: ${leadInfo?.description}.\n
      PROSPECT_COMPANY: ${leadInfo?.organization}.\n
      PROSPECT_LINKEDIN_PROFILE_DESCRIPTION: ${leadInfo?.linkedin_url}.\n
      PROSPECT_LOCATION: ${JSON.stringify(prospectAddress)}.\n
      PROSPECT_SKILLS: ${JSON.stringify(leadInfo?.skills)}.\n
      PROSPECT_EDUCATION:${JSON.stringify(leadInfo?.education)}.\n
      PROSPECT_JOB_HISTORY:  ${JSON.stringify(leadInfo?.job_history)}.\n
      PROSPECT_COMPANY_DESCRIPTION: ${
        leadInfo?.job_history ? leadInfo?.job_history?.[0]?.description : ""
      }.\n
      PROSPECT_COMPANY_INDUSTRY: ${leadInfo?.organization}.\n`;

    return context;
  };
  const [leadInfo, setLeadInfo] = useState(
    Boolean(leadId)
      ? null
      : {
          email: leadEmail,
          fname,
          lname,
        }
  );
  const [mailbox, setMailbox] = useState();
  const [history, setHistory] = useState([]);
  const [trackingCompleted, setTrackingCompleted] = useState(false);

  const addPageView = async () => {
    await axios.post(`${API_SERVICE_BACKEND}/addpageview`, {
      leadId: leadInfo._id,
      campaignId,
      leadEmail: leadInfo.email,
    });
  };
  const leadData = async (leadId) => {
    if (!Boolean(leadId)) return;

    try {
      const res = await axios.post(`${API_SERVICE_BACKEND}/leadOpt`, {
        payload: {
          filter: {
            _id: leadId,
          },
        },
      });

      const leadInfo = res.data;

      if (leadInfo.error) {
        toast.error(leadInfo.error);
        return;
      }

      if (!Boolean(leadInfo) || leadInfo.length === 0) {
        const error = `No user found with id: ${leadId}`;
        toast.error(error);
        return;
      }

      leadInfo[0].fname = leadInfo[0]?.firstName || leadInfo[0]?.first_name;
      leadInfo[0].lname = leadInfo[0]?.lastName || leadInfo[0]?.last_name;
      setLeadInfo(leadInfo[0]);

      if (leadInfo[0]?.mailBoxId) {
        const mailboxRes = await axios.get(
          `${API_SERVICE_BACKEND}/getsinglemailbox/${leadInfo[0].mailBoxId}`,
          {
            payload: {
              filter: {
                _id: leadId,
              },
            },
          }
        );
        setMailbox(mailboxRes?.data);
      }
    } catch (error) {
      toast.error(`Error fetching lead data: ${error.message}`);
    }
  };

  const [cronofyToken, setCronofyToken] = useState(null);
  const [isGlobal, setIsGlobal] = useState(false);
  const [calenderId, setCalenderId] = useState(null);
  const [sub, setSub] = useState();
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    const cronofycalendar = async () => {
      try {
        const responseDataLog = await fetch(
          `${API_SERVICE_BACKEND_3}/calendar`,
          {
            method: "GET",
            headers: {
              cronofyToken: cronofyToken,
            },
          }
        ).then((res) => res.json());
        setCalenderId(responseDataLog.calendars[0].calendar_id);
      } catch (err) {}
    };
    if (!calenderId && cronofyToken) {
      cronofycalendar();
    }
  }, [cronofyToken]);

  const hasFetchedData = useRef(false);
  useEffect(() => {
    if (!leadInfo || hasFetchedData.current) return;

    const { email: leadEmail, fname, lname, userId, organization } = leadInfo;

    if (leadEmail && leadEmail !== "anonymous" && !history?.length) {
      hasFetchedData.current = true;
      (async () => {
        const res = await axios.get(`${API_SERVICE_BACKEND}/leadInfo`, {
          params: {
            campaignId,
            leadEmail,
          },
        });
      })();

      (async () => {
        const res = await axios.get(`${API_SERVICE_BACKEND}/getLeadConvo`, {
          params: {
            leadEmail,
            campaignId,
            emailId: emailId || null,
          },
        });
        if (res.status === 200) {
          setHistory(res.data.convo.history);
          setTrackingCompleted(true);
        } else if (res.status === 204) {
          const payload = {
            leadEmail,
            campaignId,
            emailId: emailId || null,
            firstName: fname,
            lastName: lname,
            leadId,
          };

          const payload2 = {
            leadEmail,
            campaignId,
            emailId: emailId || null,
            firstName: fname,
            lastName: lname,
            leadId,
            isView: true,
          };
          const checkWindowClosed = () => {
            axios
              .post(`${API_SERVICE_BACKEND}/addConvo`, payload)
              .then((res) => {})
              .catch((error) => {});
          };
          let timerId;
          window.addEventListener("beforeunload", () => {
            if (!timerId) {
              checkWindowClosed();
            }
            clearTimeout(timerId);
          });
          timerId = setTimeout(async () => {
            const res = await axios.post(
              `${API_SERVICE_BACKEND}/addConvo`,
              payload2
            );
            timerId = null;
          }, 3000);

          if (leadInfo?.email && campaignId) {
            setTimeout(() => {
              addPageView();
            }, 5000);
          }

          if (res.status === 201) {
            setHistory(res.data.convo.history);
          } else if (res.status === 200) {
          }
          setTrackingCompleted(true);
        }
      })();
    } else {
      setTrackingCompleted(true);
    }
  }, [leadInfo]);
  const getAccessToken = async (campaign) => {
    try {
      const userRes = await axios.post(
        `${API_SERVICE_BACKEND_3}/getUserAccessToken`,
        {
          email: campaign?.clientEmail,
        }
      );
    } catch (err) {
      if ((err.response.status = 404)) {
        hotToast.error(err.response?.data?.msg);
      }
    }

    const userInfo = await getUserInfo(campaign?.clientEmail);
    setWhiteLableId( userInfo.isWhiteLabel
      ? userInfo?._id
      : userInfo?.whiteLabelUserId)
    const accessToken = userInfo?.extra?.accessToken?.access_token;
    const sub = userInfo?.extra?.accessToken?.sub;
    setSub(sub);
    setCronofyToken(accessToken);
    setIsGlobal(true);
  };

  const findcampaign = async (campaignId) => {
    if (!campaignId) {
      navigate("/404");
      return;
    }
    axios
      .get(`${API_SERVICE_BACKEND}/filterCampaigns`, {
        params: { _id: campaignId },
      })
      .then(async (res) => {
        if (res.status === 200 && res.data.length) {
          const campaignData = res.data[0];

          if (campaignData?.extra?.accessToken) {
            const campaignRes = await axios.post(
              `${API_SERVICE_BACKEND_3}/getCampaignAccessToken`,
              {
                campaign_id: campaignId,
              }
            );
            let campaign = {};
            if (campaignRes?.data.extra) {
              campaign = { ...campaignData, extra: campaignRes?.data.extra };
            } else {
              campaign = { ...campaignData };
            }

            setCampaignData(campaign);
            const accessToken = campaign?.extra?.accessToken?.access_token;

            if (accessToken) {
              const calender = await fetch(
                `${API_SERVICE_BACKEND_3}/calendar`,
                {
                  method: "GET",
                  headers: {
                    cronofyToken: accessToken,
                  },
                }
              ).then((res) => res.json());
              let calenderId = calender?.calendars[0]?.calendar_id;

              if (!calenderId) {
                getAccessToken(campaign);
                setCampaignData(campaign);
              } else {
                const sub = campaign?.extra?.accessToken?.sub;
                setSub(sub);
                setCronofyToken(accessToken);
              }
            } else {
              getAccessToken(campaign);
              setCampaignData(campaign);
            }
          } else {
            getAccessToken(campaignData);
            setCampaignData(campaignData);
          }
          const context = generateContext(campaignData, leadInfo);
          const initPrompt = [{ role: "user", content: context }];
          setPrompt(initPrompt);

          if (redirectURL === "true") {
            if (res.data[0].link?.enabled) {
              handleOpenDialog("redirect");
            }
          }
        } else {
          toast.warn(
            `The landing page preview is not available for AI Agents in "DRAFT" mode. Please launch your AI Agents and then check again.`
          );
        }
      })
      .catch((err) => {
        toast.error(err.response?.statusText);
      });
  };

  const [dialog, setDialog] = useState(false);
  const handleOpenDialog = (dialog) => {
    setDialog(dialog);
  };
  const handleCloseDialog = () => {
    setDialog(null);
  };

  const handleOpenChat = () => {
    setIsChatClick(true);
    setIsVideo(false);
    setIsvideoHit(false);
    setHideStreamCont(true);
  };

  useEffect(() => {
    if (!campaignData || !trackingCompleted) {
      return;
    }
    if (redirectURL == true || campaignData.link?.url !== "") {
      setTimeout(() => {
        window.location.replace(campaignData.link?.url);
      }, 2500);
    }
  }, [campaignData, trackingCompleted]);

  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState("");
  const [chats, setChats] = useState([]);
  const [prompt, setPrompt] = useState([]);
  const [prompting, setPrompting] = useState(true);
  const [promptForMeet, setPromptForMeet] = useState({
    hidden: false,
    show: false,
  });
  const handlePromptForMeet = (field, value) => {
    setPromptForMeet((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const getGPTResponse = async (messages, options = {}) => {
    const { rawRes, stream, ...otherOptions } = options;
    const res = await fetch(
      `https://b2brocket-salesgpt.openai.azure.com/openai/deployments/gpt-35-salesgpt/chat/completions?api-version=2024-02-15-preview`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "api-key": `${process.env.REACT_APP_OPENAI_SECRET}`,
        },
        body: JSON.stringify({
          model: "gpt-35-salesgpt",
          messages,
          stream: stream ? true : false,

          temperature: 0.7,
          top_p: 0.95,
          frequency_penalty: 0,
          presence_penalty: 0,
          stop: [" user:", " assistant:"],
        }),
      }
    );

    if (rawRes) return res;
    const data = await res.json();
    return data;
  };

  const streamRes = async (res) => {
    try {
      const reader = res.body.getReader();
      let tempResFromStream = "";
      while (true) {
        const chunk = await reader.read();
        const { done, value } = chunk;
        if (done) break;
        const decodedChunk = decoder.decode(value);
        const lines = decodedChunk.split("\n");
        const parsedLines = lines
          ?.map((line) => line?.replace(/^data: /, "").trim())
          .filter((line) => line !== "" && line !== "[DONE]")
          .map((line) => JSON.parse(line));
        for (const line of parsedLines || []) {
          const content = line?.choices[0]?.delta.content;
          const streamCont = document.getElementById("stream-container");

          if (content && streamCont) {
            tempResFromStream += content;
            const chatContainer = document.querySelector("#chat-container");
            console.log("chatContainer", chatContainer)

            if (chatContainer !== null && (redirectURL === "false" || campaignData?.link?.url === "")) {
              chatContainer.scrollTop = chatContainer?.scrollHeight;
            }
          }
        }
      }
      return tempResFromStream;
    } catch (err) {
      return false;
    }
  };

  const [hideStreamCont, setHideStreamCont] = useState(true);
  const getConvoUnreadCount = async () => {
    try {
      const campaigns = await jobsApi.getUserCampaigns(
        campaignData?.clientEmail
      );
      const campaignIds = campaigns?.data?.map(({ _id }) => _id);

      let url = `${API_SERVICE_BACKEND}/getCampConvo`;
      // url += `/all?campaignIdListStr=${campaignIds.join(",")}`;
      const payload = {
        campaignIdListStr: campaignIds.join(","),
        campaignId: "all",
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      const count = data?.conversations.length;
      return count;
    } catch (err) {
      console.log(err);
    }
  };
  const submitInput = async (progInp, options = {}) => {
    if (!input && !progInp) return;
    const localInput = progInp ? progInp : input;
    handlePromptForMeet("show", false);
    setIsLoading(true);
    setPrompting(true);
    if (!options.hidden) {
      setChats((prev) => [...prev, { isUser: true, text: localInput }]);
    }

    if (videoData?.video_url && videoData?.chatLinkType === "VIDEO") {
      setIsChatClick(true);
    }

    if (checkKeywords(localInput)) {
      setInput("");
      setIsLoading(false);
      setPrompting(false);
      setChats((prev) => [
        ...prev,
        {
          isUser: false,
          text: "Schedule a meet as per the availability.",
          isMeetLink: true,
        },
      ]);
      return;
    }
    const lastPrompt = { role: "user", content: localInput };
    const tempPrompt = [...prompt, lastPrompt];
    setPrompt(tempPrompt);
    setInput("");
    try {
      const res = await getGPTResponse(tempPrompt, {
        rawRes: true,
        stream: true,
      });
      setHideStreamCont(false);
      const text = await streamRes(res);

      if (text) {
        const streamCont = document.getElementById("stream-container");
        setHideStreamCont(true);
        streamCont.innerText = "";
        setChats((prev) => [...prev, { isUser: false, text }]);
        const aiResObj = { role: "assistant", content: text };
        setPrompt((prev) => [...prev, aiResObj]);
        axios
          .patch(`${API_SERVICE_BACKEND}/updateConvo`, {
            filter: { leadEmail: leadInfo.email, campaignId },
            chats: [lastPrompt, aiResObj],
            leadId,
            emailId,
          })
          .then((res) => {})
          .catch((err) => {});
      }
    } catch (error) {
      toast.error("Something went wrong, try later !");
    }
    const count = await getConvoUnreadCount();
    const conversation_detail = {
      email: campaignData?.clientEmail,
      ai_agent_name: campaignData?.title,
      prospect_fullname: leadInfo?.firstName + " " + leadInfo?.lastName,
      prospect_email: leadInfo?.email,
      prospect_title: leadInfo?.description,
      prospect_company: leadInfo?.organization,
      prospect_linkedin_url: leadInfo?.linkedin_url,
      conversation_count: count,
    };
    fetch(`${API_SERVICE_BACKEND}/intercom-event`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(conversation_detail),
    })
      .then((response) => response.json())
      .then((data) => console.log("Success:", data))
      .catch((error) => console.error("Error:", error));
    setIsLoading(false);
    setPrompting(false);
    handlePromptForMeet("show", true);
  };
  useEffect(() => {
  window.Intercom("update", {
    "hide_default_launcher": true
    });
  }, []);

  const [videoData, setVideoData] = useState();
  const [isvideoHit, setIsvideoHit] = useState(false);

  const getVideoInfo = async () => {
    async function fetchData(campaignId) {
      try {
        const response = await axios.get(
          `${API_SERVICE_BACKEND}/filterCampaigns`,
          {
            params: { _id: campaignId },
          }
        );

        if (response.status === 200 && response.data.length) {
          return response;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    const res = await fetchData(campaignId);
    if (res !== undefined) {
      // const videoData = {
      //   data: {
      //     data: [
      //       {
      //         video_urls: [
      //           {
      //             video_url: res?.data[0]?.videoUrl,
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // };
      const videoData = {
        video_url: res?.data[0]?.videoUrl,
        chatLinkType:
          res?.data[0]?.sequence?.value?.steps[step -1]?.variants?.value[
            variant - 1
          ]?.template?.config?.chatLinkType,
      };
      setVideoData(videoData);

      if (videoData?.video_url && videoData?.chatLinkType === "VIDEO") {
        setIsvideoHit(true);
        setIsVideo(true);
        setChats((prev) => [
          ...prev,
          {
            isUser: false,
            text: `Hi ${leadInfo?.fname} , I created this special video for`,
            isVideoPresent: true,
          },
        ]);
      }
    }
  };

  const fetchApi = async (initPrompt) => {
    getGPTResponse(
      [
        ...initPrompt,
        {
          role: "user",
          content:
            leadEmail === "anonymous"
              ? `Present yourself as a representative of the previously mentioned company in order to capture the customer's interest. Inquire if they would like to explore further about the products and services provided by your company, emphasizing how these offerings can benefit their own business ventures. Remember to keep it really short and consice. Customer Information:\nFIRST_NAME: ${leadInfo.fname}\nLAST_NAME: ${leadInfo.lname}`
              : await generateAIVar(campaignData || {}, leadInfo || {}),
        },
      ],
      {
        rawRes: true,
        stream: true,
      }
    )
      .then(async (res) => {
        setHideStreamCont(false);
        let text = await streamRes(res);
        const streamCont = document.getElementById("stream-container");

        if (text && streamCont) {
          // const text = data.choices[0].message.content;
          // text += `<br/><br/>${limitedSlotText(timezone)}`;
          // In your code, you can render the component like this:
          // text += ` Feel free to book a meeting with our team or visit our website using the buttons below.`;
          text += `${ReactDOMServer.renderToString(
            <LimitedSlotText
              timezone={Intl.DateTimeFormat().resolvedOptions().timeZone}
            />
          )}`;

          setHideStreamCont(true);
          streamCont.innerText = "";

          if (chats.length) {
            chats.map((chats) => {
              function cleanAndSplit(str) {
                // Remove punctuation except "?" and ","
                if (str) {
                  const cleanedStr = str
                    .replace(/[^\w\s]|_/g, "")
                    .replace(/\s+/g, " ");
                  // Split the string into an array of words
                  return cleanedStr.split(" ");
                }
              }
              // Clean and split the strings

              const words1 = chats && cleanAndSplit(chats?.text);
              const words2 = cleanAndSplit(text);

              // Function to compare two arrays of words and check if they contain any common words
              function checkForCommonWords(arr1, arr2) {
                for (let word of arr1) {
                  // Skip words containing "?" or ","
                  if (!word.includes("?") && !word.includes(",")) {
                    if (arr2.includes(word)) {
                      return true; // Found a common word
                    } else {
                      return false;
                    }
                  }
                }
                return false; // No common words found
              }

              // Check for common words
              const commonWordsExist =
                chats && checkForCommonWords(words1, words2);

              if (!commonWordsExist) {
                setChats((prev) => [
                  ...prev,
                  {
                    isUser: false,
                    text,
                    eg: true,
                    showSchedule: true,
                    // quickResponses: ["Yes", "Not yet"],
                  },
                ]);
                setPrompt((prev) => [
                  ...prev,
                  { role: "assistant", content: text },
                ]);
              }
              if (commonWordsExist && chats.isVideoPresent) {
                setChats((prev) => [
                  ...prev,
                  {
                    isUser: false,
                    text,
                    eg: true,
                    showSchedule: true,
                    // quickResponses: ["Yes", "Not yet"],
                  },
                ]);
                setPrompt((prev) => [
                  ...prev,
                  { role: "assistant", content: text },
                ]);
                setIsVideo(false);
              }
            });
          } else {
            setChats((prev) => [
              ...prev,
              {
                isUser: false,
                text,
                eg: true,
                showSchedule: true,
                // quickResponses: ["Yes", "Not yet"],
              },
            ]);
            setPrompt((prev) => [
              ...prev,
              { role: "assistant", content: text },
            ]);
          }
        }
      })
      .catch((err) => {
        setHideStreamCont(true);
        setChats((prev) => [
          ...prev,
          {
            isUser: false,
            text: "I'm experiencing some problems, try again later!",
          },
        ]);
      })
      .finally(() => {
        setIsLoading(false);
        setPrompting(false);
      });
  };

  useEffect(() => {
    if (leadInfo?.email && leadInfo?.email !== "anonymous") {
      getVideoInfo();
    }
  }, [leadInfo?.email]);

  useEffect(() => {
    setIsLoading(false);

    if (!campaignData || !leadInfo || !history || redirectURL === "true")
      return;

    const context = generateContext(campaignData, leadInfo);
    const initPrompt = [{ role: "user", content: context }];

    if (history.length !== 0) {
      const tempHistory = [
        ...initPrompt,
        ...history.map((chat) => {
          delete chat._id;
          return chat;
        }),
      ];
      setPrompt(tempHistory);
      const processedChats = [];
      for (let i = 1; i < tempHistory.length; i += 1) {
        const { role, content } = tempHistory[i];
        processedChats.push({ isUser: role === "user", text: content });
      }
      const videoPresent = chats.find(
        (i) => i.isVideoPresent || i.isVideoPresent == false
      );
      if (videoPresent) {
        processedChats.splice(0, 0, videoPresent);
      }

      setChats(processedChats);
      setPrompting(false);
      return;
    }

    setPrompt(initPrompt);

    if (
      isChatClick &&
      videoData?.video_url &&
      videoData?.chatLinkType === "VIDEO"
    ) {
      fetchApi(initPrompt);
    } else if (
      videoData &&
      // videoData?.video_url &&
      videoData?.chatLinkType !== "VIDEO"
    ) {
      fetchApi(initPrompt);
    } else if (campaignId_urlParam && leadEmail === "anonymous") {
      fetchApi(initPrompt);
    } else {
      setHideStreamCont(true);
      setIsLoading(false);
      setPrompting(false);
    }
  }, [campaignData, leadInfo?.email, history, videoData]);

  useEffect(() => {
    const chatContainer = document.querySelector("#chat-container");
    console.log("chatContainer", chatContainer)
    if (chatContainer !== null && (redirectURL === "false" || campaignData?.link?.url === "")) {
      chatContainer.scrollTop = chatContainer?.scrollHeight;
    }
  }, [chats]);

  const [accessToken, setAccessToken] = useState(null);
  const [dateTimeOptions, setDateTimeOptions] = useState(null);
  const [userDetails, setUserDetails] = useState();
  const [testimonialDispaly, SetTestimonialDispaly] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const getAllWhiteLabelClientConfigurations = async (id) => {
    setIsLoadingData(true);
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getwhiteLabelConfig/${id}`
      );
      const data = await response.json();
      setCompanyName(data?.data?.info?.companyName);
      setIsLoadingData(false);
    } catch (err) {
      console.log("API fetch error:", err);
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    if (whiteLableId) {
      getAllWhiteLabelClientConfigurations(whiteLableId);
    }
  }, [whiteLableId]);

  useEffect(() => {
    if (!campaignData || accessToken) return;
    setUserEmail(campaignData?.clientEmail);
    (async () => {
      const userInfo = await getUserInfo(campaignData?.clientEmail);
      const accessToken = userInfo?.extra?.access_token;
      setUserDetails(userInfo);
      setAccessToken(accessToken);
    })();
  }, [campaignData]);

  useEffect(() => {
    if (!accessToken) return;
    const now = Date.now();

    setDateTimeOptions({
      data_center: cronofy.data_center,
      target_id: "cronofy-date-time-picker",
      availability_query: {
        participants: [
          {
            required: "all",
            members: [{ sub: accessToken.sub, managed_availability: true }],
          },
        ],
        required_duration: { minutes: meetPrefs.durationInMins },
        available_periods: [
          {
            start: `${
              new Date(now + meetPrefs.delayInMS).toISOString().split(".")[0]
            }Z`,
            end: `${
              new Date(
                now +
                  meetPrefs.allowMeetsUptoXDaysInFuture * 24 * 60 * 60 * 1000
              )
                .toISOString()
                .split(".")[0]
            }Z`,
          },
        ],
      },
    });
  }, [accessToken]);

  const scheduleMeet = () => {
    handlePromptForMeet("show", false);
    submitInput("meet", { hidden: true });
    setIsVideo(false);
  };

  const getRandomImageURL = () => {
    const randomIndex = Math.floor(Math.random() * 15) + 1; // Generate a random index between 1 and 15
    return `/assets/avatarImg/i-${randomIndex}.webp`; // Use the random index to construct the image URL
  };

  const [feedBackOpen, setFeedbackOpen] = useState(false);

  let testimonials = [
    {
      text: campaignData?.testimonials_v2?.[0]?.testimonial,
    },
    {
      text: campaignData?.testimonials_v2?.[1]?.testimonial,
    },
    {
      text: campaignData?.testimonials_v2?.[2]?.testimonial,
    },
  ];

  let testimonialsName = [
    {
      text: campaignData?.testimonials_v2?.[0]?.clientName,
    },
    {
      text: campaignData?.testimonials_v2?.[1]?.clientName,
    },
    {
      text: campaignData?.testimonials_v2?.[2]?.clientName,
    },
  ];

  let testimonialsCompanyName = [
    {
      text: campaignData?.testimonials_v2?.[0]?.clientCompanyName,
    },
    {
      text: campaignData?.testimonials_v2?.[1]?.clientCompanyName,
    },
    {
      text: campaignData?.testimonials_v2?.[2]?.clientCompanyName,
    },
  ];
  let filteredTestimonials = [];
  let filteredTestimonialsName = [];
  let filteredTestimonialsCompanyName = [];
  for (let i in testimonials) {
    if (testimonials[i].text !== "") {
      filteredTestimonials.push(testimonials[i]);
      filteredTestimonialsName.push(testimonialsName[i]);
      filteredTestimonialsCompanyName.push(testimonialsCompanyName[i]);
    }
  }
  testimonials = filteredTestimonials;
  testimonialsName = filteredTestimonialsName;
  testimonialsCompanyName = filteredTestimonialsCompanyName;

  const handleOpen1 = () => {
    setFeedbackOpen(true);
  };
  const mobileNav = useMobileNav();
  const currentDate = new Date();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const currentDay = daysOfWeek[currentDate.getDay()];

  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);

  const handleNext = () => {
    if (currentTestimonialIndex < testimonials.length - 1) {
      setCurrentTestimonialIndex(currentTestimonialIndex + 1);
    } else {
      setCurrentTestimonialIndex(0);
    }
  };

  const handlePrevious = () => {
    if (currentTestimonialIndex > 0) {
      setCurrentTestimonialIndex(currentTestimonialIndex - 1);
    } else {
      setCurrentTestimonialIndex(testimonials.length - 1);
    }
  };

  const userEmailId = getSessionStorage("userEmail");

  const [mobileOpen, setMobileOpen] = useState(false);
  const myScrollableDivRef = useRef(null);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    setIsVideoVisible(false);
  };

  useEffect(() => {
    campaignData?.testimonials_v2.map((data) => {
      if (
        data.clientCompanyName ||
        data.clientName ||
        data.clientTitle ||
        data.testimonial
      ) {
        SetTestimonialDispaly(true);
      }
    });
  }, [campaignData?.testimonials]);

  const element = useRoutes(routes);

  const getOriginalUrl = async (options = {}) => {
    const { id } = options;
    if (!id) return;
    const res = await axios.get(`${API_SERVICE_BACKEND}/convertshorten`, {
      params: {
        id,
      },
    });
    return res.data?.originalURL;
  };

  useEffect(() => {
    if (
      element?.props?.match?.pathname.includes("/chat/private") ||
      element?.props?.match?.pathname.includes("/invitations")
    ) {
      if (urlId) {
        getOriginalUrl({ id: urlId }).then((url) => {
          if (url) {
            const regex = /\/([a-f\d]{24})\/([a-f\d]{24})\/([a-f\d]{24})/;
            const params = url.split("invitations/")[1]?.split("/");
            const matches = url.match(regex);
            setSearchParams({
              ["campaignId"]: params[0],
              ["user"]: params[1],
              ["eid"]: params[2],
              ["step"]: params[3],
              ["variant"]: params[4],
              // ["redirectURL"]: redirectURL,
            });

            findcampaign(matches[1]);
            leadData(matches[2]);
          } else {
            http: alert("Invalid URL");
          }
        });
        return;
      }
    } else if (element?.props?.match?.pathname.includes("/chat")) {
      if (campaignId_urlParam && leadEmail === "anonymous") {
        findcampaign(campaignId_urlParam);
        return;
      }
    }
  }, [ab.length]);

  const visistWebsite = async () => {
    if (leadInfo.isWebSiteVisit) return;

    await axios
      .post(`${API_SERVICE_BACKEND_2}/visit_Website`, {
        leadId: leadInfo._id,
        campaignId: campaignData._id,
      })
      .then((res) => {
        if (res.data.message === "success")
          setLeadInfo((pre) => ({ ...pre, isWebSiteVisit: true }));
      });
  };
  const [showFullTestimonial, setShowFullTestimonial] = useState(false);

  const toggleShowFullTestimonial = () => {
    setShowFullTestimonial(!showFullTestimonial);
  };

  const testimonial = testimonials[currentTestimonialIndex];

  const renderTestimonialText = () => {
    const textLimit = 30;
    const text = testimonials[currentTestimonialIndex]?.text.split(" ");

    if (!showFullTestimonial && text.length > textLimit) {
      return (
        <div>
          {text.slice(0, textLimit).join(" ")}...
          <span
            onClick={toggleShowFullTestimonial}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Read More
          </span>
        </div>
      );
    } else {
      return (
        <div>
          {testimonial.text}
          {showFullTestimonial && (
            <span
              onClick={toggleShowFullTestimonial}
              style={{
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Show Less
            </span>
          )}
        </div>
      );
    }
  };

  const settings = useSettings();
  const theme = createTheme({
    colorPreset: settings.colorPreset,
    contrast: settings.contrast,
    direction: settings.direction,
    paletteMode: "light", //settings.paletteMode,
    responsiveFontSizes: settings.responsiveFontSizes,
  });

  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="Select a Date & Time" />
        <Dialog
          open={Boolean(dialog)}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="lg"
          className="chatpage-paper"
        >
          {dialog === "redirect" ? (
            <>
              <DialogTitle id="alert-dialog-title">
                Please wait, we're redirecting you to
                <span style={{ color: "blue" }}>
                  {campaignData?.organisationName || "company"}'s
                </span>{" "}
                official webpage...
              </DialogTitle>
            </>
          ) : null}
        </Dialog>

        {isLoadingData ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "99.15%",
            }}
          >
            {
              domain === "app.b2brocket.ai" ?
              <BotAvatar />
              :
              <>
                
              <SplashScreen />
              <Box className="text-loader"></Box>
              </>
            }
          </Box>
        ) : redirectURL === "false11" || campaignData?.link?.url === "" ? (
          <Box
            sx={{
              backgroundColor: "#F3F1FA",
              width: "100vw",
              height: "100vh",
              overflowX: "none",
              overflowY: "hidden",
              // pb: 1,
            }}
          >
            <div
              style={{ display: "flex", flexDirection: lgUp ? "row" : "column" }}
            >
              <SideMenu
                mobileOpen={mobileOpen}
                handleDrawerToggle={handleDrawerToggle}
                campaignData={campaignData}
                handlePrevious={handlePrevious}
                leadInfo={leadInfo}
                scheduleMeet={scheduleMeet}
                testimonialDispaly={testimonialDispaly}
                testimonials={testimonials}
                currentTestimonialIndex={currentTestimonialIndex}
                testimonialsName={testimonialsName}
                testimonialsCompanyName={testimonialsCompanyName}
                setCurrentTestimonialIndex={setCurrentTestimonialIndex}
                handleNext={handleNext}
                visistWebsite={visistWebsite}
                feedBackOpen={feedBackOpen}
                setFeedbackOpen={setFeedbackOpen}
              />
              <Box
                Box
                sx={{
                  borderRadius: { xs: 0, md: "16px 16px 0px 0px" },
                  backgroundColor: {
                    xs: lgUp ? "#ffffff" : "#ECE9F5",
                    md: "#F3F1FA",
                  },
                  height: { xs: "calc(100vh - 0px)", md: "calc(100vh - 0px)" },
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: lgUp ? "center" : "flex-end",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "",
                    alignItems: "center",
                    height: "100%",
                    width: lgUp ? "70%" : "100%",
                    border: "1px solid #e9e5e5",
                    borderRadius: "10px",
                  }}
                >
                  <>
                    <div
                      style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 999,
                        backgroundColor: "white",
                        borderTopRightRadius: "10px",
                        borderTopLeftRadius: "10px",
                        padding: "10px 8px 10px 13px",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "white",
                          padding: "10px 8px 0px 13px",
                          width: "100%",
                        }}
                      >
                        <AvatarGroup sx={{ justifyContent: "flex-end" }}>
                          <Avatar src={companyLogo1} />
                          {/* {
                            whiteLableStatus || subUserStatus
                            ? 
                            <></>
                            :

                          <Avatar src={companyLogo2} />
                          } */}
                        </AvatarGroup>
                        <Typography
                          sx={{
                            color: "#111927",
                            fontSize: mdUp ? "35px" : lgUp ? "35px" : "16px",
                            fontWeight: "400",
                            ml: "10px",
                            textAlign: "center",
                          }}
                        >
                          Invitation to{" "}
                          <span style={{ fontWeight: "700" }}>
                            {
                              companyName ? companyName : leadInfo?.organization || "Prospect Company"
                            }
                          </span>{" "}
                          from{" "}
                          <span style={{ fontWeight: "700" }}>
                            {(campaignData?.companyName ||
                              campaignData.sequence?.value?.steps[step - 1]?.variants?.value[variant - 1]?.template?.config?.companyDesc || ""
                            )?.replace(/B2B Rocket|B2B/g, companyName)}
                          </span>
                        </Typography>
                      </div>
                    </div>
                  </>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      // height: "100%",
                      width: "100%",
                      justifyContent: "flex-end",
                      pb: { xs: "0px", md: "0px" },
                    }}
                  >
                    <Stack
                      direction="column"
                      id="content-div"
                      // ref={myScrollableDivRef}
                      sx={{
                        overflow: { xs: "auto", sm: "unset" },
                        position: "relative",
                        maxHeight: "100%",
                        backgroundColor: "transparent",
                        // marginTop: lgUp ? " " : { xs: "5px" },
                        marginBottom: lgUp ? "95px" : { xs: "60px", sm: "0px" },
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: lgUp ? "5px 0px" : "0px 0px 6px 0px",
                        }}
                      >
                        <Divider
                          sx={{
                            " &::before": {
                              borderColor: "red",
                              flex: 1,
                            },
                          }}
                        ></Divider>
                      </div>
                      <Stack
                        id="sticky-header"
                        sx={{
                          width: "100%",
                          pr: "7px",
                          backgroundColor: "rgb(236, 233, 245)",
                          position: "fixed",
                          left: 0,
                          px: "10px",
                          zIndex: 9999,
                          display: {
                            xs: isVideoVisible ? "flex" : "none",
                            sm: "none",
                          },
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "6px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: lgUp ? "10px 0px" : "0px 0px 5px 0px",
                          }}
                        >
                          <Divider
                            sx={{
                              " &::before": {
                                borderColor: "red",
                                flex: 1,
                              },
                            }}
                          ></Divider>
                        </div>
                      </Stack>
                      <Stack
                        id="chat-container"
                        ref={myScrollableDivRef}
                        sx={{
                          flexGrow: 1,
                          overflowY: "auto",
                          padding: "0px 18px",
                          mb: {
                            xs: lgUp ? "0vh" : "0px",
                            md: lgUp ? "0vh" : "4vh",
                          },
                          maxHeight: { xs: "80vh", sm: "80vh", md: "80vh" },
                          boxShadow: 0,
                          /* width */
                          "&::-webkit-scrollbar": {
                            width: { xs: "4px", md: "10px" },
                            zIndex: 999,
                          },

                          /* Track */
                          "&::-webkit-scrollbar-track": {
                            borderRadius: "10px",
                            background: "#6e7d99",
                          },

                          /* Handle */
                          "&::-webkit-scrollbar-thumb": {
                            background: "#1C2536",
                            borderRadius: "5px",
                          },

                          /* Handle on hover */
                          "&::-webkit-scrollbar-thumb:hover": {
                            background: "#1C2536",
                          },
                        }}
                      >
                        {leadInfo && userDetails && chats.length <= 0 ? (
                          <Box
                            sx={{
                              display: "flex",
                              height: "616px",
                              width: "100%",
                              backgroundColor: "white",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <BouncingDotsLoader />
                          </Box>
                        ) : (
                          chats.map((chat, index) => {
                            return (
                              <AiChatBlock
                                companyName={companyName}
                                cronofyToken={cronofyToken}
                                isGlobal={isGlobal}
                                calenderId={calenderId}
                                sub={sub}
                                lgUp={lgUp}
                                midUp={midUp}
                                mdUp={mdUp}
                                userEmail={userEmail}
                                primaryBrandColor={primaryBrandColor}
                                isChatClick={isChatClick}
                                isVideo={isVideo}
                                videoData={videoData}
                                videoRef={myScrollableDivRef}
                                scrollPosition={scrollPosition}
                                setScrollPosition={setScrollPosition}
                                setIsVideo={setIsVideo}
                                handleOpenChat={handleOpenChat}
                                setIsChatClick={setIsChatClick}
                                scheduleMeet={scheduleMeet}
                                userDetails={userDetails}
                                visistWebsite={visistWebsite}
                                key={index}
                                data={chat}
                                busy={isLoading}
                                handleEgSelect={submitInput}
                                extra={{
                                  accessToken,
                                  dateTimeOptions,
                                  setDateTimeOptions,
                                  leadInfo: {
                                    email: leadInfo.email,
                                    fname: leadInfo.firstName,
                                    lname: leadInfo.lastName,
                                    organization: leadInfo?.organization,
                                    ...leadInfo,
                                  },
                                  campaignData,
                                  handlePromptForMeet,
                                }}
                                campaignId={campaignId}
                                firefun={firefun}
                                mailbox={mailbox}
                                step={step}
                                variant={variant}
                              />
                            );
                          })
                        )}
                        {userDetails && !hideStreamCont ? (
                          <AiChatBlock
                            isGlobal={isGlobal}
                            cronofyToken={cronofyToken}
                            calenderId={calenderId}
                            primaryBrandColor={primaryBrandColor}
                            setScrollPosition={setScrollPosition}
                            sub={sub}
                            userEmail={userEmail}
                            videoRef={myScrollableDivRef}
                            scheduleMeet={scheduleMeet}
                            visistWebsite={visistWebsite}
                            userDetails={userDetails}
                            data={{
                              isUser: false,
                              text: "",
                              id: "stream-container",
                            }}
                            extra={{
                              accessToken,
                              dateTimeOptions,
                              setDateTimeOptions,
                            }}
                            campaignId={campaignId}
                            step={step}
                            variant={variant}
                          />
                        ) : (
                          isLoading && (
                            <>
                              <Stack
                                direction="row"
                                spacing={1}
                                justifyContent="center"
                                sx={{ py: 1, mb: 2 }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    opacity: "50%",
                                  }}
                                >
                                  {/* <BotAvatar /> */}
                                  <img
                                    src={faviconUrl}
                                    alt={`${companyName} Logo`}
                                    style={{
                                      width: "32px",
                                      height: "32px",
                                      objectFit: "contain",
                                    }}
                                  />
                                </Box>
                                <Skeleton
                                  variant="rectangular"
                                  width="100%"
                                  height={50}
                                  sx={{
                                    backgroundColor: primaryBrandColor,
                                    borderRadius: "8px",
                                  }}
                                />
                              </Stack>
                            </>
                          )
                        )}
                      </Stack>
                    </Stack>
                  </Box>
                  {isChatClick && chats.length === 1 && (
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: { xs: "0px", md: "15px" },
                        zIndex: 999,
                      }}
                    >
                      <CircularProgress
                        color="inherit"
                        sx={{ marginBottom: "40%" }}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: lgUp ? "70%" : "100%",
                      marginLeft: lgUp ? "10px" : "0%",
                      marginRight: lgUp ? "10px" : "0px",
                      pl: { xs: 0, md: "5px" },
                      pr: { xs: 0, md: "0px" },
                      position: "absolute",
                      bottom: { xs: "0px", md: "16px" },
                      zIndex: 999,
                    }}
                  >
                    <TextField
                      variant="standard"
                      id="user-input"
                      placeholder="Chat now to ask us anything"
                      inputProps={{
                        style: { fontSize: lgUp ? "25px" : "16px" },
                      }}
                      sx={{
                        width: lgUp ? "100%" : "100%",
                        margin: lgUp ? "0px 12px" : "0px",
                        py: { xs: 1.5 },
                        borderRadius: { xs: "0px", md: "8px" },
                        background: "#FFF",
                        boxShadow: "0px 1px 2px 0px #1018280D",
                        "& div fieldset": {
                          border: "none",
                        },
                        px: 2,
                        height: lgUp ? "75px" : "56px",
                        fontSize: "30px",
                        border: "1px solid #ECE9F5",
                        display: "flex",
                        justifyContent: "center",
                      }}
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key !== "Enter" || prompting) return;
                        submitInput();
                      }}
                      InputProps={{
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              variant="contained"
                              onClick={() => submitInput()}
                              sx={{
                                borderRadius: "8px",
                                backgroundColor: primaryBrandColor,
                                color: "#fff",
                                py: 0.9,
                                px: 2,
                                "&:hover": {
                                  backgroundColor:  secondaryBrandColor,
                                  boxShadow: "none",
                                },
                                textAlign: "left",
                                fontFamily: "Inter",
                                textTransform: "capitalize",
                                fontSize: lgUp ? "25px" : "16px",
                                fontWeight: 600,
                                lineHeight: "20px",
                                boxShadow: "0px 1px 2px 0px #1018280D",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: lgUp ? "50px" : "32px",
                              }}
                            >
                              Send{" "}
                              <img src={send} style={{ marginLeft: "8px" }} />
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </div>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "99.15%",
            }}
          >
            {
              domain === "app.b2brocket.ai" ?
                <BotAvatar />
                :
                <>
                
                <SplashScreen />
                <Typography className="text-loader">Loading...</Typography>
                </>
            }
          </Box>
        )}
      </>
    </ThemeProvider>
  );
};

export default ChatPage;
