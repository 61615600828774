import "../style.css";
import {
  Typography,
  Stack,
  Box,
  IconButton,
  Popover,
  Tooltip,
  Divider,
  Grid,
  alpha,
} from "@mui/material";

import { useState, useEffect, useRef } from "react";
import { API_SERVICE_BACKEND } from "src/config";
import axios from "axios";
import moment from "moment";
import OpenIcon from "src/components/logos/mailOpened.png";
import ClickIcon from "src/components/logos/mailClicked.png";

const SequenceStepEmailContent = ({ content, status }) => {
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const divRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleOpen1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleOpen2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const open1 = Boolean(anchorEl1);
  const open2 = Boolean(anchorEl2);
  const id1 = open1 ? "mouse-over-popover1" : undefined;
  const id2 = open2 ? "mouse-over-popover2" : undefined;
  const [emailData, setEmailData] = useState({});
  const [linkClicksData, setLinkClicksData] = useState({});
  const [clickDates, setClickDates] = useState([]);
  const [sortedClicks, setSortedClicks] = useState([]);
  const [sortedOpens, setSortedOpens] = useState([]);

  useEffect(() => {
    const fetchEmailContent = async () => {
      try {
        const res = await axios.get(
          `${API_SERVICE_BACKEND}/getEmailSentInfo?id=${content}&analytics=true`
        );

        if (!res.data) return;
        setEmailData({
          clicked: res.data.clicked,
          opened: res.data.opened,
          email: res.data.email,
          from: res.data.from,
          to: res.data.to,
          sentAt: res.data.createdAt,
        });
        setLinkClicksData(res.data.analyticsData);
        setSortedClicks(res.data.clicked);
        setSortedOpens(res.data.opened);
      } catch (error) {
        console.log(error);
      }
    };
    if (content) {
      fetchEmailContent();
    }
  }, [content]);
  const getTimeZone = () => {
    const intl = Intl.DateTimeFormat().resolvedOptions().timeZone; // Asia/Tokyo
    moment.tz(intl).zoneName(); // JST
    return moment.tz(intl).zoneAbbr();
  };

  const handleClick = (event, target, clickData) => {
    setAnchorEl(event.currentTarget);
    if (clickData?.clicks?.length > 0) {
      setClickDates(clickData?.clicks);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setClickDates([]);
  };

  useEffect(() => {
    if (divRef?.current && linkClicksData?.count && linkClicksData?.count > 0) {
      const links = divRef.current.querySelectorAll("a");

      links.forEach((link) => {
        const href = link.getAttribute('href');
        const clickData = linkClicksData.urls && linkClicksData.urls.length > 0 && href && linkClicksData.urls.map((url) => {
          if (url.originalUrl === href) {
            return url
          } else {
            return null;
          }
        }).filter((item) => item !== null)[0];

        const span = document.createElement('span');
        span.textContent = ` ${clickData?.clicks?.length}`;
        span.style.marginLeft = "5px";
        span.style.fontSize = "12px";
        span.style.backgroundColor = "#e3ffec";
        span.style.color = "#4CAF50";
        span.style.padding = "2px 8px";
        span.style.borderRadius = "100%";
        span.style.display = "inline-flex";
        span.style.alignItems = "center";
        span.style.justifyContent = "center"; 
        span.style.fontWeight = "600";
        span.style.cursor = "pointer";
        span.addEventListener("click", (event) => handleClick(event, link, clickData));
        link.parentNode.insertBefore(span, link.nextSibling);
      });
    }
  }, [linkClicksData]);

  return (
    <>
      <Stack
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
        }}
        className="glass-pane"
      >
        {!content && status === 2 ? (
          <Typography sx={{ p: "28px" }}>
            The prospect has potentially unsubscribed from the email journey.
          </Typography>
        ) : (
          <Stack sx={{ px: "28px", pt: "18px" }} gap={2}>
            <Grid container>
              <Grid item xs={10}>
                <Stack gap={0.2}>
                  <Typography sx={{ fontWeight: "700" }}>
                    Email sent to {emailData?.to}
                  </Typography>
                  <Typography>From: {emailData?.from}</Typography>
                  <Typography sx={{ fontSize: "14px" }}>
                    Sent at:{" "}
                    {moment(emailData?.sentAt).format("MM/DD/YYYY, h:mm A")}{" "}
                    {getTimeZone()}
                  </Typography>
                  {emailData?.email?.meta?.proxy?.ip && <Typography>IP: {emailData?.email?.meta?.proxy?.ip}</Typography>}
                </Stack>
              </Grid>
              <Grid item xs={2}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Tooltip title="Clicks" placement="top" arrow>
                    <IconButton
                      onClick={handleOpen1}
                      aria-owns={open1 ? "mouse-over-popover1" : undefined}
                      aria-haspopup="true"
                      sx={{ p: 0 }}
                    >
                      <img src={ClickIcon} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Opens" placement="top" arrow>
                    <IconButton
                      aria-owns={open2 ? "mouse-over-popover2" : undefined}
                      aria-haspopup="true"
                      onClick={handleOpen2}
                      sx={{ p: 0 }}
                    >
                      <img src={OpenIcon} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Typography sx={{ fontWeight: "700" }}>
                Subject: {emailData?.email?.subject}
              </Typography>
            </Grid>

            <Divider sx={{ mb: "-12px" }} />
            <Box sx={{ position: 'relative' }}>
              {emailData?.email?.meta?.wordCount && <Typography
                sx={{
                  width: "fit-content",
                  backgroundColor: alpha("#5761FE", 0.1),
                  p: 1,
                  borderRadius: "50px",
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "#5761FE",
                  border: `1px solid ${alpha("#5761FE", 0.2)}`,
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  mt: 1
                }}
              >
                {emailData?.email?.meta?.wordCount} words
              </Typography>}
              <div ref={divRef} 
              dangerouslySetInnerHTML={{ __html: emailData?.email?.html }} />
                <Popover
                  sx={{ "& .MuiPopover-paper": { p: 2 } }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  {clickDates?.length === 0 ? (
                    <Typography>No clicks</Typography>
                  ) : (
                    <Stack direction={"column"}
                      gap={1}>
                      {clickDates?.map((click, index) => (
                        <Typography key={index}>
                          {moment(click).format("MM/DD/YYYY, h:mm A")}
                        </Typography>
                      ))}
                    </Stack>
                  )}
                </Popover>
            </Box>
          </Stack>
        )}
      </Stack>
      <Popover
        sx={{ "& .MuiPopover-paper": { p: 2 } }}
        id={id1}
        open={open1}
        anchorEl={anchorEl1}
        onClose={handleClose1}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {emailData?.clicked?.length === 0 ? (
          <Typography>Not clicked</Typography>
        ) : (
          <Stack direction={"column"} gap={1}>
            {sortedClicks?.map((click, index) => (
              <Typography key={index}>
                {moment(click).format("MM/DD/YYYY, h:mm A")}
              </Typography>
            ))}
          </Stack>
        )}
      </Popover>
      <Popover
        sx={{ "& .MuiPopover-paper": { p: 2 } }}
        id={id2}
        open={open2}
        anchorEl={anchorEl2}
        onClose={handleClose2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {emailData?.opened?.length === 0 ? (
          <Typography>Not opened</Typography>
        ) : (
          <Stack direction={"column"} gap={1}>
            {sortedOpens?.map((open, index) => (
              <Typography key={index}>
                {moment(open).format("MM/DD/YYYY, h:mm A")}
              </Typography>
            ))}
          </Stack>
        )}
      </Popover>
    </>
  );
};

export default SequenceStepEmailContent;
