import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Typography, IconButton, Dialog, DialogContent, DialogActions, Stack } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";

const UploadFile = ({ selectedFile, setStatus, status, debouncedSubmit, setSelectedFile }) => {

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [originalImage, setOriginalImage] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 50, height: 50, x: 25, y: 25 });

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop({
      unit: "%",
      width: 80,
      height: 80 * (height / width),
      x: 10,
      y: 10
    });
  };
  const cropRef = useRef(null);
  const [cropResult, setCropResult] = useState(null);

  useEffect(() => {
    if (cropRef.current && crop.width && crop.height) {
      getCroppedImg();
    }
  }, [crop]);

  const getCroppedImg = () => {
    const image = cropRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const cropWidth = crop.width * scaleX;
    const cropHeight = crop.height * scaleY;
    const startX = crop.x * scaleX;
    const startY = crop.y * scaleY;

    const canvas = document.createElement("canvas");
    canvas.width = cropWidth;
    canvas.height = cropHeight;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
      image,
      startX,
      startY,
      cropWidth,
      cropHeight,
      0,
      0,
      cropWidth,
      cropHeight
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        dataURLtoFile(reader.result, originalImage.name);
      };
    }, "image/png");
  };


  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    setCropResult(croppedImage);
  };

  const handleUpload = async () => {
    setSelectedFile(cropResult);
    setDialogOpen(false);
    debouncedSubmit();
    return () => debouncedSubmit.cancel();
  };

  const fileInputRef = useRef(null);

  const handleClose = () => {
    setDialogOpen(false);
  };

  const acceptedFileTypesString = ".jpg,.jpeg,.png";

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setOriginalImage(file);
    const preview = URL.createObjectURL(file);
    setFilePreview(preview);
    setDialogOpen(true);

  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setOriginalImage(file);
    const preview = URL.createObjectURL(file);
    setFilePreview(preview);
    setDialogOpen(true);

  };

  const handleFile = (file) => {
    setSelectedFile(file);
    debouncedSubmit();
    return () => debouncedSubmit.cancel();
  };

  const handleFileDelete = () => {
    setSelectedFile(null);
    debouncedSubmit();
    return () => debouncedSubmit.cancel();
  };

  

  const handleCustomButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Box>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogContent>
          <Stack>
            <ReactCrop
              crop={crop}
              onChange={(c) => setCrop(c)}
              keepSelection={true}
            >
              <img
                src={filePreview}
                ref={cropRef}
                onLoad={onImageLoad}
                alt="Your uploaded image"
                style={{minWidth: "200px"}}
              />
            </ReactCrop>
          </Stack>
        </DialogContent>
        <DialogActions sx={{justifyContent: "center"}}>
          <Button onClick={handleUpload} variant="contained">Save Logo</Button>
        </DialogActions>
      </Dialog>
      {/* Drag-and-Drop Section */}
      {selectedFile ? (
        <Box
          border={2}
          borderColor="grey.300"
          borderRadius="16px"
          minHeight="170px"
          maxHeight="170px"
          className="glass-wrapper"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <Box display="flex" alignItems="center">
            {typeof selectedFile === 'string' ? (
                <img
                  src={selectedFile}  // If it's a URL, just display it
                  alt="File Icon"
                  style={{ width: "100%",maxWidth:'220px',  height: "134px",  objectFit: "contain" }}
                />
              ) : (
                <img
                  src={URL.createObjectURL(selectedFile)}  // If it's a file, use object URL
                  alt="File Icon"
                  style={{ width: "100%",maxWidth:'220px',  height: "134px",  objectFit: "contain" }}
                />
              )}
            </Box>
            <IconButton edge="end" color="error" onClick={handleFileDelete}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          border={"2px dashed"}
          borderColor="grey.300"
          borderRadius="16px"
          minHeight="170px"
          maxHeight="170px"
          overflow="auto"
          p={2}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => handleDrop(e)}
          className="glass-wrapper"
        >
          <Box textAlign="center">
            <CloudUploadIcon sx={{color:theme=>theme.palette.text.primary}} />
            <Typography variant="body1" fontWeight="600" color="text.primary">
              Drag and Drop the file
            </Typography>
            <Typography variant="body1" fontWeight="700" color="text.primary">
              or
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleCustomButtonClick}
            >
              Upload File
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              accept={acceptedFileTypesString}
              style={{ display: "none" }}
              onChange={handleFileChange}
              onClick={(e) => (e.target.value = null)} // To allow re-uploading same file
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UploadFile;
