import { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  CircularProgress,
} from "@mui/material";

function CouponBox({
  couponModal,
  setCouponModal,
  setAlertModal,
  isLoading,
  setIsLoading,
  handleUpdatePlan,
  couponError,
  setCouponError,
}) {
  const [coupon, setCoupon] = useState("");

  const handleChange = (event) => {
    setCoupon(event.target.value);
    setCouponError("");
  };

  const handleClose = () => {
    setCouponModal(false);
    setAlertModal(false);
  };
  const handleWithoutCoupon = async () => {
    setIsLoading(true);
    const res = await handleUpdatePlan();
    if (res) {
      setCouponModal(false);
      setAlertModal(false);
    }
  };
  const handleApplyCoupon = async () => {
    setIsLoading(true);
    const res = await handleUpdatePlan(coupon);
    if (res) {
      setCouponModal(false);
      setAlertModal(false);
    }
  };

  return (
    <>
      <Dialog
        open={couponModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="alert-dialog-title">
          Do you have a coupon code?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <TextField
              autoFocus
              margin="dense"
              id="couponCode"
              label="Enter coupon code"
              type="text"
              className="glass-input"
              fullWidth
              value={coupon}
              onChange={handleChange}
              error={!!couponError}
              helperText={couponError ? couponError : ""}
            />
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {isLoading && <CircularProgress size={24} />}
          <Button
          className="glass-button-tertiary"
            onClick={handleWithoutCoupon}
            disabled={coupon || isLoading ? true : false}
          >
            Without Coupon
          </Button>

          <Button
          className="glass-button-tertiary"
            onClick={handleApplyCoupon}
            disabled={coupon && !isLoading ? false : true}
            autoFocus
          >
            Apply Coupon
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default CouponBox;
