import { alpha, lighten, darken } from "@mui/material/styles";
import { error, info, neutral, success, warning, withAlphas } from "../colors";
import { getPrimary } from "../utils";

export const createPalette = (config) => {
  const { colorPreset, contrast, primaryBrandColor,primaryTextColor,secondaryBrandColor,secondaryTextColor} = config;
  const isWhiteLabel = localStorage.getItem("isWhiteLabel");
// custom theme
  const customPalette = withAlphas({
    lightest:primaryBrandColor ? lighten(primaryBrandColor, 0.9) : '#F5F8FF',
    light: primaryBrandColor ? lighten(primaryBrandColor, 0.7) : '#EBEFFF',
    main: primaryBrandColor || "#5862FF",
    dark: secondaryBrandColor ? darken(secondaryBrandColor,0.1): '#004EEB',
    darkest: primaryBrandColor ? darken(primaryBrandColor,0.2) : '#00359E',
    contrastText: "#FFFFFF",
  })
  // custom theme ends
  return {
    action: {
      active: neutral[500],
      disabled: alpha(neutral[100], 0.38),
      disabledBackground: alpha(neutral[100], 0.12),
      focus: alpha(neutral[100], 0.16),
      hover: alpha(neutral[100], 0.04),
      selected: alpha(neutral[100], 0.12),
    },
    background: {
      default: contrast === "high" ? "#1C2536" : "#0E1320",
      paper: neutral[900],
    },
    divider: "#2D3748",
    error,
    info,
    mode: "dark",
    neutral,
    primary: isWhiteLabel === "true" ? customPalette : getPrimary(colorPreset),
    success,
    text: {
      primary: "rgba(255, 255, 255, 1)",
      secondary: "rgba(253, 253, 253, 0.5)",
      disabled: "rgba(255, 255, 255, 0.48)",
    },
    warning,
  };
};
