import {
    Avatar,
    Box,
    Button,
    IconButton,
    Stack,
    Typography,
    Dialog,
    DialogTitle,
    Drawer,
    Divider,
    DialogContent,
    DialogActions,
    AvatarGroup,
    Tooltip,
    useMediaQuery,
} from "@mui/material";
import { ChevronLeftOutlined, ChevronRightOutlined } from "@mui/icons-material";
import { QuillEditor } from "src/components/quill-editor";
import CloseIcon from "@mui/icons-material/Close";
import Star from "../../assets/Star.png";
import user from "../../assets/user.png";
import companyLogo2 from "../../assets/companyLogo2.png";
import companyLogo1 from "../../assets/companyLogo1.png";
import arrowRight from "../../assets/arrowRight.png";
import { useContext } from "react";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";


const SideMenu = (props) => {
    const {
        mobileOpen,
        handleDrawerToggle,
        campaignData,
        handlePrevious,
        leadInfo,
        scheduleMeet,
        testimonialDispaly,
        testimonials,
        currentTestimonialIndex,
        testimonialsName,
        testimonialsCompanyName,
        setCurrentTestimonialIndex,
        handleNext,
        visistWebsite,
        feedBackOpen,
        setFeedbackOpen
    } = props
    const mdUp = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const { primaryBrandColor, secondaryBrandColor  } = useContext(WhiteLabelConfigurationContext);


    return (
        <>
            <Drawer
                variant="temporary"
                anchor="right"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                PaperProps={{
                    sx: {
                        maxWidth: "100%",
                        width: "30%",
                    },
                }}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <div
                    style={{
                        display: "flex",
                        backgroundColor: "white",
                        padding: "15px",
                    }}
                >
                    <AvatarGroup sx={{ justifyContent: "flex-end" }}>
                        <Avatar
                            src={companyLogo1}
                            sx={{ width: "30px", height: "30px" }}
                        />
                        <Avatar
                            src={companyLogo2}
                            sx={{ width: "30px", height: "30px" }}
                        />
                    </AvatarGroup>
                    <Typography
                        sx={{
                            color: "#111927",
                            fontSize: mdUp ? "23px " : "13px",
                            fontWeight: "500",
                            ml: "5px",
                            mr: "14px",
                        }}
                    >
                        Invitation to{" "}
                        <span style={{ fontWeight: "700" }}>
                            {leadInfo?.organization || "Prospect Company"}{" "}
                        </span>{" "}
                        from{" "}
                        <span style={{ fontWeight: "700" }}>
                            {campaignData?.companyName || ""}
                        </span>
                    </Typography>
                    <IconButton
                        aria-label="close"
                        onClick={handleDrawerToggle}
                        style={{
                            position: "absolute",
                            right: 0,
                            top: 0,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <Divider
                    sx={{
                        border: "1px solid #D1CBE4",
                    }}
                ></Divider>
                <div
                    style={{
                        backgroundColor: "#F8F7FC",
                        padding: "25px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <Stack spacing={2}>
                        {/* <Typography
                      sx={{
                        color: "#050C46",
                        fontSize: mdUp ? "20px" : "16px",
                        fontWeight: "700",
                      }}
                    >
                      {campaignData?.companyName || ""} •{" "}
                      {leadInfo?.organization || "Prospect Company"}
                    </Typography> */}
                        <Typography
                            sx={{
                                color: "#111927",
                                fontSize: mdUp ? "20px" : "16px",
                                fontWeight: "500",
                                margin: "0px 3px",
                                fontFamily: "DM Sans",
                            }}
                        >
                            {/* Prepared exclusively for{" "}
                      {leadInfo?.fname + " " + leadInfo?.lname} at{" "}
                      {leadInfo?.organization || "Prospect Company"} Brands with
                      love */}
                            Hey{" "}
                            <Typography
                                sx={{
                                    display: "inline",
                                    color: "#111927",
                                    fontSize: mdUp ? "20px" : "16px",
                                    fontWeight: "700",
                                    fontFamily: "DM Sans",
                                }}
                            >
                                {leadInfo?.fname}
                            </Typography>
                            , I've prepared this invitation exclusively for you.
                        </Typography>
                        <Button
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "8px",
                                fontSize: mdUp ? "18px" : "",
                                border:`1px solid ${primaryBrandColor}`,
                                backgroundColor: primaryBrandColor,
                                "&:hover": {
                                    backgroundColor: secondaryBrandColor,
                                    border:`1px solid ${secondaryBrandColor}`,
                                  },
                                color: "white",
                            }}
                            logout
                            variant="contained"
                            onClick={(e) => {
                                scheduleMeet();
                                handleDrawerToggle();
                            }}
                            endIcon={<img src={arrowRight} />}
                        >
                            Book A Meeting With Me
                        </Button>
                    </Stack>
                    <div>
                        {/* {testimonialDispaly && (
                            <div style={{ marginTop: "10px" }}>
                                <Typography
                                    sx={{
                                        color: "#050C46",
                                        fontSize: mdUp ? "18px" : "16px",
                                        fontWeight: "500",
                                        fontFamily: "DM Sans",
                                    }}
                                >
                                    See what others are saying about{" "}
                                    {campaignData?.companyName}
                                </Typography>
                                <>
                                    <Stack
                                        spacing={2}
                                        sx={{
                                            backgroundColor: "white",
                                            border: "1px solid white",
                                            padding: "20px",
                                            margin: "15px 0px",
                                            borderRadius: "12px",
                                        }}
                                    >
                                        <div>
                                            <img src={Star} />
                                            <img src={Star} />
                                            <img src={Star} />
                                            <img src={Star} />
                                            <img src={Star} />
                                        </div>
                                        <Typography
                                            sx={{
                                                color: "#050C46",
                                                fontSize: mdUp ? "16px" : "13px",
                                                fontWeight: "400",
                                                fontFamily: "DM Sans",
                                            }}
                                        >
                                            {testimonials[currentTestimonialIndex]?.text}
                                        </Typography>
                                        <Stack spacing={1}>
                                            <Avatar src={user} />
                                            <Typography
                                                style={{
                                                    color: "#050C46",
                                                    fontSize: mdUp ? "18px" : "14px",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                {
                                                    testimonialsName[currentTestimonialIndex]
                                                        ?.text
                                                }
                                            </Typography>
                                            <Typography
                                                style={{
                                                    color: "#7E82A5",
                                                    fontSize: mdUp ? "18px" : "13px",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                {
                                                    testimonialsCompanyName[
                                                        currentTestimonialIndex
                                                    ]?.text
                                                }
                                            </Typography>
                                        </Stack>

                                        {testimonials?.length > 1 && (
                                            <Stack
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Box>
                                                    <Tooltip
                                                        title="Previous"
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <IconButton
                                                            onClick={handlePrevious}
                                                            sx={{
                                                                borderRadius: "50%",
                                                                margin: 2,
                                                                p: 0,
                                                                border: "1px solid #EAECF0",
                                                                backgroundColor: "white",
                                                                color: "#5761FE",
                                                            }}
                                                        >
                                                            <ChevronLeftOutlined />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                                {testimonials.map((testimonial, index) => (
                                                    <button
                                                        style={{
                                                            padding: "5px",
                                                            backgroundColor:
                                                                index === currentTestimonialIndex
                                                                    ? "#5761FE"
                                                                    : "#B3B5C7",
                                                            borderRadius: "16px",
                                                            border:
                                                                index === currentTestimonialIndex
                                                                    ? "1px solid #5761FE"
                                                                    : "1px solid #B3B5C7",
                                                            margin: 2,
                                                        }}
                                                        onClick={() =>
                                                            setCurrentTestimonialIndex(index)
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                width: "1px",
                                                                height: "1px",
                                                                borderRadius: "50%",
                                                                cursor: "pointer",
                                                            }}
                                                        ></div>
                                                    </button>
                                                ))}
                                                <Box>
                                                    <Tooltip
                                                        title="Next"
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <IconButton
                                                            onClick={handleNext}
                                                            sx={{
                                                                borderRadius: "50%",
                                                                margin: 2,
                                                                p: 0,
                                                                border: "1px solid #EAECF0",
                                                                backgroundColor: "white",
                                                                color: "#5761FE",
                                                            }}
                                                        >
                                                            <ChevronRightOutlined />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Box>
                                            </Stack>
                                        )}
                                    </Stack>
                                </>
                            </div>
                        )} */}
                        {(campaignData && campaignData?.companyWebsite && (
                            <a
                                href={
                                    campaignData && campaignData?.companyWebsite
                                        ? campaignData.companyWebsite.startsWith("http://") || campaignData.companyWebsite.startsWith("https://")
                                            ? campaignData.companyWebsite
                                            : `http://${campaignData.companyWebsite}`
                                        : "#"
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={visistWebsite}
                            >
                                <Button
                                    endIcon={<img src={arrowRight} />}
                                    fullWidth
                                    style={{
                                        backgroundColor:primaryBrandColor,
                                        color: "white",
                                        fontSize: "16px",
                                    }}
                                >
                                    Visit Our Website
                                </Button>
                            </a>
                        )) ||
                            ""}
                    </div>

                    {feedBackOpen && (
                        <Dialog
                            open={feedBackOpen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            fullWidth
                            maxWidth="md"
                        >
                            <DialogTitle
                                sx={{
                                    color: "#050C46",
                                    fontWeight: "700",
                                    fontSize: "22px",
                                }}
                            >
                                Your Feedback
                                <IconButton
                                    aria-label="close"
                                    onClick={() => setFeedbackOpen(false)}
                                    style={{
                                        position: "absolute",
                                        right: 8,
                                        top: 4,
                                    }}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <QuillEditor
                                    placeholder="Write something"
                                    sx={{ height: 200 }}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    fullWidth
                                    onClick={() => setFeedbackOpen(false)}
                                    sx={{ border: "1px solid #ECE9F5" }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={() => { }}
                                    sx={{
                                        backgroundColor: primaryBrandColor,
                                        color: "white",
                                    }}
                                >
                                    Send
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )}
                </div>
            </Drawer>
        </>
    )
};

export default SideMenu;
