import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Chip from '@mui/material/Chip';
import moment from "moment-timezone";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import { toast } from "react-hot-toast";
import { copyToClipboard } from "src/utils/misc";
import { API_SERVICE_BACKEND } from "src/config";
import { getSessionStorage } from "src/utils/storage";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import axios from "axios";
import { Seo } from "src/components/seo";
const CustomDomain = () => {
  const email = getSessionStorage("userEmail");
  const [formData, setFormData] = useState({
    domain: "",
  });
  const [trackDomainData, setTrackDomainData] = useState({
    domain: "",
  });
  const [verifyData, setverifyData] = useState({
    domain: "",
    email: ""
  });
  const [verifyDataStatus, setverifyDataStatus] = useState(false);
        const [defaultData, setDefaultData] = useState({});
  
  const [userConfig, setUserConfig] = useState("");
  const [verifyStatus, setVerifyStatus] = useState("");
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [defaultDomainData, setDefaultDomainData] = useState({});
  const [domainError, setDomainError] = useState("");
  const [trackDomainError, setTrackDomainError] = useState("");
  const [domainVerifyError, setDomainVerifyError] = useState("");
  const [resendDomainData, setResendDomainData] = useState();
  const { whiteLableId, getAllWhiteLabelClientConfigurations, whiteLableData, metaTitle, companyName } = useContext(WhiteLabelConfigurationContext);

  const getAllWhiteLabelClients = async () => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getwhiteLabelConfig/${whiteLableId}`
      );
      const data = await response.json();
      const defaultDomainSettings = {
        domain: data?.data?.domain || ""
      };
      setDefaultData(data.data);
      setFormData(defaultDomainSettings);
      setverifyData({
        domain: data?.data?.customEmailConfig?.domain,
        email: data?.data?.customEmailConfig?.email
      })
      setTrackDomainData({
        domain:  data?.data?.metadata?.trackDomain,
      });
      setDefaultDomainData(data?.data);
      if (data.data.customEmailConfig) {
        setUserConfig(data?.data?.customEmailConfig);
        handleGetResendDomainData(data?.data?.customEmailConfig?.domainId)
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllWhiteLabelClients();
  }, [whiteLableId]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const domainRegex = /^(?!https?:\/\/)(?!www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (name === "domain") {
      if (!domainRegex.test(value)) {
        setDomainError("Domain is invalid. It should not start with 'http://', 'https://', 'www.', or contain any path.");
      } else {
        setDomainError(""); 
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleTrackDomainChange = (e) => {
    const { name, value } = e.target;

    if (name == "domain" && (value.startsWith("https://") || value.startsWith("www"))) {
      setTrackDomainError("Domain should not start with 'https://' or 'www'.");
    } else {
      setTrackDomainError(""); // Clear error if validation passes
    }

    setTrackDomainData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeVerify = (e) => {
    const { name, value } = e.target;

    if (name == "domain" && (value.startsWith("https://") || value.startsWith("www"))) {
      setDomainVerifyError("Domain should not start with 'https://' or 'www'.");
    } else {
      setDomainVerifyError("");
    }
    setverifyDataStatus(false)
    setverifyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleReset = () => {
    setFormData({
      domain: defaultDomainData?.domain
    });
  };

  const handleSubmit = async () => {
    const requestData = {
      email: email,
      domain: formData.domain
    };

    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/handleWhiteLabelConfig`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.status === 200) {
        toast.success(response.message ?? "Domain updated successfully!", {
          autoClose: 3000,
        });
        getAllWhiteLabelClients();
        getAllWhiteLabelClientConfigurations(whiteLableId);
      } else {
        toast.error(response.message ?? "Failed to connect", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error submitting SEO data:", error);
    }
  };

  const handleVerify = async (state) => {
    try {
      setVerifyLoading(true);
      const requestData = {
        domain:  state === 3 ? trackDomainData?.domain : defaultDomainData?.domain,
        value: state === 3 ? "track.b2brocket.ai" :  "partners.b2brocket.ai"
      }
      const response = await axios.post(`${API_SERVICE_BACKEND}/verifyDnsRecords`, {
        ...requestData
      });

      if (response?.status == 200) {
        setVerifyStatus("true");
        toast.success(response?.data.message ?? "DNS record is correctly configured", {
          autoClose: 3000,
        })
      }
      else {
        setVerifyStatus("false");
        toast.error(response?.data?.message ?? "DNS record is not correctly configured", {
          autoClose: 3000,
        })
      }
    } catch (error) {
      console.error("Error verifying custom domain:", error);
      setVerifyStatus("false");
      toast.error(error?.response?.data?.message ?? "DNS record is not correctly configured", {
        autoClose: 3000,
      })
    }
    finally {
      setVerifyLoading(false)
    }
  };
  const [verifyTrackLoading ,setVerifyTrackLoading] = useState(false)
  const handleTrackVerify = async () => {
    try {
      setVerifyTrackLoading(true);
      const requestData = {
        domain:   trackDomainData?.domain,
        value: "track.b2brocket.ai"
      }
      const response = await axios.post(`${API_SERVICE_BACKEND}/verifyDnsRecords`, {
        ...requestData
      });

      if (response?.status == 200) {
        setVerifyTrackLoading("true");
        toast.success(response?.data.message ?? "DNS record is correctly configured", {
          autoClose: 3000,
        })
      }
      else {
        setVerifyTrackLoading("false");
        toast.error(response?.data?.message ?? "DNS record is not correctly configured", {
          autoClose: 3000,
        })
      }
    } catch (error) {
      console.error("Error verifying custom domain:", error);
      setVerifyTrackLoading("false");
      toast.error(error?.response?.data?.message ?? "DNS record is not correctly configured", {
        autoClose: 3000,
      })
    }
    finally {
      setVerifyTrackLoading(false)
    }
  };
  const handleVerifyDomain = async () => {
    const { domain } = verifyData;

    if (!domain) {
      toast.error("Please provide a domain to verify.");
      return;
    }

    try {
      const response = await axios.get(
        `${API_SERVICE_BACKEND}/verifyResendDomain`,
        {
          params: {
            domain,
            userEmail: whiteLableData?.email,
          },
        }
      );

      toast.success(response?.data?.message);
      if (userConfig?.domainId) {
        handleGetResendDomainData(userConfig?.domainId)
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.error || error.message || "Something went wrong."
      );
    }
  };

  const handleCreateDomain = async () => {
    const { email, domain } = verifyData;

    if (!domain) {
      toast.error("Domain is required.");
      return;
    }

    if (!email) {
      toast.error("Email is required.");
      return;
    }

    const emailDomain = email?.split("@")[1] || "";

    if (emailDomain !== domain) {
      setDomainVerifyError(
        `The email's domain (${emailDomain}) does not match the specified domain (${domain}).`
      );
    } else {
      setDomainVerifyError("");

      try {
        const requestData = {
          id: defaultDomainData?._id,
          domain: domain,
          email: email
        }
        const response = await fetch(`${API_SERVICE_BACKEND}/createResendDomain`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });
        const data = await response.json();
        if (response.ok) {
          getAllWhiteLabelClients()
          toast.success("Domain added successfully!");
        } else {
          toast.error(data.error);
        }
      } catch (error) {

        toast.error(error.message || "An unexpected error occurred.");
      }
    }
  };

  const handleGetResendDomainData = async (id) => {
    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getResendDomainById/${id}`,
        {
          method: "GET",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setResendDomainData(data?.data);
        setverifyDataStatus(true)
      }
    } catch (error) {
      setverifyDataStatus(false)
      toast.error(`Error: ${error.message || "An unexpected error occurred."}`);
    }
  }

  const handleDeleteDomain = async () => {
    const { domain } = verifyData;

    if (!domain) {
      toast.error("Domain is required.");
      return;
    }

    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/deleteResendDomain?domain=${domain}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        const data = await response.json();
        setResendDomainData();
        setverifyData({
          domain: "",
          email: ""
        });
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    if (resendDomainData) {
      handleVerifyDomain()
    }
  }, [])

  const handleTrackDomainSubmit = async () => {
    const requestData = {
        email: email,
        seo: {
          title: defaultData.seo.title,
          description: defaultData.seo.description,
          tagLine: defaultData.seo.tagLine,
        },
        info: {
          companyName: defaultData.info.companyName,
          logoUrl: defaultData.info.logoUrl,
          favIcon: defaultData.info.favIcon,
        },
        theme: {
          primaryTextColor: defaultData.theme.primaryTextColor,
          secondaryTextColor: defaultData.theme.secondaryTextColor,
          primaryBrandColor: defaultData.theme.primaryBrandColor,
          secondaryBrandColor: defaultData.theme.secondaryBrandColor,
        },
        metadata: {
          should_show_company_name:defaultData.metadata.should_show_company_name,
          supportcenter: defaultData.metadata.supportcenter,
        },
      };
    
      if (trackDomainData.domain) {
        requestData.metadata.trackDomain = trackDomainData.domain;
      }

    try {
      const response = await fetch(
        `${API_SERVICE_BACKEND}/handleWhiteLabelConfig`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      const data = await response.json();

      if (response.status === 200) {
        toast.success(response.message ?? "Track domain updated successfully!", {
          autoClose: 3000,
        });
        getAllWhiteLabelClients();
        getAllWhiteLabelClientConfigurations(whiteLableId);
      } else {
        // Error
        toast.error(response.message ?? "Failed to connect", {
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error submitting Track domain data:", error);
    }
  };

  const pageTitle = `Partner/White Label: Custom Domain - ${metaTitle || companyName}`;

  return (
    <>
      <Seo title={pageTitle} />
      <Box sx={{ width: { xs: "100%", md: "60%" } }}>
        {/* <Box sx={{ display: "flex", alignItems: "center", my: "20px" }}>
        <Typography
          sx={{ fontSize: "32px", fontWeight: 700, color: "#111927" }}
        >
          Custom Domain
        </Typography>
      </Box> */}
        <Grid container spacing={1}>
          <Grid item xs={12}>

            <TextField
              required
              id="domain"
              label="Your Domain or SubDomain"
              variant="outlined"
              fullWidth
              name="domain"
              value={formData?.domain}
              onChange={handleChange}
              error={!!domainError}
              helperText={domainError}
              sx={{ mb: 3 }}
              className="glass-input"
            />
            <Alert severity="warning" className="glass-wrapper">DNS setup required! Add the records below to your DNS provider to complete your custom domain setup.</Alert>
          </Grid>
          {/* Reset Domain */}
          {/* <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: "20px", width: "100%" }}
            onClick={handleReset}
          >
            Reset Domain
          </Button>
        </Grid> */}

          {/* Update Domain */}
          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              disabled={domainError}
              sx={{ marginTop: "20px", width: "100%" }}
              onClick={handleSubmit}
            >
              Update Domain
            </Button>
          </Grid>

          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                marginTop: "10px",
                marginBottom: "10px",
                color: theme => theme.palette.text.primary,
              }}
            >
              Please add the following details to your domain's DNS record and
              click Verify Record to continue
            </Typography>

            <TableContainer
              component={Paper}
              sx={{ border: "1px solid rgba(224, 224, 229, 1)" }}
              className="glass-wrapper"
            >
              <Table aria-label="simple table">
                <TableHead
                  sx={{ borderBottom: "1px solid rgba(224, 224, 229, 1)" }}
                  className="glass-table-header"
                >
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>CNAME Record</TableCell>
                    <TableCell>@ or subdomain</TableCell>
                    <TableCell sx={{ display: "flex" }}>
                      partners.b2brocket.ai
                      <ContentCopyRoundedIcon
                        fontSize="small"
                        sx={{
                          cursor: "pointer",
                          color: "#7E82A5",
                          marginLeft: "10px",
                          "&:hover": {
                            color: "#5761FE",
                          },
                        }}
                        onClick={async () => {
                          if (await copyToClipboard("partners.b2brocket.ai")) {
                            toast.success("Copied to clipboard");
                          } else {
                            toast.error("Something went wrong");
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                marginTop: "10px",
                color: theme => theme.palette.text.primary,
              }}
            >
              Note: It can take upto 24 hours for yours DNS records to update
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <LoadingButton
                variant="outlined"
                disabled={!defaultDomainData?.domain}
                onClick={handleVerify}
                sx={{
                  borderColor:
                    verifyStatus === "true"
                      ? "green"
                      : verifyStatus === "false"
                        ? "red"
                        : "#6792ea",
                  "&.Mui-disabled": {
                    borderColor:
                      verifyStatus === "true"
                        ? "green"
                        : verifyStatus === "false"
                          ? "red"
                          : "#6792ea",
                    color:
                      verifyStatus === "true"
                        ? "green"
                        : verifyStatus === "false"
                          ? "red"
                          : "#6792ea",
                  },
                }}
                className="glass-button-secondary"
              >
                {verifyLoading ? (
                  <CircularProgress />
                ) : verifyStatus === "true" ? (
                  <CheckIcon style={{ color: "green" }} />
                ) : verifyStatus === "false" ? (
                  <ErrorIcon style={{ color: "red" }} />
                ) : (
                  "Verify Record"
                )}
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
        {/* <Grid container spacing={1} sx={{mt: "15px"}} >
        <Grid item xs={12}>
        <Box sx={{ display: "flex", alignItems: "center", my: "20px" }}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: 700, color: theme => theme.palette.text.primary }}
          >
            Track Domain
          </Typography>
        </Box>
        </Grid>
          <Grid item xs={12}>

            <TextField
              required
              id="domain"
              label="Your Track Domain"
              variant="outlined"
              fullWidth
              name="domain"
              value={trackDomainData?.domain}
              onChange={handleTrackDomainChange}
              error={!!trackDomainError}
              helperText={trackDomainError}
              sx={{ mb: 3 }}
              className="glass-input"
            />
            <Alert severity="warning" className="glass-wrapper">DNS setup required! Add the records below to your DNS provider to complete your custom track domain setup.</Alert>
          </Grid>

          <Grid item xs={6}>
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: "20px", width: "100%" }}
              onClick={handleTrackDomainSubmit}
            >
              Update Track Domain
            </Button>
          </Grid>
          
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "700",
                marginTop: "10px",
                marginBottom: "10px",
                color: theme => theme.palette.text.primary,
              }}
            >
              Please add the following details to your domain's DNS record and
              click Verify Record to continue
            </Typography>

            <TableContainer
              component={Paper}
              sx={{ border: "1px solid rgba(224, 224, 229, 1)" }}
              className="glass-wrapper"
            >
              <Table aria-label="simple table">
                <TableHead
                  sx={{ borderBottom: "1px solid rgba(224, 224, 229, 1)" }}
                  className="glass-table-header"
                >
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>CNAME Record</TableCell>
                    <TableCell>@ or subdomain</TableCell>
                    <TableCell sx={{ display: "flex" }}>
                      track.b2brocket.ai
                      <ContentCopyRoundedIcon
                        fontSize="small"
                        sx={{
                          cursor: "pointer",
                          color: "#7E82A5",
                          marginLeft: "10px",
                          "&:hover": {
                            color: "#5761FE",
                          },
                        }}
                        onClick={async () => {
                          if (await copyToClipboard("track.b2brocket.ai")) {
                            toast.success("Copied to clipboard");
                          } else {
                            toast.error("Something went wrong");
                          }
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                marginTop: "10px",
                color: theme => theme.palette.text.primary,
              }}
            >
              Note: It can take upto 24 hours for yours DNS records to update
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: "20px",
              }}
            >
              <LoadingButton
                variant="outlined"
                disabled={!trackDomainData?.domain}
                onClick={()=> handleTrackVerify()}
                sx={{
                  borderColor:
                    verifyTrackLoading === "true"
                      ? "green"
                      : verifyTrackLoading === "false"
                        ? "red"
                        : "#6792ea",
                  "&.Mui-disabled": {
                    borderColor:
                      verifyTrackLoading === "true"
                        ? "green"
                        : verifyTrackLoading === "false"
                          ? "red"
                          : "#6792ea",
                    color:
                      verifyTrackLoading === "true"
                        ? "green"
                        : verifyTrackLoading === "false"
                          ? "red"
                          : "#6792ea",
                  },
                }}
                className="glass-button-secondary"
              >
                {verifyTrackLoading ? (
                  <CircularProgress />
                ) : verifyTrackLoading === "true" ? (
                  <CheckIcon style={{ color: "green" }} />
                ) : verifyTrackLoading === "false" ? (
                  <ErrorIcon style={{ color: "red" }} />
                ) : (
                  "Verify Record"
                )}
              </LoadingButton>
            </div>
          </Grid>
        </Grid> */}
        <Box sx={{ display: "flex", alignItems: "center", my: "20px" }}>
          <Typography
            sx={{ fontSize: "32px", fontWeight: 700, color: theme => theme.palette.text.primary }}
          >
            Domain & Email Verification
          </Typography>
        </Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              required
              id="domain"
              label="Your Domain"
              variant="outlined"
              fullWidth
              name="domain"
              value={verifyData?.domain}
              onChange={handleChangeVerify}
              error={!!domainVerifyError}
              helperText={domainVerifyError}
              sx={{ mb: 3 }}
              className="glass-input"
            />
          </Grid>
          <Grid item xs={12}>

            <TextField
              required
              id="email"
              label="Your Email"
              variant="outlined"
              fullWidth
              name="email"
              value={verifyData?.email}
              onChange={handleChangeVerify}
              sx={{ mb: 3 }}
              className="glass-input"
            />
          </Grid>
          <Grid item xs={2}>
            {
              !verifyDataStatus ?
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%", mb: 2 }}
                  onClick={handleCreateDomain}
                >
                  Verify
                </Button>
                :
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: "100%", mb: 2 }}
                  onClick={handleVerifyDomain}
                >
                  Verify
                </Button>
            }
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              sx={{ width: "100%", mb: 2 }}
              onClick={handleDeleteDomain}
              className="glass-button-warning"
            >
              Delete
            </Button>
          </Grid>
          {resendDomainData?.records?.length > 0 && (
            <>
              <Grid item xs={12}>
                <TableContainer
                  component={Paper}
                  sx={{ border: "1px solid rgba(224, 224, 229, 1)" }}
                  className="glass-wrapper"
                >
                  <Table aria-label="simple table">
                    <TableHead
                      sx={{ borderBottom: "1px solid rgba(224, 224, 229, 1)" }}
                      className="glass-table-header"
                    >
                      <TableRow>
                        <TableCell>CREATED</TableCell>
                        <TableCell>STATUS</TableCell>
                        <TableCell>REGION</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell>{moment(resendDomainData?.created_at).fromNow()}</TableCell>
                        <TableCell>
                          <span
                            style={{
                              backgroundColor: resendDomainData?.status === "verified" ? "green" : resendDomainData?.status === "pending" ? "orange" : "red",
                              color: resendDomainData?.status ? 'white' : "black",
                              padding: '4px 8px',
                              borderRadius: '6px',
                              display: 'inline-block',
                            }}
                          >
                            {resendDomainData?.status.charAt(0).toUpperCase() + resendDomainData?.status.slice(1) || "-"}
                          </span>
                        </TableCell>
                        <TableCell>USA</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: "1px solid rgba(224, 224, 229, 1)",
                    padding: "10px",
                    borderRadius: "8px"
                  }}
                  className="glass-wrapper"
                >
                  <Box sx={{ display: "flex", alignItems: "center", mb: "20px" }}>
                    <Typography
                      sx={{ fontSize: "24px", fontWeight: 700, color: theme => theme.palette.text.primary }}
                    >
                      DNS Records
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", my: "20px" }}>
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: 700, color: theme => theme.palette.text.primary }}
                    >
                      DKIM and SPF <span className="glass-chip-warning" style={{ fontSize: "12px", border: "1px solid gray", padding: "3px 7px", marginLeft: "10px", color: "gray", borderRadius: "6px" }}>Required</span>
                    </Typography>
                  </Box>
                  <TableContainer
                    component={Paper}
                    sx={{ border: "1px solid rgba(224, 224, 229, 1)", marginBottom: "10px" }}
                    className="glass-wrapper-50"
                  >
                    <Table aria-label="simple table">
                      <TableHead
                        sx={{ borderBottom: "1px solid rgba(224, 224, 229, 1)" }}
                        className="glass-table-header"
                      >
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell>Host / Name</TableCell>
                          <TableCell>Value</TableCell>
                          <TableCell>Priority</TableCell>
                          <TableCell>TTL</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {resendDomainData?.records?.map((record, index) => (
                          <TableRow
                            key={index}
                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          >
                            <TableCell>{record?.type}</TableCell>
                            <TableCell>{record?.name}
                              <ContentCopyRoundedIcon
                                fontSize="12px"
                                sx={{
                                  cursor: "pointer",
                                  color: "#7E82A5",
                                  marginLeft: "10px",
                                  "&:hover": {
                                    color: "#5761FE",
                                  },
                                }}
                                onClick={async () => {
                                  if (await copyToClipboard(record?.name)) {
                                    toast.success("Copied to clipboard");
                                  } else {
                                    toast.error("Something went wrong");
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              {record?.value.slice(0, 22)}...
                              <ContentCopyRoundedIcon
                                fontSize="12px"
                                sx={{
                                  cursor: "pointer",
                                  color: "#7E82A5",
                                  marginLeft: "10px",
                                  "&:hover": {
                                    color: "#5761FE",
                                  },
                                }}
                                onClick={async () => {
                                  if (await copyToClipboard(record?.value)) {
                                    toast.success("Copied to clipboard");
                                  } else {
                                    toast.error("Something went wrong");
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell>{record?.priority || ""}</TableCell>
                            <TableCell>{record?.ttl}</TableCell>
                            <TableCell>
                              <span
                                style={{
                                  backgroundColor: record.status === "pending" ? "orange" : record.status === "verified" ? "green" : 'red',
                                  color: "white",
                                  padding: "4px 8px",
                                  borderRadius: "6px",
                                  display: "inline-block",
                                }}
                              >
                                {record?.status.charAt(0).toUpperCase() + record?.status.slice(1)}
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Box sx={{ display: "flex", alignItems: "center", my: "20px" }}>
                    <Typography
                      sx={{ fontSize: "16px", fontWeight: 700, color: theme => theme.palette.text.primary }}
                    >
                      DMARC <span className="glass-chip-success" style={{ fontSize: "12px", border: "1px solid gray", padding: "3px 7px", marginLeft: "10px", color: "gray", borderRadius: "6px" }}>Recommended</span>
                    </Typography>
                  </Box>
                  <TableContainer
                    component={Paper}
                    sx={{ border: "1px solid rgba(224, 224, 229, 1)", marginBottom: "25px" }}
                    className="glass-wrapper-50"
                  >
                    <Table aria-label="simple table">
                      <TableHead
                        sx={{ borderBottom: "1px solid rgba(224, 224, 229, 1)" }}
                        className="glass-table-header"
                      >
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell>Host / Name</TableCell>
                          <TableCell>Value</TableCell>
                          <TableCell>TTL</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell>TXT</TableCell>
                          <TableCell>_dmarc
                            <ContentCopyRoundedIcon
                              fontSize="12px"
                              sx={{
                                cursor: "pointer",
                                color: "#7E82A5",
                                marginLeft: "10px",
                                "&:hover": {
                                  color: "#5761FE",
                                },
                              }}
                              onClick={async () => {
                                if (await copyToClipboard("_dmarc")) {
                                  toast.success("Copied to clipboard");
                                } else {
                                  toast.error("Something went wrong");
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>v=DMARC1; p=none;
                            <ContentCopyRoundedIcon
                              fontSize="12px"
                              sx={{
                                cursor: "pointer",
                                color: "#7E82A5",
                                marginLeft: "10px",
                                "&:hover": {
                                  color: "#5761FE",
                                },
                              }}
                              onClick={async () => {
                                if (await copyToClipboard("v=DMARC1; p=none;")) {
                                  toast.success("Copied to clipboard");
                                } else {
                                  toast.error("Something went wrong");
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>Auto</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>

              </Grid>
            </>
          )}
        </Grid>

      </Box>
    </>
  );
};

export default CustomDomain;
