import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
import isEqual from "lodash.isequal";
import { WhiteLabelConfigurationContext } from "./ClientConfiguration";
import { API_SERVICE_BACKEND } from "src/config";

const STORAGE_KEY = "app.settings";

const restoreSettings = () => {
  let value = null;

  try {
    const restored = window.sessionStorage.getItem(STORAGE_KEY);

    if (restored) {
      value = JSON.parse(restored);
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return value;
};

const deleteSettings = () => {
  try {
    window.sessionStorage.removeItem(STORAGE_KEY);
  } catch (err) {
    console.error(err);
  }
};

const storeSettings = (value) => {
  try {
    window.sessionStorage.setItem(STORAGE_KEY, JSON.stringify(value));
  } catch (err) {
    console.error(err);
  }
};

const initialSettings = {
  colorPreset: "blue",
  contrast: "normal",
  direction: "ltr",
  layout: "vertical",
  navColor: "evident",
  paletteMode: localStorage.getItem("isNewInterfaceEnabled") === "true" ? "dark" : "light",
  responsiveFontSizes: true,
  stretch: false,
};

const initialState = {
  ...initialSettings,
  isInitialized: false,
  openDrawer: false,
};

export const SettingsContext = createContext({
  ...initialState,
  handleDrawerClose: () => {},
  handleDrawerOpen: () => {},
  handleReset: () => {},
  handleUpdate: () => {},
  isCustom: false,
});

export const SettingsProvider = (props) => {
  const { children } = props;
  const [state, setState] = useState(initialState);
  const newInterfaceValue = localStorage.getItem("isNewInterfaceEnabled") === "true";
  const [isNewInterfaceEnabled, setIsNewInterfaceEnabled] = useState(newInterfaceValue || false);

  // Get values from WhiteLabelConfigurationContext
  const {
    primaryBrandColor,
    secondaryBrandColor,
    primaryTextColor,
    secondaryTextColor,
  } = useContext(WhiteLabelConfigurationContext);

  useEffect(() => {
    const restored = restoreSettings();

    if (restored) {
      setState((prevState) => ({
        ...prevState,
        ...restored,
        isInitialized: true,
      }));
    }
  }, []);

  const handleReset = useCallback(() => {
    deleteSettings();
    setState((prevState) => ({
      ...prevState,
      ...initialSettings,
    }));
  }, []);

  const handleUpdate = useCallback((settings) => {
    setState((prevState) => {
      storeSettings({
        colorPreset: prevState.colorPreset,
        contrast: prevState.contrast,
        direction: prevState.direction,
        layout: prevState.layout,
        navColor: prevState.navColor,
        paletteMode: prevState.paletteMode,
        responsiveFontSizes: prevState.responsiveFontSizes,
        stretch: prevState.stretch,
        ...settings,
      });

      return {
        ...prevState,
        ...settings,
      };
    });
  }, []);

  const handleDrawerOpen = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      openDrawer: true,
    }));
  }, []);

  const handleDrawerClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      openDrawer: false,
    }));
  }, []);

  const isCustom = useMemo(() => {
    return !isEqual(initialSettings, {
      colorPreset: state.colorPreset,
      contrast: state.contrast,
      direction: state.direction,
      layout: state.layout,
      navColor: state.navColor,
      paletteMode: state.paletteMode,
      responsiveFontSizes: state.responsiveFontSizes,
      stretch: state.stretch,
    });
  }, [state]);

  const getUserInterfaceInfo = async () => {
    try {
      const email = sessionStorage.getItem("userEmail");
      const isNewInterfaceEnabledLocal = localStorage.getItem("isNewInterfaceEnabled") === "true"
      const response = await fetch(
        `${API_SERVICE_BACKEND}/getuserdetail/${email}`,
        {
          method: "GET",
        }
      );

      const data = await response.json();
      const newInterfaceEnabled = email ? Boolean(data?.data?.newDesignInterface) : isNewInterfaceEnabledLocal;
      setIsNewInterfaceEnabled(newInterfaceEnabled);
      localStorage.setItem("isNewInterfaceEnabled", JSON.stringify(newInterfaceEnabled));

    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserInterfaceInfo();
  }, []);

  return (
    <SettingsContext.Provider
      value={{
        ...state,
        handleDrawerClose,
        handleDrawerOpen,
        handleReset,
        handleUpdate,
        isCustom,
        primaryBrandColor,
        secondaryBrandColor, 
        primaryTextColor,
        secondaryTextColor,
        isNewInterfaceEnabled,
        getUserInterfaceInfo
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;
