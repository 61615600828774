import React, { useCallback, useContext, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Link,
  Stack,
  TextField,
  Typography,
  FormHelperText,
  IconButton,
  Checkbox,
  useTheme,
} from "@mui/material";
import { Seo } from "src/components/seo";
import { useNavigate } from "react-router";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useAuth } from "../../../hooks/use-auth";
import { useMounted } from "../../../hooks/use-mounted";
import { usePageView } from "../../../hooks/use-page-view";
import { useSearchParams } from "../../../hooks/use-search-params";
import { paths } from "../../../paths";

import { API_SERVICE_BACKEND, API_SERVICE_BACKEND_2 } from "../../../config";

import axios from "axios";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { getUserInfo } from "src/pages/dashboard/account";
import GoogleIcon from "../../../components/logos/SocialIcon.svg";

import { v4 as uuidv4 } from "uuid";
import { useRouter } from "src/hooks/use-router";
import {
  clearSessionStorage,
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from "src/utils/storage";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import { SplashScreen } from "src/components/splash-screen";
const initialValues = {
  email: "",
  password: "",
  submit: null,
};

export async function generateHMAC(message, key) {
  const encoder = new TextEncoder();
  const keyData = encoder.encode(key);
  const messageData = encoder.encode(message);

  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    keyData,
    { name: "HMAC", hash: { name: "SHA-256" } },
    false,
    ["sign"]
  );

  const signature = await crypto.subtle.sign("HMAC", cryptoKey, messageData);
  return Array.from(new Uint8Array(signature))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}
const validationSchema = Yup.object({
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  password: Yup.string().max(255).required("Password is required"),
});
const Page = () => {
  const navigate = useNavigate();
  const isMounted = useMounted();
  const router = useRouter();
  const {
    issuer,
    updateAuthState,
    signInWithEmailAndPassword,
    signInWithGoogle,
  } = useAuth();

  const searchParams = useSearchParams();
  const returnTo = searchParams.get("returnTo");
  const theme=useTheme();

  const status =
    searchParams.get("status") || getSessionStorage("magic_link_status");

  const [loading, setloading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const { isLogin } = useContext(WhiteLabelConfigurationContext);

  // Snackbar states
  const [Baropen, setBarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  // eslint-disable-next-line no-unused-expressions

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      values.email = values.email.trim().toLowerCase();
      setloading(true);
      try {
        clearSessionStorage();
        await signInWithEmailAndPassword(values.email, values.password);
        const response = await fetch(
          `${API_SERVICE_BACKEND}/getuserdetail/${values.email}?saveLogin=true`
        );
        const userData = await response.json();
        console.log({ userData });

        const hash = await generateHMAC(
          userData.data.email,
          process.env.REACT_APP_INTERCOM_HASH_KEY
        );

        if (userData.data.accountStatus === 0) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: "Account Disabled" });
          helpers.setSubmitting(false);
          setloading(false);
        } else {
          if (isMounted()) {
            window.Intercom("boot", {
              app_id: "qaecvjwi",
            });
            window.intercomSettings = {
              app_id: "qaecvjwi",
              name: userData.data.name,
              email: userData.data.email,
              user_hash: hash,
            };
            console.log(userData, "<<<userData");
            if (userData?.data?.freeSubscription === true) {
              // window.location.href = returnTo || paths.dashboard.campaigns;
              setSessionStorage("isNewUser", false)
              window.location.href = returnTo || paths.authDemo.workspace;
            } else if (!userData?.data?.subscribedUsers) {
              window.location.href = paths.authDemo.workspace
              //window.location.href = paths.authDemo.pricingPage;
            } else {
             // window.location.href = returnTo || paths.dashboard.campaigns;
             setSessionStorage("isNewUser", false)
             window.location.href = returnTo || paths.authDemo.workspace;
            }
            setloading(false);
          }
        }
      } catch (err) {
        console.error(err);
        setloading(false);
        if (isMounted()) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }
    },
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const handleGoogleClick = useCallback(async () => {
    clearSessionStorage();
    try {
      const data = await signInWithGoogle();
      if (data) {
        var organization_id = String(`${Date.now()}_${uuidv4()}`);
        var dataSend = {
          name: data?.displayName,
          companyName: data?.companyName || "No Company Name",
          email: data?.email,
          phone: data?.phoneNumber || "",
          organization_id: organization_id,
          plan: "No Plan",
          role: "admin",
          campaginManager: "None",
          campaginManagerEmail: "none@gmail.com",
          accountStatus: 1,
          profile_image: data?.photoURL || null,
        };

        const resGoogleLog = await fetch(
          `${API_SERVICE_BACKEND}/addregistereduser`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataSend),
          }
        );

        if (resGoogleLog.status === 200 || resGoogleLog.status === 201) {
          setloading(false);
          if (isMounted()) {
            setloading(false);
            // returnTo could be an absolute path
            const userInfo = await getUserInfo(data?.email);

            if(!userInfo)
            {
              openSnackbar("Your account is not active. please contact to administratior.", "error");
              return false;
            }
            
            setSessionStorage("photoUrl", userInfo.profile_image);
            setSessionStorage("organization_id", userInfo?.organization_id);
            setSessionStorage("userEmail", data?.email);
            setSessionStorage(
              "companyName",
              data?.companyName || "No Company Name"
            );
            setSessionStorage("fullName", data?.displayName);
            setSessionStorage(
              "SubscriptionStartDate",
              userInfo?.userSubscription?.subscriptions?.base?.startDate
            );
            setSessionStorage(
              "SubscriptionEndDate",
              userInfo?.userSubscription?.subscriptions?.base?.endDate
            );
            setSessionStorage(
              "SubscriptionType",
              userInfo?.userSubscription?.subscriptions?.base?.planId
            );
            if (userInfo?.userSubscription) {
              setSessionStorage("Paid", true);
            } else {
              setSessionStorage("Paid", false);
            }
            const hash = await generateHMAC(
              getSessionStorage("userEmail"),
              process.env.REACT_APP_INTERCOM_HASH_KEY
            );
            window.Intercom("boot", {
              app_id: "qaecvjwi",
              name: getSessionStorage("fullName"),
              email: getSessionStorage("userEmail"),
              user_hash: hash,
            });

            const compName = userInfo?.companyName;
            if (compName === "No Company Name") {
              window.location.href = returnTo || paths.authDemo.workspace;
            } else {
              window.location.href = returnTo || paths.authDemo.workspace;
            }
          }
        } else {
          setloading(false);
        }
      }
      // if (isMounted()) {
      //   // returnTo could be an absolute path
      //   // window.location.href = returnTo || paths.dashboard.index;
      // }
    } catch (err) {
      console.error(err);
    }
  }, [signInWithGoogle, isMounted, returnTo]);

  const openSnackbar = (message, severity = "success") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setBarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setBarOpen(false);
  };

  useEffect(() => {
    if(status === 'in-active')
      {
        openSnackbar("Your account is not active. please contact to administratior.", "error");
        clearSessionStorage();
      }
  }, [status])

  const email = getSessionStorage("userEmail");
  const ownerEmail = getSessionStorage("ownerEmail");

  useEffect(() => {
    if (email && !ownerEmail) {
      navigate("/dashboard/campaigns");
    }
  }, [email]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  usePageView();
  const getDomainFromLocation = () => {
    const domain = window.location.hostname;
    return domain;
  };
  
  const domain = getDomainFromLocation();
  return (
    <>
      <Seo title="Login" />
      <Snackbar
        open={Baropen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {isLogin ? (
        <>
          <SplashScreen />
        </>
      ) : (
        <div>
          <Stack sx={{ mb: 3 }} spacing={1}>
            <Typography
              style={{ fontSize: "32px", fontWeight: "700", color: theme.palette.text.primary }}
            >
              Log In
            </Typography>
            {(domain === "app.b2brocket.ai" || domain === "localhost") &&
              <Typography color="text.secondary" variant="body2">
                Don&apos;t have an account? &nbsp;
                <Link
                  href="/auth-main/register/v2"
                  underline="hover"
                  variant="subtitle2"
                  style={{ color: theme.palette.primary.main }}
                >
                  Register
                </Link>
              </Typography>
            }
          </Stack >
          <form noValidate onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
              <Stack spacing={1}>
                <Typography style={{ fontSize: "14px",color: theme.palette.text.primary}}>Email</Typography>
                <TextField
                  error={!!(formik.touched.email && formik.errors.email)}
                  variant="outlined"
                  name="email"
                  placeholder="Enter your email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className="glass-input"
                />
                {formik.touched.email && formik.errors.email ? (
                  <div
                    style={{
                      color: "red",
                      marginTop: "0px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <ErrorOutlineIcon fontSize="14px" />
                    <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                      {formik.errors.email}
                    </p>
                  </div>
                ) : null}
              </Stack>
              <Stack spacing={1}>
                <Typography style={{ fontSize: "14px",color: theme.palette.text.primary}}>Password</Typography>
                <TextField
                  error={!!(formik.touched.password && formik.errors.password)}
                  variant="outlined"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your Password"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.password}
                  className="glass-input"
                />
                {formik.touched.password && formik.errors.password ? (
                  <div
                    style={{
                      color: "red",
                      marginTop: "0px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <ErrorOutlineIcon fontSize="14px" />
                    <p style={{ fontSize: "12px", marginLeft: "5px" }}>
                      {formik.errors.password}
                    </p>
                  </div>
                ) : null}
              </Stack>
            </Stack>
            {formik.errors.submit && (
              <FormHelperText error sx={{ mt: 3 }}>
                {formik.errors.submit ===
                  "Firebase: Error (auth/user-not-found)." ? (
                  <>No user found with this email address</>
                ) : formik.errors.submit ===
                  "Firebase: Error (auth/wrong-password)." ? (
                  <>Wrong Password</>
                ) : formik.errors.submit === "Account Disabled" ? (
                  <>Your account is disabled please contact the support</>
                ) : (
                  <>Something went wrong</>
                )}
              </FormHelperText>
            )}
            <Box
              sx={{
                mt: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: "14px" ,color: theme.palette.text.primary }}>
                <Checkbox /> Remember me
              </div>
              <Link
                href="/auth-main/reset-password/v2"
                underline="hover"
                variant="subtitle2"
                style={{ color: theme.palette.primary.main, fontSize: "14px" }}
                className={(domain === "app.b2brocket.ai" || domain === "localhost") ? "" : "glass-link"}
              >
                Forgot password?
              </Link>
            </Box>
            <Button
              fullWidth
              sx={{ mt: 3 }}
              size="medium"
              type="submit"
              disabled={loading}
              variant="contained"
              style={{ backgroundColor: theme.palette.primary.main, fontSize: "14px" }}
            >
              Sign in
            </Button>
            {(domain === "app.b2brocket.ai" || domain === "localhost") &&
              <Button
                fullWidth
                type="button"
                disabled={loading}
                variant="outlined"
                style={{
                  marginTop: "15px",
                  textTransform: "none",
                  padding: "0px",
                }}
                onClick={handleGoogleClick}
                className="glass-button-secondary-50"
              >
                <IconButton
                  color="inherit"
                  aria-label="Sign up with Google"
                  component="span"
                >
                  <img src={GoogleIcon} height="22px" />
                </IconButton>
                <Typography style={{ color: theme.palette.text.secondary, fontSize: "14px" }}>
                  Sign in with Google
                </Typography>
              </Button>
            }
          </form>
        </div >
      )}
    </>
  );
};
export default Page;
