import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Stack,
  Skeleton,
  useTheme,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

import { API_SERVICE_BACKEND } from "src/config";

export default function Invoices({ details }) {
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [seeData, setSeeData] = useState(false);
  const theme=useTheme();

  const toggleSeeData = () => {
    setSeeData(!seeData);
  };

  const getInvoiceData = async (limit) => {
    try {
      setIsLoading(true);
      const res = await axios.post(`${API_SERVICE_BACKEND}/getInvoice`, {
        customerId: details.customerId,
        limit: limit || 5,
      });
      if (res) {
        setInvoiceData(res.data);
      }
      toggleSeeData();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (details) {
      getInvoiceData();
    }
  }, [details]);

  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  }

  return (
    <>
      {isLoading ? (
        <Stack spacing={2}>
          <Skeleton variant="text" width={150} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
          <Skeleton variant="rectangular" height={40} />
        </Stack>
      ) : (
        details &&
        details.customerId && (
          <Stack spacing={2}>
            <Typography variant="h6" component="h2" sx={{ paddingTop: 2, color:theme.palette.text.primary }}>
              Invoice List
            </Typography>
            <TableContainer component={Paper} elevation={2} className="glass-wrapper-50">
              <Table size="small">
                <TableHead className="glass-table-header">
                  <TableRow >
                    <TableCell width="10%" sx={{ color: `${theme.palette.text.primary} !important` }}>Invoice</TableCell>
                    <TableCell align="right" width="20%" sx={{ color: `${theme.palette.text.primary} !important` }}>
                      Amount Due
                    </TableCell>
                    <TableCell align="right" width="20%" sx={{ color: `${theme.palette.text.primary} !important` }}>
                      Amount Paid
                    </TableCell>
                    <TableCell align="right" width="30%" sx={{ color: `${theme.palette.text.primary} !important` }}>
                      Invoice Date
                    </TableCell>
                    <TableCell align="center" width="40%" sx={{ color: `${theme.palette.text.primary} !important` }}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceData.length ? (
                    invoiceData.map((e) => (
                      <TableRow key={e.id}>
                        <TableCell>
                          <IconButton
                            component="a"
                            href={`${e?.invoice_pdf}`}
                            color="primary"
                            aria-label="view invoice PDF"
                            target="_blank"
                          >
                            <img
                              src="/assets/pdf.svg"
                              alt="View Invoice"
                              width={30}
                              height={30}
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1" fontWeight="bold" sx={{color:theme.palette.text.primary}}>
                            {currencyFormatter.format(
                              Math.abs(e?.amount_due / 100)
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1" fontWeight="bold" sx={{color:theme.palette.text.primary}}>
                            {currencyFormatter.format(
                              Math.abs(e?.amount_paid / 100)
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="body1" sx={{color:theme.palette.text.primary}}>
                            {formatTimestamp(e?.created)}
                            {/* {formatTimestamp(e?.lines?.data[0]?.period?.start)} */}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <IconButton
                              component="a"
                              href={`${e?.invoice_pdf}`}
                              aria-label="download invoice"
                              target="_blank"
                            >
                              <DownloadIcon sx={{color:theme.palette.text.primary}} />
                            </IconButton>
                            <Button
                              variant="contained"
                              href={`${e?.hosted_invoice_url}`}
                              sx={{ marginLeft: 1 }}
                              target="_blank"
                              style={{
                                
                                fontSize: "14px",
                              }}
                            >
                              View
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                            textAlign: "center",
                            paddingY: "10px",
                            color:theme.palette.text.primary
                          }}
                        >
                          No Invoices Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {invoiceData.length ? (
              <Box mt={3} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  
                  onClick={() => {
                    getInvoiceData(seeData ? 100 : 5);
                  }}
                >
                  {seeData ? "See More" : "See Less"}
                </Button>
              </Box>
            ) : (
              ""
            )}
          </Stack>
        )
      )}
    </>
  );
}


