import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { API_SERVICE_BACKEND } from "src/config";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";

const PaymentConfirmation = ({
  setOpen,
  open,
  setSelectedPlans,
  getAllWhiteLabelClients,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { whiteLableData } = useContext(WhiteLabelConfigurationContext);
  const handleClose = () => {
    setOpen({ open: false, data: null, planId: null });
    getAllWhiteLabelClients();
    setSelectedPlans("");
    // setOpenModal(false);
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const requestData = {
        userEmail: open.data.email,
        planId:open.planId,
        whiteLabelUserId: whiteLableData?._id,
      };

      const url = `${API_SERVICE_BACKEND}/plans/assign-plan`;
      const method = "POST";
      const response = await axios.post(url , requestData);
        getAllWhiteLabelClients();
        toast.success(response?.data?.message);
        setIsLoading(false);
      handleClose();
    } catch (error) {
      setIsLoading(false);
      handleClose();
      toast.error(error?.response?.data?.error);
      setIsLoading(false);
      if(error?.response?.data?.isPaymentError) {
        setOpenModal(true)
        setIsLoading(false);
      }
    }
  };
  const handleNewPaymentMethod = async () => {
    try {
      const baseURL = `${API_SERVICE_BACKEND}/createCustomerPortalSession?userEmail=${sessionStorage.getItem(
        "userEmail"
      )}`;
      const response = await axios.get(baseURL);
      setOpenModal(false)
      if (response.data.url) {
        window.open(response.data.url, "_blank");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error || error?.message);
      console.log(error);
    }
  };
  return (
    <>
    <Dialog open={open.open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>Payment Confirm</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={handleClose}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography>
          To proceed with the selected plan, payment is required. Please confirm
          if you want to continue with the payment.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button
          onClick={() => handleConfirm()}
          color="primary"
          variant="contained"
        >
          {isLoading && <CircularProgress size={20} mr={1} />}
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
     <Dialog open={openModal} onClose={handleClose} maxWidth="xs" fullWidth>
     <DialogTitle>Payment Confirm</DialogTitle>
     <Box position="absolute" top={0} right={0}>
       <IconButton onClick={handleClose}>
         <Close />
       </IconButton>
     </Box>
     <DialogContent>
       <Typography>
       To complete the payment process, you need to add a payment method. Please
    confirm if you want to continue with the payment 
       </Typography>
     </DialogContent>
     <DialogActions>
       <Button onClick={handleClose} color="primary" variant="contained">
         Cancel
       </Button>
       <Button
         onClick={() => handleNewPaymentMethod()}
         color="primary"
         variant="contained"
       >
    
      Add Payment Method
       </Button>
     </DialogActions>
   </Dialog>
    </>
  );
};

export default PaymentConfirmation;
