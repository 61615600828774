import "../style.css";
import {
  Stack,
  Divider,
  Typography,
  TextField,
  CardHeader,
  CardActions,
  Button,
  CircularProgress,
  Box,
  Avatar, Card,
  Skeleton,
  Tooltip
} from "@mui/material";
import StepItem from "./StepItem";
import SequenceStepEmailContent from "./SequenceStepEmailContent";
import { convertSecondsToUnitTime } from "src/utils/time";
import SequenceStepLinkedinContent from "./SequenceStepLinkedinContent";
import { useEffect, useState } from "react";
import { API_SERVICE_BACKEND } from "src/config";
import axios from "axios";
import { TaskAlt } from "@mui/icons-material";

export const LinkedInConnectionRequest = ({
  providers, linkedinURL, template, withMessage, content
}) => {
  const linkedin = providers?.linkedin;
  const [linkedinProfile, setLinkedinProfile] = useState({});
  const [linkedinData, setLinkedinData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const accountId = linkedin?.accountId
  const getLinkedinProfile = async () => {
    try{
      const publicIdentifier = linkedinURL.split("/").filter(Boolean).pop();
      const res = await axios.get(
        `${API_SERVICE_BACKEND}/unipile/linkedin/getUserProfile?accountId=${accountId}&identifier=${publicIdentifier}`
      );
      if (!res.data) return;
      setLinkedinProfile(res.data);
      setIsFetching(false);
    }catch(err){
      setIsFetching(false);
      console.log(err)
    }
  };
  const handleConnect = () => {
    setIsLoading(true);
    setTimeout(() => {
      setLinkedinProfile({ ...linkedinProfile, invitation: { status: "PENDING" } });
      setIsLoading(false);
    }, 2000);
  }
  const handleCancelInvitation = async (invitationId) => {
    setIsLoading(true);
    const res = await axios.post(
      `${API_SERVICE_BACKEND}/unipile/linkedin/cancelInvitation`,
      {
        accountId: accountId,
        providerId: linkedinProfile.provider_id,
      }
    );

    if (!res.data) return;
    setLinkedinProfile({ ...linkedinProfile, invitation: { status: "WITHDRAW" } });
    setIsLoading(false);
  };
  const fetchEmailContent = async () => {
    const res = await axios.get(
      `${API_SERVICE_BACKEND}/getEmailSentInfo?id=${content}`
    );
    if (!res.data) return;
    setLinkedinData({
      clicked: res.data.clicked,
      opened: res.data.opened,
      email: res.data.email,
      from: res.data.from,
      to: res.data.to,
      sentAt: res.data.createdAt,
    });
    // sortedClicks = res?.data?.clicked?.reverse();
    // sortedOpens = res?.data?.opened?.reverse();
  };
  useEffect(() => {
    if (linkedinURL && accountId) {
      if (content) {
        fetchEmailContent();
      }
      setIsFetching(true);
      getLinkedinProfile();
    }
  }
    , [linkedinURL]);


  return (
   linkedinProfile.first_name ?  <Box
      sx={{
        width: "auto",
        margin: "auto",
        marginTop: "20px",
        padding: "20px",
        borderRadius: "10px",
        //boxShadow: 3,
        //bgolor: "#f3f2ef",
      }}
    >
      <Card
        key={linkedinProfile.id}
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          boxShadow: 1,
          p:1
        }}
        className="glass-wrapper-25"
      >
        {isFetching ? (
          <Skeleton variant="circular" width={40} height={40} sx={{ margin: "10px" }} />
        ) : !linkedinProfile?.profile_picture_url ? (
          <Avatar sx={{ bgcolor: "#0a66c2", margin: "10px" }}>
            {linkedinProfile?.name?.charAt(0)}
          </Avatar>
        ) : (
          <Avatar
            alt="User"
            src={linkedinProfile?.profile_picture_url ?? "https://www.w3schools.com/howto/img_avatar.png"}
            sx={{ width: 32, height: 32, margin: "10px" }}
          />
        )}

        <Box sx={{ flex: 1 }}>
          {isFetching ? (
            <Skeleton variant="text" width="80%" height={20} />
          ) : (
            <CardHeader
              title={linkedinProfile.first_name + " " + linkedinProfile.last_name}
              subheader={linkedinProfile.headline}
              sx={{ padding: "0" }}
            />
          )}
        </Box>

        <CardActions>
          {linkedinProfile?.invitation?.status == "WITHDRAWN" ? (
            <Button variant="text" disabled>
              Cancelled
            </Button>
          ) :linkedinProfile?.invitation?.status == "IGNORED" ? (
            <Tooltip title={`Invitation Ignored by ${linkedinProfile.first_name} ${linkedinProfile.last_name}`} arrow>
            <Typography variant="text" color={"error"} fontWeight={700}>
              Rejected
            </Typography>
            </Tooltip>
          ) : (
            linkedinProfile?.invitation?.status == "PENDING" && <Button
              variant="outlined"
              size="small"
              onClick={() => handleCancelInvitation()}
              disabled={isLoading}
              startIcon={
                isLoading && (
                  <CircularProgress size={16} color="inherit" />
                )
              }
            >
              Withdraw
            </Button>
          )}

          {
            linkedinProfile.is_relationship && (
              <Tooltip title="Invitation Accepted" arrow>
                <Typography variant="text" disabled>
                  <TaskAlt sx={{ color: "success" }} />
                </Typography>
              </Tooltip>
            )
          }
          {
            !linkedinProfile?.invitation?.status && !linkedinProfile.is_relationship && (
              <Typography>
                Declined
              </Typography>
            )
          }
        </CardActions>
      </Card>
      {withMessage && <Typography
        sx={{
          fontSize: "16px",
          color: "text.secondary",
          marginBottom: "10px",
        }}

        disabled
      >
        <div
          dangerouslySetInnerHTML={{
            __html: linkedinData?.email?.html,
          }}
        />
      </Typography>}
    </Box> : <Box
      sx={{
        width: "400px",
        margin: "auto",
        marginTop: "20px",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: 3,
        bgolor: "#f3f2ef",
      }}
      className="glass-wrapper-25"
    >
      <Typography
        sx={{
          fontSize: "16px",
          color: "text.primary",
          marginBottom: "10px",
          textAlign: "center"
        }}
        disabled
      >
        Invitation Not Sent Yet
      </Typography>
    </Box>
  );
};

export const SequenceStepTimeline = (props) => {
  const { emailSeqStatus, steps, lead } = props;
  const getStepContent = (step, emailSeqStatus, index) => {
    switch (step?.type) {
      case "email.scratch":
        return <SequenceStepEmailContent
          template={step?.variants?.value[0]?.template}
          content={emailSeqStatus?.initial?.prospect[`${index + 1}`]}
        />
      case "in.message":
        return <SequenceStepLinkedinContent
          template={step?.variants?.value[0]?.template}
          providers={lead?.multiChannel}
          linkedinURL={lead?.linkedin_url}
          content={emailSeqStatus?.in_message?.prospect[`${index + 1}`]}

        />
      case "in.connect":
        return <LinkedInConnectionRequest
          providers={lead?.multiChannel}
          linkedinURL={lead?.linkedin_url}
          template={step?.variants?.value[0]?.template}
          withMessage={step?.withMessage}
          content={emailSeqStatus?.in_connect?.prospect[`${index + 1}`]}
        />
      default:
        return null;
    }
  };

  const isStepCompleted = (step, emailSeqStatus, index) => {
    switch (step.type) {
      case "email.scratch":
        return emailSeqStatus?.initial?.prospect[`${index + 1}`];
      case "in.message":
        return emailSeqStatus?.in_message?.prospect[`${index + 1}`];
      case "in.connect":
        return emailSeqStatus?.in_connect?.prospect[`${index + 1}`];
      default:
        return false;
    }
  }
  return (
    <Stack 
      spacing={3} 
      gap={3} 
      direction="column" 
      className="contentBorder glass-wrapper-50"
    >

      {steps.map((step, index) => {
        // if (step.isDeleted) return;
        return (
          <>
            {" "}
            <StepItem
              key={index}
              stepTitle={step?.label ?? `Step ${index + 1}`}
              stepText={`${convertSecondsToUnitTime(step.nextStep.delay.seconds, step.nextStep.delay.unit)} ${step.nextStep.delay.unit.toUpperCase()} AFTER PREVIOUS EMAIL`}
              stepDelayUnit={step.nextStep.delay.unit ?? "days"}
              stepContent={getStepContent(step, emailSeqStatus, index)}
              completed={isStepCompleted(step, emailSeqStatus, index)}
              deleted={step?.isDeleted}
            />
            {index < steps.length - 1 && (
              <Divider sx={{ "&.MuiDivider-root": { my: 0 } }} />
            )}
            
          </>
        )
      })}
    </Stack>
  );
};
