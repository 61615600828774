import React from "react";
import { Box, FormLabel, TextField, Switch, styled, Select, MenuItem } from "@mui/material";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 38,
  height: 20,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#296cf1",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    width: 16,
    height: 16,
    backgroundColor: "#ffffff",
  },
  "& .MuiSwitch-track": {
    borderRadius: 20 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
  },
}));

export const TextInput = ({ label, name, value, onChange }) => (
  <Box sx={{ display: "flex", alignItems:'center', gap: 1 ,}}>
    <FormLabel sx={{ fontSize: "12px", fontWeight: 700, whiteSpace:"nowrap" }}>
      {label}
    </FormLabel>
    <TextField
      name={name}
      value={value}
      className="glass-input"
      onChange={onChange}
      fullWidth
      InputProps={{
        sx: {
          "& .MuiOutlinedInput-input": {
            py: "8.5px", // Adjust vertical padding
          },
        },
      }}
      variant="outlined"
    />
  </Box>
);

export const NumberInput = ({ label, name, value, onChange }) => (
  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
    <FormLabel sx={{ fontSize: "12px", fontWeight: 700 }}>
      {label}
    </FormLabel>
    <TextField
      name={name}
      value={value}
      className="glass-input"
      onChange={onChange}
      type="number"
      fullWidth
      InputProps={{
        inputMode: "numeric",
        pattern: "[0-9]*",
        sx: {
          "& .MuiOutlinedInput-input": {
            py: "8.5px", // Adjust vertical padding
            appearance: "textfield", // Remove arrows in WebKit browsers
          },
          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "& input[type=number]": {
            MozAppearance: "textfield", // Remove arrows in Firefox
          },
          
        },
      }}
      variant="outlined"
    />
  </Box>
);

export const SwitchInput = ({ label, name, checked, onChange }) => (
  <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
    <IOSSwitch checked={checked} onChange={onChange} name={name} color="primary" />
    <FormLabel sx={{ fontSize: "12px", fontWeight: 700 }}>
      {label}
    </FormLabel>
  </Box>
);

export const SelectInput = ({ label, name, value, onChange, options }) => (
    <Box  sx={{ display: "flex",  alignItems:'center', gap: 1 }}>
      <FormLabel sx={{ fontSize: "12px",whiteSpace:"nowrap" , fontWeight: 700 }}>
        {label}
      </FormLabel>
      <Select
        name={name}
        value={value}
        className="glass-dropdown"
        onChange={onChange}
        fullWidth
        size="small"
        displayEmpty
      >
        {options?.map((item, index) => (
          <MenuItem key={index} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );