import React, { useState } from "react";
import { Popover, Box, Button, Typography, useTheme, alpha } from "@mui/material";
import { ExpandMoreOutlined } from "@mui/icons-material";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";

const timelineButtons = [
  {
    name: "Today",
    value: {
      // start: new Date().setHours(0, 0, 0, 0),
      // end: new Date().setHours(23, 59, 59, 999),
      start: Date.now(),
      end: Date.now(),
    },
  },
  {
    name: "Yesterday",
    value: {
      // start: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0),
      // end: new Date(new Date().setDate(new Date().getDate() - 1)).setHours(23, 59, 59, 999),
      start: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
      end: new Date(new Date().getTime() - 24 * 60 * 60 * 1000),
    },
  },
  {
    name: "Past 7 days",
    value: {
      start: new Date().setDate(new Date().getDate() - 7),
      end: Date.now(),
    },
  },
  {
    name: "Month to date",
    value: { start: new Date().setDate(1), end: Date.now() },
  },
  {
    name: "Past 4 weeks",
    value: {
      start: new Date().setDate(new Date().getDate() - 28),
      end: Date.now(),
    },
  },
  {
    name: "Past 3 months",
    value: {
      start: new Date().setMonth(new Date().getMonth() - 3),
      end: Date.now(),
    },
  },
  {
    name: "Past 6 months",
    value: {
      start: new Date().setMonth(new Date().getMonth() - 6),
      end: Date.now(),
    },
  },
  {
    name: "Past 12 months",
    value: {
      start: new Date().setMonth(new Date().getMonth() - 12),
      end: Date.now(),
    },
  },
  {
    name: "Custom",
    value: {
      start: "",
      end: "",
    },
  },
];

const TimelineDropdown = ({
  setDateRange,
  height = "40px",
  handleCustomRange,
  displayAll,
  loader,
  selectedTimeline,
  setSelectedTimeline,
}) => {
  const theme = useTheme();
  const emptyValue = {
    name: "Date Range",
    value: {
      start: "",
      end: "",
    },
  };
  const [timeline, setTimeline] = useState(
    displayAll ? selectedTimeline || emptyValue : timelineButtons[2]
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const spanRef = React.useRef();

  React.useEffect(() => {
    if (selectedTimeline) {
      setTimeline(selectedTimeline);
    }
  }, [selectedTimeline]);

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      <Button
        ref={spanRef}
        size="small"
        disabled={loader}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "left",
          fontSize: { xs: "12px", sm: "14px" }, // Adjust font size
          fontWeight: 500,
          lineHeight: "16.38px",
          color: theme.palette.text.primary,
          backgroundColor: "#fff",
          whiteSpace: "nowrap",
          px: { xs: 1, sm: 1.5 }, // Adjust padding
          mr: { xs: 0.5, sm: 1.3 },
          minWidth: { xs: "100px", sm: "auto" }, // Ensure button doesn't shrink too much
          "&:hover": {
            backgroundColor: "#fff",
          },
          border: `2px solid ${open ? theme.palette.primary.main : "#E4E4E5"}`,
          height,
        }}
        onClick={handleClick}
        startIcon={<CalendarTodayRoundedIcon />}
        className="glass-dropdown"
      >
        {timeline?.name}
        <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
          <ExpandMoreOutlined />
        </Box>
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          mt: 0.5,
          maxWidth: "90vw", // Prevents overflow on small screens
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 1.1,
            width: { xs: "160px", sm: "200px" }, // Adjust width dynamically
          }}
        >
          {timelineButtons.map((item, i) => (
            <Button
              key={i}
              fullWidth
              sx={{
                py: 1,
                px: 2,
                borderRadius: "5px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                color: theme.palette.text.primary,
                fontSize: "14px",
                fontWeight: "500",
                backgroundColor:
                  timeline?.name === item.name &&
                  alpha(theme.palette.primary.main, 0.1),
                "&:hover": {
                  backgroundColor: alpha(theme.palette.primary.main, 0.15),
                },
              }}
              onClick={() => {
                setTimeline(item);
                if (setSelectedTimeline) {
                  setSelectedTimeline(item);
                }
                if (item.name === "Custom") {
                  handleCustomRange(spanRef);
                } else {
                  setDateRange({
                    from: new Date(item.value.start),
                    to: new Date(item.value.end),
                  });
                }
                setAnchorEl(null);
              }}
            >
              {item.name}
            </Button>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};


export default TimelineDropdown;
