import { Box, ButtonBase, Stack, Tooltip, Typography } from "@mui/material";
import { RouterLink } from "src/components/router-link";

const badgeMap = {
  Calendar: "unseenCount",
  "AI Agents Conversations": "unreadCount",
};

const SideNavItemNew = ({
  active,
  disabled,
  external,
  icon,
  path,
  title,
  options,
  index,
  expanded
}) => {
  const badgeCount = options?.[badgeMap[title]];
  const linkProps = path
    ? external
      ? {
          component: "a",
          href: path,
          target: "_blank",
        }
      : {
          component: RouterLink,
          href: path,
          state: { prev: path },
          key: title,
        }
    : {};

  return (
    <li key={index} style={{ listStyleType: "none" }}>
      <ButtonBase
        disableTouchRipple
        disabled={disabled}
        sx={{
          alignItems: "center",
          borderRadius: 1,
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          ...(active && {
            backgroundColor: "var(--nav-item-active-bg)",
            color: "var(--nav-item-active-color)",
          }),
          "&:hover": {
            ...(!active && {
              backgroundColor: "var(--nav-item-hover-bg)",
              color: "var(--nav-item-active-color)",
            }),
            textDecoration: "none",
          },
          "&:active, :focus": {
            textDecoration: "none",
          },
        }}
        {...linkProps}
      >
        <Box
          component="span"
          sx={{
            alignItems: "center",
            display: "inline-flex",
            justifyContent: "flex-start",
            pl: "2px",
            gap: 2,
            color: "var(--nav-item-icon-color)",
            ...(active && {
              color: "var(--nav-item-icon-active-color)",
            }),
            "& .MuiSvgIcon-root": {
              width: 20,
              height: 20,
            },
          }}
        >
          <Tooltip title={expanded ? "" : title} placement="right" arrow>
            {icon}
          </Tooltip>
          {expanded && <Typography sx={{color: "inherit", fontWeight: 500, lineHeight: 1.2}}>{title}</Typography>}
        </Box>

        {Boolean(badgeCount) && (
          <Box component="span">
            <Stack
              ml={1}
              justifyContent="center"
              alignItems="center"
              style={{
                opacity: 1,
                fontSize: "10px",
                height: "20px",
                width: "20px",
                color: "white",
                borderRadius: "50%",
                backgroundColor: "#007ad4",
              }}
            >
              <b>{badgeCount}</b>
            </Stack>
          </Box>
        )}
      </ButtonBase>
    </li>
  );
};

export default SideNavItemNew;
