import axios from 'axios';
import { API_SERVICE_BACKEND_ANALYTICS_DATA } from 'src/config';

export const getAnalyticsData = async (userId) => {
  try {
    const response = await axios.get(`${API_SERVICE_BACKEND_ANALYTICS_DATA}/api/track/user/${userId}`);
    console.log(userId,"==============================",response)
    return response.data;
  } catch (error) {
    console.error('Error fetching analytics data:', error);
    throw error;
  }
};

export const getVisitorsByCountry = async (userId) => {
  try {
    const response = await axios.get(`${API_SERVICE_BACKEND_ANALYTICS_DATA}/api/track/map/${userId}`);
    console.log(userId,"==============================",response)
    return response.data.data;
  } catch (error) {
    console.error('Error fetching visitors by country data:', error);
    throw error;
  }
};

export const addDomain = async (userId, domain) => {
  try {
    const response = await axios.post(`${API_SERVICE_BACKEND_ANALYTICS_DATA}/api/domain/add-domain`, {
      userId,
      domain
    });
    return response.data;
  } catch (error) {
    console.error('Error adding domain:', error);
    throw error;
  }
};

export const verifyDomain = async (userId, domain) => {
  try {
    const response = await axios.post(`${API_SERVICE_BACKEND_ANALYTICS_DATA}/api/domain/verify-domain`, {
      userId,
      domain
    });
    return response.data;
  } catch (error) {
    console.error('Error verifying domain:', error);
    throw error;
  }
};

export const getDomains = async (userId) => {
  try {
    const response = await axios.get(`${API_SERVICE_BACKEND_ANALYTICS_DATA}/api/domain/get-domains/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching domains:', error);
    throw error;
  }
};
export const getVisitorsByDays = async (userId, days) => {
  try {
    const response = await axios.get(`${API_SERVICE_BACKEND_ANALYTICS_DATA}/api/track/visitors/${userId}/${days}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching visitors by days:', error);
    throw error;
  }
};
