import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Typography, useTheme } from "@mui/material";

const PlanShow = ({ value, label }) => {
  const theme = useTheme();
  const numberFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
      }}
    >
      {value !== 0 || typeof value === "boolean" ? (
        <CheckCircleIcon sx={{ color: "#18bf29" }} />
      ) : (
        <CancelIcon sx={{ color: "#e51818" }} />
      )}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
        }}
      >
        <Typography
          sx={{
            color: theme.palette.text.primary,
            fontSize: "14px",
            fontWeight: "700",
          }}
        >
          {typeof value === "boolean"
            ? ""
            : value !== null
            ? numberFormatter.format(value)
            : ""}{" "}
          {label}
        </Typography>
      </div>
    </div>
  );
};

export default PlanShow;
