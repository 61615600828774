import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Stack, Grid } from "@mui/material";
import { ImageLayout } from "src/pages/auth-demo/ImageLayout";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
// import LeadinglyLogo from '../../assets/logo-lg.83418505.png';
import B2BRocketLogo from "../../assets/b2b rocket logo blue cropped.png";
import "./style.css";
import BgImage from "../../assets/login-bg.png";
import { useContext, useEffect, useState } from "react";
import { WhiteLabelConfigurationContext } from "src/contexts/ClientConfiguration";
import { getSessionStorage } from "src/utils/storage";
import { SettingsContext } from "src/contexts/settings-context";

export const Layout = (props) => {
  const { children } = props;
  let location = useLocation();
  let navigate = useNavigate();

  const currentUrl = location.pathname;
  const { logoUrl, isLogin } = useContext(WhiteLabelConfigurationContext);

  const email = getSessionStorage("userEmail");
  const ownerEmail = getSessionStorage("ownerEmail");

  const getDomainFromLocation = () => {
    const domain = window.location.hostname;
    return domain;
  };

  const domain = getDomainFromLocation();

  useEffect(() => {
    if (email && !ownerEmail) {
      navigate("/dashboard/campaigns");
    }
  }, [email]);

  const {isNewInterfaceEnabled} = useContext(SettingsContext);

  const isB2bDomain = (domain === "app.b2brocket.ai" || domain === "localhost");

  return (
    <Grid
      container
      sx={{
        backgroundColor: "background.default",
        backgroundImage: isNewInterfaceEnabled ? (isB2bDomain ? "none" : `url(${BgImage})`) : "none",
        backgroundPosition: "center",
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
        overflow: currentUrl.includes("login") ? "hidden" : "auto",
        // display: "flex",
        // flex: "1 1 auto",
        // flexDirection: {
        //   xs: "column-reverse",
        //   md: "row",
        // },
      }}
    >
      <Grid
        item
        xs={12}
        md={isB2bDomain ? 7 : 12}
        sx={{
          //backgroundColor: "background.paper",
          display: "flex",
          // flex: {
          //   xs: "1 1 auto",
          //   md: "0 0 auto",
          // },
          flexDirection: "column",
          justifyContent: {
            xs: "center",
          },
          alignItems: "center",
          maxWidth: "100%",
          // pt: 2,
          // pb: 0,
          // pl: 25,
          // pr: 4,
          width: {
            //md: 650,
            xs: "100%",
          },
        }}
        className="loginContainer"
      >
        <Box className={isB2bDomain ? "" : "glass-wrapper"} sx={{ width: { xs: "100%", sm: "80%", md: isB2bDomain ? "50%" : "30%" }, p: isB2bDomain ? 0 : 2 }}>
          {!isLogin && (
            <Box>
              <Stack
                alignItems="center"
                component={RouterLink}
                direction="row"
                display="inline-flex"
                href={paths.index}
                spacing={2}
                sx={{ textDecoration: "none" }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    height: 44,
                    width: 44,
                    marginBottom: "20px",
                  }}
                >
                  <img src={logoUrl} alt="Logo" />
                </Box>
              </Stack>
            </Box>
          )}
          {children}
        </Box>
      </Grid>

      {isB2bDomain && <Grid
        item
        xs={12}
        md={5}
        className="imageContainer"
        sx={{
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          color: "common.white",
          display: { xs: "none", md: "flex" },
          justifyContent: "right",

          // flex: {
          //   xs: "0 0 auto",
          //   lg: "2 2 auto",
          // },
          // p: {
          //   xs: 8,
          //   md: 0,
          // },
        }}
      >
        <ImageLayout currentUrl={currentUrl} />
      </Grid>}
    </Grid>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};
